import { bindActionCreators } from 'redux';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { openCompanyArtFolder } from '../company-art-folder/company-art-folder.actions';
import { setNotValidatedEmbellishmentUpdate } from '../product/product.actions';
import { handleUploadNewLogo, onPantoneSelectionConfirm, handlePantoneSelectionConfirm, revertToOriginalPreview, convertLogoToOneColor, confirmDtg, addColor, removeColor, cancelUploadingAnimation } from './logo-editing.actions';
export const maxPrintColorsLimitPopupDispatcher = dispatch => ({
  onColorRemove: color => dispatch(removeColor(color)),
  onColorAdd: color => dispatch(addColor(color)),
  onContinueClick: () => dispatch(handlePantoneSelectionConfirm()),
  onUploadNewLogo: (file, view) => dispatch(handleUploadNewLogo(file, view)),
  cancelUploading: () => dispatch(cancelUploadingAnimation()),
  onSelectLogoFromList: view => dispatch(openCompanyArtFolder(view)),
  onClose: () => {
    dispatch(hidePopup());
    dispatch(setNotValidatedEmbellishmentUpdate({
      embellishmentId: null
    }));
  }
});
export const convertToOneColorDispatcher = dispatch => bindActionCreators({
  onColorChange: convertLogoToOneColor,
  onContinueClick: onPantoneSelectionConfirm,
  onRevertOriginalPreviewClick: revertToOriginalPreview
}, dispatch);
export const confirmDtgDispatcher = dispatch => ({
  onConfirm: () => {
    dispatch(confirmDtg());
  },
  onUploadAnotherLogo: file => {
    dispatch(handleUploadNewLogo(file[0], file[1]));
  },
  cancelUploading: () => {
    dispatch(cancelUploadingAnimation());
  }
});