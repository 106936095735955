function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { Price } from 'swag-client-common/utils/price';
import pickSkuForLineItem from 'swag-common/business-logic/order/pick-sku-for-line-item.logic';
import uuidv1 from 'uuid/v1';
import { PRODUCTION_TIME_STANDARD_INDEX, FULL_DESIGN_DEFAULT_VARIANT_INDEX } from 'swag-common/constants/product.constants';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import getSetupFeeDeductionDiscount from 'swag-common/business-logic/order/get-setup-fee-deduction-discount.logic';
import { dynamicQuantityAndLocationsOptionsForDecorationMethodsSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { getProductInStockLevelsByProductIdAndVariantSku, getIsAlwaysInStock, getInStockVariants, getVisibleProductVariants, getIsVariantInStock, getSupplierOutOfStockThresholdsBySupplierId, getProductSupplierStockLevelsByProductIdAndVariantSku } from 'swag-client-common/utils/product.utils';
import { productsSelector } from '../products/products.selectors';
import { checkIsNonPSOutOfStock } from '../../../customer/redux/product/product.utils';
import { getSizeIndex, getVariantIndexesInCustomStore } from './order-more.selectors.utils';
export const orderMoreRootSelector = state => state.orderMore;
export const selectedProductIdSelector = createSelector(orderMoreRootSelector, orderMore => orderMore.selectedProductId);
export const variantsSelector = createSelector(orderMoreRootSelector, orderMore => orderMore.variants);
export const variantIndexesSelector = createSelector(variantsSelector, variants => variants.map(variant => variant.variantIndex));
export const productInStockLevelsByProductIdAndVariantSkuSelector = createSelector(orderMoreRootSelector, state => getProductInStockLevelsByProductIdAndVariantSku({
  supplierInventoryLevels: state.supplierInventoryLevels
}));
export const productSupplierStockLevelsByProductIdAndVariantSkuSelector = createSelector(orderMoreRootSelector, state => getProductSupplierStockLevelsByProductIdAndVariantSku(state.supplierInventoryLevels));
export const supplierInventoryLevelsSelector = createSelector(orderMoreRootSelector, state => state.supplierInventoryLevels || []);
export const supplierThresholdByIdSelector = createSelector(orderMoreRootSelector, orderMore => getSupplierOutOfStockThresholdsBySupplierId(orderMore.suppliers));
export const productListAvailableSizesSelector = createSelector(orderMoreRootSelector, orderMore => orderMore.productListAvailableSizes);
export const selectedProductSelector = createSelector(productsSelector, selectedProductIdSelector, productListAvailableSizesSelector, (inventoryProducts, selectedId, productListAvailableSizes) => {
  const selectedProduct = inventoryProducts.find(({
    _id
  }) => _id === selectedId);
  if (selectedProduct) {
    const correctSizeSettings = productListAvailableSizes.find(({
      _id
    }) => {
      var _selectedProduct$swag;
      return _id && _id === ((_selectedProduct$swag = selectedProduct.swagProductId) === null || _selectedProduct$swag === void 0 ? void 0 : _selectedProduct$swag.size);
    });
    if (correctSizeSettings) {
      selectedProduct.sizeSettings = correctSizeSettings;
    }
  }
  return selectedProduct;
});
export const swagProductSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
product => product.swagProductId);
export const initialCreationDateSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
product => product.initialCreationDate);
export const productInStockLevelsByVariantSkuSelector = createSelector(swagProductSelector, productInStockLevelsByProductIdAndVariantSkuSelector, (product, productInStockLevelsByProductIdAndVariantSku) => productInStockLevelsByProductIdAndVariantSku[product._id] || {});
export const availableColorsSelector = createSelector(swagProductSelector, productInStockLevelsByVariantSkuSelector, supplierThresholdByIdSelector, selectedProductSelector, (realProduct, productInStockLevelsByVariantSku, supplierThresholdById, selectedProduct) => {
  // @todo. auto generated. fix this error
  // @ts-ignore
  const existingVariantsByColor = selectedProduct.variants.reduce((acc, item) => {
    acc[item.colorValue] = item.colorValue;
    return acc;
  }, {});
  const isLE = realProduct.limitedEdition.isLimitedEdition;
  const allVariants = realProduct.variants.reduce((acc, item, index) => {
    if (existingVariantsByColor[item.color]) {
      return acc;
    }
    // @todo. auto generated. fix this error
    // @ts-ignore
    acc.push(_objectSpread({}, item, {
      variantIndex: index
    }));
    return acc;
  }, []);
  const isAlwaysInStock = getIsAlwaysInStock(realProduct);
  return getInStockVariants({
    variants: getVisibleProductVariants({
      variants: allVariants
    }),
    isAlwaysInStock,
    productInStockLevelsByVariantSku,
    outOfStockThreshold: 0,
    isLE
  });
});
export const minQuantitySelector = createSelector(swagProductSelector, selectedProductSelector, dynamicQuantityAndLocationsOptionsForDecorationMethodsSelector, ({
  productSettings,
  minQuant: productMinQuant
}, selectedProduct, isDynamicQuantityAndLocationsOptionsForDecorationMethods) => {
  let minQuant = productMinQuant !== null && productMinQuant !== void 0 ? productMinQuant : 0;
  if (isDynamicQuantityAndLocationsOptionsForDecorationMethods) {
    var _productSettings$find;
    const settings = (_productSettings$find = productSettings === null || productSettings === void 0 ? void 0 : productSettings.find(settings => settings.embellishmentMethodId === (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.embellishmentMethod))) !== null && _productSettings$find !== void 0 ? _productSettings$find : {
      minQuant: 0
    };
    minQuant = settings.minQuant;
  }
  return minQuant;
});
export const sizeSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
({
  sizeSettings
}) => sizeSettings);
export const selectedSizeIndexSelector = createSelector(
// @todo. auto generated. fix this error
// @ts-ignore
selectedProductSelector, getSizeIndex);
export const isFullDesignSelector = createSelector(swagProductSelector, product => isProductFullDesign(product));
export const isSupplierStockLevelsLoadingSelector = createSelector(orderMoreRootSelector, state => state.isSupplierStockLevelsLoading);
export const isSelectedProductABoxSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
product => isBox(product.swagProductId));
export const variantIndexesInCustomStoreSelector = createSelector(selectedProductSelector, isFullDesignSelector, isSelectedProductABoxSelector,
// @todo. auto generated. fix this error
// @ts-ignore
getVariantIndexesInCustomStore);
export const swagProductVariantsSelector = createSelector(swagProductSelector, isFullDesignSelector, ({
  num,
  variants
}, isFullDesignMode) => {
  if (isFullDesignMode) {
    return [{
      color: '#ffffff',
      name: 'custom-design',
      productSku: num,
      // @todo. auto generated. fix this error
      // @ts-ignore
      colorFamily: null,
      // @todo. auto generated. fix this error
      // @ts-ignore
      images: null,
      isHidden: false,
      sizeSkus: {},
      isAlwaysInStockVariant: false,
      variantId: uuidv4()
    }];
  }
  return variants;
});
export const variantsWithQuantitiesSelector = createSelector(swagProductVariantsSelector, variantsSelector, isFullDesignSelector, isSelectedProductABoxSelector, swagProductSelector, productInStockLevelsByVariantSkuSelector, (swagProductVariants, variants, isFullDesign, isBox, realProduct, productInStockLevelsByVariantSku) => {
  if (isFullDesign || isBox) {
    var _variants$find;
    const isVariantInStock = isFullDesign ? getIsVariantInStock({
      variant: swagProductVariants[0],
      outOfStockThreshold: 0,
      productInStockLevelsByVariantSku
    }) : true;
    const quantities = !isVariantInStock ? [0] : ((_variants$find = variants.find(variant => variant.variantIndex === FULL_DESIGN_DEFAULT_VARIANT_INDEX)) === null || _variants$find === void 0 ? void 0 : _variants$find.quantities) || [];
    return [_objectSpread({}, swagProductVariants[0], {
      variantIndex: FULL_DESIGN_DEFAULT_VARIANT_INDEX,
      sizeSkus: {},
      isVariantInStock,
      quantities
    })];
  }
  const isAlwaysInStock = getIsAlwaysInStock(realProduct);
  return variants.map(variant => {
    const isVariantInStock = isAlwaysInStock || getIsVariantInStock({
      variant: swagProductVariants[variant.variantIndex],
      outOfStockThreshold: 0,
      productInStockLevelsByVariantSku
    });
    const quantities = !isVariantInStock ? [0] : variant.quantities;
    return _objectSpread({}, swagProductVariants[variant.variantIndex], variant, {
      isVariantInStock,
      quantities
    });
  });
});
export const totalQuantitySelector = createSelector(variantsWithQuantitiesSelector, variants => {
  return variants.reduce((totalQuantity, variant) => {
    return totalQuantity + variant.quantities.reduce((sum, quantity) => sum + quantity, 0);
  }, 0);
});
export const logosSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
({
  logos
}) => logos);
export const variantsToOrderSelector = createSelector(variantsWithQuantitiesSelector, variants => {
  return variants.filter(({
    quantities
  }) => quantities && quantities.some(q => q > 0)).map(({
    variantIndex,
    quantities
  }) => ({
    variantIndex,
    quantities
  }));
});
export const productSkuSelector = createSelector(swagProductSelector, variantsToOrderSelector, pickSkuForLineItem);
const DEFAULT_MINIMUM_COLORS_NUMBER = 1;
export const colorsAmountSelector = createSelector(logosSelector, isFullDesignSelector, isSelectedProductABoxSelector, (logos = {}, isFullDesign, isBox) => {
  if (isFullDesign || isBox) {
    // always count design as 1 color print, unless there is no design uploaded
    return DEFAULT_MINIMUM_COLORS_NUMBER;
  }
  const colorsOfLogos = Object.keys(logos).reduce((colorsSum, location) => {
    // @ts-ignore
    const number = logos[location].reduce((colorsSumPerLocation, nextLogo) => nextLogo.colors.colorsNumber + colorsSumPerLocation, 0);
    return number + colorsSum;
  }, 0);
  return colorsOfLogos;
});
export const embellishmentMethodSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
({
  embellishmentMethod
}) => embellishmentMethod);
export const isCustomizedBlankSelector = createSelector(selectedProductSelector, product => {
  // @todo. auto generated. fix this error
  // @ts-ignore
  return product.variants.some(variant => variant.isCustomizedBlank);
});
export const textsSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
({
  texts
}) => texts || {});
export const textImagesSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
({
  textImages
}) => {
  return textImages && Object.keys(textImages).reduce((allImages, location) => {
    return [...allImages, ...textImages[location]];
  }, []);
});
const reorderIdSelector = createSelector(selectedProductSelector,
// @todo. auto generated. fix this error
// @ts-ignore
({
  reorderId
}) => reorderId);

// @todo. auto generated. fix this error
// @ts-ignore
const itemIdSelector = createSelector(selectedProductSelector, ({
  itemId
}) => itemId);
export const orderMoreRegularItemSelector = createSelector(swagProductSelector, selectedProductIdSelector, variantsToOrderSelector, productSkuSelector, selectedSizeIndexSelector, totalQuantitySelector, logosSelector, colorsAmountSelector, embellishmentMethodSelector, textsSelector, textImagesSelector, sizeSelector, isCustomizedBlankSelector,
// @ts-ignore
reorderIdSelector, itemIdSelector, initialCreationDateSelector, (swagProduct, productId, variants, itemSku, sizeInd, quantity, logos, colorsAmount, embellishmentMethod, texts, textImages, size, isCustomizedBlank, reorderId, itemId, initialCreationDate) => {
  const {
    _id: prodId
  } = swagProduct;
  const orderItem = {
    prodId,
    variants,
    itemSku,
    texts,
    textImages,
    quantity,
    isCustomizedBlank,
    sizeInd: sizeInd !== -1 ? sizeInd : 0,
    logos: logos || {},
    discount: false,
    prod: _objectSpread({}, swagProduct, {
      size
    }),
    prodTime: PRODUCTION_TIME_STANDARD_INDEX,
    isReorder: true,
    isSample: false,
    isPrintedSample: false,
    asRelatedItemId: uuidv1(),
    blendedQuantity: quantity,
    customProductId: productId,
    customProductCreated: true,
    colors: colorsAmount,
    price: 0,
    initialCreationDate
  };
  orderItem.setupFeeDeductionDiscount = getSetupFeeDeductionDiscount(orderItem);
  orderItem.price = Price.getPrice({
    orderItem: orderItem,
    product: swagProduct
  });
  if (embellishmentMethod) {
    orderItem.embellishmentMethod = embellishmentMethod;
  }
  if (swagProduct.variantDependentPrice && !isBox(swagProduct)) {
    orderItem.variantInd = variants[0].variantIndex;
  }
  if (reorderId) {
    orderItem.reorderId = reorderId;
  }
  if (itemId) {
    orderItem.itemId = itemId;
  }
  return [orderItem];
});
export const selectedVariantsInStockLevelsSelector = createSelector(swagProductVariantsSelector, variantIndexesInCustomStoreSelector, productInStockLevelsByVariantSkuSelector, sizeSelector, supplierInventoryLevelsSelector, (variants, selectedVariants, stockLevelsByVariantSku, size, supplierInventoryLevels) => {
  const isNonPSOutOfStock = checkIsNonPSOutOfStock({
    supplierInventoryLevels
  });
  return selectedVariants.map(i => {
    const {
      sizeSkus,
      isAlwaysInStockVariant,
      productSku
    } = variants[i] || {};
    if (!sizeSkus || isAlwaysInStockVariant) {
      return;
    }
    if (!size || !size.options) {
      return;
    }
    return size.options.map(size => {
      const sku = isNonPSOutOfStock ? productSku : sizeSkus[size];
      return stockLevelsByVariantSku[sku];
    });
  });
});
export const outOfStockSizesSelector = createSelector(selectedVariantsInStockLevelsSelector, selectedVariantsInStockLevels => getOutOfStockSizes(selectedVariantsInStockLevels));
export const getOutOfStockSizes = selectedVariantsInStockLevels => selectedVariantsInStockLevels.reduce((acc, item) => {
  const values = new Set();
  if (item) {
    item.forEach((count, index) => {
      if (count === 0) {
        values.add(index);
      }
    });
  }
  acc.push(values);
  return acc;
}, []);
export const productSizeSettingsByIdSelector = sizeSettingsId => createSelector(productListAvailableSizesSelector, sizeSettingsList => sizeSettingsList.find(({
  _id
}) => _id === sizeSettingsId));