import _head from "lodash/head";
import _get from "lodash/get";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { createSelector, createStructuredSelector } from 'reselect';
import { Price } from 'swag-client-common/utils/price';
import { PRODUCT_BUILDER_MODES } from 'swag-client-common/utils/constants';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { getProductOutOfStockThreshold } from 'swag-common/utils/product/product.utils';
import { getProductInStockLevelsByVariantSku } from 'swag-common/utils/product/product.utils';
import getNotificationText from 'swag-common/utils/product/get-notification-text-for-product-quantity.util';
import { featureTogglesSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { getItemProd } from 'swag-common/utils/items/get-item-prod';
import { currentVariantLocationsSelector as mainProductCurrentVariantLocationsSelector, productViewsSelector as mainProductViewsSelector, embellishmentMethodIdSelector, logosByLocationSelector, logosColorsAmountSelector } from '../product/product.selectors';
import { textsSelector } from '../logo/logo.selectors';
import { calculatePriceBreaks, getColorsNumberForItem, getCurrentImages, getCurrentVariantImages, getPricesWithQuantity, getRelatedProductActiveSettings } from '../product/product.utils';
import { MIN_RELATED_AMOUNT } from './product.utils';
import { selectedSplitColorsTooltip, unselectedSplitColorsTooltip } from './product.constants';
export const cartItemsSelector = state => state.cart.items;
export const parentProductIdSelector = state => state.product.product._id;
export const relatedProductSelector = state => state.relatedProduct;
export const productSelector = state => state.relatedProduct.product;
export const productVariantsSelector = state => state.relatedProduct.product.variants || [];
export const productLoadedSelector = state => state.relatedProduct.productLoaded;
export const sizeLoadedSelector = state => state.relatedProduct.sizeLoaded;
export const productIdSelector = state => state.relatedProduct.product._id;
export const serviceCodeSelector = state => state.relatedProduct.serviceCode;
export const isFullDesignProductSelector = state => isProductFullDesign(state.relatedProduct.product);
export const imagesWithoutImprintAreaSelector = state => state.relatedProduct.product.imagesWithoutImprintArea;
export const variantIndexSelector = state => state.relatedProduct.variantIndex;
export const sizeIndexSelector = state => state.relatedProduct.sizeIndex;
export const viewTypeSelector = state => state.relatedProduct.view;
export const designNotesSelector = state => state.relatedProduct.designNotes;
export const discountSelector = state => state.relatedProduct.discount;
export const selectedProductionTimeSelector = state => state.relatedProduct.selectedProductionTime;
export const sizeSelector = state => state.relatedProduct.size;
export const itemIdSelector = state => state.relatedProduct.itemId;
export const orderModeSelector = state => state.relatedProduct.mode;
export const popupModeSelector = state => state.relatedProduct.popupMode;
export const selectedVariantsSelector = state => state.relatedProduct.selectedVariants.sort((previousVariantIndex, variantIndex) => previousVariantIndex > variantIndex ? 1 : -1);
export const splitOrderModeSelector = state => state.relatedProduct.splitOrderMode;
export const variantDependentPriceSelector = state => state.relatedProduct.product.variantDependentPrice;
export const blankSamplePriceSelector = state => state.relatedProduct.product.samplePrice;
export const printedSamplePriceSelector = state => state.relatedProduct.product.printedSamplePrice;
export const additionalPrintLocationsSelector = state => state.relatedProduct.product.additionalPrintLocations ? state.relatedProduct.product.additionalPrintLocations.map(location => location) : [];
export const quantitiesSelector = state => state.relatedProduct.quantities;
export const quantitiesReadySelector = state => state.relatedProduct.quantitiesReady;
export const showColorSectionSelector = state => state.relatedProduct.product.variants.length > 0;
export const designAttachmentsSelector = state => state.relatedProduct.attachments;
const productInStockLevelsByVariantSkuSelector = createSelector(productSelector, getProductInStockLevelsByVariantSku);
const productIsAlwaysInStockSelector = createSelector(productSelector, product => product.isAlwaysInStock);
export const quantitySelector = createSelector(selectedVariantsSelector, quantitiesSelector, (variants, quantities) => variants.reduce((allVariantsQuantity, variantIndex) => {
  if (!quantities[variantIndex]) {
    return allVariantsQuantity;
  }
  const singleVariantQuantity = quantities[variantIndex].reduce((variantQuantity, q) => q + variantQuantity, 0);
  return allVariantsQuantity + singleVariantQuantity;
}, 0));
export const sizeDependentPriceSelector = createSelector(productSelector, product => product.sizeDependentPrice);
export const productOutOfStockThresholdSelector = createSelector(productSelector, product => getProductOutOfStockThreshold(product));
export const hasSizeSelector = createSelector(productSelector, product => product.hasSize);
const variantSelector = createSelector(variantIndexSelector, productSelector, (variantIndex, product) => {
  var _product$variants;
  return (product === null || product === void 0 ? void 0 : (_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : _product$variants[variantIndex]) || null;
});
export const productSkuSelector = createSelector(productLoadedSelector, variantSelector, productSelector, (isLoaded, variant, product) => isLoaded ? (variant === null || variant === void 0 ? void 0 : variant.productSku) || product.num : null);
export const variantImagesSelector = createSelector(productSelector, variantIndexSelector, viewTypeSelector, (product, variantIdx, view) => {
  const images = _get(product, ['variants', variantIdx, 'images'], {});
  return {
    images: Object.values(images).filter(Boolean).map(_head),
    view
  };
});
const selectedVariantsDataSelector = createSelector(productLoadedSelector, selectedVariantsSelector, productVariantsSelector, quantitiesSelector, (productLoaded, selectedVariants, productVariants, quantities) => {
  if (!productLoaded || !productVariants.length) {
    return [];
  }
  return selectedVariants.map(variantIndex => {
    const variant = productVariants.find((item, index) => (item.index || index) === variantIndex);
    const {
      color,
      name,
      productSku,
      isAlwaysInStockVariant,
      sizeSkus
    } = variant || {};
    const variantQuantities = quantities[variantIndex] || [0];
    return {
      variantIndex,
      color,
      name,
      quantities: variantQuantities,
      productSku,
      isAlwaysInStockVariant,
      sizeSkus
    };
  });
});
const orderItemVariantsSelector = createSelector(productLoadedSelector, selectedVariantsSelector, quantitiesSelector, (productLoaded, selectedVariants, quantities) => {
  if (!productLoaded) {
    return [];
  }
  return selectedVariants.map(variantIndex => {
    const variantQuantities = quantities[variantIndex];
    return {
      variantIndex,
      quantities: variantQuantities
    };
  });
});
const productSettingsSelector = createSelector(productSelector, product => product.productSettings || []);
export const currentProductSettingsSelector = createSelector(productSettingsSelector, embellishmentMethodIdSelector, getRelatedProductActiveSettings);
export const minimumQuantitySelector = createSelector(productSelector, currentProductSettingsSelector, featureTogglesSelector, (product, currentProductSettings, {
  dynamicQuantityAndLocationsOptionsForDecorationMethods
}) => {
  if (!dynamicQuantityAndLocationsOptionsForDecorationMethods) {
    return (product === null || product === void 0 ? void 0 : product.minQuant) || 0;
  }
  return (currentProductSettings === null || currentProductSettings === void 0 ? void 0 : currentProductSettings.minQuant) || 0;
});
export const currentVariantLocationsSelector = createSelector(currentProductSettingsSelector, variantSelector, (currentProductSettings, variant) => {
  return (currentProductSettings === null || currentProductSettings === void 0 ? void 0 : currentProductSettings.locations[(variant === null || variant === void 0 ? void 0 : variant.variantId) || '']) || null;
});
const colorsForOrderItemSelector = createSelector(logosColorsAmountSelector, isFullDesignProductSelector, getColorsNumberForItem);
export const orderItemSelector = createSelector([productSelector, productLoadedSelector, productIdSelector, orderItemVariantsSelector, productSkuSelector, sizeIndexSelector, quantitySelector, designNotesSelector, discountSelector, selectedProductionTimeSelector, orderModeSelector, itemIdSelector, serviceCodeSelector, cartItemsSelector, variantDependentPriceSelector, designAttachmentsSelector, textsSelector, featureTogglesSelector, currentProductSettingsSelector, logosByLocationSelector, colorsForOrderItemSelector], (product, isProductLoaded, prodId, selectedVariants, itemSku, sizeInd, quantity, designNotes, discount, selectedProductionTime, mode, itemId, serviceCode, cartItems, variantDependentPrice, attachments, texts, featureFlags, currentProductSettings, logos, colors) => {
  if (!isProductLoaded) {
    return {};
  }
  const orderItem = {
    prodId,
    variants: selectedVariants,
    itemSku,
    sizeInd,
    logos,
    colors,
    quantity,
    designNotes: designNotes || '',
    attachments,
    discount,
    prodTime: selectedProductionTime,
    texts,
    _id: itemId,
    prod: getItemProd(product, featureFlags, currentProductSettings === null || currentProductSettings === void 0 ? void 0 : currentProductSettings.embellishmentMethodId)
  };
  if (currentProductSettings !== null && currentProductSettings !== void 0 && currentProductSettings.embellishmentMethod) {
    orderItem.embellishmentMethod = currentProductSettings.embellishmentMethod;
  }
  if (variantDependentPrice) {
    orderItem.variantInd = selectedVariants[0].variantIndex;
  }
  orderItem.price = Price.getPrice({
    orderItem,
    product,
    featureFlags
  });
  if (mode === PRODUCT_BUILDER_MODES.REBUILD_MODE) {
    const itemToRebuild = cartItems.find(item => item._id === itemId);
    const {
      totalCharges,
      isNyTaxFree,
      deliveryTime,
      vendor,
      inHandDate,
      status
    } = itemToRebuild;
    orderItem._id = itemId;
    // tslint:disable-next-line: prefer-object-spread
    _extends(orderItem, {
      serviceCode,
      totalCharges,
      isNyTaxFree,
      deliveryTime,
      vendor,
      inHandDate,
      status
    });
  }
  return orderItem;
});
export const pricesWithQuantitySelector = createSelector(productSelector, orderItemSelector, featureTogglesSelector, (product, orderItem, featureFlags) => {
  return getPricesWithQuantity({
    product,
    orderItem,
    featureFlags
  });
});
export const priceBreaksSelector = createSelector(pricesWithQuantitySelector, prices => {
  const priceBreaks = calculatePriceBreaks(prices);
  const hasMoreThanOneItem = priceBreaks.length > 1;
  return {
    prices: priceBreaks,
    showPriceBreaks: hasMoreThanOneItem
  };
});
export const showPriceBreaksSelector = createSelector(priceBreaksSelector, ({
  showPriceBreaks
}) => showPriceBreaks);
export const productInformationSelector = createSelector(productSelector, printedSamplePriceSelector, blankSamplePriceSelector, (product, printedSamplePrice, blankSamplePrice) => {
  const {
    name,
    descr,
    _id,
    forbiddenForInventory,
    forbiddenForBox
  } = product;
  return {
    name: name.trim().replace(/ {2}/g, ' '),
    descr,
    productId: _id,
    hasBlankSample: !!blankSamplePrice,
    hasPrintedSample: !!printedSamplePrice,
    forbiddenForInventory,
    acceptableForBox: !forbiddenForBox
  };
});
export const currentVariantImagesSelector = createSelector(variantSelector, currentVariantLocationsSelector, featureTogglesSelector, getCurrentVariantImages);
export const selectedVariantsImagesSelector = createSelector(variantIndexSelector, currentVariantImagesSelector, productLoadedSelector, imagesWithoutImprintAreaSelector, isFullDesignProductSelector, (variantIndex, currentVariantImages, productLoaded, imagesWithoutImprintArea, isFullDesignProduct) => {
  return getCurrentImages({
    productLoaded,
    isFullDesignProduct,
    variantIndex,
    imagesWithoutImprintArea,
    currentVariantImages
  });
});
export const activeProductImageSelector = createSelector(selectedVariantsImagesSelector, viewTypeSelector, variantIndexSelector, (currentImages, view, variantIndex) => {
  const activeProductImage = currentImages.find(image => {
    const isSameView = view === image.id;
    const isSameVariant = variantIndex === image.variantIndex;
    return isSameView && isSameVariant;
  });
  if (!activeProductImage) {
    return currentImages[0];
  }
  return activeProductImage;
});
const quantityPerSizeEnabledSelector = createSelector(hasSizeSelector, sizeDependentPriceSelector, (hasSize, sizeDependent) => hasSize && !sizeDependent);
const sizeOptionsSelector = createSelector(productSelector, product => {
  var _product$productSize;
  return (_product$productSize = product.productSize) === null || _product$productSize === void 0 ? void 0 : _product$productSize.options;
});
export const selectedVariantsInStockLevelsSelector = createSelector(productVariantsSelector, selectedVariantsSelector, productInStockLevelsByVariantSkuSelector, sizeOptionsSelector, (variants, selectedVariants, stockLevelsByVariantSku, sizeSizeOptions) => {
  return selectedVariants.map(i => {
    const variant = variants[i];
    if (!variant) {
      return;
    }
    const {
      sizeSkus,
      isAlwaysInStockVariant
    } = variant;
    if (!sizeSkus || isAlwaysInStockVariant) {
      return;
    }
    return sizeSizeOptions.map(size => {
      const sku = sizeSkus[size];
      return stockLevelsByVariantSku[sku];
    });
  });
});
export const isQuantityAmountValid = createSelector(quantitySelector, productInStockLevelsByVariantSkuSelector, selectedVariantsDataSelector, sizeIndexSelector, hasSizeSelector, sizeOptionsSelector, productIsAlwaysInStockSelector, (totalQuantity, stockLevelsByVariantSku, selectedVariants, sizeIndex, hasSize, sizeSizeOptions, productIsAlwaysInStock) => {
  // @todo. auto generated. fix this error
  // @ts-ignore
  const mainQuantityValid = !getNotificationText({
    quantityForCurrentItem: totalQuantity,
    minQuantity: MIN_RELATED_AMOUNT,
    variants: selectedVariants,
    stockLevelsByVariantSku
  });
  const isEveryVariantValid = selectedVariants.every(variant => {
    const {
      quantities
    } = variant;
    if (hasSize) {
      return sizeSizeOptions.every((sizeLabel, index) => {
        var _variant$sizeSkus;
        // @todo. auto generated. fix this error
        // @ts-ignore
        return !getNotificationText({
          stockLevelsByVariantSku,
          productIsAlwaysInStock: productIsAlwaysInStock,
          quantityForCurrentItem: quantities[index],
          variants: [_objectSpread({}, variant, {
            productSku: (_variant$sizeSkus = variant.sizeSkus) === null || _variant$sizeSkus === void 0 ? void 0 : _variant$sizeSkus[sizeLabel]
          })]
        });
      });
    } else {
      const quantity = quantities && quantities[sizeIndex];
      // @todo. auto generated. fix this error
      // @ts-ignore
      return !getNotificationText({
        quantityForCurrentItem: quantity,
        variants: [variant],
        stockLevelsByVariantSku
      });
    }
  });
  return mainQuantityValid && isEveryVariantValid;
});
export const detailsSelector = createStructuredSelector({
  showPriceBreaks: showPriceBreaksSelector,
  sizeIndex: sizeIndexSelector,
  splitOrderMode: splitOrderModeSelector,
  isFullDesignProduct: isFullDesignProductSelector,
  additionalPrintLocations: additionalPrintLocationsSelector,
  designNotes: designNotesSelector,
  showColorSection: showColorSectionSelector,
  variant: variantSelector,
  product: productSelector,
  sizes: sizeSelector,
  sizeLoaded: sizeLoadedSelector,
  discount: discountSelector,
  parentProductId: parentProductIdSelector,
  popupMode: popupModeSelector,
  isQuantityAmountValid
});
export const singleQuantityBlockSelector = createSelector([priceBreaksSelector, quantitiesSelector, quantitySelector, sizeSelector, variantIndexSelector, quantityPerSizeEnabledSelector, sizeDependentPriceSelector, quantitiesReadySelector, variantSelector, productInStockLevelsByVariantSkuSelector, productIsAlwaysInStockSelector, productOutOfStockThresholdSelector], ({
  showPriceBreaks
}, quantities, totalQuantity, size, variantIndex, quantityPerSizeEnabled, sizeDependent, quantitiesReady, variant, stockLevelsByVariantSku, productIsAlwaysInStock, productOutOfStockThreshold) => {
  return {
    showPriceBreaks,
    minQuant: MIN_RELATED_AMOUNT,
    size,
    totalQuantity,
    quantities: quantities[variantIndex],
    variantIndex,
    quantityPerSizeEnabled,
    sizeDependent,
    stockLevelsByVariantSku,
    productIsAlwaysInStock,
    variant,
    outOfStockThreshold: productOutOfStockThreshold,
    errorText: quantitiesReady && getNotificationText({
      quantityForCurrentItem: totalQuantity,
      minQuantity: MIN_RELATED_AMOUNT,
      variants: variant ? [variant] : [],
      stockLevelsByVariantSku
    })
  };
});
export const multipleQuantityBlockSelector = createSelector([priceBreaksSelector, selectedVariantsDataSelector, sizeSelector, sizeIndexSelector, sizeLoadedSelector, quantitySelector, quantityPerSizeEnabledSelector, productInStockLevelsByVariantSkuSelector, productIsAlwaysInStockSelector, productOutOfStockThresholdSelector], ({
  showPriceBreaks
}, variants, size, sizeIndex, sizeLoaded, totalQuantity, quantityPerSizeEnabled, stockLevelsByVariantSku, productIsAlwaysInStock, productOutOfStockThreshold) => {
  if (!sizeLoaded) {
    return {
      variants: []
    };
  }
  return {
    showPriceBreaks,
    variants,
    size,
    sizeIndex,
    totalQuantity,
    quantityPerSizeEnabled,
    stockLevelsByVariantSku,
    productIsAlwaysInStock,
    outOfStockThreshold: productOutOfStockThreshold,
    errorText: getNotificationText({
      quantityForCurrentItem: totalQuantity,
      minQuantity: MIN_RELATED_AMOUNT,
      variants,
      stockLevelsByVariantSku,
      productIsAlwaysInStock
    })
  };
});
const variantsSelector = createSelector(productVariantsSelector, currentProductSettingsSelector, mainProductCurrentVariantLocationsSelector, mainProductViewsSelector, featureTogglesSelector, (variants, currentProductSettings, mainProductCurrentVariantLocations, productViews, {
  dynamicQuantityAndLocationsOptionsForDecorationMethods
}) => {
  if (!dynamicQuantityAndLocationsOptionsForDecorationMethods) {
    return variants.filter(variant => Object.values(variant.images).filter(Boolean).length === productViews.length);
  }
  if (!currentProductSettings || !mainProductCurrentVariantLocations) {
    return [];
  }
  const mainProductLocationsCount = Object.keys(mainProductCurrentVariantLocations).length;
  return variants.filter(variant => {
    const variantLocations = currentProductSettings.locations[variant.variantId] || {};
    return Object.keys(variantLocations).length === mainProductLocationsCount;
  });
});
export const chooseColorSelector = createSelector(variantsSelector, splitOrderModeSelector, selectedVariantsSelector, variantDependentPriceSelector, productSelector, (productVariants, isSplitOrderModeOn, selectedVariants, variantDependentPrice, product) => {
  const variants = productVariants.map(variant => _objectSpread({}, variant, {
    isActive: selectedVariants.indexOf(variant.index) > -1
  }));
  const filteredVariants = variants.filter(variant => !variant.isHidden);
  return {
    variants,
    isSplitOrderModeOn,
    isSplitOrderModeAvailable: !variantDependentPrice && filteredVariants.length > 1,
    selectedVariants,
    splitColorsTooltip: isSplitOrderModeOn ? selectedSplitColorsTooltip : unselectedSplitColorsTooltip,
    product
  };
});