import _cloneDeep2 from "lodash/cloneDeep";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { fetchFeatureToggles } from 'swag-client-common/redux/feature-toggles/feature-toggles.actions';
import { removePreviousServiceCodes } from 'swag-client-common/utils/local-storage.util';
import { detectIE } from 'swag-client-common/utils/ui.utils';
import { ALL_SWAG_CATEGORY, LOGIN_POPUP } from 'swag-client-common/constants';
import { CollectSizesUrl } from 'swag-common/utils/urls/collect-sizes.url';
import { showPredefinedNotification } from 'swag-client-common/redux/notification/notification.actions';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { getAllFavouriteProductIds } from 'swag-client-common/redux/favourite-products/favourite-products.actions';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { getHubspotCalendarLinks, getReplacementPriceRange } from 'swag-client-common/redux/settings/settings.actions';
import { resetLogosPaginationAction } from 'swag-client-common/redux/company-art-folder/company-art-folder.actions';
import { fetchProfile, FETCH_PROFILE_SUCCESS } from 'swag-client-common/redux/user/user.actions';
import { getCartInitial, applyUserPlan as applyUserPlanAction, getRecentlyViewedAndSuggested, getCartWithoutDraft } from 'swag-client-common/redux/cart/cart.actions';
import { hasMyCompanyItem } from 'swag-client-common/utils/my-company.util';
import { getIsAllProductsPage } from 'swag-client-common/utils/seo.utils';
import { instantScrollTop } from 'swag-client-common/utils/tricks';
import { getShipoutShutdownSettings, getSuperSpeedSettings, getSuperSpeedSettingsForProductBuilder } from 'swag-client-common/redux/settings/settings-other.actions';
import { SIGN_INVENTORY_CONTRACT_TOKEN_KEY } from 'swag-client-common/constants/local-storage-keys';
import { fetchSpecialBoxProducts } from 'swag-client-common/redux/product/product.actions';
import { checkIsRestrictedCuratedBrandStore } from 'swag-common/utils/curated-brand-store.utils';
import { selectResellerInCBSMode } from 'swag-client-common/redux/user/user.selectors';
import { getStoreSettings } from 'swag-client-common/utils/get-store-settings';
import { isNeedToShowCBSLoginPopUp } from '../../utils/curated-brand-store.utils';
import { getCustomHomePageData } from '../../redux/reseller-home-page/reseller-home-page.actions';
import { isCuratedBrandStoreSelector } from '../../redux/curated-brand-store/curated-brand-store-other.selector';
import { moveUpIntercom, moveDownIntercom, isMobile, isTablet } from '../../utils/ui.utils';
import { changePageParams } from '../../redux/pages/pages.actions';
import { checkDeliveryForm, prefillDelivery, resetPaymentPopupState, prefillShipping, toggleDeliverToFulfillmentCenterOption, toggleAddToInventoryWidgetOpen, resetDeliveryOption, getCheckoutCredits, openCheckoutPage } from '../../redux/checkout/checkout.actions';
import { cartItemsSelector, inventoryIdFromCartItemSelector } from '../../redux/cart/cart.selectors';
import { fetchProduct, resetReducer, checkLocalMockupForSaving } from '../../redux/product/product.actions';
import { setPasswordInitialState } from '../../redux/set-password/set-password.actions';
import { verifyEmail } from '../../redux/account-verification/account-verification.actions';
import { fetchCategories, changeCategory, setIsAllProductsPage, changeActiveSubcategoryId, changeAppliedCategoryFilter, resetProducts, resetFilter, getCategoriesFAQData, resetCategoriesFAQData } from '../../redux/product-listing/product-listing.actions';
import { getCurrentCategoryNameSelector } from '../../redux/product-listing/product-listing.selectors';
import { searchProducts, editQuery, changeActiveState, changeIsSearchResultsShown } from '../../redux/search-products/search-products.actions';
import { fetchSwagSampleBox } from '../../redux/product/product.actions';
import { shouldLoadCompanyArtFolderSelector } from '../../redux/company-art-folder/company-art-folder.selectors-other';
import { DEFAULT_ART_FOLDER_PER_PAGE, getCompanyArtFolderList } from '../../redux/company-art-folder/company-art-folder.actions';
import { fetchCategories as searchResultsPageFetchCategories, getProductListingFilterData, changeQueryString as changeSearchResultsPageQueryString, changeSelectedProduct, openFilterInSearchPopup, closeFilterInSearchPopup } from '../../redux/search-results/search-results.actions';
import { isOpeningProductFromCategorySelector } from '../../redux/search-products/search-products.selectors';
import { fetchMockups } from '../../redux/account-mockups/account-mockups.actions';
import { showLoginMode } from '../../redux/authorize-popup/authorize-popup.actions';
import { reset as resetPasswordForm } from '../../redux/profile-password-form/profile-password-form.actions';
import { postponeAction } from '../../middleware/postpone-actions.middleware';
import { cleanBreadcrumb } from '../../redux/navigation/navigation.actions';
import { getCustomStore } from '../../redux/custom-store/custom-store.actions';
import { getCollectSizesLinkDataAction, eraseSizeSubmissionCardsAction, getSizeSubmissionCardsAction, setSuccessfullySubmitSize } from '../../redux/collect-sizes/collect-sizes.actions';
import { isSuccessfullySubmittedSelector } from '../../redux/collect-sizes/collect-sizes.selectors';
import { fetchCompanyDepartments, fetchCompanyUsers } from '../../redux/company-departments/company-departments.actions';
import { fetchPendingOrders } from '../../redux/pending-orders/pending-orders.actions';
import { checkInvitation } from '../../redux/invitation/invitation.actions';
import { resetOrders, fetchAccountOrders } from '../../redux/account-orders/account-orders.actions';
import { fetchCompanyOrders, resetCompanyOrders } from '../../redux/company-orders/company-orders.actions';
import { openCartPage, openDeliveryPage, resetPickedSpecialBoxesProducts } from '../../redux/cart/cart.actions';
import { isLoggedInSelector, isApiAccessEnabledSelector, isApiPaymentMethodCardSelector, profileFetchedSelector, isOrderApprovalFlowSelector } from '../../redux/user/user.selectors';
import { isAllSwagCategory } from '../../redux/product-listing/product-listing.utils';
import { getStatesForCountry } from '../../redux/distribution-fee-estimation/distribution-fee-estimation.actions';
import { getPastWorkImages } from '../../redux/past-work/past-work.actions';
import { getApiCredentials } from '../../redux/api-settings/api-settings.action';
import { checkUserExistsByDeliveryEmail, getUserInventories } from '../../redux/inventory/inventory.actions';
import { getResellerLandingsData } from '../../redux/landings/landings.actions';
import { getResellerInventoryContractDetails } from '../../redux/reseller-invoice/reseller-invoice.actions';
import { getCBSProducts } from '../../redux/curated-brand-store/curated-brand-store.actions';
import { fetchInitialStatesOther, getGamification } from './route-hooks-other';
import { getCheckoutTenantData } from './route-hooks-other.dynamic';
export const requiredToBeLoggedIn = store => (state, replace) => {
  if (!isLoggedInSelector(store.getState()) && state.location.pathname !== '/login') {
    replace(APP_ROUTES.HOME);
    return false;
  }
  instantScrollTop();
  return true;
};
export const fetchInitialStates = (store, dispatch) => async (nextState, replace, next) => {
  await Promise.all([dispatch(fetchProfile()), dispatch(fetchFeatureToggles())]);
  dispatch(getCartWithoutDraft());
  dispatch(prefillShipping());
  removeToken(nextState, replace);
  checkForNotification(nextState, replace, dispatch);
  dispatch(getReplacementPriceRange());
  dispatch(fetchFeatureToggles());
  fetchInitialStatesOther(dispatch);
  dispatch(getHubspotCalendarLinks());
  const state = store.getState();
  if (isLoggedInSelector(state)) {
    dispatch(getAllFavouriteProductIds());
  }
  if (window.location.pathname === APP_ROUTES.HOME && window.location.search.includes('utm_source=email')) {
    dispatch(showLoginMode());
    dispatch(showPopup({
      popup: 'authorize'
    }));
  }
  const version = detectIE();
  if (version && version < 12) {
    dispatch(showPopup({
      popup: 'internetExplorer'
    }));
  }
  if (selectResellerInCBSMode(state)) {
    dispatch(getCBSProducts());
  }
  next === null || next === void 0 ? void 0 : next();
};
const isFilterShouldBeReseted = ({
  subcategorySlug,
  categorySlug,
  categoriesMap,
  currentCategoryName,
  appliedFilter,
  selectedFilter
}) => !subcategorySlug && Object.keys(categoriesMap).length && categoriesMap[currentCategoryName] && categoriesMap[currentCategoryName].parent_id !== categoriesMap[categorySlug]._id && appliedFilter.categoryId && selectedFilter.categoryId && selectedFilter.categoryId.length === 1 && appliedFilter.categoryId && appliedFilter.categoryId.length === 1 && selectedFilter.categoryId[0] !== appliedFilter.categoryId[0] || categorySlug === ALL_SWAG_CATEGORY;
export const onCategoriesEnter = (store, dispatch) => (nextState, replace, next) => {
  const {
    productListing: {
      currentCategoryName,
      subCategoryName,
      categoriesMap,
      products,
      selectedFilter,
      appliedFilter,
      isAllProductsPage: prevIsAllProductsPage
    },
    pages: {
      scrollTop
    }
  } = store.getState();
  const {
    params: {
      categorySlug,
      subcategorySlug
    },
    location: {
      action,
      pathname,
      state,
      query: {
        page,
        perpage
      }
    }
  } = nextState;
  if (state && state.skipRouteHooks) {
    return next === null || next === void 0 ? void 0 : next();
  }
  const category = {
    slug: categorySlug
  };
  const filterData = {
    subcategorySlug,
    categorySlug,
    categoriesMap,
    currentCategoryName,
    appliedFilter,
    selectedFilter
  };
  const actionBackInBrowser = action === 'POP';
  const nextIsAllProductsPage = getIsAllProductsPage(pathname);
  const subCategoryIsTheSame = subCategoryName === category.slug;
  if (prevIsAllProductsPage !== nextIsAllProductsPage && subCategoryIsTheSame) {
    dispatch(resetProducts(nextIsAllProductsPage));
    dispatch(resetFilter({
      redirectAllSwag: false
    }));
    instantScrollTop();
  }
  let fullCategory = categoriesMap[category.slug];
  let parentCategory;
  const currentCategory = _cloneDeep2(category);
  if (fullCategory && fullCategory.parent_id) {
    parentCategory = Object.values(categoriesMap).find(item => item._id === fullCategory.parent_id);
    fullCategory = parentCategory || fullCategory;
  }
  if (fullCategory) {
    currentCategory.seoName = fullCategory.seoName;
    currentCategory.seoBrowserTabTitle = fullCategory.seoBrowserTabTitle;
    currentCategory.seoBrowserTabDescription = fullCategory.seoBrowserTabDescription;
    currentCategory.parent_id = fullCategory.parent_id;
    currentCategory._id = fullCategory._id;
    currentCategory.name = fullCategory.name;
    currentCategory.slug = fullCategory.slug;
  }
  dispatch(setIsAllProductsPage({
    isAllProductsPage: nextIsAllProductsPage,
    page: page && Number(page),
    perpage: perpage && Number(perpage)
  }));
  if (!subCategoryIsTheSame) {
    dispatch(resetProducts(nextIsAllProductsPage));
  }
  if (isFilterShouldBeReseted(filterData) && !actionBackInBrowser) {
    dispatch(resetFilter({
      redirectAllSwag: false
    }));
  }
  dispatch(cleanBreadcrumb());
  dispatch(changeCategory({
    category: fullCategory || currentCategory,
    subCategoryName: category.slug
  }));
  dispatch(changeActiveSubcategoryId(subcategorySlug && categoriesMap[subcategorySlug] ? categoriesMap[subcategorySlug]._id : null));
  if (Object.keys(categoriesMap).length && !isAllSwagCategory()) {
    var _categoriesMap$catego;
    const activeCategoryId = (_categoriesMap$catego = categoriesMap[categorySlug]) === null || _categoriesMap$catego === void 0 ? void 0 : _categoriesMap$catego._id;
    const includeSearchableOnly = prevIsAllProductsPage !== nextIsAllProductsPage && subCategoryIsTheSame;
    dispatch(changeAppliedCategoryFilter(parentCategory ? [parentCategory._id, activeCategoryId] : [activeCategoryId], includeSearchableOnly));
  }
  if (!products.length || currentCategoryName !== category.slug) {
    dispatch(fetchCategories());
  }
  if (scrollTop) {
    dispatch(changePageParams('scrollTop', false));
    instantScrollTop();
  }
  dispatch(getCategoriesFAQData());
  dispatch(getSuperSpeedSettings());
  next === null || next === void 0 ? void 0 : next();
};
export const onCategoriesLeave = (store, dispatch) => () => {
  dispatch(resetCategoriesFAQData());
};
export const onProductEnter = (store, dispatch) => nextState => {
  const state = store.getState();
  const currentCategoryName = getCurrentCategoryNameSelector(state);
  const isOpeningProductFromCategory = isOpeningProductFromCategorySelector(state);
  let currentCategoryObj = {};
  if (isOpeningProductFromCategory && currentCategoryName) {
    currentCategoryObj = {
      currentCategoryName
    };
  }
  setTimeout(instantScrollTop, 0);
  moveUpIntercom();
  dispatch(getSuperSpeedSettingsForProductBuilder());
  dispatch(fetchProduct(_objectSpread({
    id: nextState.params.seoSlug
  }, nextState.location.query.cbsProductId && {
    cbsProductId: nextState.location.query.cbsProductId
  }, currentCategoryObj)));
  dispatch(getCustomStore());
  checkLocalMockupForSaving(dispatch);
  if (shouldLoadCompanyArtFolderSelector(state)) {
    dispatch(resetLogosPaginationAction(DEFAULT_ART_FOLDER_PER_PAGE));
    dispatch(getCompanyArtFolderList());
  }
};
export const onInaboxLandingEnter = (store, dispatch) => () => {
  instantScrollTop();
  dispatch(fetchSwagSampleBox());
  dispatch(getHubspotCalendarLinks());
};
export const onGiveawayLandingEnter = (store, dispatch) => () => {
  instantScrollTop();
  dispatch(getHubspotCalendarLinks());
};
export const onShopifyLandingEnter = (store, dispatch) => () => {
  instantScrollTop();
  dispatch(getHubspotCalendarLinks());
};
export const onDistributeLandingEnter = (store, dispatch) => () => {
  instantScrollTop();
  dispatch(getStatesForCountry(COUNTRY_CODES.US));
  dispatch(getHubspotCalendarLinks());
};
export const onSizeSubmissions = (_, dispatch) => () => {
  instantScrollTop();
  dispatch(getSizeSubmissionCardsAction());
};
export const onSizeSelectionEnter = (_, dispatch) => nextState => {
  instantScrollTop();
  dispatch(getCollectSizesLinkDataAction({
    link: nextState.params.link
  }));
};
export const onSizeSelectionSuccessEnter = (store, dispatch) => nextState => {
  instantScrollTop();
  const isSuccessfullySubmitted = isSuccessfullySubmittedSelector(store.getState());
  if (!isSuccessfullySubmitted) {
    browserHistory.push(CollectSizesUrl.getCollectSizesSubmitLanding({
      link: nextState.params.link
    }));
  }
  dispatch(setSuccessfullySubmitSize(false));
};
export const onSizeSubmissionsLeave = (_, dispatch) => () => {
  instantScrollTop();
  dispatch(eraseSizeSubmissionCardsAction());
};
export const onProductLeave = (store, dispatch) => () => {
  moveDownIntercom();
  dispatch(cleanBreadcrumb());
  dispatch(resetReducer());
};
export const startVerifyingEmail = (store, dispatch) => (nextState, replace, next) => {
  const {
    token
  } = nextState.params;
  replace(APP_ROUTES.HOME);
  dispatch(verifyEmail({
    token
  }));
  next();
};
export const onDeliveryEnter = (store, dispatch) => (nextState, replace, next) => {
  instantScrollTop();
  dispatch(checkDeliveryForm());
  dispatch(getShipoutShutdownSettings());
  const state = store.getState();
  if (isOrderApprovalFlowSelector(state)) {
    dispatch(fetchCompanyDepartments());
  }
  if (!profileFetchedSelector(state) && !isLoggedInSelector(state)) {
    dispatch(fetchProfile());
    dispatch(resetDeliveryOption());
    dispatch(checkUserExistsByDeliveryEmail());
    postponeAction({
      actionCreator: prefillDelivery,
      untilAction: FETCH_PROFILE_SUCCESS
    });
  } else {
    dispatch(prefillDelivery());
  }
  if (isTablet()) {
    dispatch(toggleAddToInventoryWidgetOpen(0));
    dispatch(toggleDeliverToFulfillmentCenterOption(false));
  }
  dispatch(openDeliveryPage());
  getCheckoutTenantData(dispatch);
  next === null || next === void 0 ? void 0 : next();
};
export const onAccountOrders = (store, dispatch) => (nextState, replace, next) => {
  requiredToBeLoggedIn(store)(nextState, replace);
  dispatch(getCartWithoutDraft());
  dispatch(getUserInventories());
  dispatch(resetOrders());
  dispatch(hidePopup());
  dispatch(fetchAccountOrders());
  getGamification(dispatch);
  const {
    user: {
      isCompanyOrdersAvailable,
      companyEmailDomain
    }
  } = store.getState();
  if (isCompanyOrdersAvailable) {
    dispatch(fetchCompanyOrders({
      companyEmailDomain
    }));
  }
  next();
};
export const onCompanyOrders = (store, dispatch) => (nextState, replace, next) => {
  const {
    user: {
      isCompanyOrdersAvailable,
      companyEmailDomain
    }
  } = store.getState();
  if (!isCompanyOrdersAvailable) {
    return replace(APP_ROUTES.NOT_FOUND);
  }
  dispatch(resetCompanyOrders());
  dispatch(fetchCompanyOrders({
    companyEmailDomain
  }));
  getGamification(dispatch);
  next();
};
export const onCheckoutEnter = (store, dispatch) => (nextState, replace, next) => {
  const {
    cart: {
      items
    },
    productListing: {
      categories
    }
  } = store.getState();
  dispatch(fetchProfile());
  dispatch(resetPaymentPopupState());
  if (!items || items.length === 0) {
    replace(APP_ROUTES.CART);
  }
  if (!categories.length) {
    dispatch(fetchCategories());
  }
  const inventoryId = inventoryIdFromCartItemSelector(store.getState());
  if (inventoryId) {
    dispatch(getCheckoutCredits({
      inventoryId
    }));
  }
  dispatch(openCheckoutPage());
  instantScrollTop();
  next === null || next === void 0 ? void 0 : next();
};
export const onSetPassword = (store, dispatch, {
  mode
}) => (nextState, replace, next) => {
  const requireApproveTerms = nextState.location.query.requireApproveTerms;
  const {
    curatedBrandStore
  } = getStoreSettings();
  const {
    restrictionLevel
  } = curatedBrandStore !== null && curatedBrandStore !== void 0 ? curatedBrandStore : {};
  const isRestrictedCuratedBrandStore = checkIsRestrictedCuratedBrandStore(restrictionLevel);
  dispatch(setPasswordInitialState({
    token: nextState.params.token,
    mode
  }));
  replace(APP_ROUTES.HOME);
  dispatch(showPopup({
    popup: 'setPassword',
    requireApproveTerms,
    isRestrictedCuratedBrandStore
  }));
  next();
};
export const onCheckoutLeave = store => () => {
  const items = cartItemsSelector(store.getState());
  removePreviousServiceCodes(items);
};
export const checkForNotification = (nextState, replace, dispatch) => {
  const {
    location: {
      pathname,
      query,
      query: {
        notification
      }
    }
  } = nextState;
  if (notification) {
    dispatch(showPredefinedNotification({
      notificationName: notification
    }));
    delete query.notification;
    replace({
      pathname,
      query
    });
  }
};
export const applyUserPlan = (store, dispatch) => (nextState, replace, next) => {
  dispatch(applyUserPlanAction());
  replace({
    pathname: APP_ROUTES.COLLECTIONS
  });
  next();
};
export const removeToken = (nextState, replace) => {
  if (nextState.location.query.token) {
    const stateToReplace = _objectSpread({}, nextState);
    delete stateToReplace.location.query.token;
    replace(stateToReplace.location);
  }
};
export const onSpringEnter = (nextState, replace) => {
  const capitalOneTransactionId = nextState.location.query.uid;
  if (capitalOneTransactionId) {
    localStorage.setItem('swagUser', JSON.stringify({
      capitalOneTransactionId
    }));
    replace({
      pathname: APP_ROUTES.SPRING
    });
  }
};
export const onAccountMockups = (store, dispatch) => () => {
  instantScrollTop();
  dispatch(fetchMockups());
};
export const onKickstarterCalculatorEnter = () => () => {
  instantScrollTop();
};
export const onProfileSettingsEnter = (store, dispatch) => () => {
  const state = store.getState();
  if (!profileFetchedSelector(state) && !isLoggedInSelector(state)) {
    dispatch(fetchProfile());
  }
};
export const onApiSettingsEnter = (store, dispatch) => (nextState, replace, next) => {
  const state = store.getState();
  if (!profileFetchedSelector(state) || !isApiAccessEnabledSelector(state)) {
    replace(APP_ROUTES.HOME);
    next === null || next === void 0 ? void 0 : next();
    return;
  }
  dispatch(getApiCredentials());
  next === null || next === void 0 ? void 0 : next();
};
export const onApiPaymentSettingsEnter = (store, dispatch) => (nextState, replace, next) => {
  const state = store.getState();
  if (!profileFetchedSelector(state) || !isApiPaymentMethodCardSelector(state)) {
    replace(APP_ROUTES.ACCOUNT_PUBLIC_API);
    next === null || next === void 0 ? void 0 : next();
    return;
  }
  dispatch(getApiCredentials());
  next === null || next === void 0 ? void 0 : next();
};
export const onProfilePasswordLeave = (store, dispatch) => () => {
  dispatch(resetPasswordForm());
};
export const onCartEnter = (store, dispatch) => () => {
  instantScrollTop();
  dispatch(getUserInventories());
  dispatch(getCartInitial());
  dispatch(fetchSwagSampleBox());
  dispatch(fetchSpecialBoxProducts());
  dispatch(getRecentlyViewedAndSuggested());
  dispatch(openCartPage());
};
export const onCartLeave = (store, dispatch) => () => {
  dispatch(resetPickedSpecialBoxesProducts());
  dispatch(hidePopup());
};
const isSmallScreenView = isMobile() || isTablet();
export const onSearchResultsEnter = (store, dispatch) => () => {
  dispatch(changeActiveState(!isSmallScreenView));
  dispatch(changeIsSearchResultsShown(false));
  dispatch(isMobile() ? closeFilterInSearchPopup() : openFilterInSearchPopup());
  const {
    searchResults
  } = store.getState();
  const searchStr = window.location.search;
  if (searchStr) {
    var _currentLocation$quer;
    const paramsStr = searchStr.substring(1);
    const filteredCategories = Array.from(paramsStr.matchAll(/categoryId=([^&]*)/g), cat => cat[1]);
    const currentLocation = browserHistory.getCurrentLocation();
    dispatch(changeAppliedCategoryFilter(filteredCategories));
    if (currentLocation !== null && currentLocation !== void 0 && (_currentLocation$quer = currentLocation.query) !== null && _currentLocation$quer !== void 0 && _currentLocation$quer.query) {
      const {
        query
      } = currentLocation.query;
      dispatch(searchResultsPageFetchCategories());
      if (query !== searchResults.query) {
        dispatch(searchProducts({
          query
        }));
        dispatch(getProductListingFilterData());
        dispatch(editQuery(query));
        dispatch(changeSearchResultsPageQueryString(query));
      }
    }
    const selectedProduct = localStorage.getItem('productId');
    if (selectedProduct) {
      dispatch(changeSelectedProduct(selectedProduct));
      localStorage.removeItem('productId');
    }
  }
};
export const onSearchResultsLeave = (store, dispatch) => () => {
  dispatch(changePageParams('scrollTop', false));
};
export const onCompanyDepartments = (store, dispatch) => (_, replace, next) => {
  const {
    user: {
      companyEmailDomain,
      role,
      orderApprovalFlow
    }
  } = store.getState();
  if (!hasMyCompanyItem({
    userRole: role,
    orderApprovalFlow,
    userIsHeadOfCompanyDepartment: false
  })) {
    replace(APP_ROUTES.HOME);
    next === null || next === void 0 ? void 0 : next();
    return;
  }
  dispatch(fetchCompanyDepartments({
    companyEmailDomain
  }));
  dispatch(fetchCompanyUsers({
    companyEmailDomain
  }));
  next === null || next === void 0 ? void 0 : next();
};
export const onPendingOrders = (store, dispatch) => (_, replace, next) => {
  const {
    user: {
      role,
      orderApprovalFlow,
      isHeadOfCompanyDepartment
    }
  } = store.getState();
  if (!hasMyCompanyItem({
    userRole: role,
    orderApprovalFlow,
    userIsHeadOfCompanyDepartment: isHeadOfCompanyDepartment
  })) {
    replace(APP_ROUTES.HOME);
    next === null || next === void 0 ? void 0 : next();
    return;
  }
  dispatch(fetchPendingOrders());
  next === null || next === void 0 ? void 0 : next();
};
export const onInvitationEnter = (store, dispatch) => (nextState, replace, next) => {
  dispatch(checkInvitation({
    inviteId: nextState.params.inviteId
  }));
  next === null || next === void 0 ? void 0 : next();
};
export const onPastWorkEnter = (store, dispatch) => (nextState, replace, next) => {
  instantScrollTop();
  dispatch(getPastWorkImages());
  next === null || next === void 0 ? void 0 : next();
};
export const onResellerHomePageEnter = (store, dispatch) => (nextState, replace, next) => {
  var _window$swag$storeSet;
  if (!((_window$swag$storeSet = window.swag.storeSettings) !== null && _window$swag$storeSet !== void 0 && _window$swag$storeSet.isHomePageActive)) {
    browserHistory.push(APP_ROUTES.ALL_SWAG);
    return;
  }
  instantScrollTop();
  dispatch(getCustomHomePageData());
  next === null || next === void 0 ? void 0 : next();
};
export const onResellerWhatWeOfferPageEnter = (store, dispatch) => (nextState, replace, next) => {
  if (window.swag.storeSettings.isWhatWeOfferActive && !isCuratedBrandStoreSelector()) {
    instantScrollTop();
    next === null || next === void 0 ? void 0 : next();
    return;
  }
  browserHistory.push(APP_ROUTES.ALL_SWAG);
};
export const onResellerAgreementPageEnter = (_, dispatch) => (nextState, replace, next) => {
  instantScrollTop();
  dispatch(getResellerLandingsData());
  next === null || next === void 0 ? void 0 : next();
};
export const onInventoryContractPageEnter = (_, dispatch) => (nextState, replace, next) => {
  const {
    params: {
      token
    }
  } = nextState;
  dispatch(getResellerLandingsData());
  dispatch(getResellerInventoryContractDetails({
    token
  }));
  localStorage.setItem(SIGN_INVENTORY_CONTRACT_TOKEN_KEY, token);
  next === null || next === void 0 ? void 0 : next();
};
export const onRouteChange = (store, dispatch) => () => {
  const state = store.getState();
  const pathname = window.location.pathname;
  if (isNeedToShowCBSLoginPopUp(state)) {
    if (pathname !== APP_ROUTES.ALL_SWAG) {
      browserHistory.push(APP_ROUTES.ALL_SWAG);
    }
    dispatch(showPopup({
      popup: LOGIN_POPUP
    }));
  }
};