import { getStoreSettings } from 'swag-client-common/utils/get-store-settings';
export const dynamicContactsSelector = () => {
  var _storeSettings$addres;
  const storeSettings = getStoreSettings();
  return {
    email: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyEmail,
    phone: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyPhone,
    companyShowPhone: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyShowPhone,
    countryCode: storeSettings === null || storeSettings === void 0 ? void 0 : (_storeSettings$addres = storeSettings.address) === null || _storeSettings$addres === void 0 ? void 0 : _storeSettings$addres.billingCountry,
    isChatAvailable: !!(storeSettings !== null && storeSettings !== void 0 && storeSettings.intercomAppId)
  };
};