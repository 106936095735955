import { LASER_ENGRAVED_EMBELISHMENT_METHOD_POPUP } from '../../../common/constants';
import { showPopup } from '../../../common/redux/popup-manager/popup-manager.actions';
export const showLaserEngravedPopupAction = (productId, methodId) => showPopup({
  popup: LASER_ENGRAVED_EMBELISHMENT_METHOD_POPUP,
  productId,
  methodId
});
export const showLaserEngravedPopupOnItemEditAction = (itemId, isItemEdit) => {
  return showPopup({
    popup: LASER_ENGRAVED_EMBELISHMENT_METHOD_POPUP,
    itemId,
    isItemEdit
  });
};