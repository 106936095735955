import _differenceBy from "lodash/differenceBy";
import _getFormValues from "redux-form/es/getFormValues";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART_SUCCESS } from 'swag-client-common/redux/cart/cart.actions';
import { GiveawayTypes } from 'swag-common/interfaces';
import { calculateOrderShipmentByItems, getTaxes } from 'swag-common/utils/order';
import { Price } from 'swag-common/utils/price';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { GOOGLE_TAG_MANAGER_EVENT, mapActionTypeToFormAndLandingName, GOOGLE_TAG_MANAGER_DEFAULT_VALUES } from '../../constants/google-tag-manager';
import { OPEN_CART_PAGE, OPEN_DELIVERY_PAGE } from '../../../customer/redux/cart/cart.actions';
import { cartItemsSelector, absoluteDiscountValueSelector } from '../../../customer/redux/cart/cart.selectors';
import { OPEN_CHECKOUT_PAGE, PAYMENT_POPUP_OPEN, PROCEED_ORDER_SUCCESS } from '../../../customer/redux/checkout/checkout.actions';
import { REQUEST_SUCCESS } from '../../../customer/redux/contact/contact.actions';
import { SUBMIT_SUCCESS } from '../../../customer/redux/custom-swag/custom-swag.actions';
import { REQUEST_AUTOMATION_CONTACT_SUCCESS, REQUEST_ENTERPRISE_SUCCESS, REQUEST_GIVEAWAY_CONTACT_SUCCESS, REQUEST_IN_A_BOX_CONTACT_SUCCESS, REQUEST_SHOPIFY_CONTACT_SUCCESS, REQUEST_ZAPIER_CONTACT_SUCCESS } from '../../../customer/redux/landings/landings.actions';
import { FIRST_LOGO_UPLOAD } from '../../../customer/redux/logo/logo.actions';
import { categoriesMapSelector } from '../../../customer/redux/product-listing/product-listing.selectors';
import { FETCH_PRODUCT_SUCCESS } from '../../../customer/redux/product/product.actions';
import { isRebuildModeSelector } from '../../../customer/redux/product/product.selectors';
import { REQUEST_DEMO_SUCCESS } from '../../../customer/redux/send-email/send-email.action';
import { getItemPositionInTheCart } from '../../../customer/utils/google-tag-manager/get-item-position-in-the-cart.util';
import { getItemsForGoogleTagManager, getItemsToSend, getValue } from '../../../customer/utils/google-tag-manager/get-items-for-google-tag-manager.util';
import { getProductForGoogleTagManager } from '../../../customer/utils/google-tag-manager/get-product-for-google-tag-manager.util';
import { handleSomeFormDates } from '../../../customer/utils/google-tag-manager/handle-some-form-date';
import { SEARCH_SUCCESS_GA } from '../../../customer/redux/search-products/search-products.actions';
import { SHIPOUT_PAYMENT_SUCCESS } from '../../../custom-store-admin/redux/store-shipouts/store-shipouts.actions';
import { SELECT_INVENTORY_PRODUCT_CLICK, SELECT_PRODUCT_COLOR, SELECT_PRODUCT_TO_MANAGE } from '../../../custom-store-admin/redux/products/products.actions';
import { PAY_FOR_GIVEAWAY_SUCCESS, UPDATE_AUTOPAY_SUCCESS } from '../../../custom-store-admin/redux/giveaway/actions/giveaway-payment-popup.actions';
import { PFI_PAYMENT_SUCCESS } from '../../../custom-store-admin/redux/pull-from-inventory/pull-from-inventory.actions';
import { USER_CLICKED_ORDER_MORE_BUTTON, USER_CLICKED_SOA_BUTTON, USER_CLICKED_SMA_BUTTON, USER_CLICKED_GIVEAWAY_BUTTON, SUBMIT_GIVEAWAY_LINK, ON_GIVEAWAY_EDIT_CLICK, ON_INVENTORY_SETTINGS_CLICK, ON_PAYMENT_METHODS_CLICK, ADD_NEW_CARD_CLICK, ON_INVENTORY_SELECT_CLICK, INVENTORY_STORAGE_FEE_CLICK, INVENTORY_CREDITS_CLICK, INVENTORY_FAQ_TAB_CLICKED, INVENTORY_SUB_TAB_CLICKED, GIVEAWAY_COMPLETED_ANALYTICS, UPDATE_INVENTORY_USERS_PERMISSIONS_REQUEST, USER_CLICKED_INVENTORY_LINK, APPROVE_DISTRIBUTION_REQUEST, REJECT_DISTRIBUTION_REQUEST } from '../analytics/analytics.actions';
export const googleTagManagerMiddleware = ({
  getState
}) => {
  return next => action => {
    try {
      switch (action.type) {
        case REQUEST_ENTERPRISE_SUCCESS:
        case REQUEST_IN_A_BOX_CONTACT_SUCCESS:
        case REQUEST_GIVEAWAY_CONTACT_SUCCESS:
        case REQUEST_SHOPIFY_CONTACT_SUCCESS:
        case REQUEST_AUTOMATION_CONTACT_SUCCESS:
        case REQUEST_ZAPIER_CONTACT_SUCCESS:
        case SUBMIT_SUCCESS:
        case REQUEST_DEMO_SUCCESS:
        case REQUEST_SUCCESS:
          {
            const formValues = _getFormValues(mapActionTypeToFormAndLandingName[action.type].formName)(getState());
            const updatedFormValues = handleSomeFormDates(action.type, formValues);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push(_objectSpread({
              event: GOOGLE_TAG_MANAGER_EVENT.FORM_SUBMIT,
              'landing page': mapActionTypeToFormAndLandingName[action.type].landingPageName
            }, updatedFormValues));
            break;
          }
        case FETCH_PRODUCT_SUCCESS:
          {
            const isRebuild = isRebuildModeSelector(getState());
            if (isRebuild) {
              break;
            }
            const product = action.payload;
            const categoriesMap = categoriesMapSelector(getState());
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.VIEW_ITEM,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value: '',
                items: [getProductForGoogleTagManager(product, Object.values(categoriesMap))]
              }
            });
            break;
          }
        case ADD_ITEM_TO_CART:
          {
            const categoriesMap = categoriesMapSelector(getState());
            const itemsInCart = cartItemsSelector(getState());
            const {
              order: {
                items: updatedItems
              }
            } = action.body;
            const newlyItems = _differenceBy(updatedItems, itemsInCart, '_id');
            const items = getItemsToSend(newlyItems, categoriesMap, GOOGLE_TAG_MANAGER_EVENT.ADD_TO_CART);
            const value = getValue(newlyItems);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.ADD_TO_CART,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                items
              }
            });
            break;
          }
        case OPEN_CART_PAGE:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const value = getValue(itemsInCart);
            const items = getItemsToSend(itemsInCart, categoriesMap);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.VIEW_CART,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                items
              }
            });
            break;
          }
        case REMOVE_ITEM_FROM_CART_SUCCESS:
          {
            const deletedItem = action.body.deletedItem;
            const deletedItemIndex = action.body.deletedItemIndex;
            const categoriesMap = categoriesMapSelector(getState());
            const itemsToRemove = getItemsForGoogleTagManager(deletedItem, getItemPositionInTheCart(deletedItemIndex), Object.values(categoriesMap));
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.REMOVE_FROM_CART,
              ecommerce: {
                itemsToRemove
              }
            });
            break;
          }
        case OPEN_DELIVERY_PAGE:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const absoluteDiscountValue = absoluteDiscountValueSelector(getState());
            const items = getItemsToSend(itemsInCart, categoriesMap);
            const value = getValue(itemsInCart);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.BEGIN_CHECKOUT,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                coupon: Price.formatNumber(absoluteDiscountValue),
                items
              }
            });
            break;
          }
        case OPEN_CHECKOUT_PAGE:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const absoluteDiscountValue = absoluteDiscountValueSelector(getState());
            const value = getValue(itemsInCart);
            const items = getItemsToSend(itemsInCart, categoriesMap);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.ADD_SHIPPING_INFO,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                coupon: Price.formatNumber(absoluteDiscountValue),
                items
              }
            });
            break;
          }
        case PAYMENT_POPUP_OPEN:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const items = getItemsToSend(itemsInCart, categoriesMap);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.CHECKOUT_PAYMENT_INFO,
              ecommerce: {
                items
              }
            });
            break;
          }
        case FIRST_LOGO_UPLOAD:
          {
            const {
              product: {
                _id,
                categoryName
              }
            } = getState().product;
            dataLayer.push({
              event: 'Upload Image',
              'Product ID': _id,
              'Product Category': categoryName
            });
            break;
          }
        case PROCEED_ORDER_SUCCESS:
          {
            const {
              order
            } = action.body;
            const categoriesMap = categoriesMapSelector(getState());
            const absoluteDiscountValue = absoluteDiscountValueSelector(getState());
            const items = getItemsToSend(order.items, categoriesMap, GOOGLE_TAG_MANAGER_EVENT.PURCHASE);
            const shipping = calculateOrderShipmentByItems(order);
            const tax = getTaxes(order);
            const value = getValue(order.items);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.PURCHASE,
              ecommerce: {
                transaction_id: order._id.toString(),
                value,
                tax: Price.formatNumber(tax),
                shipping: Price.formatNumber(shipping),
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                coupon: Price.formatNumber(absoluteDiscountValue),
                items
              }
            });
            break;
          }
        case SEARCH_SUCCESS_GA:
          {
            const {
              matchByName = [],
              matchByCategory = [],
              matchByTag = [],
              query,
              isQuickSearch
            } = action.payload;
            if (query.trim().length < 3) {
              break;
            }
            const productsList = [...matchByName, ...matchByCategory, ...matchByTag].slice(0, 6).map(({
              name,
              seoSlug
            }) => ({
              url: `${APP_ROUTES.PRODUCT}/${seoSlug}`,
              name
            }));
            const formattedQuery = query.replace(' ', '+');
            const event = isQuickSearch ? GOOGLE_TAG_MANAGER_EVENT.SEARCH_RESULTS_QUICK_SEARCH : GOOGLE_TAG_MANAGER_EVENT.SEARCH_RESULTS_SEARCH_PAGE;
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event,
              ecommerce: {
                query: `/search/?query=${formattedQuery}`,
                items: productsList
              }
            });
            break;
          }
        case USER_CLICKED_ORDER_MORE_BUTTON:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_ORDER_MORE_BUTTON_CLICKED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case USER_CLICKED_SOA_BUTTON:
          {
            const {
              userId,
              email,
              inventoryId,
              btnLocation
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_SOA_STARTED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                btn_location: btnLocation
              }
            });
            break;
          }
        case USER_CLICKED_SMA_BUTTON:
          {
            const {
              userId,
              email,
              inventoryId,
              btnLocation
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_SMA_STARTED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                btn_location: btnLocation
              }
            });
            break;
          }
        case USER_CLICKED_GIVEAWAY_BUTTON:
          {
            const {
              btnLocation,
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_GIVEAWAY_STARTED,
              payload: {
                btn_location: btnLocation,
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case SUBMIT_GIVEAWAY_LINK:
          {
            const {
              userId,
              email,
              inventoryId,
              giveawayId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_GIVEAWAY_LINK_SUBMITTED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                giveaway_id: giveawayId
              }
            });
            break;
          }
        case ON_GIVEAWAY_EDIT_CLICK:
          {
            const {
              userId,
              email,
              inventoryId,
              giveawayId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_GIVEAWAY_EDIT_STARTED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                giveaway_id: giveawayId
              }
            });
            break;
          }
        case SELECT_INVENTORY_PRODUCT_CLICK:
          {
            const {
              userId,
              email,
              inventoryId,
              selectionStatus
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_PRODUCT_SELECTED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                selection_status: selectionStatus
              }
            });
            break;
          }
        case ON_INVENTORY_SETTINGS_CLICK:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_SETTINGS_OPENED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case ON_PAYMENT_METHODS_CLICK:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_PAYMENT_METHODS_OPENED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case ADD_NEW_CARD_CLICK:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_PAYMENT_METHODS_SAVED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case ON_INVENTORY_SELECT_CLICK:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_CLOSET_SWITCHED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case INVENTORY_STORAGE_FEE_CLICK:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_VIEW_STORAGE_FEES_CLICKED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case INVENTORY_CREDITS_CLICK:
          {
            const {
              userId,
              email,
              inventoryId,
              creditsStatus
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_CREDITS_CLICKED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                credits_status: creditsStatus
              }
            });
            break;
          }
        case INVENTORY_FAQ_TAB_CLICKED:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_FAQ_PAGE_OPENED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case SELECT_PRODUCT_TO_MANAGE:
          {
            const {
              userId,
              email,
              inventoryId
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_MANAGE_IMAGES_CLICKED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId
              }
            });
            break;
          }
        case SELECT_PRODUCT_COLOR:
          {
            const {
              userId,
              email,
              inventoryId,
              colorValue
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_COLOR_SWATCHES_CLICKED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                color: colorValue
              }
            });
            break;
          }
        case INVENTORY_SUB_TAB_CLICKED:
          {
            const {
              userId,
              email,
              inventoryId,
              activitySubTab
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_ACTIVITY_PAGES_CLICKED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                activity_menu_item: activitySubTab
              }
            });
            break;
          }
        case UPDATE_AUTOPAY_SUCCESS:
          {
            const {
              userId,
              email,
              inventoryId,
              autopayPaymentMethod,
              giveawayType
            } = action.payload;
            if (giveawayType !== GiveawayTypes.swag) {
              break;
            }
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_GIVEAWAY_AUTOPAY_TOGGLED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                status: Boolean(autopayPaymentMethod)
              }
            });
            break;
          }
        case PFI_PAYMENT_SUCCESS:
          {
            const {
              analytics
            } = action.payload;
            if (analytics) {
              const {
                userId,
                email,
                inventoryId,
                totalPaid,
                items
              } = analytics;
              dataLayer.push({
                payload: null
              });
              dataLayer.push({
                event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_SOA_COMPLETED,
                payload: {
                  user_id: userId,
                  email: email,
                  closet_id: inventoryId,
                  amount: totalPaid,
                  items
                }
              });
              break;
            }
          }
        case SHIPOUT_PAYMENT_SUCCESS:
          {
            const {
              analytics
            } = action.payload;
            if (analytics) {
              const {
                userId,
                email,
                inventoryId,
                totalPaid,
                items
              } = analytics;
              dataLayer.push({
                payload: null
              });
              dataLayer.push({
                event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_SMA_COMPLETED,
                payload: {
                  user_id: userId,
                  email: email,
                  closet_id: inventoryId,
                  amount: totalPaid,
                  items
                }
              });
            }
            break;
          }
        case GIVEAWAY_COMPLETED_ANALYTICS:
          {
            const {
              userId,
              email,
              inventoryId,
              items
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_GIVEAWAY_COMPLETED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                items
              }
            });
            break;
          }
        case PAY_FOR_GIVEAWAY_SUCCESS:
          {
            const {
              userId,
              email,
              inventoryId,
              giveawayId,
              amount
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_GIVEAWAY_FEE_PAID,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                amount,
                giveaway_id: giveawayId
              }
            });
            break;
          }
        case UPDATE_INVENTORY_USERS_PERMISSIONS_REQUEST:
          {
            const {
              userId,
              email,
              inventoryId,
              permissionLevel
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_CLOSET_PERMISSIONS_UPDATED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                share_type: permissionLevel
              }
            });
            break;
          }
        case USER_CLICKED_INVENTORY_LINK:
          {
            const {
              userId,
              email
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_PORTAL_OPENED,
              payload: {
                user_id: userId,
                email: email
              }
            });
            break;
          }
        case APPROVE_DISTRIBUTION_REQUEST:
          {
            const {
              userId,
              email,
              inventoryId,
              transactionId,
              items
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_ORDER_APPROVED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                transaction_id: transactionId,
                items
              }
            });
            break;
          }
        case REJECT_DISTRIBUTION_REQUEST:
          {
            const {
              userId,
              email,
              inventoryId,
              transactionId,
              items
            } = action.payload;
            dataLayer.push({
              payload: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.INVENTORY_ORDER_REJECTED,
              payload: {
                user_id: userId,
                email: email,
                closet_id: inventoryId,
                transaction_id: transactionId,
                items
              }
            });
          }
        default:
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    next(action);
  };
};