import _pick2 from "lodash/pick";
import _flattenDeep2 from "lodash/flattenDeep";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getAmountOfUniqueColorsForAllSides } from 'swag-common/utils/colors';
import { DesignUploadMode } from 'swag-common/interfaces';
import { UPSELL_WIDGET_STATES } from 'swag-common/constants/main-swag.constants';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { getProductInStockLevelsByVariantSku, getProductOutOfStockThreshold, getTotalInStock, isSuperSpeedProductionOption, spreadQuantities } from 'swag-common/utils/product/product.utils';
import { DEFAULT_MINIMUM_COLORS_NUMBER, FRONT, PRODUCTION_TIME_STANDART_TEXT, SUPER_SPEED_PRODUCTION_OPTION } from 'swag-common/constants/product.constants';
import { checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { getLocationsDataFromProductSettings } from 'swag-common/utils/product-settings/get-locations-data-from-product-settings';
import { getVariantWithNearestColor } from 'swag-common/utils/product/get-nearest-color-variant.util';
import { Price } from 'swag-client-common/utils/price';
import { getInStockVariants, getIsAlwaysInStock, getVisibleProductVariants } from 'swag-client-common/utils/product.utils';
import { getTooltipItemName, TOOLTIP_INDIVIDUAL_ITEM_NAME } from '../../utils/get-tooltip-item-name';
import { getVariantsInfo, groupMulticolorSharedItemsInSameBox } from '../cart/cart.utils';
import { mergePriceBreaks } from '../../utils/get-upsell-widget-data/calculate-upsell-quantity/merge-price-breaks';
import { isViewHasLogo } from '../logo/logo.utils';
import { getItemPriceFromProduct } from './product-other.utils';
export const getPercentageDifference = (v1, v2) => 100 - v2 * 100 / v1;
export const isImprintAreaChanged = (newState, oldState) => {
  const isImprintAreaHeightChanged = newState.imprintAreaStyle.height !== oldState.imprintAreaStyle.height;
  const isImprintAreaWidthChanged = newState.imprintAreaStyle.width !== oldState.imprintAreaStyle.width;
  const isImprintAreaDifferent = isImprintAreaHeightChanged || isImprintAreaWidthChanged;
  const isViewTheSame = newState.view === oldState.view;
  const isImprintAreaNotZeroWidth = oldState.imprintAreaStyle.width !== 0;
  const isImprintAreaNotZeroHeight = oldState.imprintAreaStyle.height !== 0;
  return isImprintAreaDifferent && isViewTheSame && isImprintAreaNotZeroWidth && isImprintAreaNotZeroHeight;
};
export const isImageChanged = (newImage, oldImage) => {
  if (!newImage || !oldImage) {
    return false;
  }
  return newImage.url !== oldImage.url;
};
export const isVariantUpdated = (newImage, oldImage) => {
  if (!newImage || !oldImage) {
    return false;
  }
  return newImage.variantIndex !== oldImage.variantIndex;
};
export const generateFontsImport = fonts => {
  return fonts.map(font => `@import url(${font.src});`).join(' ');
};
export const getValidRelatedProducts = (productViews, relatedProducts) => {
  const validRelatedProducts = [];
  relatedProducts.forEach(item => {
    return item.variants.length > 0 && item.variants.every(variant => Object.values(variant.images).filter(Boolean).length === productViews.length) ? validRelatedProducts.push(item) : null;
  });
  return validRelatedProducts;
};
export const getDeletedRelatedItems = (relatedOrderItems, mainItemId) => {
  return relatedOrderItemFromCart => !relatedOrderItems.some(relatedOrderItem => relatedOrderItem._id === relatedOrderItemFromCart._id && mainItemId !== relatedOrderItem._id);
};
export function getBiggestScreenPrice(logos, texts, selectedProductSettings) {
  if (selectedProductSettings.length === 1) {
    return 0;
  }
  const colorAmount = getAmountOfUniqueColorsForAllSides(logos, texts);
  const amountOfSides = Object.keys(logos).length;
  const screenPrices = selectedProductSettings.map(settings => {
    let colorNumber = colorAmount;
    if (settings.addOneColor) {
      colorNumber += amountOfSides;
    }
    return colorNumber * settings.screenPrice / amountOfSides;
  });
  return Math.max.apply(null, screenPrices);
}
export function isQuantityChangedWhenUpsellWidgetWasDismissedAtBuilder({
  upsellDismissedStates,
  originalCartItem,
  newItem
}) {
  return originalCartItem && newItem.quantity !== originalCartItem.quantity && upsellDismissedStates && upsellDismissedStates[UPSELL_WIDGET_STATES.PRODUCT_BUILDER];
}
export function updateRelatedItemBeforeAdd(item) {
  const itemToProcess = _objectSpread({}, item, {
    textImages: {}
  });
  delete itemToProcess.relatedMode;
  return itemToProcess;
}
export function formatRelatedProductForQuantitiesBlock(item, items, sharedItems, withoutSharedItems) {
  const {
    product,
    size,
    sizeIndex,
    selectedVariants,
    quantities,
    isShared
  } = item;
  const variants = selectedVariants.map(index => {
    return formatVariantsForRelatedProductQuantitiesBlock(index, quantities, product);
  });
  const sharedInfo = getSharedItemsTotalQuantities(items, variants, product, sharedItems, item, withoutSharedItems);
  const isQuantityPerSizeEnabled = product.hasSize && !product.sizeDependentPrice;
  return {
    size,
    product,
    isShared,
    variants,
    sizeIndex,
    sharedInfo,
    isQuantityPerSizeEnabled
  };
}
function formatVariantsForRelatedProductQuantitiesBlock(variantIndex, quantities, product) {
  const {
    color,
    name,
    sizeSkus
  } = product.variants.find((item, index) => (item.index || index) === variantIndex);
  const variantQuantities = quantities[variantIndex];
  return {
    variantIndex,
    color,
    name,
    sizeSkus,
    quantities: variantQuantities
  };
}
export function getSharedItemsTotalQuantities(items, variants, prod, sharedItems, editingItem, skipIndividualItemQuantity = false) {
  if (!sharedItems.length) {
    return {
      isSharedItem: false
    };
  }
  const individualItemVariantsInfo = skipIndividualItemQuantity ? [{
    quantity: 0
  }] : getVariantsInfo({
    variants,
    prod
  });
  let totalQuantity = individualItemVariantsInfo.reduce((acc, variantInfo) => acc + variantInfo.quantity, 0);
  const isEditedSplitShippedToInventory = editingItem && checkIsSplitShippedToInventory(editingItem);
  const sharedItemsInfo = [{
    name: isEditedSplitShippedToInventory ? `${TOOLTIP_INDIVIDUAL_ITEM_NAME} (Warehouse)` : TOOLTIP_INDIVIDUAL_ITEM_NAME,
    variantsInfo: individualItemVariantsInfo
  }];
  groupMulticolorSharedItemsInSameBox(sharedItems).forEach(sharedItem => {
    var _box$name;
    const box = items.find(inner => inner.boxItemsId === sharedItem.boxItemsId && inner.prod.designUploadMode === DesignUploadMode.box);
    totalQuantity += sharedItem.quantity;
    const boxName = `${(_box$name = box === null || box === void 0 ? void 0 : box.name) !== null && _box$name !== void 0 ? _box$name : ''} (Box)`;
    const name = getTooltipItemName(editingItem, sharedItem, boxName);
    sharedItemsInfo.push({
      name,
      variantsInfo: getVariantsInfo(sharedItem)
    });
  });
  return {
    isSharedItem,
    totalQuantity,
    sharedItemsInfo
  };
}
export function getRelatedProductVariantQuantities(item, variant) {
  if (isItemBlankSampleOrPrintedSample(item)) {
    return spreadQuantities({
      quantity: 100,
      itemsQuantityToSpread: variant.quantities.length
    });
  }
  return variant.quantities;
}
export const checkIsNonPSOutOfStock = ({
  supplierInventoryLevels
}) => Boolean((supplierInventoryLevels === null || supplierInventoryLevels === void 0 ? void 0 : supplierInventoryLevels.length) && supplierInventoryLevels.every(lvl => !lvl.isTrulyPSRecord));
export const getSuperSpeedProductionTimeWithIndex = productionTimeList => {
  let result = {
    productionTime: null,
    index: -1
  };
  productionTimeList === null || productionTimeList === void 0 ? void 0 : productionTimeList.forEach((productionTime, index) => {
    if (productionTime.name === SUPER_SPEED_PRODUCTION_OPTION) {
      result = {
        productionTime,
        index
      };
    }
  });
  return result;
};
export const isStandardProductionTime = productionTime => productionTime.name === PRODUCTION_TIME_STANDART_TEXT;
export const getStandardProductionTimeIndex = (list = []) => list.findIndex(isStandardProductionTime);
export const getProductionTimeIndex = (list, selectedIdx) => {
  if (list.length && !list[selectedIdx]) {
    let idx = getStandardProductionTimeIndex(list);
    if (idx === -1) {
      idx = 0;
    }
    return idx;
  }
  return selectedIdx;
};
export const getLongestProductionTimeIdx = (list = []) => {
  let index = 0;
  if (isSuperSpeedProductionOption(list[index]) && list.length > index + 1) {
    index++;
  }
  for (let i = 0; i < list.length; i++) {
    if (list[i].time > list[index].time && !isSuperSpeedProductionOption(list[i])) {
      index = i;
    }
  }
  return index;
};
export const getDefaultProductionTimeIdx = list => {
  const idx = getStandardProductionTimeIndex(list);
  if (idx !== -1) {
    return idx;
  }
  return getLongestProductionTimeIdx(list);
};
export const prepareCompanyArtFolderLogosForSaving = data => {
  var _Object$values;
  const logos = _flattenDeep2((_Object$values = Object.values(data)) !== null && _Object$values !== void 0 ? _Object$values : []).map(logo => _objectSpread({}, _pick2(logo, ['etag', 'preview', 'originalPreview', 'original', 'previewWOBackground', 'originalFileName']), {
    colors: _pick2(logo.colors, ['detectedColors', 'selectedColors', 'colorsToPrint', 'convertTo', 'isBackgroundRemoved', 'hasBackground', 'colorsNumber'])
  }));
  return logos;
};
export const mapImagesForSpec = (imagesForSpec, name) => {
  return imagesForSpec.map(image => _objectSpread({}, image, {
    alt: `Specification image for product - ${name}`
  }));
};
export const getProductionTimeIndexFromSettings = (settings, productionTime) => settings.productionTimeList.findIndex(({
  name,
  time,
  cost
}) => name === productionTime.name && time === productionTime.time && cost === productionTime.cost);
export const getShortestProductionTimeFromList = productionTimeList => {
  let result = productionTimeList[0] || {};
  for (const productionTime of productionTimeList) {
    if (productionTime.time < result.time) {
      result = productionTime;
    }
  }
  return result;
};
const mapImagesWithoutImprintArea = ({
  imagesWithoutImprintArea = [],
  variantIndex
}) => {
  return imagesWithoutImprintArea.filter(image => image).map(image => _objectSpread({}, image, {
    isImageWithImprintArea: false,
    variantIndex
  }));
};
const mapImagesWithImprintArea = ({
  images,
  variantIndex
}) => {
  return Object.keys(images).map(viewName => {
    return _objectSpread({}, images[viewName], {
      isImageWithImprintArea: true,
      variantIndex,
      id: viewName
    });
  });
};
export const mergeVariantImagesAndImagesWithoutImprintArea = params => {
  const imagesWithoutImprintArea = mapImagesWithoutImprintArea(params);
  const imagesWithImprintArea = mapImagesWithImprintArea(params);
  const allImages = [...imagesWithImprintArea, ...imagesWithoutImprintArea].filter(image => !!image && !!image.url);
  if (params.brandedImage) {
    allImages.push(params.brandedImage);
  }
  return allImages;
};
export const getCurrentImages = ({
  productLoaded,
  isFullDesignProduct,
  isBoxProduct,
  variantIndex,
  currentVariantImages,
  brandedImage,
  imagesWithoutImprintArea = []
}) => {
  if (!productLoaded) {
    return [];
  }
  if (isFullDesignProduct || isBoxProduct) {
    const imagesArr = imagesWithoutImprintArea.map(images => _objectSpread({}, images, {
      variantIndex
    }));
    if (brandedImage) {
      imagesArr.push(brandedImage);
    }
    return imagesArr;
  }
  const images = mergeVariantImagesAndImagesWithoutImprintArea({
    images: currentVariantImages,
    variantIndex,
    imagesWithoutImprintArea,
    brandedImage
  });
  return images.sort((a, b) => {
    if (a.isImageWithImprintArea === b.isImageWithImprintArea) {
      return 0;
    }
    return a.isImageWithImprintArea ? -1 : 1;
  });
};
export const getCurrentVariantImages = (variant, locations, {
  dynamicQuantityAndLocationsOptionsForDecorationMethods
}) => {
  if (!variant) {
    return {};
  }
  return Object.keys(variant.images).reduce((result, location) => {
    var _variant$images$locat;
    const variantImage = (_variant$images$locat = variant.images[location]) === null || _variant$images$locat === void 0 ? void 0 : _variant$images$locat[0];
    if (!dynamicQuantityAndLocationsOptionsForDecorationMethods) {
      if (variantImage) {
        result[location] = variantImage;
      }
      return result;
    }
    const locationData = locations === null || locations === void 0 ? void 0 : locations[location];
    if (variantImage) {
      const image = {
        url: variantImage.url
      };
      if (locationData) {
        image.name = locationData.name;
        image.size = locationData.size;
        image.rectangle = locationData.rectangle;
      }
      result[location] = image;
    }
    return result;
  }, {});
};
const prepareLogosForRelatedItemFromVariants = ({
  itemLogos,
  associatedProduct,
  mainProduct
}) => {
  return Object.keys(itemLogos).reduce((result, location) => {
    var _associatedProduct$va, _associatedProduct$va2, _mainProduct$variants, _mainProduct$variants2;
    const associatedProductImages = (_associatedProduct$va = (_associatedProduct$va2 = associatedProduct.variants[0]) === null || _associatedProduct$va2 === void 0 ? void 0 : _associatedProduct$va2.images) !== null && _associatedProduct$va !== void 0 ? _associatedProduct$va : {};
    const mainProductImages = (_mainProduct$variants = (_mainProduct$variants2 = mainProduct.variants[0]) === null || _mainProduct$variants2 === void 0 ? void 0 : _mainProduct$variants2.images) !== null && _mainProduct$variants !== void 0 ? _mainProduct$variants : {};
    const associatedProductImagesKeys = Object.keys(associatedProductImages);
    if (associatedProductImagesKeys.length > 0 && !associatedProductImagesKeys.includes(location)) {
      var _mainProductImages$lo;
      const mainLogoLocation = (_mainProductImages$lo = mainProductImages[location]) === null || _mainProductImages$lo === void 0 ? void 0 : _mainProductImages$lo[0].name;
      let associatedLogoLocation = mainLogoLocation || '';
      if (!associatedProductImages[associatedLogoLocation]) {
        associatedLogoLocation = associatedProductImagesKeys.find(key => {
          var _associatedProductIma;
          return ((_associatedProductIma = associatedProductImages[key]) === null || _associatedProductIma === void 0 ? void 0 : _associatedProductIma[0].name) === mainLogoLocation;
        });
      }
      if (associatedLogoLocation) {
        result[associatedLogoLocation] = [_objectSpread({}, itemLogos[location][0], {
          location: associatedLogoLocation
        })];
      }
    } else {
      result[location] = itemLogos[location];
    }
    return result;
  }, {});
};
export const prepareLogosForRelatedItem = params => {
  var _mainProduct$variants3, _associatedProduct$va3;
  const {
    featureFlags,
    itemLogos,
    mainProduct,
    associatedProduct,
    embellishmentMethodId
  } = params;
  if (!featureFlags.dynamicQuantityAndLocationsOptionsForDecorationMethods) {
    return prepareLogosForRelatedItemFromVariants(params);
  }
  const mainProductVariantId = (_mainProduct$variants3 = mainProduct.variants[0]) === null || _mainProduct$variants3 === void 0 ? void 0 : _mainProduct$variants3.variantId;
  const associatedProductVariantId = (_associatedProduct$va3 = associatedProduct.variants[0]) === null || _associatedProduct$va3 === void 0 ? void 0 : _associatedProduct$va3.variantId;
  return Object.keys(itemLogos).reduce((result, location) => {
    var _mainProductLocations;
    const associatedProductLocations = getLocationsDataFromProductSettings({
      productSettings: associatedProduct.productSettings || [],
      embellishmentMethodId,
      variantId: associatedProductVariantId
    });
    const associatedProductLocationsKeys = Object.keys(associatedProductLocations);
    if (associatedProductLocationsKeys.includes(location)) {
      result[location] = itemLogos[location];
      return result;
    }
    const mainProductLocations = getLocationsDataFromProductSettings({
      productSettings: mainProduct.productSettings || [],
      embellishmentMethodId,
      variantId: mainProductVariantId
    });
    const mainLogoLocation = (_mainProductLocations = mainProductLocations[location]) === null || _mainProductLocations === void 0 ? void 0 : _mainProductLocations.name;
    let associatedLogoLocation = mainLogoLocation || '';
    if (!associatedProductLocations[associatedLogoLocation]) {
      associatedLogoLocation = associatedProductLocationsKeys.find(key => {
        var _associatedProductLoc;
        return ((_associatedProductLoc = associatedProductLocations[key]) === null || _associatedProductLoc === void 0 ? void 0 : _associatedProductLoc.name) === mainLogoLocation;
      }) || '';
    }
    if (associatedLogoLocation) {
      result[associatedLogoLocation] = itemLogos[location].map(logo => _objectSpread({}, logo, {
        location: associatedLogoLocation
      }));
    }
    return result;
  }, {});
};
export const getLogosByLocation = ({
  layers,
  logos,
  currentLocations,
  product,
  featureFlags
}) => {
  return Object.keys(layers).reduce((result, location) => {
    if (product.designUploadMode !== DesignUploadMode.fullDesign && (!currentLocations || !currentLocations[location])) {
      //TODO: [SWAG-23643] - Remove only nested if and return "result"
      if (featureFlags.dynamicQuantityAndLocationsOptionsForDecorationMethods) {
        return result;
      }
    }
    const logosForLocation = layers[location].reduce((acc, mediaId) => {
      if (logos[mediaId]) {
        acc.push(_objectSpread({}, logos[mediaId], {
          location
        }));
      }
      return acc;
    }, []);
    return _objectSpread({}, result, {
      [location]: logosForLocation
    });
  }, {});
};
export const getRelatedProductActiveSettings = (productSettings = [], embellishmentMethodId) => {
  const settingsForCurrentEmbellishmentMethod = productSettings.find(setting => setting.embellishmentMethodId === embellishmentMethodId);
  if (settingsForCurrentEmbellishmentMethod) {
    return settingsForCurrentEmbellishmentMethod;
  }
  const firstNotBlankProductSetting = productSettings.find(setting => setting.embellishmentMethodId);
  return firstNotBlankProductSetting || null;
};
function getVariantIndexes(variants) {
  return variants.reduce((acc, item, index) => {
    acc[item.productSku] = index;
    return acc;
  }, {});
}
export function getProductVariantsToChoose(product) {
  var _product$limitedEditi, _product$limitedEditi2;
  const outOfStockThreshold = getProductOutOfStockThreshold(product);
  const variants = getVisibleProductVariants(product);
  const isAlwaysInStock = getIsAlwaysInStock(product);
  const productInStockLevelsByVariantSku = getProductInStockLevelsByVariantSku(product);
  const totalInStock = getTotalInStock(productInStockLevelsByVariantSku);
  const variantIndexesBySku = getVariantIndexes(product.variants);
  const isLE = product === null || product === void 0 ? void 0 : (_product$limitedEditi = product.limitedEdition) === null || _product$limitedEditi === void 0 ? void 0 : _product$limitedEditi.isLimitedEdition;
  const isInfinity = product === null || product === void 0 ? void 0 : (_product$limitedEditi2 = product.limitedEdition) === null || _product$limitedEditi2 === void 0 ? void 0 : _product$limitedEditi2.isInfinity;
  const minQuant = product === null || product === void 0 ? void 0 : product.minQuant;
  const indexedVariants = variants.map(v => _objectSpread({}, v, {
    index: variantIndexesBySku[v.productSku]
  }));
  if (!outOfStockThreshold && outOfStockThreshold !== 0) {
    return indexedVariants;
  }
  if (isLE && isInfinity) {
    return indexedVariants;
  }
  if (isLE && !isInfinity && totalInStock < minQuant) {
    return [];
  }
  const inStockVariants = getInStockVariants({
    variants,
    isAlwaysInStock,
    productInStockLevelsByVariantSku,
    outOfStockThreshold,
    isLE
  });
  return inStockVariants.map(variant => _objectSpread({}, variant, {
    index: variantIndexesBySku[variant.productSku]
  }));
}
export const mapRelatedProduct = ({
  product,
  color,
  view,
  embellishmentMethodId,
  featureFlags
}) => {
  const relatedProductVariant = getVariantWithNearestColor(getProductVariantsToChoose(product), color);
  const fallback = product.variants[0];
  const source = relatedProductVariant || fallback;
  const imagesBySide = source.images[view] || source.images[FRONT];
  const activeProductSettings = getRelatedProductActiveSettings(product.productSettings, embellishmentMethodId);
  const productSettings = activeProductSettings ? [activeProductSettings] : product.productSettings || [];
  const edgePrices = Price.calculateDefaultEdgePrice({
    product: _objectSpread({}, product, {
      productSettings
    }),
    featureFlags
  });
  const minQuant = featureFlags.dynamicQuantityAndLocationsOptionsForDecorationMethods ? (activeProductSettings === null || activeProductSettings === void 0 ? void 0 : activeProductSettings.minQuant) || 0 : product.minQuant;
  return _objectSpread({}, product, edgePrices, {
    minQuant,
    logoThumbnail: imagesBySide && imagesBySide[0]
  });
};
const getSetupFeeForPriceBreaks = ({
  screenPrice = 0,
  addOneColor = false
} = {}) => addOneColor ? screenPrice * 2 : screenPrice;
const defaultLogos = {
  front: [{
    colors: {
      colorsToPrint: ['White'],
      colorsNumber: DEFAULT_MINIMUM_COLORS_NUMBER
    }
  }],
  back: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }],
  left: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }],
  right: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }]
};
export const getPricesWithQuantity = ({
  product,
  orderItem,
  featureFlags
}) => {
  var _orderItem$prod, _product$productSetti;
  const currentProductSettings = (orderItem === null || orderItem === void 0 ? void 0 : (_orderItem$prod = orderItem.prod) === null || _orderItem$prod === void 0 ? void 0 : _orderItem$prod.productSettings[0]) || ((_product$productSetti = product.productSettings) === null || _product$productSetti === void 0 ? void 0 : _product$productSetti[0]) || [];
  const formattedPriceBreaks = mergePriceBreaks({
    basePriceQuantities: product.quantities,
    printRunQuantities: currentProductSettings === null || currentProductSettings === void 0 ? void 0 : currentProductSettings.printRunQuantities
  });
  const availablePriceBreakQuantities = formattedPriceBreaks.filter(q => q > currentProductSettings.minQuant);
  const quantitiesToShow = [currentProductSettings.minQuant, ...availablePriceBreakQuantities];
  const {
    prodTime,
    discount,
    colors,
    logos,
    sizeInd,
    variantInd,
    defaultPrintingMethod
  } = orderItem;
  return quantitiesToShow.map(quantity => {
    const hasColor = orderItem.colorsNumber;
    const item = {
      quantity,
      discount,
      prodTime,
      variantInd,
      logos: hasColor && logos || defaultLogos,
      colors: colors || DEFAULT_MINIMUM_COLORS_NUMBER,
      sizeInd,
      defaultPrintingMethod,
      prod: orderItem.prod || {}
    };
    const price = getItemPriceFromProduct({
      orderItem: item,
      product,
      discardMultiplier: true,
      featureFlags
    });
    const setupFee = getSetupFeeForPriceBreaks(currentProductSettings);
    const cost = Price.getItemPriceWithoutSetUpFee({
      price,
      quantity,
      setupFee
    });
    return {
      quantity,
      cost
    };
  });
};
export const calculatePriceBreaks = prices => {
  var _prices$;
  const maxPrice = (_prices$ = prices[0]) === null || _prices$ === void 0 ? void 0 : _prices$.cost;
  return prices.map(item => {
    const saving = getPercentageDifference(maxPrice, item.cost);
    return _objectSpread({}, item, {
      saving
    });
  });
};
export const getColorsAmount = (logos, isFullDesignProduct) => {
  if (isFullDesignProduct) {
    return DEFAULT_MINIMUM_COLORS_NUMBER;
  }
  return Object.keys(logos).reduce((colorsSum, location) => {
    const number = logos[location].reduce((colorsSumPerLocation, nextLogo) => nextLogo.colors.colorsNumber + colorsSumPerLocation, 0);
    return number + colorsSum;
  }, 0);
};
export const getColorsNumberForItem = (logosColorsAmount, isFullDesignProduct) => {
  if (isFullDesignProduct) {
    return logosColorsAmount > 0 ? DEFAULT_MINIMUM_COLORS_NUMBER : 0;
  }
  return logosColorsAmount;
};
export const getVariantViews = (availableViews, variantLocations) => availableViews.map(v => v.id).filter(view => !!variantLocations[view]);
export const getActiveView = (variantViews, layers, currentView) => {
  if (isViewHasLogo(currentView, layers) && variantViews.includes(currentView)) {
    return currentView;
  }
  const firstViewWithLogo = variantViews.find(view => isViewHasLogo(view, layers));
  if (firstViewWithLogo) {
    return firstViewWithLogo;
  }
  return variantViews[0];
};