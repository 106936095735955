function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { v4 } from 'uuid';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { PREFIX } from 'swag-client-common/redux/company-art-folder/company-art-folder.actions';
import { COMPANY_ART_FOLDER_POPUP, MAX_PRINT_COLORS_LIMIT_EXCEEDED_POPUP } from 'swag-client-common/constants';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CompanyArtFolderApi } from '../../api/company-art-folder.api';
import { changeEmbellishmentMethodWithMaxPrintColorsValidation, changeView } from '../../redux/product/product.actions';
import { updateLogoDetails } from '../../redux/logo/logo.actions';
import { setDataToProcess } from '../logo-editing/logo-editing.actions';
import { notValidatedEmbellishmentSelector } from '../product/product.selectors';
import { editingLogoIdSelector, maxPrintColorsAmountSelector } from '../logo-editing/logo-editing.selectors';
import { selectedLogoViewSelector, getCurrentPageSelector, getPerPageSelector } from './company-art-folder.selectors';
export const DEFAULT_ART_FOLDER_PER_PAGE = 1;
export const GET_LOGOS_LIST_REQUEST = `${PREFIX}GET_LOGOS_LIST_REQUEST`;
export const GET_LOGOS_LIST_FAIL = `${PREFIX}GET_LOGOS_LIST_FAIL`;
export const GET_LOGOS_LIST_SUCCESS = `${PREFIX}GET_LOGOS_LIST_SUCCESS`;
export const getCompanyArtFolderList = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_LOGOS_LIST_REQUEST
  }),
  transformData: (_, getState) => {
    const state = getState();
    const currentPage = getCurrentPageSelector(state);
    const perPage = getPerPageSelector(state);
    return {
      page: currentPage + 1,
      perPage
    };
  },
  fn: CompanyArtFolderApi.getList,
  success: body => ({
    type: GET_LOGOS_LIST_SUCCESS,
    payload: body
  }),
  error: error => ({
    type: GET_LOGOS_LIST_FAIL,
    error
  })
});
export const SET_UPLOAD_LOGO_VIEW = `${PREFIX}SET_UPLOAD_LOGO_VIEW`;
export const setLogoView = view => ({
  type: SET_UPLOAD_LOGO_VIEW,
  payload: view
});
export const openCompanyArtFolder = view => dispatch => {
  dispatch(setLogoView(view));
  dispatch(showPopup({
    popup: COMPANY_ART_FOLDER_POPUP
  }));
};
export const selectArtFolderLogo = logoData => (dispatch, getState) => {
  const state = getState();
  const selectedView = selectedLogoViewSelector(state);
  const productMaxPrintColors = maxPrintColorsAmountSelector(state);
  const {
      colors
    } = logoData,
    rest = _objectWithoutProperties(logoData, ["colors"]);
  const selectedColorsNumber = colors.colorsToPrint.length;
  const isLogoEditingFlow = notValidatedEmbellishmentSelector(state);
  const editingLogoId = editingLogoIdSelector(state);
  const id = isLogoEditingFlow ? editingLogoId : v4();
  if (productMaxPrintColors && productMaxPrintColors < selectedColorsNumber) {
    dispatch(setDataToProcess(_objectSpread({}, logoData, {
      id,
      location: selectedView
    })));
    return dispatch(showPopup({
      popup: MAX_PRINT_COLORS_LIMIT_EXCEEDED_POPUP
    }));
  }
  const logo = _objectSpread({}, rest, colors, {
    id,
    location: selectedView
  });
  dispatch(changeView(selectedView));
  dispatch(hidePopup());
  dispatch(updateLogoDetails(logo));
  dispatch(setLogoView(''));
  const notUpdatedEmbellishmentMethod = notValidatedEmbellishmentSelector(state);
  if (notUpdatedEmbellishmentMethod) {
    dispatch(changeEmbellishmentMethodWithMaxPrintColorsValidation(notUpdatedEmbellishmentMethod));
  }
};
export const DELETE_ART_FOLDER_LOGO_REQUEST = `${PREFIX}DELETE_ART_FOLDER_LOGO_REQUEST`;
export const DELETE_ART_FOLDER_LOGO_FAIL = `${PREFIX}DELETE_ART_FOLDER_LOGO_FAIL`;
export const DELETE_ART_FOLDER_LOGO_SUCCESS = `${PREFIX}DELETE_ART_FOLDER_LOGO_SUCCESS`;
export const deleteArtFolderLogo = createAsyncAction({
  preFn: dispatch => dispatch({
    type: DELETE_ART_FOLDER_LOGO_REQUEST
  }),
  fn: CompanyArtFolderApi.deleteArtFolderLogo,
  success: (_, {
    id
  }) => ({
    type: DELETE_ART_FOLDER_LOGO_SUCCESS,
    payload: id
  }),
  error: error => ({
    type: DELETE_ART_FOLDER_LOGO_FAIL,
    payload: {
      error
    },
    error
  }),
  postSuccess: dispatch => {
    dispatch(getCompanyArtFolderList());
  },
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});