import _isEmpty2 from "lodash/isEmpty";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { FilterType } from 'swag-client-common/interfaces/filters';
import { ALL_SWAG_CATEGORY } from 'swag-client-common/constants';
import { FILTER_CUSTOM_NAMES, FILTER_ITEMS_ORDER, FILTER_NAMES, FILTERS_ORDER, HIDDEN_FOR_CURATED_BRAND_STORE, PROD_TIME, PRODUCT_SORTING } from 'swag-common/constants/product.constants';
import { getStoreSettings } from 'swag-client-common/utils/get-store-settings';
export const NON_FILTER_PARAMS = ['page', 'sorting', 'perpage', 'open', 'query', 'notification', 'RR_WCID', 'RR_WCID_TTL', 'REFERRALCODE'];
export const FILTER_PREFIX = '__swag__';
export function getProductionTimeName(time) {
  const ranges = time.split(',').map(Number);
  if (!ranges[0] && ranges[1]) {
    return `${ranges[1]} business days or less`;
  }
  if (!ranges[1] && ranges[0]) {
    return `more than ${ranges[0] - 1} business days`;
  }
  return 'Unknown value';
}
export function getMinQuantityName(time) {
  const ranges = time.split(',').map(Number);
  if (!ranges[0] && ranges[1]) {
    return `${ranges[1]} or less`;
  }
  if (!ranges[1] && ranges[0]) {
    return `more than ${ranges[0] - 1}`;
  }
  return 'Unknown value';
}
export function formatMinQuantFilter(string) {
  const splitted = string.split(',');
  if (splitted[0] && splitted[1]) {
    return `${splitted[1]} or less`;
  }
  if (!splitted[1] && splitted[0]) {
    return `More than ${splitted[0] - 1}`;
  }
}
export function formatImprintLocationsAmountFilter(string) {
  const num = Number(string);
  if (isFinite(num)) {
    return `only ${string} location${num > 1 ? 's' : ''}`;
  }
  return 'fully custom';
}
export function getMaxPrintColorsName(string) {
  const splitted = string.split(',');
  if (Number(splitted[0]) > 0) {
    return `${splitted[0]} or more`;
  }
  return 'Unlimited colors';
}
const sortAttributesByMap = (map, fieldName) => (a, b) => {
  var _map$a$fieldName, _map$b$fieldName;
  const indexA = (_map$a$fieldName = map[a[fieldName]]) !== null && _map$a$fieldName !== void 0 ? _map$a$fieldName : Number.MAX_SAFE_INTEGER;
  const indexB = (_map$b$fieldName = map[b[fieldName]]) !== null && _map$b$fieldName !== void 0 ? _map$b$fieldName : Number.MAX_SAFE_INTEGER;
  return indexA - indexB;
};
const getTypeForFilter = filterId => {
  switch (filterId) {
    case FILTER_NAMES.BOX_AND_INVENTORY:
      return FilterType.BOX_AND_INVENTORY;
    case FILTER_NAMES.DESIGN_COLOR_LIMIT:
    case FILTER_NAMES.LOCATIONS_TO_PRINT:
      return FilterType.RADIOBUTTON;
    case FILTER_NAMES.INTERATIONAL_SHIPPING:
      return FilterType.SHIPPING;
    default:
      return FilterType.CHECKBOX;
  }
};
export const getFilterDataOrder = (filterId, filterData) => {
  if (FILTER_ITEMS_ORDER[filterId]) {
    return filterData.sort(sortAttributesByMap(FILTER_ITEMS_ORDER[filterId], 'name'));
  }
  return filterData;
};
export function sortFiltersInMarketingOrder(filters, options = {
  isCuratedBrandStore: false
}) {
  let updatedFilters = filters.filter(Boolean);
  if (options.isCuratedBrandStore) {
    updatedFilters = updatedFilters.filter(filter => !HIDDEN_FOR_CURATED_BRAND_STORE.includes(filter.filterId));
  }
  return updatedFilters.sort(sortAttributesByMap(FILTERS_ORDER, 'filterId')).map(filter => _objectSpread({}, filter, {
    filterName: FILTER_CUSTOM_NAMES[filter.filterId] || filter.filterName,
    type: getTypeForFilter(filter.filterId),
    filterData: getFilterDataOrder(filter.filterId, filter.filterData)
  }));
}
export function facetsCombiner(facets) {
  if (!facets || _isEmpty2(facets)) {
    return [];
  }
  const appliedFilterKeys = Object.keys(facets).filter(key => key !== 'nonAppliedFilterFacets');
  const fullFacets = facets.nonAppliedFilterFacets.filter(item => !facets[item.key]).concat(...appliedFilterKeys.map(key => ({
    key,
    buckets: facets[key]
  })));
  return fullFacets.reduce((acc, bucket) => {
    let isEveryNull = true;
    const attribute = {
      filterName: bucket.key,
      filterId: bucket.key,
      filterData: bucket.buckets.reduce((acc, item) => {
        const count = item.doc_count;
        if (count) {
          isEveryNull = false;
          acc.push({
            name: item.key,
            id: item.key,
            count
          });
        }
        return acc;
      }, []).sort((a, b) => a.name.localeCompare(b.name, 'en', {
        sensitivity: 'base'
      }))
    };
    if (!isEveryNull) {
      acc.push(attribute);
    }
    return acc;
  }, []);
}
export const createFilter = (filter, attributesMap) => Object.keys(filter).reduce((fullFilter, nextFilter) => {
  switch (nextFilter) {
    case FilterIds.categoryId:
    case FilterIds.embellishmentMethod:
    case FilterIds.colorFamily:
      {
        if (filter[nextFilter]) {
          return _objectSpread({}, fullFilter, {
            [nextFilter]: filter[nextFilter].join(',')
          });
        }
        return fullFilter;
      }
    case FilterIds.price:
      {
        return _objectSpread({}, fullFilter, {
          [nextFilter]: createPriceQuery(filter[nextFilter])
        });
      }
    case FilterIds.includeSearchableOnly:
    case FilterIds.maxPrintColors:
    case FilterIds.minQuantity:
    case FilterIds.imprintLocationsAmount:
    case FilterIds.productionTime:
      {
        if (filter[nextFilter]) {
          return _objectSpread({}, fullFilter, {
            [nextFilter]: filter[nextFilter][0]
          });
        }
        return _objectSpread({}, fullFilter);
      }
    case FilterIds.isInternationalShipping:
    case FilterIds.isBox:
    case FilterIds.isInventory:
    case FilterIds.isSuperSpeed:
      {
        if (filter[nextFilter]) {
          return _objectSpread({}, fullFilter, {
            [nextFilter]: filter[nextFilter]
          });
        }
        return _objectSpread({}, fullFilter);
      }
    case attributesMap[nextFilter]:
      {
        return _objectSpread({}, fullFilter, {
          facetFilter: _objectSpread({}, fullFilter.facetFilter || {}, {
            [attributesMap[nextFilter]]: filter[nextFilter]
          })
        });
      }
    default:
      return _objectSpread({}, fullFilter);
  }
}, {});
const createPriceQuery = range => {
  const [min, max] = range;
  return `${min * 100},${max * 100}`;
};
export const getRangeMinEntity = number => Math.floor(Number(number / 100));
export const getRangeMaxEntity = number => Math.ceil(Number(number / 100));
export const getUrlParamsObject = () => new URLSearchParams(window.location.search);
export const addFilterPrefix = filterKey => `${FILTER_PREFIX}${filterKey}`;
export const removeFilterPrefix = filterKey => filterKey.replace(FILTER_PREFIX, '');
export const getInitialFilter = query => {
  const filter = {};
  query.forEach((value, key) => {
    if (NON_FILTER_PARAMS.includes(key)) {
      return false;
    }
    if (key.includes(FILTER_PREFIX)) {
      const filterKey = removeFilterPrefix(key);
      const parsedValue = Number(value) === Number(value) ? Number(value) : value;
      filter[filterKey] = filter[filterKey] ? filter[filterKey].concat([parsedValue]) : [parsedValue];
    }
  });
  return filter;
};
export const getInitialAttributeMap = query => {
  const attributeMap = {};
  const hardcodedKeys = Object.keys(FilterIds).concat(NON_FILTER_PARAMS);
  query.forEach((_value, key) => {
    if (hardcodedKeys.includes(key)) {
      return false;
    }
    const filterKey = removeFilterPrefix(key);
    attributeMap[filterKey] = filterKey;
  });
  return attributeMap;
};
export const isFilterPopupOpen = query => {
  const open = query.get('open');
  if (!open) {
    return false;
  }
  return open === 'true';
};
export const transformCategories = categories => {
  const subcategories = categories.filter(cat => !!cat.parent_id);
  let mainCategories = categories.filter(cat => !cat.parent_id).map(cat => _objectSpread({}, cat, {
    subcategories: subcategories.filter(sub => sub.parent_id === cat._id)
  }));
  const categoriesMap = {};
  [...mainCategories, ...subcategories].forEach(cat => {
    categoriesMap[cat.slug] = cat;
  });
  const allSwag = {
    name: 'All Swag',
    slug: ALL_SWAG_CATEGORY,
    order: 1,
    subcategories: [],
    _id: ALL_SWAG_CATEGORY,
    isVisible: true
  };
  const categoriesForFilter = mainCategories.filter(category => category.isVisible).map(category => ({
    name: category.name,
    id: category._id
  }));
  mainCategories = [allSwag, ...mainCategories];
  categoriesMap[allSwag.slug] = allSwag;
  const logoCategories = categories.filter(item => item.logoUrl);
  return {
    categories: mainCategories,
    categoriesMap,
    categoriesForFilter,
    logoCategories
  };
};
export const isAllSwagCategory = () => window.location.href.indexOf('/' + ALL_SWAG_CATEGORY) > -1;
export const mapCategoryToFilter = (category, count) => ({
  name: category.name,
  order: category.order,
  id: category._id,
  count
});
export const getCategoryFacetCount = (categoryFacets, category) => {
  const categoryFacet = (categoryFacets.global || []).find(facet => facet.key === category._id);
  const count = categoryFacet && categoryFacet.doc_count;
  if (!count) {
    return null;
  }
  return count;
};
export const isActiveProdTimeLessThan5DaysFilter = prodTime => {
  return (prodTime === null || prodTime === void 0 ? void 0 : prodTime.length) === 1 && prodTime[0] === PROD_TIME.LESS_THAN_5_DAYS;
};
export const getInitialSorting = ({
  initialUrl,
  isProductListing
}) => {
  const sorting = initialUrl.get('sorting');
  const {
    defaultSorting
  } = getStoreSettings();
  if (sorting) {
    return sorting;
  }
  if (defaultSorting) {
    return isProductListing ? defaultSorting : PRODUCT_SORTING.RELEVANCE;
  }
  return isProductListing ? PRODUCT_SORTING.MOST_POPULAR : PRODUCT_SORTING.RELEVANCE;
};