import _omit from "lodash/omit";
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { find } from 'lodash/collection';
import { push, replace } from 'react-router-redux';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { DeliveryError, DRAFT_ERRORS } from 'swag-common/interfaces';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { SendToMultipleAddressesStep, UploadCSVStepScreen } from 'swag-common/constants/inventory';
import { getDefaultValue } from 'swag-common/utils/shipping';
import { InventoryUrl } from 'swag-common/utils/urls/inventory.url';
import { forcedPath } from 'swag-common/business-logic/redirect-wrapper-state';
import { CSV_COLUMNS, SHIPOUT_STATUSES } from 'swag-common/constants/custom-store-shipouts.constants';
import { instantScrollTop } from 'swag-client-common/utils/tricks';
import { getPossibleDeliveriesList } from 'swag-common/utils/shipout/get-possible-deliveries-list';
import { isAddressNotExistsByErrorData, isAddressNotFoundByErrorData, isAddressSuggestedByErrorData } from 'swag-client-common/utils/manual-address-enter.utils';
import { popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { AddressManualType, CONFIRM_YOUR_ADDRESS_POPUP, SHIPOUT_CSV_ERROR_POPUP, SHIPOUT_VALIDATION_POPUP, SMA_INVALID_DATA_POPUP } from 'swag-client-common/constants';
import checkIsReceiverDomestic from 'swag-common/utils/shipout/check-is-receiver-domestic';
import checkIsReceiverFlatRateSelected from 'swag-common/utils/shipout/check-is-receiver-flat-rate-selected';
import { emailValidator, integerValidator, phoneValidator, regularLengthValidator, validate } from 'swag-client-common/utils/validators';
import { COUNTRY_CODES, FlatRatesTypes } from 'swag-common/constants/shipping.constants';
import { getShipoutDetailsCsvData } from '../store-shipouts/store-shipouts.selectors.utils';
import { downloadCSVAction } from '../../middleware/csv-download.middleware';
import { deselectAllProducts, listInventoryProducts, toggleSelectionProduct } from '../products/products.actions';
import { deselectShipout, removeSelectedShipoutRequest, selectShipout } from '../store-shipouts/store-shipouts.actions';
import { isSelectedDemoInventorySelector, selectedInventorySelector } from '../inventory/inventory.selectors';
import { selectedShipoutIdSelector } from '../store-shipouts/store-shipouts.selectors';
import { trackShipmentCalculationProgress, cancelCalculation } from '../store-shipouts/track-shipment-calculation-progress.util';
import { cancelValidation, trackShipoutValidationProgress } from '../store-shipouts/track-shipout-validation-progress.util';
import ShipOutApi from '../../api/shipout.api';
import { hideLoader, showLoader } from '../../../common/redux/common/common.actions';
import { checkAccountStatus, checkShutdownRestriction } from '../../utils/enterprise-access-wrapper';
import { getCustomProductsWarehouseQuantities } from '../../utils/advance-distributions.utils';
import { activeStepScreenSelector, activeStepSelector, draftHeaderSelector, draftIdSelector, draftReceiversSelector, erroredReceiverAddressSelector, erroredReceiversArraySelector, erroredReceiversSelector, headerOriginalNamesOfTitlesSelector, invalidRowsInTableSelector, isDraftHeadersWithErrorsSelector, isDraftReceiversWithErrorsSelector, isDraftTableHeaderValidSelector, selectedRowInTableAddressSelector, selectedRowInTableReceiverIdSelector, selectedRowInTableSelector, sendToMultipleAddressesShipoutSelector, shipoutsInStockErrorsSelector, validationSelector, quantityHeaderKeysSelector, selectedReceiverIdSelector, newInvalidRowsSelector, selectedInternationalShippingMethodSelector, selectedDomesticShippingMethodSelector, getIsSmaWithAdvanceDistributionSelector, possibleDomesticDeliveriesSelector, possibleInternationalDeliveriesSelector } from './send-to-multiple-addresses.selectors';
const PREFIX = 'SEND_TO_MULTIPLE_ADDRESSES/';
export const SET_ACTIVE_STEP = `${PREFIX}SET_ACTIVE_STEP`;
export const setActiveStep = step => dispatch => {
  dispatch({
    type: SET_ACTIVE_STEP,
    payload: step
  });
  if (step === SendToMultipleAddressesStep.uploadCSV) {
    return dispatch(setActiveStepScreen(UploadCSVStepScreen.upload));
  }
  dispatch(setActiveStepScreen(null));
};
export const SET_ACTIVE_STEP_SCREEN = `${PREFIX}SET_ACTIVE_STEP_SCREEN`;
export const setActiveStepScreen = screen => {
  return {
    type: SET_ACTIVE_STEP_SCREEN,
    payload: {
      screen
    }
  };
};
export const SET_FILE_NAME = `${PREFIX}SET_FILE_NAME`;
export const setOriginalFileName = name => ({
  type: SET_FILE_NAME,
  payload: name
});
export const VALIDATE_SHIPOUT_PROGRESS = `${PREFIX}VALIDATE_SHIPOUT_PROGRESS`;
export const setValidationProgress = data => ({
  type: VALIDATE_SHIPOUT_PROGRESS,
  payload: data
});
export const RESET_VALIDATION_PROGRESS = `${PREFIX}RESET_VALIDATION_PROGRESS`;
export const resetValidationProgress = () => ({
  type: RESET_VALIDATION_PROGRESS
});
export const SET_VALIDATION_STATUS = `${PREFIX}SET_VALIDATION_STATUS`;
export const setValidationStatus = value => ({
  type: SET_VALIDATION_STATUS,
  payload: value
});
const CSVValidationTimeouts = [];
export const trackValidationProgress = () => (dispatch, getState) => {
  trackShipoutValidationProgress({
    onProgress: data => {
      const {
        value,
        total
      } = data;
      const isCompleted = value === total;
      const timeout = setTimeout(() => {
        dispatch(setValidationProgress(data));
        CSVValidationTimeouts.shift();
      }, (CSVValidationTimeouts.length + 1) * 1000);
      CSVValidationTimeouts.push(timeout);
      if (isCompleted) {
        setTimeout(() => {
          dispatch(getCustomProductsWarehouseQuantitiesSma());
          dispatch(getReceiversList());
        }, (CSVValidationTimeouts.length + 1) * 1000);
      }
    },
    onError: error => {
      CSVValidationTimeouts.forEach(timeout => clearTimeout(timeout));
      const {
        value,
        total
      } = validationSelector(getState());
      for (let i = value; i < total; i++) {
        const currentValue = i;
        setTimeout(() => {
          dispatch(setValidationProgress({
            value: currentValue + 1
          }));
        }, (currentValue - value) * 1000);
      }
      setTimeout(() => {
        dispatch(_objectSpread({
          type: UPLOAD_CSV_FAIL
        }, error));
        if (!error.isLoqateValidated) {
          dispatch(showPopup({
            popup: SHIPOUT_CSV_ERROR_POPUP
          }));
          return;
        }
        if (error.inStockErrors.length > 0) {
          dispatch(getReceiversList());
        }
      }, (total - value) * 1000);
    }
  });
};
const VALIDATE_REQUEST_SUCCESS = `${PREFIX}VALIDATE_REQUEST_SUCCESS`;
const VALIDATE_REQUEST_ERROR = `${PREFIX}VALIDATE_REQUEST_ERROR`;
export const validateShipout = createAsyncAction({
  preFn: dispatch => {
    dispatch(trackValidationProgress());
  },
  transformData: (data, getState) => {
    const state = getState();
    const draftId = draftIdSelector(state);
    const inventoryId = selectedInventorySelector(state);
    return {
      draftId,
      inventoryId
    };
  },
  fn: ShipOutApi.validate,
  success: VALIDATE_REQUEST_SUCCESS,
  error: VALIDATE_REQUEST_ERROR
});
export const cancelShipoutValidation = () => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  cancelValidation(userId);
  CSVValidationTimeouts.forEach(timeout => clearTimeout(timeout));
  dispatch({
    type: RESET_CALCULATION
  });
  dispatch(hidePopup());
};
export const UPLOAD_CSV_REQUEST = `${PREFIX}UPLOAD_CSV_REQUEST`;
export const UPLOAD_CSV_SUCCESS = `${PREFIX}UPLOAD_CSV_SUCCESS`;
export const UPLOAD_CSV_FAIL = `${PREFIX}UPLOAD_CSV_FAIL`;
export const uploadCsv = createAsyncAction({
  preFn: dispatch => {
    dispatch(hidePopup());
    dispatch({
      type: UPLOAD_CSV_REQUEST
    });
    dispatch(showLoader());
    dispatch({
      type: TURN_CALCULATION_ON
    });
    dispatch(setActiveStepScreen(UploadCSVStepScreen.upload));
  },
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const userId = userIdSelector(state);
    const selectedShipoutId = selectedShipoutIdSelector(state);
    data.append('inventoryId', inventoryId);
    data.append('userId', userId);
    data.append('shipoutId', selectedShipoutId);
    return data;
  },
  fn: ShipOutApi.uploadCSV,
  success: ({
    csvUrl,
    draftId
  }) => ({
    type: UPLOAD_CSV_SUCCESS,
    payload: {
      csvUrl,
      draftId
    }
  }),
  error: UPLOAD_CSV_FAIL,
  postSuccess: (dispatch, getState, data, _res) => {
    const {
      shipoutId
    } = _res;
    dispatch(hideLoader());
    dispatch(selectShipout(shipoutId));
    dispatch(validateShipout());
  },
  postError: (dispatch, getState, error) => {
    dispatch(hideLoader());
    if (error.status === 400) {
      const text = error.bigAmountOfHeaders ? 'Your CSV file has incorrect data format. ' : "Unfortunately, your CSV file doesn't contain all of the required information to complete the upload. ";
      dispatch(showPopup({
        popup: SMA_INVALID_DATA_POPUP,
        text
      }));
    }
  }
});
export const SET_SHIPOUT_UPLOAD = `${PREFIX}SET_SHIPOUT_UPLOAD`;
export const setShipoutUpload = shipoutId => (dispatch, getState) => {
  const state = getState();
  const shipout = state.shipouts.csv.find(item => item._id.toString() === shipoutId);
  dispatch({
    type: SET_SHIPOUT_UPLOAD,
    payload: shipout
  });
};
export const TURN_CALCULATION_ON = `${PREFIX}TURN_CALCULATION_ON`;
export const getShipoutForSMA = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
  },
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return _objectSpread({}, data, {
      inventoryId
    });
  },
  fn: ShipOutApi.getById,
  success: shipout => ({
    type: SET_SHIPOUT_UPLOAD,
    payload: shipout
  }),
  error: error => ({
    type: SET_SHIPOUT_UPLOAD,
    payload: null,
    error
  }),
  postSuccess: (dispatch, getState) => {
    var _shipout$receivers;
    dispatch(hideLoader());
    const state = getState();
    const erroredReceivers = erroredReceiversArraySelector(state);
    const shipout = sendToMultipleAddressesShipoutSelector(state);
    if (!shipout) {
      return;
    }
    const {
      previousLocationBeforeTransitions
    } = state.routing;
    if (!previousLocationBeforeTransitions || previousLocationBeforeTransitions !== null && previousLocationBeforeTransitions !== void 0 && previousLocationBeforeTransitions.pathname.endsWith('/activity/csv')) {
      dispatch(validateShipout());
    }
    dispatch(listInventoryProducts({
      inventoryId: shipout.fromInventoryId
    }));
    dispatch(setOriginalFileName(shipout.originalFileName));
    shipout.products.map(({
      parentId
    }) => dispatch(toggleSelectionProduct({
      productId: String(parentId),
      isSelected: true
    })));
    const shipoutId = String(shipout._id);
    dispatch(selectShipout(shipoutId));
    dispatch(getCustomProductsWarehouseQuantitiesSma({
      shipoutId
    }));
    if (shipout.status === SHIPOUT_STATUSES.SAVED) {
      return;
    }
    if ((_shipout$receivers = shipout.receivers[shipout.receivers.length - 1]) !== null && _shipout$receivers !== void 0 && _shipout$receivers.shipment) {
      dispatch(preselectShippingMethod(shipout));
      dispatch(calculateCsvPossibleDeliveriesCosts(shipout));
    }
    if ([SHIPOUT_STATUSES.SHIPMENT_REQUESTED, SHIPOUT_STATUSES.SHIPMENT_REQUEST_PROGRESS, SHIPOUT_STATUSES.SHIPMENT_IN_PROGRESS, SHIPOUT_STATUSES.SHIPPED, SHIPOUT_STATUSES.DELIVERED, SHIPOUT_STATUSES.SCHEDULED].includes(shipout.status) || shipout.paymentMethod !== CREDIT_CARD && Object.prototype.hasOwnProperty.call(shipout, 'paidAt')) {
      return dispatch(setActiveStep(SendToMultipleAddressesStep.success));
    }
    if (shipout.status === SHIPOUT_STATUSES.SHIPMENT_CALCULATION_PROGRESS) {
      dispatch({
        type: TURN_CALCULATION_ON
      });
    }
    if (!erroredReceivers.length && shipout.status !== SHIPOUT_STATUSES.SHIPMENT_CALCULATION_PROGRESS) {
      dispatch(setActiveStep(SendToMultipleAddressesStep.shipmentSetup));
    }
  },
  postError: dispatch => {
    dispatch(hideLoader());
    dispatch(goToInventoryPageAction());
  }
});
export const GET_SHIPOUT_AFTER_CALCULATION_REQUEST = `${PREFIX}GET_SHIPOUT_AFTER_CALCULATION_REQUEST`;
export const GET_SHIPOUT_AFTER_CALCULATION_SUCCESS = `${PREFIX}GET_SHIPOUT_AFTER_CALCULATION_SUCCESS`;
export const GET_SHIPOUT_AFTER_CALCULATION_FAIL = `${PREFIX}GET_SHIPOUT_AFTER_CALCULATION_FAIL`;
export const getShipoutAfterCalculation = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_SHIPOUT_AFTER_CALCULATION_REQUEST
    });
  },
  fn: ShipOutApi.getById,
  success: shipout => ({
    type: GET_SHIPOUT_AFTER_CALCULATION_SUCCESS,
    payload: shipout
  }),
  error: GET_SHIPOUT_AFTER_CALCULATION_FAIL,
  postSuccess: (dispatch, getState, _, shipout) => {
    dispatch(preselectShippingMethod(shipout));
    dispatch(calculateCsvPossibleDeliveriesCosts(shipout));
  }
});
export const CALCULATE_SHIPOUT_COSTS_REQUEST = `${PREFIX}CALCULATE_SHIPOUT_COSTS_REQUEST`;
export const CALCULATE_SHIPOUT_COSTS_SUCCESS = `${PREFIX}CALCULATE_SHIPOUT_COSTS_SUCCESS`;
export const CALCULATE_SHIPOUT_COSTS_FAIL = `${PREFIX}CALCULATE_SHIPOUT_COSTS_FAIL`;
export const CALCULATE_SHIPOUT_COSTS_PROGRESS = `${PREFIX}CALCULATE_SHIPOUT_COSTS_PROGRESS`;
export const trackCalculationProgress = () => (dispatch, getState) => {
  const state = getState();
  trackShipmentCalculationProgress({
    userId: userIdSelector(state),
    onProgress: data => {
      const {
        currentLine,
        total,
        shipoutId
      } = data;
      const isCompleted = currentLine === total;
      dispatch({
        type: CALCULATE_SHIPOUT_COSTS_PROGRESS,
        payload: data
      });
      if (isCompleted) {
        const erroredReceivers = erroredReceiversArraySelector(getState());
        if (erroredReceivers.length) {
          dispatch(setActiveStep(SendToMultipleAddressesStep.uploadCSV));
          dispatch(setActiveStepScreen(UploadCSVStepScreen.table));
          dispatch(getReceiversList());
          return;
        }
        dispatch(getShipoutAfterCalculation({
          shipoutId
        }));
      }
    }
  });
};
export const GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_REQUEST = `${PREFIX}GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_REQUEST`;
export const GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_SUCCESS = `${PREFIX}GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_SUCCESS`;
export const GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_FAILURE = `${PREFIX}GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_FAILURE`;
const getCustomProductsWarehouseQuantitiesSma = getCustomProductsWarehouseQuantities({
  requestActionType: GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_REQUEST,
  successActionType: GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_SUCCESS,
  failureActionType: GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_FAILURE
});
export const GET_RECEIVERS_LIST_REQUEST = `${PREFIX}GET_RECEIVERS_LIST_REQUEST`;
export const GET_RECEIVERS_LIST_SUCCESS = `${PREFIX}GET_RECEIVERS_LIST_SUCCESS`;
export const GET_RECEIVERS_LIST_ERROR = `${PREFIX}GET_RECEIVERS_LIST_ERROR`;
export const getReceiversList = createAsyncAction({
  transformData: (data = {}, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const draftId = draftIdSelector(state);
    return {
      draftId: (data === null || data === void 0 ? void 0 : data.draftId) || draftId,
      inventoryId: (data === null || data === void 0 ? void 0 : data.inventoryId) || inventoryId
    };
  },
  preFn: dispatch => {
    dispatch({
      type: GET_RECEIVERS_LIST_REQUEST
    });
    dispatch(showLoader());
  },
  fn: ShipOutApi.getReceiversFromCSV,
  success: payload => ({
    type: GET_RECEIVERS_LIST_SUCCESS,
    payload
  }),
  error: error => ({
    type: GET_RECEIVERS_LIST_ERROR,
    error
  }),
  postSuccess: (dispatch, getState, transformedData, {
    productsMap,
    shipoutId
  }) => {
    dispatch(hideLoader());
    dispatch(hidePopup());
    const state = getState();
    const receiversErrors = isDraftReceiversWithErrorsSelector(state);
    const headersErrors = isDraftHeadersWithErrorsSelector(state);
    const shipoutsInStockErrors = shipoutsInStockErrorsSelector(state);
    const activeScreen = activeStepScreenSelector(state);
    const step = activeStepSelector(state);
    const inventoryId = selectedInventorySelector(state);
    const isAdvanceDistribution = getIsSmaWithAdvanceDistributionSelector(state);
    const href = InventoryUrl.getSMAPage(inventoryId, shipoutId);
    if (!window.location.href.includes(href)) {
      dispatch(push(InventoryUrl.getSMAPage(inventoryId, shipoutId)));
    }
    productsMap.map(({
      id
    }) => dispatch(toggleSelectionProduct({
      productId: id,
      isSelected: true
    })));
    if (receiversErrors || headersErrors || shipoutsInStockErrors.length > 0) {
      dispatch(setActiveStepScreen(UploadCSVStepScreen.table));
      dispatch(resetValidationProgress());
      return;
    }
    dispatch(setValidationStatus(true));
    const isAdvanceDistributionOnShipmentStep = step === SendToMultipleAddressesStep.shipmentSetup && isAdvanceDistribution;
    if (activeScreen === UploadCSVStepScreen.table || step === SendToMultipleAddressesStep.uploadCSV || isAdvanceDistributionOnShipmentStep) {
      dispatch(calculateShipoutCosts());
    }
  },
  postError: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: 'List of addresses was not received'
    }));
  }
});
export const calculateShipoutCosts = createAsyncAction({
  transformData: (data, getState) => {
    var _smaShipout$reserveTr;
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const draftId = draftIdSelector(state);
    const smaShipout = sendToMultipleAddressesShipoutSelector(state);
    const shouldRemoveExistingReceivers = Boolean(smaShipout === null || smaShipout === void 0 ? void 0 : (_smaShipout$reserveTr = smaShipout.reserveTransactions) === null || _smaShipout$reserveTr === void 0 ? void 0 : _smaShipout$reserveTr.length);
    const payload = {
      inventoryId,
      draftId
    };
    if (shouldRemoveExistingReceivers) {
      payload.removeExistingReceivers = shouldRemoveExistingReceivers;
    }
    return payload;
  },
  preFn: dispatch => {
    dispatch({
      type: CALCULATE_SHIPOUT_COSTS_REQUEST
    });
    dispatch(showLoader());
  },
  fn: ShipOutApi.calculateCosts,
  success: shipout => ({
    type: CALCULATE_SHIPOUT_COSTS_SUCCESS,
    payload: shipout
  }),
  error: CALCULATE_SHIPOUT_COSTS_FAIL,
  postSuccess: (dispatch, getState, _, res) => {
    const {
      fromInventoryId,
      _id,
      originalFileName
    } = res;
    dispatch(hideLoader());
    dispatch(setOriginalFileName(originalFileName));
    dispatch(replace(InventoryUrl.getSMAPage(fromInventoryId, _id.toString())));
    dispatch(selectShipout(_id.toString()));
    dispatch(trackCalculationProgress());
    dispatch(setActiveStep(SendToMultipleAddressesStep.shipmentSetup));
  },
  postError: (dispatch, getState, error) => {
    dispatch(hideLoader());
    dispatch(setActiveStep(SendToMultipleAddressesStep.uploadCSV));
    if (error.data.validation) {
      return dispatch(showPopup({
        popup: SHIPOUT_CSV_ERROR_POPUP
      }));
    }
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const SET_ERRORED_RECEIVERS_TO_EDIT = `${PREFIX}SET_ERRORED_RECEIVERS_TO_EDIT`;
export const setErroredReceiversToEdit = () => (dispatch, getState) => {
  const receivers = erroredReceiversSelector(getState());
  dispatch({
    type: SET_ERRORED_RECEIVERS_TO_EDIT,
    payload: receivers
  });
};
export const EDIT_ERRORED_RECEIVER = `${PREFIX}EDIT_ERRORED_RECEIVER`;
export const editErroredReceiver = (id, field, value) => ({
  type: EDIT_ERRORED_RECEIVER,
  payload: {
    id,
    field,
    value
  }
});
export const SET_ERRORED_RECEIVER = `${PREFIX}SET_ERRORED_RECEIVER`;
export const setErroredReceiver = (id, receiver) => ({
  type: SET_ERRORED_RECEIVER,
  payload: {
    id,
    receiver
  }
});
export const REEVALUATE_ERRORED_RECEIVER_ERROR = `${PREFIX}REEVALUATE_ERRORED_RECEIVER_ERROR`;
export const REEVALUATE_ERRORED_RECEIVER_SUCCESS = `${PREFIX}REEVALUATE_ERRORED_RECEIVER_SUCCESS`;
export const reevaluateErroredReceiver = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
  },
  transformData: ({
    _id,
    address
  }, getState) => {
    const state = getState();
    const shipout = sendToMultipleAddressesShipoutSelector(state);
    if (shipout) {
      const {
        _id: shipoutId
      } = shipout;
      const receiver = erroredReceiverAddressSelector(state, {
        receiverId: _id,
        address
      });
      const inventoryId = selectedInventorySelector(state);
      return {
        shipoutId,
        inventoryId,
        receiver: _omit(receiver, 'isEdited')
      };
    }
  },
  fn: ShipOutApi.reevaluateReceiver,
  success: (receiver, {
    shipoutId
  }) => ({
    type: REEVALUATE_ERRORED_RECEIVER_SUCCESS,
    payload: {
      receiver,
      shipoutId
    }
  }),
  error: (err, _, dispatch) => {
    dispatch(setErroredReceiver(err._id, err));
    return {
      type: REEVALUATE_ERRORED_RECEIVER_ERROR,
      err,
      error: err
    };
  },
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: 'Address was not verified'
    }));
  }
});
export const RESET_CALCULATION = `${PREFIX}RESET_CALCULATION`;
export const resetState = () => ({
  type: RESET_CALCULATION
});
export const resetCalculation = () => (dispatch, getState) => {
  const state = getState();
  cancelCalculation(userIdSelector(state));
  dispatch(resetState());
};
export const SELECT_SHIPPING_METHOD = `${PREFIX}SELECT_SHIPPING_METHOD`;
export const SELECT_INTERNATIONAL_SHIPPING_METHOD = `${PREFIX}SELECT_INTERNATIONAL_SHIPPING_METHOD`;
export const CALCULATE_SHIPPING_COSTS = `${PREFIX}CALCULATE_SHIPPING_COSTS`;
export const preselectShippingMethod = customShipout => (dispatch, getState) => {
  const {
    selectedShipoutId,
    pfi,
    csv
  } = getState().shipouts;
  const shipout = customShipout || find(pfi, {
    _id: selectedShipoutId
  }) || find(csv, {
    _id: selectedShipoutId
  });
  const domesticReceivers = [];
  const internationalReceivers = [];
  shipout.receivers.forEach(receiver => {
    if (checkIsReceiverDomestic(receiver)) {
      domesticReceivers.push(receiver);
    } else {
      internationalReceivers.push(receiver);
    }
  });
  const hasInternationalShipments = Boolean(internationalReceivers.length);
  const hasDomesticShipments = Boolean(domesticReceivers.length);
  if (hasDomesticShipments) {
    var _deliveryToProcess$sh;
    const deliveryToProcess = domesticReceivers.find(checkIsReceiverFlatRateSelected) || domesticReceivers.find(checkIsReceiverDomestic);
    const serviceCode = deliveryToProcess === null || deliveryToProcess === void 0 ? void 0 : (_deliveryToProcess$sh = deliveryToProcess.shipment) === null || _deliveryToProcess$sh === void 0 ? void 0 : _deliveryToProcess$sh.selectedDeliveryServiceCode;
    dispatch({
      type: SELECT_SHIPPING_METHOD,
      payload: {
        selectedDomesticShippingMethod: serviceCode
      }
    });
  }
  if (hasInternationalShipments) {
    const popularityMap = internationalReceivers.reduce(buildPopularityMapOfServiceCodes, {});
    const keysMap = Object.keys(popularityMap);
    const serviceCode = keysMap.includes(FlatRatesTypes.International) ? FlatRatesTypes.International : keysMap.reduce(findMostPopularServiceCode(popularityMap), null);
    dispatch({
      type: SELECT_INTERNATIONAL_SHIPPING_METHOD,
      payload: {
        selectedInternationalShippingMethod: serviceCode
      }
    });
  }
};
const buildPopularityMapOfServiceCodes = (popMap, cur) => {
  if (!cur.shipment) {
    return popMap;
  }
  const serviceCode = cur.shipment.selectedDeliveryServiceCode;
  if (Object.prototype.hasOwnProperty.call(popMap, serviceCode)) {
    popMap[serviceCode] += 1;
  } else {
    popMap[serviceCode] = 1;
  }
  return popMap;
};
const findMostPopularServiceCode = popularityMap => (res, cur) => {
  if (res === null) {
    return cur;
  }
  if (popularityMap[cur] > popularityMap[res]) {
    return cur;
  }
  return res;
};
export const calculateCsvPossibleDeliveriesCosts = customShipout => (dispatch, getState) => {
  var _possibleDomesticDeli, _possibleDomesticDeli2, _possibleInternationa, _possibleInternationa2;
  const {
    selectedShipoutId,
    pfi,
    csv
  } = getState().shipouts;
  const state = getState();
  const selectedDomesticShippingMethod = selectedDomesticShippingMethodSelector(state);
  const selectedInternationalShippingMethod = selectedInternationalShippingMethodSelector(state);
  const shipout = customShipout || find(pfi, {
    _id: selectedShipoutId
  }) || find(csv, {
    _id: selectedShipoutId
  });
  const domesticReceivers = [];
  const internationalReceivers = [];
  shipout.receivers.forEach(receiver => {
    if (checkIsReceiverDomestic(receiver)) {
      domesticReceivers.push(receiver);
    } else {
      internationalReceivers.push(receiver);
    }
  });
  const getDeliveryCodes = ({
    shipment
  }) => shipment && shipment.possibleDeliveries ? Object.keys(shipment.possibleDeliveries) : [];
  const possibleDomesticDeliveries = getPossibleDeliveriesList(domesticReceivers, getDeliveryCodes, ({
    shipment
  }) => shipment ? shipment.possibleDeliveries || {} : {}, ({
    shipment
  }) => shipment ? shipment.selectedDeliveryServiceCode || '' : '');
  const possibleInternationalDeliveries = getPossibleDeliveriesList(internationalReceivers, getDeliveryCodes, ({
    shipment
  }) => shipment ? shipment.possibleDeliveries || {} : {}, ({
    shipment
  }) => shipment ? shipment.selectedDeliveryServiceCode || '' : '');
  const domesticShippingPrice = (_possibleDomesticDeli = possibleDomesticDeliveries === null || possibleDomesticDeliveries === void 0 ? void 0 : (_possibleDomesticDeli2 = possibleDomesticDeliveries[selectedDomesticShippingMethod]) === null || _possibleDomesticDeli2 === void 0 ? void 0 : _possibleDomesticDeli2.shippingPrice) !== null && _possibleDomesticDeli !== void 0 ? _possibleDomesticDeli : 0;
  const internationalShippingPrice = (_possibleInternationa = possibleInternationalDeliveries === null || possibleInternationalDeliveries === void 0 ? void 0 : (_possibleInternationa2 = possibleInternationalDeliveries[selectedInternationalShippingMethod]) === null || _possibleInternationa2 === void 0 ? void 0 : _possibleInternationa2.shippingPrice) !== null && _possibleInternationa !== void 0 ? _possibleInternationa : 0;
  dispatch({
    type: CALCULATE_SHIPPING_COSTS,
    payload: {
      possibleDomesticDeliveries,
      possibleInternationalDeliveries,
      shippingPrice: internationalShippingPrice + domesticShippingPrice
    }
  });
};
export const proceedSelectDomesticShippingMethod = newDomesticShippingMethod => (dispatch, getState) => {
  const state = getState();
  const previousDomesticShippingMethod = selectedDomesticShippingMethodSelector(state);
  const currentInternationalShippingMethod = selectedInternationalShippingMethodSelector(state);
  if (newDomesticShippingMethod !== previousDomesticShippingMethod) {
    dispatch(selectDomesticShippingMethod(newDomesticShippingMethod));
    dispatch(setSelectedShippingMethodShipout({
      selectedDomesticShippingMethod: newDomesticShippingMethod,
      selectedInternationalShippingMethod: currentInternationalShippingMethod
    }));
  }
};
export const proceedSelectInternationalShippingMethod = newInternationalShippingMethod => (dispatch, getState) => {
  const state = getState();
  const previousInternationalShippingMethod = selectedInternationalShippingMethodSelector(state);
  const currentDomesticShippingMethod = selectedDomesticShippingMethodSelector(state);
  if (newInternationalShippingMethod !== previousInternationalShippingMethod) {
    dispatch(selectInternationalShippingMethod(newInternationalShippingMethod));
    dispatch(setSelectedShippingMethodShipout({
      selectedDomesticShippingMethod: currentDomesticShippingMethod,
      selectedInternationalShippingMethod: newInternationalShippingMethod
    }));
  }
};
const selectDomesticShippingMethod = method => (dispatch, getState) => {
  var _possibleDomesticDeli3, _possibleDomesticDeli4, _possibleInternationa3, _possibleInternationa4;
  const state = getState();
  const possibleDomesticDeliveries = possibleDomesticDeliveriesSelector(state);
  const possibleInternationalDeliveries = possibleInternationalDeliveriesSelector(state);
  const selectedInternationalShippingMethod = selectedInternationalShippingMethodSelector(state);
  const domesticShippingPrice = (_possibleDomesticDeli3 = (_possibleDomesticDeli4 = possibleDomesticDeliveries.find(met => met.serviceCode === method)) === null || _possibleDomesticDeli4 === void 0 ? void 0 : _possibleDomesticDeli4.shippingPrice) !== null && _possibleDomesticDeli3 !== void 0 ? _possibleDomesticDeli3 : 0;
  const internationalShippingPrice = (_possibleInternationa3 = (_possibleInternationa4 = possibleInternationalDeliveries.find(met => met.serviceCode === selectedInternationalShippingMethod)) === null || _possibleInternationa4 === void 0 ? void 0 : _possibleInternationa4.shippingPrice) !== null && _possibleInternationa3 !== void 0 ? _possibleInternationa3 : 0;
  dispatch({
    type: SELECT_SHIPPING_METHOD,
    payload: {
      selectedDomesticShippingMethod: method,
      shippingPrice: domesticShippingPrice + internationalShippingPrice
    }
  });
};
const selectInternationalShippingMethod = method => (dispatch, getState) => {
  var _possibleDomesticDeli5, _possibleDomesticDeli6, _possibleInternationa5, _possibleInternationa6;
  const state = getState();
  const possibleDomesticDeliveries = possibleDomesticDeliveriesSelector(state);
  const possibleInternationalDeliveries = possibleInternationalDeliveriesSelector(state);
  const selectedDomesticShippingMethod = selectedDomesticShippingMethodSelector(state);
  const domesticShippingPrice = (_possibleDomesticDeli5 = (_possibleDomesticDeli6 = possibleDomesticDeliveries.find(met => met.serviceCode === selectedDomesticShippingMethod)) === null || _possibleDomesticDeli6 === void 0 ? void 0 : _possibleDomesticDeli6.shippingPrice) !== null && _possibleDomesticDeli5 !== void 0 ? _possibleDomesticDeli5 : 0;
  const internationalShippingPrice = (_possibleInternationa5 = (_possibleInternationa6 = possibleInternationalDeliveries.find(met => met.serviceCode === method)) === null || _possibleInternationa6 === void 0 ? void 0 : _possibleInternationa6.shippingPrice) !== null && _possibleInternationa5 !== void 0 ? _possibleInternationa5 : 0;
  dispatch({
    type: SELECT_INTERNATIONAL_SHIPPING_METHOD,
    payload: {
      selectedInternationalShippingMethod: method,
      shippingPrice: domesticShippingPrice + internationalShippingPrice
    }
  });
};
export const SET_SELECTED_SHIPPING_METHOD_SHIPOUT_SUCCESS = `${PREFIX}SET_SELECTED_SHIPPING_METHOD_SHIPOUT_SUCCESS`;
export const SET_SELECTED_SHIPPING_METHOD_SHIPOUT_FAILURE = `${PREFIX}SET_SELECTED_SHIPPING_METHOD_SHIPOUT_FAILURE`;
export const setSelectedShippingMethodShipout = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const state = getState();
    const fromInventoryId = selectedInventorySelector(state);
    const shipoutId = selectedShipoutIdSelector(state);
    return {
      selectedDomesticShippingMethod: data.selectedDomesticShippingMethod,
      selectedInternationalShippingMethod: data.selectedInternationalShippingMethod,
      inventoryId: fromInventoryId,
      shipoutId
    };
  },
  fn: ShipOutApi.updateShipout,
  success: shipout => ({
    type: SET_SELECTED_SHIPPING_METHOD_SHIPOUT_SUCCESS,
    payload: shipout
  }),
  error: err => ({
    type: SET_SELECTED_SHIPPING_METHOD_SHIPOUT_FAILURE,
    err
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const minimizeCsvUploadPopup = () => (dispatch, getState) => {
  const inventoryId = selectedInventorySelector(getState());
  dispatch(replace(forcedPath(InventoryUrl.getMainPage(inventoryId))));
  dispatch(showPopup({
    popup: 'sendProductsOutMinimized'
  }));
};
export const resetSaveForLater = () => dispatch => {
  dispatch(resetCalculation());
  dispatch(deselectAllProducts());
  dispatch(deselectShipout());
  dispatch(hidePopup());
  dispatch(goToActivityCSVPageAction());
};
export const goToInventoryPageAction = () => (dispatch, getState) => {
  const inventoryId = selectedInventorySelector(getState());
  dispatch({
    type: RESET_CALCULATION
  });
  dispatch(replace(forcedPath(InventoryUrl.getMainPage(inventoryId))));
  instantScrollTop();
};
export const goToActivityCSVPageAction = () => (dispatch, getState) => {
  const inventoryId = selectedInventorySelector(getState());
  dispatch(replace(forcedPath(InventoryUrl.getActivitiesCSVPage(inventoryId))));
  instantScrollTop();
};
export const goToSMACreate = () => (dispatch, getState) => {
  const inventoryId = selectedInventorySelector(getState());
  dispatch(replace(forcedPath(InventoryUrl.getCreateSMA(inventoryId))));
};
const startToGoToSMAPage = ({
  shipoutId
}) => (dispatch, getState) => {
  const inventoryId = selectedInventorySelector(getState());
  dispatch(hidePopup());
  dispatch(push(InventoryUrl.getSMAPage(inventoryId, shipoutId)));
};
export const startToGoToSMAPageWrappedIntoCheckAccountStatus = checkAccountStatus(startToGoToSMAPage);
export const goToSMAPage = checkShutdownRestriction(startToGoToSMAPageWrappedIntoCheckAccountStatus);
export const cancelShipoutSetup = () => (dispatch, getState) => {
  const inventoryId = selectedInventorySelector(getState());
  dispatch(removeSelectedShipoutRequest());
  dispatch(deselectShipout());
  dispatch(resetCalculation());
  dispatch(listInventoryProducts({
    inventoryId
  }));
  dispatch(goToSMACreate());
};
export const downloadSMABreakdownAction = () => (dispatch, getState) => {
  const {
    headers,
    data
  } = getShipoutDetailsCsvData(sendToMultipleAddressesShipoutSelector(getState()));
  dispatch(downloadCSVAction([headers, ...data], 'Send_To_Multiple_Addresses'));
};
const handleResetSMAOnDemoExit = () => (dispatch, getState) => {
  const inventoryId = selectedInventorySelector(getState());
  dispatch(replace(forcedPath(InventoryUrl.getMainPage(inventoryId))));
  dispatch(deselectShipout());
  dispatch(deselectAllProducts());
  dispatch(resetCalculation());
  dispatch(listInventoryProducts({
    inventoryId
  }));
  dispatch(replace(forcedPath(InventoryUrl.getMainPage(inventoryId))));
  instantScrollTop();
};
export const saveForLaterSMA = () => (dispatch, getState) => {
  const isDemo = isSelectedDemoInventorySelector(getState());
  if (isDemo) {
    return dispatch(handleResetSMAOnDemoExit());
  }
  dispatch(showPopup({
    popup: 'confirmShipoutSaveForLaterPopup'
  }));
};
function showManualAddressEnterPopup(params) {
  return showPopup(_objectSpread({
    popup: CONFIRM_YOUR_ADDRESS_POPUP
  }, params));
}
function openManualAddressEnterPopup(receiverId, suggestedAddress, originalAddress) {
  return dispatch => {
    dispatch(showManualAddressEnterPopup({
      confirmAddresses: [_objectSpread({}, suggestedAddress, {
        addressType: AddressManualType.ADDRESS_SUGGESTED
      }), _objectSpread({}, originalAddress, {
        addressType: AddressManualType.ADDRESS_ENTERED
      })],
      onConfirmAddress: type => dispatch => {
        const address = type === AddressManualType.ADDRESS_ENTERED ? originalAddress : suggestedAddress;
        dispatch(updateDraftReceiver({
          address
        }));
        dispatch(hidePopup());
      },
      onEnterAddress: () => dispatch => {
        dispatch(hidePopup());
      }
    }));
  };
}
function openManualAddressErrorPopup(receiverId, originalAddress) {
  return dispatch => {
    dispatch(showManualAddressEnterPopup({
      confirmAddresses: [_objectSpread({}, originalAddress, {
        addressType: AddressManualType.ADDRESS_ENTERED
      })],
      onConfirmAddress: () => dispatch => {
        dispatch(updateDraftReceiver({
          address: originalAddress
        }));
        dispatch(hidePopup());
      },
      onEnterAddress: () => dispatch => {
        dispatch(hidePopup());
      }
    }));
  };
}
export const onManualAddressEnterPopupAction = (receiverId, error, originalAddress) => dispatch => {
  if (isAddressSuggestedByErrorData(error)) {
    dispatch(openManualAddressEnterPopup(receiverId, error.address, originalAddress));
  } else if (isAddressNotFoundByErrorData(error)) {
    dispatch(openManualAddressErrorPopup(receiverId, originalAddress));
  } else if (isAddressNotExistsByErrorData(error)) {
    dispatch(showNotification({
      text: "Can't validate address"
    }));
  } else {
    dispatch(openManualAddressErrorPopup(receiverId, originalAddress));
  }
};
export const SET_COLUMN_NAME = `${PREFIX}SET_COLUMN_NAME`;
export const setColumnName = ({
  value
}) => (dispatch, getState) => {
  const state = getState();
  const {
    columnName
  } = popupParamsSelector(state);
  const draftHeader = draftHeaderSelector(state);
  const header = [];
  draftHeader.forEach(item => {
    if (item.original === columnName) {
      header.push(_objectSpread({}, item, {
        error: false,
        title: value
      }));
    } else {
      const newItem = _objectSpread({}, item);
      if (item.title.toLowerCase() === value.toLowerCase()) {
        newItem.title = 'Unassigned';
        newItem.error = true;
      }
      header.push(newItem);
    }
  });
  dispatch({
    type: SET_COLUMN_NAME,
    payload: {
      draftHead: header
    }
  });
};
export const VALIDATE_HEAD_SUCCESS = `${PREFIX}VALIDATE_HEAD_SUCCESS`;
export const VALIDATE_HEAD_FAILURE = `${PREFIX}VALIDATE_HEAD_FAILURE`;
export const validateDraftHeader = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
  },
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const draftId = draftIdSelector(state);
    const header = draftHeaderSelector(state);
    return {
      draftId,
      inventoryId,
      header
    };
  },
  fn: ShipOutApi.updateDraft,
  success: draft => ({
    type: VALIDATE_HEAD_SUCCESS,
    payload: draft
  }),
  postSuccess: (dispatch, getState, transformedData, res, data) => {
    dispatch(hideLoader());
    if (data !== null && data !== void 0 && data.saveForLater) {
      return dispatch(resetSaveForLater());
    }
    dispatch(showPopup({
      popup: SHIPOUT_VALIDATION_POPUP
    }));
    dispatch(validateShipout());
  },
  error: err => ({
    type: VALIDATE_HEAD_FAILURE,
    err
  }),
  postError: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const UPDATE_DRAFT_RECEIVER_SUCCESS = `${PREFIX}UPDATE_DRAFT_RECEIVER_SUCCESS`;
export const UPDATE_DRAFT_RECEIVER_FAILURE = `${PREFIX}UPDATE_DRAFT_RECEIVER_FAILURE`;
export const updateDraftReceiver = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
  },
  transformData: ({
    address,
    saveForLater = false
  }, getState) => {
    const state = getState();
    const selectedRow = selectedRowInTableSelector(state);
    const inventoryId = selectedInventorySelector(state);
    const receivers = draftReceiversSelector(state);
    const transformedAddress = {};
    const selectedReceiver = receivers[selectedRow || 0];
    const {
        _id: receiverId
      } = selectedReceiver,
      selectedReceiverData = _objectWithoutProperties(selectedReceiver, ["_id"]);
    if (address) {
      const headerMap = headerOriginalNamesOfTitlesSelector(state);
      Object.keys(address).forEach(key => {
        transformedAddress[headerMap[key]] = address[key];
      });
      transformedAddress.isAddressEnteredManually = true;
    }
    return {
      inventoryId,
      receiverId,
      updateOnly: saveForLater,
      receiverData: _objectSpread({}, selectedReceiverData, transformedAddress)
    };
  },
  fn: ShipOutApi.updateDraftReceiver,
  success: hideLoader,
  postSuccess: (dispatch, getState, transformedData, receiver, data) => {
    if (data !== null && data !== void 0 && data.saveForLater) {
      return dispatch(resetSaveForLater());
    }
    const state = getState();
    const editableRow = selectedRowInTableSelector(state);
    const invalidRows = invalidRowsInTableSelector(state);
    const errors = receiver.errorsList || {};
    const isReceiverDataInvalid = Object.keys(errors).length > 0;
    const newInvalidRows = isReceiverDataInvalid ? invalidRows : invalidRows.filter(i => i !== editableRow);
    const receivers = draftReceiversSelector(state);
    const newReceivers = [...receivers];
    newReceivers[editableRow || 0] = receiver;
    dispatch({
      type: UPDATE_DRAFT_RECEIVER_SUCCESS,
      payload: {
        invalidRows: newInvalidRows,
        draftReceivers: newReceivers
      }
    });
    if (isReceiverDataInvalid) {
      return;
    }
    if (newInvalidRows.length) {
      dispatch(moveToNextInvalidRow());
    }
  },
  error: err => ({
    type: UPDATE_DRAFT_RECEIVER_FAILURE,
    err
  }),
  postError: (dispatch, getState, error) => {
    var _error$data;
    dispatch(hideLoader());
    const state = getState();
    const originalAddress = selectedRowInTableAddressSelector(state);
    const receiverId = selectedRowInTableReceiverIdSelector(state);
    const isPostalSystemError = (error === null || error === void 0 ? void 0 : (_error$data = error.data) === null || _error$data === void 0 ? void 0 : _error$data.code) === DeliveryError.zipStateMismatch;
    if (error && originalAddress && !isPostalSystemError) {
      return dispatch(onManualAddressEnterPopupAction(receiverId, error, originalAddress));
    }
    if (isPostalSystemError) {
      return dispatch(showNotification({
        text: "We were unable to calculate your shipping costs. Postal code doesn't match the state."
      }));
    }
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const REMOVE_DRAFT_RECEIVER_SUCCESS = `${PREFIX}REMOVE_DRAFT_RECEIVER_SUCCESS`;
export const REMOVE_DRAFT_RECEIVER_FAILURE = `${PREFIX}REMOVE_DRAFT_RECEIVER_FAILURE`;
export const removeDraftReceiver = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
  },
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const receiverId = selectedReceiverIdSelector(state);
    return {
      inventoryId,
      receiverId
    };
  },
  fn: ShipOutApi.removeDraftReceiver,
  success: hideLoader,
  postSuccess: (dispatch, getState) => {
    const state = getState();
    const editableRow = selectedRowInTableSelector(state);
    const receivers = draftReceiversSelector(state);
    receivers.splice(editableRow || 0, 1);
    const newInvalidRows = newInvalidRowsSelector({
      sendToMultipleAddresses: {
        draftReceivers: receivers
      }
    });
    dispatch({
      type: REMOVE_DRAFT_RECEIVER_SUCCESS,
      payload: {
        invalidRows: newInvalidRows,
        draftReceivers: [...receivers]
      }
    });
    if (newInvalidRows.length) {
      dispatch(moveToNextInvalidRow());
    }
    dispatch(hidePopup());
  },
  error: err => ({
    type: REMOVE_DRAFT_RECEIVER_FAILURE,
    err
  }),
  postError: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
const validateCell = (value, name, country) => {
  switch (name) {
    case CSV_COLUMNS.EMAIL.toLowerCase():
      return validate(value, emailValidator);
    case CSV_COLUMNS.PHONE.toLowerCase():
      return validate(value, _objectSpread({}, phoneValidator, {
        required: country !== COUNTRY_CODES.US
      }));
    case 'quantity':
      return validate(value, integerValidator);
    default:
      return validate(value, _objectSpread({}, regularLengthValidator, {
        required: true
      }));
  }
};
export const SET_CELL_VALUE = `${PREFIX}SET_CELL_VALUE`;
export const setCellValue = ({
  position,
  value,
  property
}) => (dispatch, getState) => {
  const state = getState();
  const list = draftReceiversSelector(state);
  const header = draftHeaderSelector(state);
  const receivers = [...list];
  const column = header.find(item => item.original === property);
  if (!column) {
    return;
  }
  const oldReceiver = _objectSpread({}, receivers[position - 1]);
  const newErrorsList = _objectSpread({}, oldReceiver.errorsList || {});
  const columnName = column.title.toLowerCase();
  const validationError = validateCell(value, columnName, oldReceiver.country);
  if (!validationError) {
    if (columnName.includes('quantity')) {
      delete newErrorsList[DRAFT_ERRORS.INVALID_PRODUCTS_QUANTITY];
    } else {
      delete newErrorsList[columnName];
    }
  }
  if (validationError) {
    newErrorsList[columnName] = validationError;
  }
  const stateUpdate = {};
  if (columnName === CSV_COLUMNS.COUNTRY.toLowerCase()) {
    const stateColumn = header.find(item => item.title.toLowerCase() === CSV_COLUMNS.STATE.toLowerCase());
    if (stateColumn) {
      stateUpdate[stateColumn.original] = getDefaultValue(String(value));
    }
  }
  const countryColumn = header.find(item => item.title.toLowerCase() === CSV_COLUMNS.COUNTRY.toLowerCase());
  const countryKey = (countryColumn === null || countryColumn === void 0 ? void 0 : countryColumn.original) || '';
  if (value === COUNTRY_CODES.US || oldReceiver[countryKey] === COUNTRY_CODES.US) {
    if (newErrorsList[CSV_COLUMNS.PHONE.toLowerCase()] && Object.keys(newErrorsList).length === 1) {
      newErrorsList[DRAFT_ERRORS.LOCATION] = 'Cannot validate your address';
    }
    delete newErrorsList[CSV_COLUMNS.PHONE.toLowerCase()];
  }
  const receiver = _objectSpread({}, oldReceiver, stateUpdate, {
    [column.original]: value,
    errorsList: newErrorsList
  });
  receivers[position - 1] = receiver;
  dispatch({
    type: SET_CELL_VALUE,
    payload: {
      draftReceivers: receivers
    }
  });
};
export const MOVE_TO_NEXT_INVALID_ROW = `${PREFIX}MOVE_TO_NEXT_INVALID_ROW`;
export const moveToNextInvalidRow = () => (dispatch, getState) => {
  const state = getState();
  const selectedRow = selectedRowInTableSelector(state);
  const invalidRows = invalidRowsInTableSelector(state);
  if (!invalidRows.length) {
    return dispatch({
      type: MOVE_TO_NEXT_INVALID_ROW,
      payload: {
        selectedRow: null
      }
    });
  }
  const currentIndex = invalidRows.findIndex(item => item === selectedRow);
  if (currentIndex < 0) {
    return;
  }
  const newPosition = invalidRows[0];
  dispatch({
    type: MOVE_TO_NEXT_INVALID_ROW,
    payload: {
      selectedRow: newPosition
    }
  });
};
export const finalizeUpload = () => (dispatch, getState) => {
  var _shipout$receivers2;
  const state = getState();
  const invalidRows = invalidRowsInTableSelector(state);
  const shipout = sendToMultipleAddressesShipoutSelector(state);
  if (shipout !== null && shipout !== void 0 && (_shipout$receivers2 = shipout.receivers) !== null && _shipout$receivers2 !== void 0 && _shipout$receivers2.length && !invalidRows.length) {
    return dispatch(getShipoutForSMA({
      shipoutId: shipout._id
    }));
  }
  dispatch(validateInStock());
};
export const saveShipoutForLater = () => (dispatch, getState) => {
  const state = getState();
  const isHeaderValid = isDraftTableHeaderValidSelector(state);
  const invalidRows = invalidRowsInTableSelector(state);
  if (!isHeaderValid) {
    return dispatch(validateDraftHeader({
      saveForLater: true
    }));
  }
  if (isHeaderValid && invalidRows.length) {
    return dispatch(updateDraftReceiver({
      saveForLater: true
    }));
  }
  dispatch(resetSaveForLater());
};
export const RESET_CSV_ERRORS = `${PREFIX}RESET_CSV_ERRORS`;
export const resetCsvErrors = () => ({
  type: RESET_CSV_ERRORS
});
export const VALIDATE_IN_STOCK_SUCCESS = `${PREFIX}VALIDATE_IN_STOCK_SUCCESS`;
export const VALIDATE_IN_STOCK_FAILURE = `${PREFIX}VALIDATE_IN_STOCK_FAILURE`;
export const validateInStock = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
    dispatch(resetCsvErrors());
  },
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const draftId = draftIdSelector(state);
    const receivers = draftReceiversSelector(state);
    const quantityHeaderKeys = quantityHeaderKeysSelector(state);
    const filteredReceivers = receivers.map(receiver => {
      return Object.entries(receiver).reduce((acc, [key, value]) => {
        return quantityHeaderKeys.includes(key) || key === '_id' ? _objectSpread({}, acc, {
          [key]: value
        }) : acc;
      }, {});
    });
    return {
      inventoryId,
      draftId,
      receivers: filteredReceivers
    };
  },
  fn: ShipOutApi.updateDraftQuantities,
  success: payload => ({
    type: VALIDATE_IN_STOCK_SUCCESS,
    payload
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
    dispatch(showPopup({
      popup: SHIPOUT_VALIDATION_POPUP
    }));
    dispatch(validateShipout());
  },
  error: VALIDATE_IN_STOCK_FAILURE,
  postError: dispatch => {
    dispatch(hideLoader());
  }
});