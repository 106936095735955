import { createSelector } from 'reselect';
import { getIsDynamicBundle } from 'swag-client-common/utils/reseller/get-is-dynamic-bundle.util';
import { TenantStoreSettingStoreTypes } from 'swag-common/constants/main-swag.constants';
import { getStoreSettings } from 'swag-client-common/utils/get-store-settings';
import { isCuratedBrandStoreInDraftSelector, isCuratedBrandStoreSelector } from '../../../customer/redux/curated-brand-store/curated-brand-store-other.selector';
const tenantStoreRoot = state => {
  return state.tenantStore;
};
export const tenantsStoreAreAdditionalScriptsLoadedSelector = createSelector(tenantStoreRoot, tenantStore => tenantStore.areAdditionalScriptsLoaded);
export const isDynamicBundleSelector = () => getIsDynamicBundle();
export const isResellerStoreTypeSelector = () => {
  const storeSettings = getStoreSettings();
  return (storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.storeType) === TenantStoreSettingStoreTypes.RESELLER;
};
export const isResellerContractSignedSelector = () => {
  var _storeSettings$curate, _storeSettings$curate2;
  const storeSettings = getStoreSettings();
  return Boolean((storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.contractSignedAt) || (storeSettings === null || storeSettings === void 0 ? void 0 : (_storeSettings$curate = storeSettings.curatedBrandStore) === null || _storeSettings$curate === void 0 ? void 0 : (_storeSettings$curate2 = _storeSettings$curate.parentStoreSettings) === null || _storeSettings$curate2 === void 0 ? void 0 : _storeSettings$curate2.contractSignedAt));
};
export const isDeactivatedStoreSelector = () => {
  const {
    swag: {
      storeSettings
    }
  } = window;
  return Boolean(storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.isDeactivated);
};
export const isResellerStoreActiveSelector = createSelector(isResellerStoreTypeSelector, isResellerContractSignedSelector, (isResellerStore, isResellerContractSigned) => {
  if (!isResellerStore) {
    return true;
  }
  return isResellerContractSigned;
});
export const isResellerContractPendingSignWidgetShownSelector = createSelector(isResellerStoreTypeSelector, isResellerContractSignedSelector, isCuratedBrandStoreInDraftSelector, isCuratedBrandStoreSelector, isDeactivatedStoreSelector, (isResellerStoreType, isResellerContractSigned, isCuratedBrandStoreInDraft, isCuratedBrandStore, isDeactivatedStore) => {
  if (isCuratedBrandStore) {
    return isCuratedBrandStoreInDraft || !isResellerContractSigned || isDeactivatedStore;
  }
  return isResellerStoreType && (!isResellerContractSigned || isDeactivatedStore);
});
export const isAllowedToOrderProceedForResellerStoreSelector = createSelector(isResellerContractPendingSignWidgetShownSelector, isResellerContractPendingSignWidgetShown => {
  return !isResellerContractPendingSignWidgetShown;
});