import _omit from "lodash/omit";
import { DesignUploadMode } from 'swag-common/interfaces';
import { DEFAULT_EMBELLISHMENT_NAME, FULL_DESIGN_EMBELLISHMENT_NAME } from 'swag-common/constants/product.constants';
import { transformProductSettingsForFeature } from 'swag-common/utils/product-settings/transform-product-settings-for-feature';
const defaultProductSettings = {
  index: 0,
  productionTimeList: [{
    cost: 0,
    time: 0,
    name: 'Standard'
  }],
  superSpeed: null,
  addOneColor: false,
  colorIndependentPrice: false,
  colorIndependentScreenPrice: false,
  printRunMorePrice: 0,
  printRunPrices: [],
  screenPrice: 0,
  printRunQuantities: [0],
  minQuant: 24,
  quantIncrement: 1,
  printedSamplePrice: 0,
  maxPrintColors: 1,
  printingProcessDescription: '',
  imprintAreaTemplate: null,
  locations: {}
};
function transformSelectedSettings(settings, product, featureFlags) {
  return transformProductSettingsForFeature([_omit(settings, 'embellishmentMethod')], product, featureFlags);
}
export const getItemSelectedProductSettings = (product, item, featureFlags) => {
  var _product$productSetti, _product$productSetti2, _product$productSetti3;
  const selectedProductSettings = (_product$productSetti = (_product$productSetti2 = product.productSettings) === null || _product$productSetti2 === void 0 ? void 0 : _product$productSetti2.find(({
    embellishmentMethodId,
    embellishmentMethod
  }) => {
    if (item.isCustomizedBlank && !embellishmentMethodId) {
      return true;
    }
    const isSameEmbellishmentById = item.embellishmentMethod && embellishmentMethodId && String(item.embellishmentMethod) === String(embellishmentMethodId);
    if (!item.isCustomizedBlank && isSameEmbellishmentById) {
      return true;
    }
    if (!item.embellishmentMethod && item.logos && Object.keys(item.logos).length > 0 && ((embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod.name) === DEFAULT_EMBELLISHMENT_NAME || product.designUploadMode === DesignUploadMode.fullDesign && (embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod.name) === FULL_DESIGN_EMBELLISHMENT_NAME)) {
      return true;
    }
    if (!item.embellishmentMethod && (!item.logos || Object.keys(item.logos).length === 0) && embellishmentMethodId === null) {
      return true;
    }
    return false;
  })) !== null && _product$productSetti !== void 0 ? _product$productSetti : (_product$productSetti3 = product.productSettings) === null || _product$productSetti3 === void 0 ? void 0 : _product$productSetti3[0];
  if (!selectedProductSettings) {
    return [];
  }
  return transformSelectedSettings(selectedProductSettings, product, featureFlags);
};
export const getSelectedProductSettingsByEmbellishmentId = (product, embellishmentMethodId, featureFlags) => {
  var _product$productSetti4;
  const selectedSettings = (_product$productSetti4 = product.productSettings) === null || _product$productSetti4 === void 0 ? void 0 : _product$productSetti4.find(settings => String(settings.embellishmentMethodId) === String(embellishmentMethodId));
  if (!selectedSettings) {
    return [];
  }
  return transformSelectedSettings(selectedSettings, product, featureFlags);
};
export const getDefaultProductSettings = (product, featureFlags) => transformSelectedSettings(defaultProductSettings, product, featureFlags);
export const getFirstOrDefaultProductSettings = (product, featureFlags) => {
  var _product$productSetti5;
  if (!((_product$productSetti5 = product.productSettings) !== null && _product$productSetti5 !== void 0 && _product$productSetti5.length)) {
    return getDefaultProductSettings(product, featureFlags);
  }
  return transformSelectedSettings(product.productSettings[0], product, featureFlags);
};
export const getSelectedProductSetting = (product, featureFlags, embellishmentMethodId) => {
  if (!embellishmentMethodId) {
    return getDefaultProductSettings(product, featureFlags)[0];
  }
  const settings = getSelectedProductSettingsByEmbellishmentId(product, embellishmentMethodId, featureFlags);
  if (!settings.length) {
    return getDefaultProductSettings(product, featureFlags)[0];
  }
  return settings[0];
};