function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { MEDIA_TYPES } from 'swag-client-common/utils/constants';
export const getViewMediaList = (layers, view) => {
  if (!layers[view]) {
    return [];
  }
  return layers[view].map(mediaId => ({
    type: MEDIA_TYPES.LOGOS,
    mediaId
  }));
};
const getLogosRect = logos => {
  return Object.keys(logos).reduce((result, mediaId) => {
    result[mediaId] = logos[mediaId].rect;
    return result;
  }, {});
};
export const getLogosRectFromProductSettings = (productSettings, logos) => {
  return productSettings.reduce((result, settings) => {
    if (settings.embellishmentMethodId) {
      result[settings.embellishmentMethodId] = getLogosRect(logos);
    }
    return result;
  }, {});
};
export const updateLogosRect = (logosRectByEmbellishments, logos) => {
  return Object.keys(logosRectByEmbellishments).reduce((result, embellishmentMethodId) => {
    result[embellishmentMethodId] = getLogosRect(logos);
    return result;
  }, {});
};
export const setLogoRectToAllEmbellishments = (logosRectByEmbellishments, logoId, rect) => {
  return Object.keys(logosRectByEmbellishments).reduce((result, embellishmentMethodId) => {
    result[embellishmentMethodId] = _objectSpread({}, logosRectByEmbellishments[embellishmentMethodId], {
      [logoId]: rect
    });
    return result;
  }, {});
};
export const setLogoRectToEmbellishment = (logosRectByEmbellishments, embellishmentMethodId, logoId, rect) => {
  return _objectSpread({}, logosRectByEmbellishments, {
    [embellishmentMethodId]: _objectSpread({}, logosRectByEmbellishments[embellishmentMethodId], {
      [logoId]: rect
    })
  });
};
export const removeLogoRect = (logosRectByEmbellishments, logoId) => {
  return Object.keys(logosRectByEmbellishments).reduce((result, embellishmentMethodId) => {
    result[embellishmentMethodId] = _objectSpread({}, logosRectByEmbellishments[embellishmentMethodId]);
    delete result[embellishmentMethodId][logoId];
    return result;
  }, {});
};
export const applyEmbellishmentMethodRectToLogos = (methodId, logosRectByEmbellishments, logos) => {
  return Object.keys(logos).reduce((result, mediaId) => {
    var _logosRectByEmbellish;
    const logo = logos[mediaId];
    const rect = ((_logosRectByEmbellish = logosRectByEmbellishments[methodId]) === null || _logosRectByEmbellish === void 0 ? void 0 : _logosRectByEmbellish[mediaId]) || logo.rect;
    result[mediaId] = _objectSpread({}, logo, {
      rect
    });
    return result;
  }, {});
};
export const isViewHasLogo = (view, layers) => {
  var _layers$view;
  return !!((_layers$view = layers[view]) !== null && _layers$view !== void 0 && _layers$view.length);
};