export function getUniqueLocationsFromProductSettings(productSettings, variantId) {
  return productSettings.reduce((result, settings) => {
    const variantLocations = settings.locations[variantId] || {};
    Object.keys(variantLocations).forEach(location => {
      if (!result.includes(location)) {
        result.push(location);
      }
    });
    return result;
  }, []);
}