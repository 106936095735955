function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector, createStructuredSelector } from 'reselect';
import { isMaxPrintColorLimitExceededPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { STATE_LOADING } from '../redux.constants';
import { capitalize } from '../../../../../common/utils/strings';
import { actualMaxPrintColorsSelector, isLimitedEditionSelector, notValidatedEmbellishmentSelector, settingsByEmbellishmentMethodsSelector, variantSelector } from '../product/product.selectors';
import { selectPantonesPopupSelector } from '../../components/popups/select-pantones-popup/connect/select-pantones-popup.selectors';
import { shouldShowCompanyArtFolderSelector } from '../company-art-folder/company-art-folder.selectors';
const WHITE_COLOR = 'white c';
const BLACK_COLOR = 'black c';
const logoEditingSelector = state => state.logoEditing;
const convertToSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.convertTo);
const previewSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.preview);
export const isSuccessSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.isSuccess);
export const editingLogoIdSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.id);
export const logoHandlingProgressSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.logoHandlingProgress);
export const logoHandlingStatusSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.logoHandlingStatus);
const selectedColorsSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.selectedColors);
export const logoLocationSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.location);
export const showOverlaySelector = createSelector(logoEditingSelector, logoEditing => logoEditing.showOverlay);
export const isRemoveBGCheckboxShown = createSelector(logoEditingSelector, logoEditing => logoEditing.hasBackground && !logoEditing.convertTo);
const mainProductMaxPrintColorsSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.productData.maxPrintColors);
export const hasDtgOptionSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.productData.hasDtgOption);
export const minimumColorsForDtgSelector = createSelector(logoEditingSelector, logoEditing => logoEditing.productData.minimumColorsForDtg);
export const maxPrintColorsSelector = createSelector(mainProductMaxPrintColorsSelector, mainProductMaxPrintColors => {
  return mainProductMaxPrintColors;
});
export const isMaxPrintColorsNotExceededSelector = createSelector(selectedColorsSelector, actualMaxPrintColorsSelector, (selectedColors, maxPrintColors) => {
  return maxPrintColors === 0 || selectedColors.length === 1 || selectedColors.length < maxPrintColors;
});
export const isRevertButtonShownSelector = createSelector(isMaxPrintColorsNotExceededSelector, convertToSelector, (isMaxPrintColorsNotExceeded, convertTo) => isMaxPrintColorsNotExceeded && !!convertTo);
export const isColorAddingPossibleSelector = createSelector(isMaxPrintColorsNotExceededSelector, convertToSelector, (isMaxPrintColorsNotExceeded, convertTo) => isMaxPrintColorsNotExceeded && !convertTo);
export const logoUploadStatusSelector = createSelector(isSuccessSelector, logoHandlingProgressSelector, logoHandlingStatusSelector, isLimitedEditionSelector, (isSuccess, progress, status, isLimitedEdition) => ({
  isSuccess,
  progress,
  status,
  isLimitedEdition
}));
export const capitalizePantoneNames = colors => colors.map(color => {
  const capitalizedName = capitalize(color.pms);
  return _objectSpread({}, color, {
    pms: capitalizedName
  });
});
export const convertToOneColorPopupSelector = state => {
  const convertTo = convertToSelector(state);
  const selectedColors = selectedColorsSelector(state);
  const preview = previewSelector(state);
  const {
    color: variantColor
  } = variantSelector(state) || {};
  const colors = selectedColors.slice();
  const whiteColorAbsent = colors.every(color => color.pms !== WHITE_COLOR);
  const blackColorAbsent = colors.every(color => color.pms !== BLACK_COLOR);
  if (whiteColorAbsent) {
    colors.unshift({
      pms: WHITE_COLOR,
      hex: '#ffffff',
      id: 'white'
    });
  }
  if (blackColorAbsent) {
    colors.unshift({
      pms: BLACK_COLOR,
      hex: '#000000',
      id: 'black'
    });
  }
  const capitalizedColors = capitalizePantoneNames([...colors]);
  return {
    loading: state.logoEditing.state === STATE_LOADING,
    variantColor,
    colors: capitalizedColors,
    convertTo,
    isButtonDisabled: state.logoEditing.state === STATE_LOADING,
    isRevertButtonShown: isRevertButtonShownSelector(state) && convertTo,
    isLimitedEdition: isLimitedEditionSelector(state),
    preview
  };
};
export const maxPrintColorsAmountSelector = createSelector(notValidatedEmbellishmentSelector, settingsByEmbellishmentMethodsSelector, actualMaxPrintColorsSelector, (notValidatedEmbellishment, settingByEmbellishment, actualMaxPrintColors) => {
  var _settingByEmbellishme;
  const notValidatedMaxPrintColors = notValidatedEmbellishment && ((_settingByEmbellishme = settingByEmbellishment[notValidatedEmbellishment]) === null || _settingByEmbellishme === void 0 ? void 0 : _settingByEmbellishme.maxPrintColors);
  return notValidatedMaxPrintColors || actualMaxPrintColors;
});
const maxPrintColorsButtonDisabledSelector = createSelector(maxPrintColorsAmountSelector, selectedColorsSelector, (maxPrintColors, selectedColors) => selectedColors.length > maxPrintColors);
export const maxPrintColorsPopupSelector = createStructuredSelector({
  view: logoLocationSelector,
  maxPrintColors: maxPrintColorsAmountSelector,
  isOpened: isMaxPrintColorLimitExceededPopupShownSelector,
  isButtonDisabled: maxPrintColorsButtonDisabledSelector,
  shouldShowCompanyArtFolder: shouldShowCompanyArtFolderSelector,
  selectedPantones: state => selectPantonesPopupSelector(state, {
    isDisabled: false
  })
});
export const logoToUploadViewSelector = state => state.logoEditing.logoToUploadView;
export const logoToUploadFileSelector = state => state.logoEditing.logoToUploadFile;