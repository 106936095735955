function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { OrderApi } from 'swag-client-common/api/order.api';
import ProductApi from '../../api/product.api';
import { productSelector, actualMaxPrintColorsSelector, maximumQuantitySelector } from '../product/product.selectors';
import { getItemPriceFromProduct } from '../product/product-other.utils';
import { maxLocationsSelector, orderItemSelector, zipSelector, productNameSelector, setupFeeByColorsSelector, shippingCountrySelector, locationsSelector, totalPriceSelector, productWithSelectedSettingsSelector, minimumQuantityForInstantQuoteSelector, quantitySelector } from './instant-quote.selectors';
const PREFIX = 'INSTANT_QUOTE_';
export const GET_SHIPMENT_WITH_TAXES_STARTED = `${PREFIX}GET_SHIPMENT_WITH_TAXES_STARTED`;
export const GET_SHIPMENT_WITH_TAXES_SUCCESS = `${PREFIX}GET_SHIPMENT_WITH_TAXES_SUCCESS`;
export const GET_SHIPMENT_WITH_TAXES_ERROR = `${PREFIX}GET_SHIPMENT_WITH_TAXES_ERROR`;
const getShipmentWithTaxes = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_SHIPMENT_WITH_TAXES_STARTED
  }),
  fn: OrderApi.getShipmentWithTaxes,
  success: body => ({
    type: GET_SHIPMENT_WITH_TAXES_SUCCESS,
    body
  }),
  error: err => ({
    type: GET_SHIPMENT_WITH_TAXES_ERROR,
    err,
    error: err
  })
});
export const UPDATE_TOTAL_PRICE = `${PREFIX}UPDATE_TOTAL_PRICE`;
export const calculatePrice = () => (dispatch, getState) => {
  const state = getState();
  const product = productWithSelectedSettingsSelector(state);
  const orderItem = orderItemSelector(state);
  const totalPrice = getItemPriceFromProduct({
    orderItem: _objectSpread({}, orderItem, {
      prod: product
    }),
    product: product,
    discardMultiplier: true
  });
  const zip = zipSelector(state);
  const country = shippingCountrySelector(state);
  const setupFee = setupFeeByColorsSelector(state);
  const pricePerItem = (totalPrice - setupFee) / orderItem.quantity;
  if (!zip) {
    return dispatch({
      type: UPDATE_TOTAL_PRICE,
      payload: {
        totalPrice,
        taxesPrice: 0,
        shippingPrice: 0,
        setupFee,
        pricePerItem,
        quantity: orderItem.quantity
      }
    });
  }
  dispatch(getShipmentWithTaxes({
    zip,
    country,
    orderItem: _objectSpread({}, orderItem, {
      setupFee,
      prod: product
    }),
    productId: product._id
  })).then(res => {
    if (res.err) {
      return;
    }
    const {
      taxesPrice,
      shippingPrice
    } = res.body;
    return dispatch({
      type: UPDATE_TOTAL_PRICE,
      payload: {
        totalPrice,
        taxesPrice,
        setupFee,
        pricePerItem,
        quantity: orderItem.quantity,
        shippingPrice
      }
    });
  });
};

/**
 * Form`s actions
 */

export const UPDATE_LOCATIONS_FIELD = `${PREFIX}UPDATE_LOCATION_FIELD`;
export const LOCATIONS_ERROR = `${PREFIX}LOCATIONS_ERROR`;
export const changeLocations = locations => (dispatch, getState) => {
  const state = getState();
  const maxLocations = maxLocationsSelector(state);
  let error = null;
  if (locations <= 0) {
    error = `You can't order ${productNameSelector(state)} without print`;
  }
  if (locations > maxLocations) {
    error = 'Too many print locations';
  }
  dispatch({
    type: LOCATIONS_ERROR,
    error
  });
  dispatch({
    type: UPDATE_LOCATIONS_FIELD,
    payload: {
      locations
    }
  });
};
export const UPDATE_ZIP_FIELD = `${PREFIX}UPDATE_ZIP_FIELD`;
export const changeZip = value => ({
  type: UPDATE_ZIP_FIELD,
  payload: {
    zip: value
  }
});
export const UPDATE_COLOR_FIELD = `${PREFIX}UPDATE_COLOR_FIELD`;
export const UPDATE_COLOR_FIELD_ERROR = `${PREFIX}UPDATE_COLOR_FIELD_ERROR`;
export const changeColors = (value, locationIndex) => (dispatch, getState) => {
  const colorsNumber = +value;
  const maxColorsNumber = actualMaxPrintColorsSelector(getState());
  const noColors = colorsNumber < 1;
  let error = null;
  if (noColors) {
    error = 'Color number cannot be smaller than 1';
  }
  if (maxColorsNumber !== 0 && colorsNumber > maxColorsNumber) {
    error = `Color number cannot be more than ${maxColorsNumber}`;
  }
  dispatch({
    type: UPDATE_COLOR_FIELD_ERROR,
    payload: {
      locationIndex
    },
    error
  });
  dispatch({
    type: UPDATE_COLOR_FIELD,
    payload: {
      locationIndex,
      colorsNumber
    }
  });
};
export const UPDATE_QUANTITY_FIELD = `${PREFIX}UPDATE_QUANTITY_FIELD`;
export const QUANTITY_ERROR = `${PREFIX}QUANTITY_ERROR`;
const updateQuantityError = value => (dispatch, getState) => {
  const minQuant = minimumQuantityForInstantQuoteSelector(getState());
  const maxQuant = maximumQuantitySelector(getState());
  if (value < minQuant) {
    const error = `You cannot order less than ${minQuant}`;
    return dispatch({
      type: QUANTITY_ERROR,
      payload: {
        error,
        quantity: value
      }
    });
  }
  if (value > maxQuant) {
    const error = `You cannot order more than ${maxQuant}`;
    return dispatch({
      type: QUANTITY_ERROR,
      payload: {
        error,
        quantity: value
      }
    });
  }
  dispatch({
    type: UPDATE_QUANTITY_FIELD,
    payload: {
      quantity: value
    }
  });
};
export const changeQuantity = value => dispatch => {
  dispatch(updateQuantityError(value));
};
export const UPDATE_SIZE_INDEX_FIELD = `${PREFIX}UPDATE_SIZE_INDEX_FIELD`;
export const changeSizeIndex = sizeIndex => dispatch => dispatch({
  type: UPDATE_SIZE_INDEX_FIELD,
  payload: {
    sizeIndex
  }
});
export const CHANGE_SHIPPING_COUNTRY = `${PREFIX}CHANGE_SHIPPING_COUNTRY`;
export const changeShippingCountry = country => dispatch => dispatch({
  type: CHANGE_SHIPPING_COUNTRY,
  payload: {
    country
  }
});
export const PREFILL_DATA = `${PREFIX}PREFILL_DATA`;
export const prefillData = data => ({
  type: PREFILL_DATA,
  payload: data
});
export const RESET = `${PREFIX}_RESET`;
export const reset = () => ({
  type: RESET
});
export const SEND_EMAIL_REQUEST_TO_HUBSPOT = `${PREFIX}SEND_EMAIL_REQUEST_TO_HUBSPOT`;
export const SEND_EMAIL_REQUEST_TO_HUBSPOT_SUCCESS = `${PREFIX}SEND_EMAIL_REQUEST_TO_HUBSPOT_SUCCESS`;
export const SEND_EMAIL_REQUEST_TO_HUBSPOT_ERROR = `${PREFIX}SEND_EMAIL_REQUEST_TO_HUBSPOT_ERROR`;
export const sendEmailRequestToHubspot = createAsyncAction({
  transformData: ({
    email
  }, getState) => {
    const state = getState();
    const product = productSelector(state);
    const orderItem = orderItemSelector(state);
    const totalPrice = totalPriceSelector(state);
    const zip = zipSelector(state);
    const country = shippingCountrySelector(state);
    const locations = locationsSelector(state);
    const {
      colors,
      quantity
    } = orderItem;
    return {
      email,
      zip: zip.length ? zip : undefined,
      country,
      iq_url: window.location.href,
      iq_product: product === null || product === void 0 ? void 0 : product.name,
      iq_quantity: quantity,
      iq_colors: colors,
      iq_print_loc: locations,
      iq_amount: (totalPrice / 100).toFixed(2)
    };
  },
  fn: ProductApi.submitInstantQuote,
  success: () => ({
    type: SEND_EMAIL_REQUEST_TO_HUBSPOT_SUCCESS
  }),
  error: errorAction(SEND_EMAIL_REQUEST_TO_HUBSPOT_ERROR)
});
export const UPDATE_EMBELLISHMENT_METHOD = `${PREFIX}UPDATE_EMBELLISHMENT_METHOD`;
export const updateEmbellishmentMethod = embellishmentMethodId => (dispatch, getState) => {
  dispatch({
    type: UPDATE_EMBELLISHMENT_METHOD,
    payload: {
      embellishmentMethodId
    }
  });
  const quantity = quantitySelector(getState());
  dispatch(updateQuantityError(quantity));
};