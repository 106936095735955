import _isEmpty from "lodash/isEmpty";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { REBUILDING_ITEM_REQUESTED } from 'swag-client-common/redux/cart/cart.actions';
import { findAndRemoveNotExistsPrintLocations } from 'swag-client-common/utils/cart.util';
import { CHANGE_VIEW, RESET_REDUCER, GET_FONTS_SUCCESS, CHANGE_PREVIEW, FETCH_PRODUCT_SUCCESS, CHANGE_METHOD_ID } from '../product/product.actions';
import { REORDER_ITEM_REQUESTED } from '../account-orders/account-orders.actions';
import { REBUILD_CUSTOMER_MOCKUP } from '../account-mockups/account-mockups.actions';
import { DESIGN_UPLOAD_SUCCESS, RESET_LOGOS } from '../logo-editing/logo-editing.actions';
import { APPLY_SIZE_SUBMISSION_MOCKUP } from '../collect-sizes/collect-sizes.actions';
import { REBUILD_CBS_PRODUCT } from '../curated-brand-store/curated-brand-store.actions';
import { SET_ALL_LOGOS_AND_LAYERS, UPDATE_LOGO_DETAILS, REMOVE, SELECT_CURRENT, ADD_LOGO, UPDATE_LAYERS, CHANGE_LOGO_DIMENSIONS, FIRST_LOGO_UPLOAD } from './logo.actions';
import { getLogosRectFromProductSettings, removeLogoRect, setLogoRectToEmbellishment, setLogoRectToAllEmbellishments, updateLogosRect, applyEmbellishmentMethodRectToLogos } from './logo.utils';
const initialState = {
  isColorsModified: undefined,
  selectedLogo: null,
  selectedText: null,
  activeView: 'front',
  isLogoDimensionsManuallyModified: false,
  layers: {},
  logos: {},
  logosRectByEmbellishments: {},
  fonts: [],
  exactPantones: [],
  logoNeverUploaded: true
};
export function logoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_SUCCESS:
      {
        const {
          productSettings = []
        } = action.payload;
        const logosRectByEmbellishments = getLogosRectFromProductSettings(productSettings, state.logos);
        return _objectSpread({}, state, {
          logosRectByEmbellishments
        });
      }
    case CHANGE_VIEW:
      {
        return _objectSpread({}, state, {
          activeView: action.view
        });
      }
    case SET_ALL_LOGOS_AND_LAYERS:
      {
        const {
          layers,
          logos
        } = action.payload;
        const logosRectByEmbellishments = updateLogosRect(state.logosRectByEmbellishments, logos);
        return _objectSpread({}, state, {
          layers,
          logos,
          logosRectByEmbellishments
        });
      }
    case CHANGE_LOGO_DIMENSIONS:
      {
        const {
          rect,
          mediaId,
          type,
          isLogoDimensionsManuallyModified,
          embellishmentMethodId
        } = action.payload;
        if (_isEmpty(rect)) {
          return state;
        }
        const nextIsLogoDimensionsManuallyModified = state.isLogoDimensionsManuallyModified || isLogoDimensionsManuallyModified;
        const updatedRect = _objectSpread({}, state[type][mediaId].rect, rect);
        const logosRectByEmbellishments = embellishmentMethodId ? setLogoRectToEmbellishment(state.logosRectByEmbellishments, embellishmentMethodId, mediaId, updatedRect) : setLogoRectToAllEmbellishments(state.logosRectByEmbellishments, mediaId, updatedRect);
        return _objectSpread({}, state, {
          isLogoDimensionsManuallyModified: nextIsLogoDimensionsManuallyModified,
          [type]: _objectSpread({}, state[type], {
            [mediaId]: _objectSpread({}, state[type][mediaId], {
              rect: updatedRect,
              fontSize: rect.fontSize || state[type][mediaId].fontSize
            })
          }),
          logosRectByEmbellishments
        });
      }
    case UPDATE_LOGO_DETAILS:
      {
        const {
          colorsToPrint,
          detectedColors,
          selectedColors,
          originalPreview,
          convertTo,
          isBackgroundRemoved,
          hasBackground,
          preview,
          printingMethod,
          original,
          previewWOBackground,
          previewsConvertedToOneColor,
          originalFileName,
          rect,
          id,
          isColorsModified = false,
          etag
        } = action.payload;
        const logos = _objectSpread({}, state.logos, {
          [id]: _objectSpread({}, state.logos[id], {
            preview,
            originalPreview,
            original: original || state.logos[id].original,
            previewWOBackground,
            previewsConvertedToOneColor,
            originalFileName: originalFileName || state.logos[id].originalFileName,
            rect: rect || state.logos[id].rect,
            colors: _objectSpread({}, state.logos[id].colors, {
              detectedColors,
              selectedColors,
              colorsToPrint,
              convertTo,
              isBackgroundRemoved,
              hasBackground,
              printingMethod,
              colorsNumber: colorsToPrint.length
            }),
            etag
          })
        });
        return _objectSpread({}, state, {
          logos,
          isColorsModified
        });
      }
    case SELECT_CURRENT:
      {
        const {
          logoId
        } = action.payload;
        return _objectSpread({}, state, {
          selectedLogo: logoId
        });
      }
    case FIRST_LOGO_UPLOAD:
      {
        return _objectSpread({}, state, {
          logoNeverUploaded: false
        });
      }
    case ADD_LOGO:
      {
        const {
          additionalLogos,
          id
        } = action.payload;
        const layers = _objectSpread({}, state.layers);
        const logosToAdd = [action.payload, ...Object.values(additionalLogos)];
        const currentLocationLogos = Object.keys(state.logos).filter(logoId => state.logos[logoId].location === state.activeView);
        logosToAdd.forEach(logo => {
          const layersBasedOnLocation = layers[logo.location] ? [...layers[logo.location]] : [];
          if (layersBasedOnLocation.indexOf(logo.id) === -1) {
            layersBasedOnLocation.push(logo.id);
          }
          layers[logo.location] = layersBasedOnLocation;
        });
        const logos = logosToAdd.reduce((all, logo) => {
          const {
            colorsToPrint,
            detectedColors,
            selectedColors,
            originalPreview,
            convertTo,
            isBackgroundRemoved,
            hasBackground,
            preview,
            printingMethod,
            original,
            previewWOBackground,
            previewsConvertedToOneColor,
            id,
            location,
            originalFileName,
            etag
          } = logo;
          return _objectSpread({}, all, {
            [logo.id]: {
              preview,
              originalPreview,
              original,
              location,
              id,
              previewWOBackground,
              previewsConvertedToOneColor,
              originalFileName,
              rect: state.logos[id] && state.logos[id].rect,
              colors: {
                detectedColors,
                selectedColors,
                colorsToPrint,
                convertTo,
                isBackgroundRemoved,
                hasBackground,
                printingMethod,
                isConfirmedByCustomer: false,
                colorsNumber: colorsToPrint.length
              },
              etag
            }
          });
        }, {});
        return _objectSpread({}, state, {
          logos: _objectSpread({}, state.logos, logos),
          layers,
          selectedLogo: currentLocationLogos.length && id || null
        });
      }
    case DESIGN_UPLOAD_SUCCESS:
      {
        const {
          id,
          view,
          customerDesignLink,
          etag,
          customerDesignOriginalName
        } = action.payload;
        const logos = _objectSpread({}, state.logos, {
          [id]: {
            location: view,
            id,
            original: customerDesignLink,
            originalFileName: customerDesignOriginalName,
            colors: {
              colorsNumber: 0,
              colorsToPrint: ['Custom design']
            },
            etag
          }
        });
        const layers = _objectSpread({}, state.layers, {
          [view]: [id]
        });
        return _objectSpread({}, state, {
          logos,
          layers
        });
      }
    case REMOVE:
      {
        const {
          id
        } = action.payload;
        const logos = _objectSpread({}, state.logos);
        const layers = _objectSpread({}, state.layers);
        const logosRectByEmbellishments = removeLogoRect(state.logosRectByEmbellishments, id);
        delete logos[id];
        const location = Object.keys(layers).find(key => layers[key].includes(id));
        if (!location) {
          return _objectSpread({}, state, {
            logos,
            logosRectByEmbellishments
          });
        }
        layers[location] = layers[location].filter(mediaId => mediaId !== id);
        if (!layers[location].length) {
          delete layers[location];
        }
        return _objectSpread({}, state, {
          logos,
          layers,
          logosRectByEmbellishments
        });
      }
    case UPDATE_LAYERS:
      {
        const {
          layers,
          view
        } = action.payload;
        return _objectSpread({}, state, {
          layers: _objectSpread({}, state.layers, {
            [view]: layers
          })
        });
      }
    case REORDER_ITEM_REQUESTED:
    case REBUILD_CUSTOMER_MOCKUP:
    case REBUILDING_ITEM_REQUESTED:
    case APPLY_SIZE_SUBMISSION_MOCKUP:
      {
        const {
          item
        } = action.payload;
        const layers = {};
        findAndRemoveNotExistsPrintLocations({
          product: item.prod,
          item
        });
        const logos = Object.keys(item.logos).reduce((acc, location) => {
          layers[location] = layers[location] || [];
          item.logos[location].forEach(logo => {
            acc[logo.id] = _objectSpread({}, logo, {
              location
            });
            layers[location].push(logo.id);
          });
          return acc;
        }, {});
        if (_isEmpty(layers)) {
          return state;
        }
        const selectedLogo = Object.values(layers)[0][0];
        const logosRectByEmbellishments = updateLogosRect(state.logosRectByEmbellishments, logos);
        return _objectSpread({}, state, {
          logos,
          logosRectByEmbellishments,
          layers,
          selectedLogo,
          logoNeverUploaded: false
        });
      }
    case REBUILD_CBS_PRODUCT:
      {
        var _Object$values$;
        const {
          logos: itemLogos
        } = action.payload;
        const logos = {};
        const layers = {};
        Object.entries(itemLogos).forEach(([location, logoList]) => {
          layers[location] = logoList.map(logo => {
            logos[logo.id] = _objectSpread({}, logo, {
              location
            });
            return logo.id;
          });
        });
        const selectedLogo = (_Object$values$ = Object.values(layers)[0]) === null || _Object$values$ === void 0 ? void 0 : _Object$values$[0];
        const logosRectByEmbellishments = updateLogosRect(state.logosRectByEmbellishments, logos);
        return _objectSpread({}, state, {
          layers,
          logos,
          logosRectByEmbellishments,
          selectedLogo,
          logoNeverUploaded: false
        });
      }
    case GET_FONTS_SUCCESS:
      {
        const {
          fonts
        } = action.payload;
        return _objectSpread({}, state, {
          fonts
        });
      }
    case CHANGE_PREVIEW:
      {
        return _objectSpread({}, state, {
          activeView: action.view
        });
      }
    case CHANGE_METHOD_ID:
      {
        const {
          methodId
        } = action.payload;
        if (!methodId) {
          return _objectSpread({}, state);
        }
        const logos = applyEmbellishmentMethodRectToLogos(methodId, state.logosRectByEmbellishments, state.logos);
        return _objectSpread({}, state, {
          logos
        });
      }
    case RESET_LOGOS:
      {
        const logosRectByEmbellishments = updateLogosRect(state.logosRectByEmbellishments, {});
        return _objectSpread({}, state, {
          logos: {},
          layers: {},
          logosRectByEmbellishments
        });
      }
    case RESET_REDUCER:
      return initialState;
    default:
      return state;
  }
}