function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { DesignUploadMode } from 'swag-common/interfaces';
import { getViewMediaList } from './logo.utils';
export const selectedLogoIdSelector = state => state.logo.selectedLogo;
export const logosSelector = state => state.logo.logos;
export const isLogoDimensionsManuallyModifiedSelector = state => state.logo.isLogoDimensionsManuallyModified;
export const layersSelector = state => state.logo.layers;
export const layersByLocationSelector = state => state.logo.layers[state.product.view];
export const textsSelector = _ => ({});
const isBoxProductSelector = state => state.product.product && state.product.product.designUploadMode === DesignUploadMode.box;
const activeViewSelector = state => state.logo.activeView;
export const fontsSelector = state => state.logo.fonts;
export const selectedLogoSelector = createSelector(selectedLogoIdSelector, logosSelector, layersByLocationSelector, isBoxProductSelector, (selectedId, logos, currentLocationLogos, isBox) => {
  if (!isBox && currentLocationLogos && currentLocationLogos.length === 1) {
    return logos[currentLocationLogos[0]];
  }
  return logos[selectedId || ''];
});
export const currentViewLogosListSelector = createSelector(layersSelector, activeViewSelector, getViewMediaList);
export const locationsWithUploadedLogoSelector = createSelector(layersSelector, logosSelector, (layers, logos) => {
  return Object.keys(layers).filter(location => {
    const mediaIds = layers[location];
    return mediaIds.some(mediaId => !!logos[mediaId]);
  });
});
export const currentLogoSelector = createSelector(logosSelector, selectedLogoIdSelector, (logos, selectedLogo) => {
  if (!selectedLogo) {
    return null;
  }
  return _objectSpread({}, logos[selectedLogo], {
    logoId: selectedLogo
  });
});