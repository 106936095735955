/* eslint-disable spellcheck/spell-checker */

export const COUNTRIES_LIST_ALL = [{
  common: 'Aruba',
  official: 'Aruba',
  cca2: 'AW',
  ccn3: '533',
  cca3: 'ABW',
  cioc: 'ARU',
  alternativeNames: ['Aruba', 'アルバ', '아루바', 'آروبا', 'Аруба', 'اروبا', '阿鲁巴']
}, {
  common: 'Afghanistan',
  official: 'Islamic Republic of Afghanistan',
  cca2: 'AF',
  ccn3: '004',
  cca3: 'AFG',
  cioc: 'AFG',
  isShipmentUnavailable: true,
  alternativeNames: ['Islamic Republic of Afghanistan', 'Afghanistan', 'جمهوری اسلامی افغانستان', 'افغانستان', 'د افغانستان اسلامي جمهوریت', 'Owganystan Yslam Respublik asy', 'Owganystan', 'Afghánská islámská republika', 'Afghánistán', 'Gweriniaeth Islamaidd Affganistan', 'Affganistan', 'Islamische Republik Afghanistan', 'Afganistani Islamivabariik', 'Afganistan', 'Afganistanin islamilainen tasavalta', "République islamique d'Afghanistan", 'Islamska Republika Afganistan', 'Afganisztáni Iszlám Köztársaság', 'Afganisztán', "Repubblica islamica dell'Afghanistan", 'アフガニスタン·イスラム共和国', 'アフガニスタン', '아프가니스탄 이슬람 공화국', '아프가니스탄', 'Islamitische Republiek Afghanistan', 'Islamska Republika Afganistanu', 'República Islâmica do Afeganistão', 'Afeganistão', 'Исламская Республика Афганистан', 'Афганистан', 'Afgánsky islamský štát', 'República Islámica de Afganistán', 'Afganistán', 'Islamiska republiken Afghanistan', 'اسلامی جمہوریہ افغانستان', '阿富汗伊斯兰共和国', '阿富汗', 'Afġānistān']
}, {
  common: 'Angola',
  official: 'Republic of Angola',
  cca2: 'AO',
  ccn3: '024',
  cca3: 'AGO',
  cioc: 'ANG',
  alternativeNames: ['Republic of Angola', 'Angola', 'República de Angola', 'Angolská republika', 'Gweriniaeth Angola', 'Republik Angola', 'Angola Vabariik', 'Angolan tasavalta', "République d'Angola", 'Republika Angola', "Repubblica dell'Angola", 'アンゴラ共和国', 'アンゴラ', '앙골라 공화국', '앙골라', 'Republiek Angola', 'جمهوری آنگولا', 'آنگولا', 'Republika Angoli', 'Республика Ангола', 'Ангола', 'Republiken Angola', 'جمہوریہ انگولہ', 'انگولہ', '安哥拉共和国', '安哥拉', "ʁɛpublika de an'ɡɔla"]
}, {
  common: 'Anguilla',
  official: 'Anguilla',
  cca2: 'AI',
  ccn3: '660',
  cca3: 'AIA',
  cioc: '',
  alternativeNames: ['Anguilla', 'Angvila', 'アングィラ', 'アンギラ', '앵귈라', 'آنگویلا', 'Ангилья', 'Anguila', 'اینگویلا', '安圭拉']
}, {
  common: 'Aland Islands',
  official: 'Åland Islands',
  cca2: 'AX',
  ccn3: '248',
  cca3: 'ALA',
  cioc: '',
  hasComplemarError: true,
  alternativeNames: ['Åland Islands', 'Landskapet Åland', 'Åland', 'Ålandské ostrovy', 'Ålandy', 'Åland-Inseln', 'Ahvenamaa maakond', 'Ahvenamaa', 'Ahvenanmaan maakunta', 'Ahvenanmaa', 'Aland Islands', 'Ålandski otoci', 'Åland-szigetek', 'Isole Åland', 'Isole Aland', 'オーランド諸島', '올란드 제도', 'Åland eilanden', 'Ålandeilanden', 'جزایر الند', 'Wyspy Alandzkie', 'Ilhas Åland', 'Alândia', 'Аландские острова', 'Alandské ostrovy', 'Alandy', 'Islas Åland', 'Alandia', 'جزائر اولند', '奥兰群岛', 'Aaland', 'Aland']
}, {
  common: 'Albania',
  official: 'Republic of Albania',
  cca2: 'AL',
  ccn3: '008',
  cca3: 'ALB',
  cioc: 'ALB',
  alternativeNames: ['Republic of Albania', 'Albania', 'Republika e Shqipërisë', 'Shqipëria', 'Albánská republika', 'Albánie', 'Gweriniaeth Albania', 'Republik Albanien', 'Albanien', 'Albaania Vabariik', 'Albaania', 'Albanian tasavalta', "République d'Albanie", 'Albanie', 'Republika Albanija', 'Albanija', 'Albán Köztársaság', 'Albánia', "Repubblica d'Albania", 'アルバニア共和国', 'アルバニア', '알바니아 공화국', '알바니아', 'Republiek Albanië', 'Albanië', 'جمهوری آلبانی', 'آلبانی', 'Republika Albanii', 'República da Albânia', 'Albânia', 'Республика Албания', 'Албания', 'Albánska republika', 'Albánsko', 'República de Albania', 'Republiken Albanien', 'جمہوریہ البانیا', 'البانیا', '阿尔巴尼亚共和国', '阿尔巴尼亚', 'Shqipëri', 'Shqipnia']
}, {
  common: 'Andorra',
  official: 'Principality of Andorra',
  cca2: 'AD',
  ccn3: '020',
  cca3: 'AND',
  cioc: 'AND',
  alternativeNames: ['Principality of Andorra', 'Andorra', "Principat d'Andorra", 'Andorrské knížectví', 'Tywysogaeth Andorra', 'Fürstentum Andorra', 'Andorra Vürstiriik', 'Andorran ruhtinaskunta', "Principauté d'Andorre", 'Andorre', 'Kneževina Andora', 'Andora', 'Principato di Andorra', 'アンドラ公国', 'アンドラ', '안도라 공국', '안도라', 'Prinsdom Andorra', 'شاهزاده‌نشین آندورا', 'آندورا', 'Księstwo Andory', 'Principado de Andorra', 'Княжество Андорра', 'Андорра', 'Andorrské kniežatstvo', 'Furstendömet Andorra', 'اماراتِ انڈورا', 'انڈورا', '安道尔公国', '安道尔']
}, {
  common: 'United Arab Emirates',
  official: 'United Arab Emirates',
  cca2: 'AE',
  ccn3: '784',
  cca3: 'ARE',
  cioc: 'UAE',
  alternativeNames: ['United Arab Emirates', 'الإمارات العربية المتحدة', 'دولة الإمارات العربية المتحدة', 'Spojené arabské emiráty', 'Vereinigte Arabische Emirate', 'Araabia Ühendemiraadid', 'Yhdistyneet arabiemiirikunnat', 'Arabiemiraatit', 'Émirats arabes unis', 'Ujedinjeni Arapski Emirati', 'Egyesült Arab Emírségek', 'Emirati Arabi Uniti', 'アラブ首長国連邦', '아랍 토후국 연방', '아랍에미리트', 'Verenigde Arabische Emiraten', 'امارات متحده عربی', 'امارات', 'Zjednoczone Emiraty Arabskie', 'Emirados Árabes Unidos', 'Объединенные Арабские Эмираты', 'Объединённые Арабские Эмираты', 'Emiratos Árabes Unidos', 'Förenade Arabemiraten', 'متحدہ عرب امارات', '阿拉伯联合酋长国', 'UAE', 'Emirates']
}, {
  common: 'Argentina',
  official: 'Argentine Republic',
  cca2: 'AR',
  ccn3: '032',
  cca3: 'ARG',
  cioc: 'ARG',
  alternativeNames: ['Argentine Republic', 'Argentina', 'República Argentina', 'Argentinská republika', 'Gweriniaeth yr Ariannin', 'Ariannin', 'Argentinische Republik', 'Argentinien', 'Argentina Vabariik', 'Argentiinan tasavalta', 'Argentiina', 'République argentine', 'Argentine', 'Argentinski Republika', 'Argentin Köztársaság', 'Argentína', 'Repubblica Argentina', 'アルゼンチン共和国', 'アルゼンチン', '아르헨티나 공화국', '아르헨티나', 'Argentijnse Republiek', 'Argentinië', 'جمهوری آرژانتین', 'آرژانتین', 'Republika Argentyńska', 'Argentyna', 'Аргентинская Республика', 'Аргентина', 'Argentínska republika', 'Republiken Argentina', 'جمہوریہ ارجنٹائن', 'ارجنٹائن', '阿根廷共和国', '阿根廷']
}, {
  common: 'Armenia',
  official: 'Republic of Armenia',
  cca2: 'AM',
  ccn3: '051',
  cca3: 'ARM',
  cioc: 'ARM',
  alternativeNames: ['Republic of Armenia', 'Armenia', 'Հայաստանի Հանրապետություն', 'Հայաստան', 'Arménská republika', 'Arménie', 'Gweriniaeth Armenia', 'Republik Armenien', 'Armenien', 'Armeenia Vabariik', 'Armeenia', 'Armenian tasavalta', "République d'Arménie", 'Republika Armenija', 'Armenija', 'Örményország', 'Repubblica di Armenia', 'アルメニア共和国', 'アルメニア', '아르메니아 공화국', '아르메니아', 'Republiek Armenië', 'Armenië', 'جمهوری ارمنستان', 'ارمنستان', 'Republika Armenii', 'República da Arménia', 'Arménia', 'Республика Армения', 'Армения', 'Arménska republika', 'Arménsko', 'República de Armenia', 'Republiken Armenien', 'جمہوریہ آرمینیا', 'آرمینیا', '亚美尼亚共和国', '亚美尼亚', 'Hayastan']
}, {
  common: 'American Samoa',
  official: 'American Samoa',
  cca2: 'AS',
  ccn3: '016',
  cca3: 'ASM',
  cioc: 'ASA',
  alternativeNames: ['American Samoa', 'Sāmoa Amelika', 'Americká Samoa', 'Amerikanisch-Samoa', 'Ameerika Samoa', 'Amerikan Samoa', 'Samoa américaines', 'američka Samoa', 'Američka Samoa', 'Szamoa', 'Samoa americane', 'Samoa Americane', '米サモア', 'アメリカ領サモア', '아메리칸사모아', 'Amerikaans Samoa', 'ساموآی آمریکا', 'Samoa Amerykańskie', 'Samoa americana', 'Samoa Americana', 'американское Самоа', 'Американское Самоа', 'Amerikanska Samoa', 'امریکی سمووا', '美属萨摩亚', 'Amerika Sāmoa', 'Amelika Sāmoa']
}, {
  common: 'Antarctica',
  official: 'Antarctica',
  cca2: 'AQ',
  ccn3: '010',
  cca3: 'ATA',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Antarctica', 'Antarktida', 'Yr Antarctig', 'Antarktika', 'Antarktis', 'Etelämanner', 'Antarctique', 'Antarktisz', 'Antartide', '南極大陸', '南極', '남극', 'جنوبگان', 'Antarktyka', 'Antártica', 'Antártida', 'Антарктида', 'Antarktída', 'انٹارکٹکا', '南极洲']
}, {
  common: 'French Southern and Antarctic Lands',
  official: 'Territory of the French Southern and Antarctic Lands',
  cca2: 'TF',
  ccn3: '260',
  cca3: 'ATF',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Territory of the French Southern and Antarctic Lands', 'French Southern and Antarctic Lands', 'Territoire des Terres australes et antarctiques françaises', 'Terres australes et antarctiques françaises', 'Teritorium Francouzská jižní a antarktická území', 'Francouzská jižní a antarktická území', 'Gebiet der Französisch Süd- und Antarktisgebiete', 'Französische Süd- und Antarktisgebiete', 'Prantsuse Lõunaalad', 'Ranskan eteläiset ja antarktiset alueet', 'Teritoriju Francuski južni i antarktički teritoriji', 'Francuski južni i antarktički teritoriji', 'Francia déli és antarktiszi területek', 'Territorio della australi e antartiche francesi Terre', 'Territori Francesi del Sud', 'フランス領南方·南極地域の領土', 'フランス領南方・南極地域', '프랑스령 남부와 남극 지역', 'Grondgebied van de Franse Zuidelijke en Antarctische gebieden', 'Franse Gebieden in de zuidelijke Indische Oceaan', 'سرزمین‌های جنوبی و جنوبگانی فرانسه', 'Francuskie Terytoria Południowe i Antarktyczne', 'Território do Sul e Antártica Francesa', 'Terras Austrais e Antárticas Francesas', 'Территория Французские Южные и Антарктические земли', 'Французские Южные и Антарктические территории', 'Francúzske južné a antarktické územia', 'Francúzske juŽné a antarktické územia', 'Territorio del Francés Tierras australes y antárticas', 'Tierras Australes y Antárticas Francesas', 'Franska syd- och Antarktisterritorierna', 'Franska södra territorierna', 'سرزمینِ جنوبی فرانسیسیہ و انٹارکٹیکہ', 'سرزمین جنوبی فرانسیسیہ و انٹارکٹیکا', '法国南部和南极土地', 'French Southern Territories']
}, {
  common: 'Antigua and Barbuda',
  official: 'Antigua and Barbuda',
  cca2: 'AG',
  ccn3: '028',
  cca3: 'ATG',
  cioc: 'ANT',
  alternativeNames: ['Antigua and Barbuda', 'Antigua a Barbuda', 'Antigwa a Barbiwda', 'Antigua und Barbuda', 'Antigua ja Barbuda', 'Antigua -et-Barbuda', 'Antigua-et-Barbuda', 'Antigva i Barbuda', 'Antigua és Barbuda', 'Antigua e Barbuda', 'アンチグアバーブーダ', 'アンティグア・バーブーダ', '앤티가 바부다', 'Antigua en Barbuda', 'آنتیگوا و باربودا', 'Antigua i Barbuda', 'Antígua e Barbuda', 'Антигуа и Барбуда', 'Antigua y Barbuda', 'Antigua och Barbuda', 'اینٹیگوا و باربوڈا', '安提瓜和巴布达']
}, {
  common: 'Australia',
  official: 'Commonwealth of Australia',
  cca2: 'AU',
  ccn3: '036',
  cca3: 'AUS',
  cioc: 'AUS',
  alternativeNames: ['Commonwealth of Australia', 'Australia', 'Australské společenství', 'Austrálie', 'Cymanwlad Awstralia', 'Awstralia', 'Commonwealth Australien', 'Australien', 'Austraalia Ühendus', 'Austraalia', 'Australian liittovaltio', 'Australie', 'Australija', 'Ausztrál Államszövetség', 'Ausztrália', "Commonwealth dell'Australia", 'オーストラリア連邦', 'オーストラリア', '오스트레일리아 연방', '호주', 'Gemenebest van Australië', 'Australië', 'قلمرو همسود استرالیا', 'استرالیا', 'Związek Australijski', 'Comunidade da Austrália', 'Austrália', 'Содружество Австралии', 'Австралия', 'Austrálsky zväz', 'Mancomunidad de Australia', 'Australiska statsförbundet', 'دولتِ مشترکہ آسٹریلیا', 'آسٹریلیا', '澳大利亚联邦', '澳大利亚']
}, {
  common: 'Austria',
  official: 'Republic of Austria',
  cca2: 'AT',
  ccn3: '040',
  cca3: 'AUT',
  cioc: 'AUT',
  alternativeNames: ['Republic of Austria', 'Austria', 'Republik Österreich', 'Österreich', 'Rakouská republika', 'Rakousko', 'Gweriniaeth Awstria', 'Awstria', 'Austria Vabariik', 'Itävallan tasavalta', 'Itävalta', "République d'Autriche", 'Autriche', 'Republika Austrija', 'Austrija', 'Ausztria', "Repubblica d'Austria", 'オーストリア共和国', 'オーストリア', '오스트리아 공화국', '오스트리아', 'Republiek Oostenrijk', 'Oostenrijk', 'جمهوری اتریش', 'اتریش', 'Republika Austrii', 'República da Áustria', 'Áustria', 'Австрийская Республика', 'Австрия', 'Rakúska republika', 'Rakúsko', 'República de Austria', 'Republiken Österrike', 'Österrike', 'جمہوریہ آسٹریا', 'آسٹریا', '奥地利共和国', '奥地利', 'Osterreich', 'Oesterreich']
}, {
  common: 'Azerbaijan',
  official: 'Republic of Azerbaijan',
  cca2: 'AZ',
  ccn3: '031',
  cca3: 'AZE',
  cioc: 'AZE',
  alternativeNames: ['Republic of Azerbaijan', 'Azerbaijan', 'Azərbaycan Respublikası', 'Azərbaycan', 'Азербайджанская Республика', 'Азербайджан', 'Ázerbájdžánská republika', 'Ázerbájdžán', 'Gweriniaeth Aserbaijan', 'Aserbaijan', 'Republik Aserbaidschan', 'Aserbaidschan', 'Aserbaidžaani Vabariik', 'Aserbaidžaan', 'Azerbaidzanin tasavalta', 'Azerbaidzan', "République d'Azerbaïdjan", 'Azerbaïdjan', 'Republika Azerbajdžan', 'Azerbajdžan', 'Azerbajdzsán', "Repubblica dell'Azerbaigian", 'アゼルバイジャン共和国', 'アゼルバイジャン', '아제르바이잔 공화국', '아제르바이잔', 'Republiek Azerbeidzjan', 'Azerbeidzjan', 'جمهوری آذربایجان', 'Republika Azerbejdżanu', 'Azerbejdżan', 'República do Azerbaijão', 'Azerbeijão', 'Azerbajǆanská republika', 'AzerbajǇan', 'República de Azerbaiyán', 'Azerbaiyán', 'Republiken Azerbajdzjan', 'Azerbajdzjan', 'جمہوریہ آذربائیجان', 'آذربائیجان', '阿塞拜疆共和国', '阿塞拜疆']
}, {
  common: 'Burundi',
  official: 'Republic of Burundi',
  cca2: 'BI',
  ccn3: '108',
  cca3: 'BDI',
  cioc: 'BDI',
  alternativeNames: ['Republic of Burundi', 'Burundi', 'République du Burundi', "Republika y'Uburundi ", 'Uburundi', 'Burundská republika', 'Gweriniaeth Bwrwndi', 'Bwrwndi', 'Republik Burundi', 'Burundi Vabariik', 'Burundin tasavalta', 'Repubblica del Burundi', 'ブルンジ共和国', 'ブルンジ', '부룬디', 'Republiek Burundi', 'جمهوری بوروندی', 'بوروندی', 'Republika Burundi', 'República do Burundi', 'Республика Бурунди', 'Бурунди', 'República de Burundi', 'Republiken Burundi', 'جمہوریہ برونڈی', 'برونڈی', '布隆迪共和国', '布隆迪', "Republika y'Uburundi"]
}, {
  common: 'Belgium',
  official: 'Kingdom of Belgium',
  cca2: 'BE',
  ccn3: '056',
  cca3: 'BEL',
  cioc: 'BEL',
  alternativeNames: ['Kingdom of Belgium', 'Belgium', 'Königreich Belgien', 'Belgien', 'Royaume de Belgique', 'Belgique', 'Koninkrijk België', 'België', 'Belgické království', 'Belgie', 'Teyrnas Gwlad Belg', 'Gwlad Belg', 'Belgia Kuningriik', 'Belgia', 'Belgian kuningaskunta', 'Kraljevina Belgija', 'Belgija', 'Belga Királyság', 'Regno del Belgio', 'Belgio', 'ベルギー王国', 'ベルギー', '벨기에 왕국', '벨기에', 'پادشاهی بلژیک', 'بلژیک', 'Królestwo Belgii', 'Reino da Bélgica', 'Bélgica', 'Королевство Бельгия', 'Бельгия', 'Belgické kráľovstvo', 'Belgicko', 'Reino de Bélgica', 'Konungariket Belgien', 'مملکتِ بلجئیم', 'بلجئیم', '比利时王国', '比利时']
}, {
  common: 'Benin',
  official: 'Republic of Benin',
  cca2: 'BJ',
  ccn3: '204',
  cca3: 'BEN',
  cioc: 'BEN',
  alternativeNames: ['Republic of Benin', 'Benin', 'République du Bénin', 'Bénin', 'Beninská republika', 'Gweriniaeth Benin', 'Republik Benin', 'Benini Vabariik', 'Beninin tasavalta', 'Republika Benin', 'Benini Köztársaság', 'Repubblica del Benin', 'ベナン共和国', 'ベナン', '베냉 공화국', '베냉', 'Republiek Benin', 'جمهوری بنین', 'بنین', 'República do Benin', 'Республика Бенин', 'Бенин', 'República de Benin', 'Benín', 'Republiken Benin', 'جمہوریہ بینن', 'بینن', '贝宁共和国', '贝宁']
}, {
  common: 'Burkina Faso',
  official: 'Burkina Faso',
  cca2: 'BF',
  ccn3: '854',
  cca3: 'BFA',
  cioc: 'BUR',
  alternativeNames: ['Burkina Faso', 'République du Burkina', 'Bwrcina Ffaso', 'Burkina', 'ブルキナファソ', '부르키나파소', 'بورکینافاسو', 'Буркина -Фасо', 'Буркина-Фасо', 'برکینا فاسو', '布基纳法索']
}, {
  common: 'Bangladesh',
  official: "People's Republic of Bangladesh",
  cca2: 'BD',
  ccn3: '050',
  cca3: 'BGD',
  cioc: 'BAN',
  alternativeNames: ["People's Republic of Bangladesh", 'Bangladesh', 'বাংলাদেশ গণপ্রজাতন্ত্রী', 'বাংলাদেশ', 'Bangladéšská lidová republika', 'Bangladéš', 'Gweriniaeth Pobl Bangladesh', 'Volksrepublik Bangladesch', 'Bangladesch', 'Bangladeshi Rahvavabariik', 'Bangladeshin kansantasavalta', 'La République populaire du Bangladesh', 'Narodna Republika Bangladeš', 'Bangladeš', 'Banglades', 'Repubblica popolare del Bangladesh', 'バングラデシュ人民共和国', 'バングラデシュ', '방글라데시 인민 공화국', '방글라데시', 'Volksrepubliek Bangladesh', 'جمهوری خلق بنگلادش', 'بنگلادش', 'Ludowa Republika Bangladeszu', 'Bangladesz', 'República Popular do Bangladesh', 'Народная Республика Бангладеш', 'Бангладеш', 'Bangladéšska ľudová republika', 'República Popular de Bangladesh', 'Folkrepubliken Bangladesh', 'عوامی جمہوریہ بنگلہ دیش', 'بنگلہ دیش', '孟加拉人民共和国', '孟加拉国', 'Gônôprôjatôntri Bangladesh']
}, {
  common: 'Bulgaria',
  official: 'Republic of Bulgaria',
  cca2: 'BG',
  ccn3: '100',
  cca3: 'BGR',
  cioc: 'BUL',
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of Bulgaria', 'Bulgaria', 'Република България', 'България', 'Bulharská republika', 'Bulharsko', 'Gweriniaeth Bwlgaria', 'Bwlgaria', 'Republik Bulgarien', 'Bulgarien', 'Bulgaaria Vabariik', 'Bulgaaria', 'Bulgarian tasavalta', 'République de Bulgarie', 'Bulgarie', 'Republika Bugarska', 'Bugarska', 'Bolgár Köztársaság', 'Bulgária', 'Repubblica di Bulgaria', 'ブルガリア共和国', 'ブルガリア', '불가리아 공화국', '불가리아', 'Republiek Bulgarije', 'Bulgarije', 'جمهوری بلغارستان', 'بلغارستان', 'Republika Bułgarii', 'Bułgaria', 'República da Bulgária', 'Республика Болгария', 'Болгария', 'República de Bulgaria', 'Republiken Bulgarien', 'جمہوریہ بلغاریہ', 'بلغاریہ', '保加利亚共和国', '保加利亚']
}, {
  common: 'Bahrain',
  official: 'Kingdom of Bahrain',
  cca2: 'BH',
  ccn3: '048',
  cca3: 'BHR',
  cioc: 'BRN',
  alternativeNames: ['Kingdom of Bahrain', 'Bahrain', 'مملكة البحرين', '‏البحرين', 'Království Bahrajn', 'Bahrajn', 'Teyrnas Bahrein', 'Königreich Bahrain', 'Bahreini Kuningriik', 'Bahrein', 'Bahrainin kuningaskunta', 'Royaume de Bahreïn', 'Bahreïn', 'Kraljevina Bahrein', 'Bahreini Királyság', 'Regno del Bahrain', 'バーレーン王国', 'バーレーン', '바레인 왕국', '바레인', 'Koninkrijk Bahrein', 'پادشاهی بحرین', 'بحرین', 'Królestwo Bahrajnu', 'Reino do Bahrein', 'Королевство Бахрейн', 'Бахрейн', 'Bahrajnské kráľovstvo', 'Reino de Bahrein', 'Konungariket Bahrain', 'مملکتِ بحرین', '巴林王国', '巴林', 'Mamlakat al-Baḥrayn']
}, {
  common: 'Bahamas',
  official: 'Commonwealth of the Bahamas',
  cca2: 'BS',
  ccn3: '044',
  cca3: 'BHS',
  cioc: 'BAH',
  alternativeNames: ['Commonwealth of the Bahamas', 'Bahamas', 'Bahamské společenství', 'Bahamy', 'Cymanwlad y Bahamas', 'Commonwealth der Bahamas', 'Bahama Ühendus', 'Bahama', 'Bahaman liittovaltio', 'Bahamasaaret', 'Commonwealth des Bahamas', 'Zajednica Bahama', 'Bahami', 'Bahamai Közösség', 'Bahama-szigetek', 'Commonwealth delle Bahamas', 'バハマ', '바하마 연방', '바하마', "Gemenebest van de Bahama's", 'Bahama’s', 'قلمرو همسود باهاما', 'باهاما', 'Comunidade das Bahamas', 'Содружество Багамских Островов', 'Багамские Острова', 'Bahamské spoločenstvo', 'Commonwealth de las Bahamas', 'Samväldet Bahamas', 'دولتِ مشترکہ بہاماس', 'بہاماس', '巴哈马联邦', '巴哈马']
}, {
  common: 'Bosnia',
  official: 'Bosnia and Herzegovina',
  cca2: 'BA',
  ccn3: '070',
  cca3: 'BIH',
  cioc: 'BIH',
  alternativeNames: ['Bosnia and Herzegovina', 'Bosna i Hercegovina', 'Боснa и Херцеговина', 'Bosna a Hercegovina', 'Bosnia a Hercegovina', 'Bosnien und Herzegowina', 'Bosnia ja Hertsegoviina', 'Bosnia ja Hertsegovina', 'Bosnie-et-Herzégovine', 'Bosnie-Herzégovine', 'Bosznia-Hercegovina', 'Bosnia-Erzegovina', 'Bosnia ed Erzegovina', 'ボスニア·ヘルツェゴビナ', 'ボスニア・ヘルツェゴビナ', '보스니아 헤르체고비나', 'Bosnië-Herzegovina', 'Bosnië en Herzegovina', 'بوسنی و هرزگوین', 'Bośnia i Hercegowina', 'Bósnia e Herzegovina', 'Босния и Герцеговина', 'Republika Bosny a Hercegoviny', 'Bosnia y Herzegovina', 'Bosnien och Hercegovina', 'بوسنیا و ہرزیگووینا', '波斯尼亚和黑塞哥维那', 'Bosnia-Herzegovina', 'Босна и Херцеговина']
}, {
  common: 'Saint Barthélemy',
  official: 'Collectivity of Saint Barthélemy',
  cca2: 'BL',
  ccn3: '652',
  cca3: 'BLM',
  cioc: '',
  hasComplemarError: true,
  alternativeNames: ['Collectivity of Saint Barthélemy', 'Saint Barthélemy', 'Collectivité de Saint-Barthélemy', 'Saint-Barthélemy', 'Svatý Bartoloměj', 'Gebietskörperschaft Saint-Barthélemy', 'Saint-Barthélemy territoriaalühendus', 'Saint-Barthélemyn yhteisö', 'Kolektivnost sv Barthélemy', 'Collettività di Saint Barthélemy', 'Antille Francesi', 'サン·バルテルミー島の集合体', 'サン・バルテルミー', '생바르텔레미', 'Gemeenschap Saint Barthélemy', 'سن بارتلمی', 'Coletividade de Saint Barthélemy', 'São Bartolomeu', 'Коллективность Санкт -Бартельми', 'Сен-Бартелеми', 'Svätý Bartolomej', 'Colectividad de San Barthélemy', 'San Bartolomé', 'سینٹ بارتھیملے', '圣巴泰勒米集体', '圣巴泰勒米', 'St. Barthelemy']
}, {
  common: 'Saint Helena, Ascension and Tristan da Cunha',
  official: 'Saint Helena, Ascension and Tristan da Cunha',
  cca2: 'SH',
  ccn3: '654',
  cca3: 'SHN',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Saint Helena, Ascension and Tristan da Cunha', 'Svatá Helena, Ascension a Tristan da Cunha', 'Sankt Helena, Ascension und Tristan da Cunha', 'St. Helena, Ascension und Tristan da Cunha', 'Saint Helena, Ascension ja Tristan da Cunha', 'Sainte-Hélène, Ascension et Tristan da Cunha', 'Sveta Helena', 'Szent Ilona', 'Szent Ilona-sziget', "Sant'Elena, Ascensione e Tristan da Cunha", 'セントヘレナ・アセンションおよびトリスタンダクーニャ', '세인트헬레나', 'Sint-Helena, Ascension en Tristan da Cunha', 'سنت هلن', 'Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha', 'Santa Helena, Ascensão e Tristão da Cunha', 'Острова Святой Елены, Вознесения и Тристан-да-Кунья', 'Svätá Helena (zámorské územie)', 'Santa Elena, Ascensión y Tristán de Acuña', 'Sankta Helena', 'سینٹ ہلینا، اسینشن و ترسٹان دا کونیا', '圣赫勒拿、阿森松和特里斯坦-达库尼亚', 'Saint Helena', 'St. Helena, Ascension and Tristan da Cunha']
}, {
  common: 'Belarus',
  official: 'Republic of Belarus',
  cca2: 'BY',
  ccn3: '112',
  cca3: 'BLR',
  cioc: 'BLR',
  alternativeNames: ['Republic of Belarus', 'Belarus', 'Рэспубліка Беларусь', 'Белару́сь', 'Республика Беларусь', 'Беларусь', 'Běloruská republika', 'Bělorusko', 'Gweriniaeth Belarws', 'Belarws', 'Republik Belarus', 'Weißrussland', 'Valgevene Vabariik', 'Valgevene', 'Valko-Venäjän tasavalta', 'Valko-Venäjä', 'République de Biélorussie', 'Biélorussie', 'Republika Bjelorusija', 'Bjelorusija', 'Fehérorosz Köztársaság', 'Fehéroroszország', 'Repubblica di Belarus', 'Bielorussia', 'ベラルーシ共和国', 'ベラルーシ', '벨라루스 공화국', '벨라루스', 'Republiek Belarus', 'Wit-Rusland', 'جمهوری بلاروس', 'بلاروس', 'Republika Białorusi', 'Białoruś', 'República da Bielorrússia', 'Bielorússia', 'Bieloruská republika', 'Bielorusko', 'República de Belarús', 'Bielorrusia', 'Republiken Vitryssland', 'جمہوریہ بیلاروس', 'بیلاروس', '白俄罗斯共和国', '白俄罗斯', 'Bielaruś', 'Белоруссия', 'Республика Белоруссия']
}, {
  common: 'Belize',
  official: 'Belize',
  cca2: 'BZ',
  ccn3: '084',
  cca3: 'BLZ',
  cioc: 'BIZ',
  alternativeNames: ['Belize', 'Belice', 'Belîs', 'ベリーズ', '벨리즈', 'بلیز', 'Белиз', 'بیلیز', '伯利兹']
}, {
  common: 'Bermuda',
  official: 'Bermuda',
  cca2: 'BM',
  ccn3: '060',
  cca3: 'BMU',
  cioc: 'BER',
  alternativeNames: ['Bermuda', 'Bermudské ostrovy', 'Bermudy', 'Bermiwda', 'Bermudes', 'Bermudi', 'バミューダ', '버뮤다', 'جزایر برمودا', 'برمودا', 'Bermudas', 'Бермудские острова', 'Бермудские Острова', '百慕大', 'The Islands of Bermuda', 'The Bermudas', 'Somers Isles']
}, {
  common: 'Bolivia',
  official: 'Plurinational State of Bolivia',
  cca2: 'BO',
  ccn3: '068',
  cca3: 'BOL',
  cioc: 'BOL',
  alternativeNames: ['Plurinational State of Bolivia', 'Bolivia', 'Wuliwya Suyu', 'Wuliwya', 'Tetã Volívia', 'Volívia', 'Buliwya Mamallaqta', 'Buliwya', 'Estado Plurinacional de Bolivia', 'Mnohonárodnostní stát Bolívie', 'Bolívie', 'Gweriniaeth Bolifia', 'Bolifia', 'Plurinationaler Staat Bolivien', 'Bolivien', 'Boliivia Paljurahvuseline Riik', 'Boliivia', 'Bolivian monikansainen valtio', 'État plurinational de Bolivie', 'Bolivie', 'Bolivija', 'Bolíviai Többnemzetiségű Állam', 'Bolívia', 'Stato Plurinazionale della Bolivia', 'ボリビアの多民族国', 'ボリビア多民族国', '볼리비아 다민족국', '볼리비아', 'Plurinationale Staat van Bolivia', 'جمهوری بولیوی', 'بولیوی', 'Wielonarodowe Państwo Boliwia', 'Boliwia', 'Estado Plurinacional da Bolívia', 'Многонациональное Государство Боливия', 'Боливия', 'Bolívijská republika', 'Mångnationella staten Bolivia', 'جمہوریہ بولیویا', 'بولیویا', '多民族玻利维亚国', '玻利维亚', 'Bolivia, Plurinational State of']
}, {
  common: 'Bonaire, St. Eustatius, Saba',
  official: 'Bonaire, Sint Eustatius and Saba',
  cca2: 'BQ',
  ccn3: '535',
  cca3: 'BES',
  cioc: '',
  hasComplemarError: true,
  alternativeNames: ['Bonaire, Sint Eustatius and Saba', 'Caribbean Netherlands', 'Bonaire, Sint Eustatius en Saba', 'Caribisch Nederland', 'Boneiru, Sint Eustatius y Saba', 'Karibské Nizozemsko', 'Bonaire, Sint Eustatius und Saba', 'Karibische Niederlande', 'Bonaire, Sint Eustatius ja Saba', 'Bonaire, Saint-Eustache et Saba', 'Pays-Bas caribéens', 'Bonaire, Sint Eustatius i Saba', 'Bonaire', 'Bonaire, Sint Eustatius e Saba', 'Paesi Bassi caraibici', 'ボネール、シント・ユースタティウスおよびサバ', '보네르, 신트외스타티위스, 사바', '카리브 네덜란드', 'جزایر کارائیب هلند', 'Antyle Holenderskie', 'Bonaire, Saba e Santo Eustáquio', 'Países Baixos Caribenhos', 'Бонэйр, Синт-Эстатиус и Саба', 'Карибские Нидерланды', 'Bonaire, Sint Eustatius a Saba', 'Bonaire, San Eustaquio y Saba', 'Caribe Neerlandés', 'Karibiska Nederländerna', 'بونایر، سینٹ ایوسٹائیس اور سابا', 'کیریبین نیدرلینڈز', '荷蘭加勒比區', 'BES islands']
}, {
  common: 'Brazil',
  official: 'Federative Republic of Brazil',
  cca2: 'BR',
  ccn3: '076',
  cca3: 'BRA',
  cioc: 'BRA',
  alternativeNames: ['Federative Republic of Brazil', 'Brazil', 'República Federativa do Brasil', 'Brasil', 'Brazilská federativní republika', 'Brazílie', 'Gweriniaeth Ffederal Brasil', 'Föderative Republik Brasilien', 'Brasilien', 'Brasiilia Liitvabariik', 'Brasiilia', 'Brasilian liittotasavalta', 'Brasilia', 'République fédérative du Brésil', 'Brésil', 'Savezne Republike Brazil', 'Brazil Szövetségi Köztársaság', 'Brazília', 'Repubblica federativa del Brasile', 'Brasile', 'ブラジル連邦共和国', 'ブラジル', '브라질 연방 공화국', '브라질', 'Federale Republiek Brazilië', 'Brazilië', 'جمهوری فدراتیو برزیل', 'برزیل', 'Federacyjna Republika Brazylii', 'Brazylia', 'Федеративная Республика Бразилия', 'Бразилия', 'Brazílska federatívna republika', 'República Federativa del Brasil', 'Förbundsrepubliken Brasilien', 'وفاقی جمہوریہ برازیل', 'برازیل', '巴西联邦共和国', '巴西']
}, {
  common: 'Brunei',
  official: 'Nation of Brunei, Abode of Peace',
  cca2: 'BN',
  ccn3: '096',
  cca3: 'BRN',
  cioc: 'BRU',
  alternativeNames: ['Nation of Brunei, Abode of Peace', 'Brunei', 'Nation of Brunei, Abode Damai', 'Negara Brunei Darussalam', 'Sultanát Brunej', 'Brunej', 'Teyrnas Brwnei', 'Sultanat Brunei Darussalam', 'Brunei Darussalami Riik', 'Brunei Darussalamin valtio', 'État de Brunei Darussalam', 'Nacija od Bruneja, Kuću Mira', 'Brunei Szultanátus', 'Nazione di Brunei, Dimora della Pace', 'ブルネイ、平和の精舎の国家', 'ブルネイ・ダルサラーム', '브루나이 다루살람국', '브루나이', 'Natie van Brunei, de verblijfplaats van de Vrede', 'برونئی سرای صلح', 'برونئی', 'Państwo Brunei Darussalam', 'Nação do Brunei, Morada da Paz', 'Нация Бруней, обитель мира', 'Бруней', 'Brunejský sultanât', 'Nación de Brunei, Morada de la Paz', 'Brunei Darussalam', 'ریاستِ برونائی دارالسلام', 'برونائی', '文莱和平之国', '文莱', 'Nation of Brunei', 'the Abode of Peace']
}, {
  common: 'Bhutan',
  official: 'Kingdom of Bhutan',
  cca2: 'BT',
  ccn3: '064',
  cca3: 'BTN',
  cioc: 'BHU',
  alternativeNames: ['Kingdom of Bhutan', 'Bhutan', 'འབྲུག་རྒྱལ་ཁབ་', 'འབྲུག་ཡུལ་', 'Bhútánské království', 'Bhútán', 'Teyrnas Bhwtan', 'Bhwtan', 'Königreich Bhutan', 'Bhutani Kuningriik', 'Bhutanin kuningaskunta', 'Royaume du Bhoutan', 'Bhoutan', 'Kraljevina Butan', 'Butan', 'Bhutáni Királyság', 'Bhután', 'Regno del Bhutan', 'ブータン王国', 'ブータン', '부탄 왕국', '부탄', 'Koninkrijk Bhutan', 'پادشاهی بوتان', 'بوتان', 'Reino do Butão', 'Butão', 'Королевство Бутан', 'Бутан', 'Bhutánske krâľovstvo', 'Reino de Bután', 'Bután', 'Konungariket Bhutan', 'سلطنت بھوٹان', 'بھوٹان', '不丹王国', '不丹']
}, {
  common: 'Bouvet Island',
  official: 'Bouvet Island',
  cca2: 'BV',
  ccn3: '074',
  cca3: 'BVT',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Bouvet Island', 'Bouvetøya', 'Bouvetův ostrov', 'Bouvetinsel', 'Bouvet’ saar', "Bouvet'nsaari", 'Île Bouvet', 'Otok Bouvet', 'Bouvet-sziget', 'Isola Bouvet', 'ブーヴェ島', 'ブーベ島', '부베 섬', 'Bouveteiland', 'جزیرهٔ بووه', 'Wyspa Bouveta', 'Ilha Bouvet', 'Остров Буве', 'Bouvetov ostrov', 'Isla Bouvet', 'Bouvetön', 'جزیرہ بووہ', '布维岛', 'Bouvet-øya']
}, {
  common: 'Botswana',
  official: 'Republic of Botswana',
  cca2: 'BW',
  ccn3: '072',
  cca3: 'BWA',
  cioc: 'BOT',
  alternativeNames: ['Republic of Botswana', 'Botswana', 'Lefatshe la Botswana', 'Botswanská republika', 'Republik Botsuana', 'Botswana Vabariik', 'Botswanan tasavalta', 'République du Botswana', 'Republika Bocvana', 'Bocvana', 'Botswanai Köztársaság', 'Repubblica del Botswana', 'ボツワナ共和国', 'ボツワナ', '보츠와나 공화국', '보츠와나', 'Republiek Botswana', 'جمهوری بوتسوانا', 'بوتسوانا', 'Republika Botswany', 'República do Botswana', 'Республика Ботсвана', 'Ботсвана', 'República de Botswana', 'Republiken Botswana', 'جمہوریہ بوٹسوانا', 'بوٹسوانا', '博茨瓦纳共和国', '博茨瓦纳']
}, {
  common: 'Canada',
  official: 'Canada',
  cca2: 'CA',
  ccn3: '124',
  cca3: 'CAN',
  cioc: 'CAN',
  alternativeNames: ['Canada', 'Kanada', 'カナダ', '캐나다', 'کانادا', 'Canadá', 'Канада', 'کینیڈا', '加拿大']
}, {
  common: 'Cocos (Keeling) Islands',
  official: 'Territory of the Cocos (Keeling) Islands',
  cca2: 'CC',
  ccn3: '166',
  cca3: 'CCK',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Territory of the Cocos (Keeling) Islands', 'Cocos (Keeling) Islands', 'Kokosové ostrovy', 'Tiriogaeth yr Ynysoedd Cocos (Keeling)', 'Ynysoedd Cocos', 'Gebiet der Kokos- (Keeling-) Inseln', 'Kokosinseln', 'Kookossaarte ala', 'Kookossaared', 'Kookossaaret', 'Territoire des îles Cocos (Keeling)', 'Îles Cocos', 'Teritoriju Kokosovi (Keeling) Islands', 'Kokosovi Otoci', 'Kókusz-szigetek', 'Territorio della (Keeling) Isole Cocos', 'Isole Cocos e Keeling', 'ココス諸島の領土', 'ココス（キーリング）諸島', '코코스 제도', 'Grondgebied van de Eilanden Cocos (Keeling )', 'Cocoseilanden', 'جزایر کوکوس', 'Wyspy Kokosowe', 'Território dos Cocos (Keeling)', 'Ilhas Cocos (Keeling)', 'Территория Кокосовые (Килинг) острова', 'Кокосовые острова', 'Territorio de los (Keeling) Islas Cocos', 'Islas Cocos o Islas Keeling', 'Kokosöarna', 'جزائر (کیلنگ) کوکوس', 'جزائر کوکوس', '科科斯', 'Keeling Islands', 'Cocos Islands']
}, {
  common: 'Switzerland',
  official: 'Swiss Confederation',
  cca2: 'CH',
  ccn3: '756',
  cca3: 'CHE',
  cioc: 'SUI',
  alternativeNames: ['Swiss Confederation', 'Switzerland', 'Confédération suisse', 'Suisse', 'Schweizerische Eidgenossenschaft', 'Schweiz', 'Confederazione Svizzera', 'Svizzera', 'Confederaziun svizra', 'Svizra', 'Švýcarská konfederace', 'Švýcarsko', 'Šveitsi Konföderatsioon', 'Šveits', 'Sveitsin valaliitto', 'Sveitsi', 'švicarska Konfederacija', 'Švicarska', 'Svájc', 'Confederazione svizzera', 'スイス連邦', 'スイス', '스위스 연방', '스위스', 'Zwitserse Confederatie', 'Zwitserland', 'کنفدراسیون سوئیس', 'سوئیس', 'Konfederacja Szwajcarska', 'Szwajcaria', 'Confederação Suíça', 'Suíça', 'Швейцарская Конфедерация', 'Швейцария', 'Švajčiarska konfederácia', 'Švajčiarsko', 'Confederación Suiza', 'Suiza', 'Schweiziska edsförbundet', 'سوئیس  متحدہ', 'سویٹذرلینڈ', '瑞士联邦', '瑞士']
}, {
  common: 'Chile',
  official: 'Republic of Chile',
  cca2: 'CL',
  ccn3: '152',
  cca3: 'CHL',
  cioc: 'CHI',
  alternativeNames: ['Republic of Chile', 'Chile', 'República de Chile', 'Chilská republika', 'Gweriniaeth Chile', 'Republik Chile', 'Tšiili Vabariik', 'Tšiili', 'Chilen tasavalta', 'République du Chili', 'Chili', 'Republika Čile', 'Čile', 'Chilei Köztársaság', 'Repubblica del Cile', 'Cile', 'チリ共和国', 'チリ', '칠레 공화국', '칠레', 'Republiek Chili', 'جمهوری شیلی', 'شیلی', 'Republika Chile', 'República do Chile', 'Республика Чили', 'Чили', 'Čílska republika', 'Republiken Chile', 'جمہوریہ چلی', 'چلی', '智利共和国', '智利']
}, {
  common: "China, People's Republic of",
  official: "People's Republic of China",
  cca2: 'CN',
  ccn3: '156',
  cca3: 'CHN',
  cioc: 'CHN',
  alternativeNames: ["People's Republic of China", 'China', '中华人民共和国', '中国', 'Čínská lidová republika', 'Čína', 'Gweriniaeth Pobl Tsieina', 'Tsieina', 'Volksrepublik China', 'Hiina Rahvavabariik', 'Hiina', 'Kiinan kansantasavalta', 'Kiina', 'République populaire de Chine', 'Chine', 'Narodna Republika Kina', 'Kina', 'Kínai Népköztársaság', 'Kína', 'Repubblica popolare cinese', 'Cina', '中華人民共和国', '중화인민공화국', '중국', 'Volksrepubliek China', 'جمهوری خلق چین', 'چین', 'Chińska Republika Ludowa', 'Chiny', 'República Popular da China', 'Народная Республика Китай', 'Китай', 'Čínska ľudová republika', 'República Popular de China', 'Folkrepubliken Kina', 'عوامی جمہوریہ چین', 'Zhōngguó', 'Zhongguo', 'Zhonghua', 'Zhōnghuá Rénmín Gònghéguó']
}, {
  common: 'Ivory Coast',
  official: "Republic of Côte d'Ivoire",
  cca2: 'CI',
  ccn3: '384',
  cca3: 'CIV',
  cioc: 'CIV',
  alternativeNames: ["Republic of Côte d'Ivoire", 'Ivory Coast', "République de Côte d'Ivoire", "Côte d'Ivoire", 'Republika Pobřeží slonoviny', 'Pobřeží slonoviny', "Republik Côte d'Ivoire", 'Elfenbeinküste', 'Côte d’Ivoire’i Vabariik', 'Elevandiluurannik', 'Norsunluurannikon tasavalta', 'Norsunluurannikko', "République de Côte d' Ivoire", "Republika Côte d'Ivoire", 'Obala Bjelokosti', 'Elefántcsontparti Köztársaság', 'Elefántcsontpart', "Repubblica della Costa d'Avorio", "Costa d'Avorio", 'コートジボワール共和国', 'コートジボワール', '코트디부아르 공화국', '코트디부아르', 'Republiek Ivoorkust', 'Ivoorkust', 'جمهوری ساحل عاج', 'ساحل عاج', 'Republika WybrzeŻa Kości Słoniowej', 'WybrzeŻe Kości Słoniowej', "República da Côte d'Ivoire", 'Costa do Marfim', "Республика Кот-д'Ивуаре", 'Кот-д’Ивуар', 'Republika Pobrežie Slonoviny', 'Pobržie Slonoviny', "República de Côte d'Ivoire", 'Costa de Marfil', 'Republiken Elfenbenskusten', 'Elfenbenskusten', 'جمہوریہ کوت دیواغ', 'آئیوری کوسٹ', '科特迪瓦共和国', '科特迪瓦']
}, {
  common: 'Cameroon',
  official: 'Republic of Cameroon',
  cca2: 'CM',
  ccn3: '120',
  cca3: 'CMR',
  cioc: 'CMR',
  alternativeNames: ['Republic of Cameroon', 'Cameroon', 'République du Cameroun', 'Cameroun', 'Kamerunská republika', 'Kamerun', 'Gweriniaeth Camerŵn', 'Camerŵn', 'Republik Kamerun', 'Kameruni Vabariik', 'Kamerunin tasavalta', 'Republika Kamerun', 'Kameruni Köztársaság', 'Repubblica del Camerun', 'Camerun', 'カメルーン共和国', 'カメルーン', '카메룬 공화국', '카메룬', 'Republiek Kameroen', 'Kameroen', 'جمهوری کامِرون', 'کامِرون', 'Republika WybrzeŻa Kości Słoniowej', 'WybrzeŻe Kości Słoniowej', 'República dos Camarões', 'Camarões', 'Республика Камерун', 'Камерун', 'República de Camerún', 'Camerún', 'Republiken Kamerun', 'جمہوریہ کیمرون', 'کیمرون', '喀麦隆共和国', '喀麦隆']
}, {
  common: 'Democratic Republic of Congo',
  official: 'Democratic Republic of the Congo',
  cca2: 'CD',
  ccn3: '180',
  cca3: 'COD',
  cioc: 'COD',
  alternativeNames: ['Democratic Republic of the Congo', 'DR Congo', 'République démocratique du Congo', 'RD Congo', 'Repubilika ya Kongo Demokratiki', 'Republiki ya Kongó Demokratiki', 'Ditunga dia Kongu wa Mungalaata', 'Jamhuri ya Kidemokrasia ya Kongo', 'Demokratická republika Kongo', 'DR Kongo', 'Gweriniaeth Ddemocrataidd Congo', 'Demokratische Republik Kongo', 'Kongo (Dem. Rep.)', 'Kongo Demokraatlik Vabariik', 'Kongo DV', 'Kongon demokraattinen tasavalta', 'Congo (Rép. dém.)', 'Demokratska Republika Kongo', 'Kongo, Demokratska Republika', 'Kongói Demokratikus Köztársaság', 'Repubblica Democratica del Congo', 'Congo (Rep. Dem.)', 'コンゴ民主共和国', '콩고 민주 공화국', 'Democratische Republiek Congo', 'Congo (DRC)', 'جمهوری دموکراتیک کنگو', 'کنگو دموکراتیک', 'Demokratyczna Republika Konga', 'República Democrática do Congo', 'Демократическая Республика Конго', 'Konžská demokratická republika', 'Kongo', 'República Democrática del Congo', 'Demokratiska republiken Kongo', 'Kongo-Kinshasa', 'جمہوری جمہوریہ کانگو', '\nکانگو', '刚果民主共和国', '民主刚果', 'Congo-Kinshasa', 'Congo, the Democratic Republic of the', 'DRC']
}, {
  common: 'Congo',
  official: 'Republic of the Congo',
  cca2: 'CG',
  ccn3: '178',
  cca3: 'COG',
  cioc: 'CGO',
  alternativeNames: ['Republic of the Congo', 'République du Congo', 'Repubilika ya Kongo', 'Republíki ya Kongó', 'Konžská republika', 'Kongo', 'Gweriniaeth y Congo', 'Republik Kongo', 'Kongo Vabariik', 'Kongon tasavalta', 'Kongo-Brazzaville', 'Congo', 'Republika Kongo', 'Kongói Köztársaság', 'Repubblica del Congo', 'コンゴ共和国', '콩고', 'Republiek Congo', 'جمهوری برازاویل کُنگو', 'جمهوری کُنگو', 'Republika Konga', 'República do Congo', 'Республика Конго', 'República del Congo', 'Republiken Kongo', 'جمہوریہ کانگو', '刚果共和国', '刚果', 'Congo-Brazzaville']
}, {
  common: 'Cook Islands',
  official: 'Cook Islands',
  cca2: 'CK',
  ccn3: '184',
  cca3: 'COK',
  cioc: 'COK',
  alternativeNames: ['Cook Islands', "Kūki 'Āirani", 'Cookovy ostrovy', 'Ynysoedd Cook', 'Cookinseln', 'Cooki saared', 'Cookinsaaret', 'Îles Cook', 'Cookovo Otočje', 'Cook-szigetek', 'Isole Cook', 'クック諸島', '쿡 제도', 'Cook eilanden', 'Cookeilanden', 'جزایر کوک', 'Wyspy Cooka', 'Ilhas Cook', 'острова Кука', 'Острова Кука', 'Cookove ostrovy', 'Islas Cook', 'Cooköarna', 'جزائر کک', '库克群岛']
}, {
  common: 'Colombia',
  official: 'Republic of Colombia',
  cca2: 'CO',
  ccn3: '170',
  cca3: 'COL',
  cioc: 'COL',
  alternativeNames: ['Republic of Colombia', 'Colombia', 'República de Colombia', 'Kolumbijská republika', 'Kolumbie', 'Gweriniaeth Colombia', 'Republik Kolumbien', 'Kolumbien', 'Colombia Vabariik', 'Kolumbian tasavalta', 'Kolumbia', 'République de Colombie', 'Colombie', 'Republika Kolumbija', 'Kolumbija', 'Kolumbiai Köztársaság', 'Repubblica di Colombia', 'コロンビア共和国', 'コロンビア', '콜롬비아 공화국', '콜롬비아', 'Republiek Colombia', 'جمهوری کلمبیا', 'کلمبیا', 'Republika Kolumbii', 'República da Colômbia', 'Colômbia', 'Республика Колумбия', 'Колумбия', 'Republiken Colombia', 'جمہوریہ کولمبیا', 'کولمبیا', '哥伦比亚共和国', '哥伦比亚']
}, {
  common: 'Comoros',
  official: 'Union of the Comoros',
  cca2: 'KM',
  ccn3: '174',
  cca3: 'COM',
  cioc: 'COM',
  alternativeNames: ['Union of the Comoros', 'Comoros', 'الاتحاد القمري', 'القمر‎', 'Union des Comores', 'Comores', 'Udzima wa Komori', 'Komori', 'Komorský svaz', 'Komory', 'Undeb y Comoros', 'Y Comoros', 'Union der Komoren', 'Komoren', 'Komoori Liit', 'Komoorid', 'Komorien liitto', 'Komorit', 'Savez Komori', 'Comore-szigeteki Unió', 'Comore-szigetek', 'Unione delle Comore', 'Comore', 'コモロ連合', 'コモロ', '코모로 연방', '코모로', 'Unie van de Comoren', 'Comoren', 'مجمع‌الجزایر قمر', 'اتحاد قُمُر', 'Związek Komorów', 'União das Comores', 'Союз Коморских Островов', 'Коморы', 'Komorská únia', 'Unión de las Comoras', 'Comoras', 'Unionen Komorerna', 'Komorerna', 'اتحاد القمری', 'القمری', '科摩罗联盟', '科摩罗', 'al-Ittiḥād al-Qumurī']
}, {
  common: 'Cape Verde',
  official: 'Republic of Cabo Verde',
  cca2: 'CV',
  ccn3: '132',
  cca3: 'CPV',
  cioc: 'CPV',
  alternativeNames: ['Republic of Cabo Verde', 'Cape Verde', 'República de Cabo Verde', 'Cabo Verde', 'Kapverdská republika', 'Kapverdy', 'Gweriniaeth Cabo Verde', 'Penrhyn Verde', 'Republik Cabo Verde', 'Kap Verde', 'Cabo Verde Vabariik', 'Roheneemesaared', 'Kap Verden tasavalta', 'République du Cap-Vert', 'Îles du Cap-Vert', 'Republika Cabo Verde', 'Zelenortska Republika', 'Zöld-foki Köztársaság', 'Repubblica di Capo Verde', 'Capo Verde', 'カーボベルデ共和国', 'カーボベルデ', '카보베르데 공화국', '카보베르데', 'Republiek van Cabo Verde', 'Kaapverdië', 'جمهوری کبو ورد', 'دماغهٔ سبز', 'Republika Zielonego Przylądka', 'Республика Кабо -Верде', 'Кабо-Верде', 'Republiken Kap Verde', 'جمہوریہ کیپ ورڈی', 'کیپ ورڈی', '佛得角共和国', '佛得角']
}, {
  common: 'Costa Rica',
  official: 'Republic of Costa Rica',
  cca2: 'CR',
  ccn3: '188',
  cca3: 'CRI',
  cioc: 'CRC',
  alternativeNames: ['Republic of Costa Rica', 'Costa Rica', 'República de Costa Rica', 'Kostarická republika', 'Kostarika', 'Gweriniaeth Costa Rica', 'Republik Costa Rica', 'Costa Rica Vabariik', 'Costa Rican tasavalta', 'République du Costa Rica', 'Republika Kostarika', 'Costa Rica-i Köztársaság', 'Repubblica di Costa Rica', 'コスタリカ共和国', 'コスタリカ', '코스타리카 공화국', '코스타리카', 'Republiek Costa Rica', 'جمهوری کاستاریکا', 'کاستاریکا', 'Republika Kostaryki', 'Kostaryka', 'República da Costa Rica', 'Республика Коста-Рика', 'Коста-Рика', 'Republiken Costa Rica', 'جمہوریہ کوسٹاریکا', 'کوسٹاریکا', '哥斯达黎加共和国', '哥斯达黎加']
}, {
  common: 'Cuba',
  official: 'Republic of Cuba',
  cca2: 'CU',
  ccn3: '192',
  cca3: 'CUB',
  cioc: 'CUB',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of Cuba', 'Cuba', 'República de Cuba', 'Kubánská republika', 'Kuba', 'Gweriniaeth Ciwba', 'Ciwba', 'Republik Kuba', 'Kuuba Vabariik', 'Kuuba', 'Kuuban tasavalta', 'République de Cuba', 'Republika Kuba', 'Kubai Köztársaság', 'Repubblica di Cuba', 'キューバ共和国', 'キューバ', '쿠바 공화국', '쿠바', 'Republiek Cuba', 'جمهوری کوبا', 'کوبا', 'Republika Kuby', 'Республика Куба', 'Куба', 'Kubánska republika', 'Republiken Kuba', 'جمہوریہ کیوبا', 'کیوبا', '古巴共和国', '古巴']
}, {
  common: 'Curacao',
  official: 'Country of Curaçao',
  cca2: 'CW',
  ccn3: '531',
  cca3: 'CUW',
  cioc: '',
  hasComplemarError: true,
  alternativeNames: ['Country of Curaçao', 'Curaçao', 'Land Curaçao', 'Pais Kòrsou', 'Autonomní země Curaçao', 'Pays de Curaçao', 'Paese di Curaçao', '퀴라소', 'کوراسائو', 'País de Curaçao', 'ilha da Curação', 'Страна Кюрасао', 'Кюрасао', 'Curacao', 'País de Curazao', 'Curazao', 'مملکتِ کیوراساؤ', 'کیوراساؤ', '库拉索', 'Kòrsou']
}, {
  common: 'Christmas Island',
  official: 'Territory of Christmas Island',
  cca2: 'CX',
  ccn3: '162',
  cca3: 'CXR',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Territory of Christmas Island', 'Christmas Island', 'Teritorium Vánočního ostrova', 'Vánoční ostrov', 'Tiriogaeth yr Ynys y Nadolig', 'Ynys y Nadolig', 'Gebiet der Weihnachtsinsel', 'Weihnachtsinsel', 'Jõulusaare ala', 'Jõulusaar', 'Joulusaaren alue', 'Joulusaari', "Territoire de l'île Christmas", 'Île Christmas', 'Teritorij Božićni otok', 'Božićni otok', 'Karácsony-sziget', 'Territorio di Christmas Island', 'Isola di Natale', 'クリスマス島の領土', 'クリスマス島', '크리스마스 섬', 'Grondgebied van Christmas Island', 'Christmaseiland', 'جزیرهٔ کریسمس', 'Wyspa Bożego Narodzenia', 'Território da Ilha Christmas', 'Ilha do Natal', 'Территория острова Рождества', 'Остров Рождества', 'Teritórium Vianočného ostrova', 'Vianočnú ostrov', 'Territorio de la Isla de Navidad', 'Isla de Navidad', 'Julön', 'ریاستِ جزیرہ کرسمس', 'جزیرہ کرسمس', '圣诞岛']
}, {
  common: 'Cayman Islands',
  official: 'Cayman Islands',
  cca2: 'KY',
  ccn3: '136',
  cca3: 'CYM',
  cioc: 'CAY',
  alternativeNames: ['Cayman Islands', 'Kajmanské ostrovy', 'Ynysoedd Cayman', 'Cayman-Inseln', 'Kaimaninseln', 'Kaimanisaared', 'Caymansaaret', 'Îles Caïmans', 'Kajmanski otoci', 'Kajmán-szigetek', 'Isole Cayman', 'ケイマン諸島', '케이맨 제도', 'Caymaneilanden', 'جزایر کیمن', 'Kajmany', 'Ilhas Cayman', 'Ilhas Caimão', 'Каймановы острова', 'Kajmanie ostrovy', 'Islas Caimán', 'Caymanöarna', 'جزائر کیمین', '开曼群岛']
}, {
  common: 'Cyprus',
  official: 'Republic of Cyprus',
  cca2: 'CY',
  ccn3: '196',
  cca3: 'CYP',
  cioc: 'CYP',
  alternativeNames: ['Republic of Cyprus', 'Cyprus', 'Δημοκρατία της Κύπρος', 'Κύπρος', 'Kıbrıs Cumhuriyeti', 'Kıbrıs', 'Kyperská republika', 'Kypr', 'Gweriniaeth Cyprus', 'Republik Zypern', 'Zypern', 'Küprose Vabariik', 'Küpros', 'Kyproksen tasavalta', 'Kypros', 'République de Chypre', 'Chypre', 'Republika Cipar', 'Cipar', 'Ciprusi Köztársaság', 'Ciprus', 'Repubblica di Cipro', 'Cipro', 'キプロス共和国', 'キプロス', '키프로스 공화국', '키프로스', 'Republiek Cyprus', 'جمهوری قبرس', 'قِبرِس', 'Republika Cypryjska', 'Cypr', 'República de Chipre', 'Chipre', 'Республика Кипр', 'Кипр', 'Cyperská republika', 'Republiken Cypern', 'Cypern', 'جمہوریہ قبرص', 'قبرص', '塞浦路斯共和国', '塞浦路斯', 'Kýpros', 'Κυπριακή Δημοκρατία']
}, {
  common: 'Czech Republic',
  official: 'Czech Republic',
  cca2: 'CZ',
  ccn3: '203',
  cca3: 'CZE',
  cioc: 'CZE',
  alternativeNames: ['Czech Republic', 'Czechia', 'česká republika', 'Česko', 'Česká republika', 'Y Weriniaeth Tsiec', 'Tschechische Republik', 'Tschechien', 'Tšehhi Vabariik', 'Tšehhi', 'Tšekin tasavalta', 'Tšekki', 'République tchèque', 'Tchéquie', 'Češka', 'Cseh Köztársaság', 'Csehország', 'Repubblica Ceca', 'Cechia', 'チェコ共和国', 'チェコ', '체코', 'Tsjechische Republiek', 'Tsjechië', 'جمهوری چک', 'Republika Czeska', 'Czechy', 'República Checa', 'Chéquia', 'Чешская Республика', 'Чехия', 'Chequia', 'Republiken Tjeckien', 'Tjeckien', 'چيک جمہوريہ', 'چيک', '捷克共和国', '捷克']
}, {
  common: 'Germany',
  official: 'Federal Republic of Germany',
  cca2: 'DE',
  ccn3: '276',
  cca3: 'DEU',
  cioc: 'GER',
  alternativeNames: ['Federal Republic of Germany', 'Germany', 'Bundesrepublik Deutschland', 'Deutschland', 'Spolková republika Německo', 'Německo', 'Saksamaa Liitvabariik', 'Saksamaa', 'Saksan liittotasavalta', 'Saksa', "République fédérale d'Allemagne", 'Allemagne', 'Njemačka Federativna Republika', 'Njemačka', 'Német Szövetségi Köztársaság', 'Németország', 'Repubblica federale di Germania', 'Germania', 'ドイツ連邦共和国', 'ドイツ', '독일 연방 공화국', '독일', 'Bondsrepubliek Duitsland', 'Duitsland', 'جمهوری فدرال آلمان', 'آلمان', 'Republika Federalna Niemiec', 'Niemcy', 'República Federal da Alemanha', 'Alemanha', 'Федеративная Республика Германия', 'Германия', 'Nemecká spolková republika', 'Nemecko', 'República Federal de Alemania', 'Alemania', 'Förbundsrepubliken Tyskland', 'Tyskland', 'وفاقی جمہوریہ جرمنی', 'جرمنی', '德意志联邦共和国', '德国']
}, {
  common: 'Djibouti',
  official: 'Republic of Djibouti',
  cca2: 'DJ',
  ccn3: '262',
  cca3: 'DJI',
  cioc: 'DJI',
  alternativeNames: ['Republic of Djibouti', 'Djibouti', 'جمهورية جيبوتي', 'جيبوتي‎', 'République de Djibouti', 'Džibutská republika', 'Džibutsko', 'Gweriniaeth Jibwti', 'Jibwti', 'Republik Dschibuti', 'Dschibuti', 'Djibouti Vabariik', 'Dijiboutin tasavalta', 'Dijibouti', 'Republika Džibuti', 'Džibuti', 'Dzsibuti Köztársaság', 'Dzsibuti', 'Repubblica di Gibuti', 'Gibuti', 'ジブチ共和国', 'ジブチ', '지부티 공화국', '지부티', 'Republiek Djibouti', 'جمهوری جیبوتی', 'جیبوتی', 'Republika Dżibuti', 'Dżibuti', 'República do Djibouti', 'Республика Джибути', 'Джибути', 'ǅibutská republika', 'ǅibutsko', 'República de Djibouti', 'Republiken Djibouti', 'جمہوریہ جبوتی', 'جبوتی', '吉布提共和国', '吉布提', 'Jabuuti', 'Gabuuti', 'Gabuutih Ummuuno', 'Jamhuuriyadda Jabuuti']
}, {
  common: 'Dominica',
  official: 'Commonwealth of Dominica',
  cca2: 'DM',
  ccn3: '212',
  cca3: 'DMA',
  cioc: 'DMA',
  alternativeNames: ['Commonwealth of Dominica', 'Dominica', 'Dominikánské společenství', 'Dominika', 'Cymanwlad Dominica', 'Commonwealth von Dominica', 'Dominica Ühendus', 'Dominican liittovaltio', 'Commonwealth de la Dominique', 'Dominique', 'Zajednica Dominika', 'Dominikai Közösség', 'Commonwealth di Dominica', 'ドミニカ国', '도미니카 공화국', 'Gemenebest Dominica', 'قلمرو همسود دومینیکا', 'دومینیکا', 'Wspólnota Dominiki', 'Comunidade da Dominica', 'Содружество Доминики', 'Доминика', 'Dominické spoločenstvo', 'Mancomunidad de Dominica', 'Samväldet Dominica', 'دولتِ مشترکہ ڈومینیکا', 'ڈومینیکا', '多米尼加共和国', '多米尼加', 'Wai‘tu kubuli']
}, {
  common: 'Denmark',
  official: 'Kingdom of Denmark',
  cca2: 'DK',
  ccn3: '208',
  cca3: 'DNK',
  cioc: 'DEN',
  alternativeNames: ['Kingdom of Denmark', 'Denmark', 'Kongeriget Danmark', 'Danmark', 'Dánské království', 'Dánsko', 'Teyrnas Denmarc', 'Denmarc', 'Königreich Dänemark', 'Dänemark', 'Taani Kuningriik', 'Taani', 'Tanskan kuningaskunta', 'Tanska', 'Royaume du Danemark', 'Danemark', 'Kraljevina Danska', 'Danska', 'Dán Királyság', 'Dánia', 'Regno di Danimarca', 'Danimarca', 'デンマーク王国', 'デンマーク', '덴마크 왕국', '덴마크', 'Koninkrijk Denemarken', 'Denemarken', 'پادشاهی دانمارک', 'دانمارک', 'Królestwo Danii', 'Dania', 'Reino da Dinamarca', 'Dinamarca', 'Королевство Дания', 'Дания', 'Dánske kráľovstvo', 'Reino de Dinamarca', 'Konungariket Danmark', 'مملکتِ ڈنمارک', 'ڈنمارک', '丹麦王国', '丹麦']
}, {
  common: 'Dominican Republic',
  official: 'Dominican Republic',
  cca2: 'DO',
  ccn3: '214',
  cca3: 'DOM',
  cioc: 'DOM',
  alternativeNames: ['Dominican Republic', 'República Dominicana', 'Dominikánská republika', 'Gweriniaeth Dominica', 'Dominikanische Republik', 'Dominikaani Vabariik', 'Dominikaaninen tasavalta', 'République Dominicaine', 'République dominicaine', 'Dominikanska Republika', 'Dominikai Köztársaság', 'Repubblica Dominicana', 'ドミニカ共和国', '도미니카 공화국', 'Dominicaanse Republiek', 'جمهوری دومینیکن', 'Republika Dominikańska', 'Dominikana', 'Доминиканская Республика', 'Dominikánska republika', 'Dominikanska republiken', 'جمہوریہ ڈومینیکن', 'ڈومینیکن', '多明尼加共和国', '多明尼加']
}, {
  common: 'Algeria',
  official: "People's Democratic Republic of Algeria",
  cca2: 'DZ',
  ccn3: '012',
  cca3: 'DZA',
  cioc: 'ALG',
  alternativeNames: ["People's Democratic Republic of Algeria", 'Algeria', 'الجمهورية الديمقراطية الشعبية الجزائرية', 'الجزائر', 'Alžírská demokratická a lidová republika', 'Alžírsko', 'Gweriniaeth Ddemocrataidd Pobl Algeria', 'Demokratische Volksrepublik Algerien', 'Algerien', 'Alžeeria Demokraatlik Rahvavabariik', 'Alžeeria', 'Algerian demokraattinen kansantasavalta', "République démocratique et populaire d'Algérie", 'Algérie', 'Narodna Demokratska Republika Alžir', 'Alžir', 'Algériai Népi Demokratikus Köztársaság', 'Algéria', 'Repubblica popolare democratica di Algeria', 'アルジェリア人民民主共和国', 'アルジェリア', '알제리 인민 민주 공화국', '알제리', 'Democratische Volksrepubliek Algerije', 'Algerije', 'جمهوری دموکراتیک خلق الجزایر', 'الجزایر', 'Algierska Republika Ludowo-Demokratyczna', 'Algieria', 'República Argelina Democrática e Popular', 'Argélia', 'Народно-Демократическая Республика Алжир', 'Алжир', 'Alžírska demokratická ľudová republika', 'República Argelina Democrática y Popular', 'Argelia', 'Demokratiska folkrepubliken Algeriet', 'Algeriet', 'عوامی جمہوری جمہوریہ الجزائر', '阿尔及利亚人民民主共和国', '阿尔及利亚', 'Dzayer']
}, {
  common: 'Ecuador',
  official: 'Republic of Ecuador',
  cca2: 'EC',
  ccn3: '218',
  cca3: 'ECU',
  cioc: 'ECU',
  alternativeNames: ['Republic of Ecuador', 'Ecuador', 'República del Ecuador', 'Ekvádorská republika', 'Ekvádor', 'Gweriniaeth Ecwador', 'Ecwador', 'Republik Ecuador', 'Ecuadori Vabariik', 'Ecuadorin tasavalta', "République de l'Équateur", 'Équateur', 'Republika Ekvador', 'Ekvador', 'Ecuadori Köztársaság', "Repubblica dell'Ecuador", 'エクアドル共和国', 'エクアドル', '에콰도르 공화국', '에콰도르', 'Republiek Ecuador', 'جمهوری اکوادور', 'اکوادور', 'Ekwador', 'República do Equador', 'Equador', 'Республика Эквадор', 'Эквадор', 'Republiken Ecuador', 'جمہوریہ ایکوڈور', 'ایکواڈور', '厄瓜多尔共和国', '厄瓜多尔']
}, {
  common: 'Egypt',
  official: 'Arab Republic of Egypt',
  cca2: 'EG',
  ccn3: '818',
  cca3: 'EGY',
  cioc: 'EGY',
  alternativeNames: ['Arab Republic of Egypt', 'Egypt', 'جمهورية مصر العربية', 'مصر', 'Egyptská arabská republika', 'Gweriniaeth Arabaidd yr Aifft', 'Yr Aifft', 'Arabische Republik Ägypten', 'Ägypten', 'Egiptuse Araabia Vabariik', 'Egiptus', 'Egyptin arabitasavalta', 'Egypti', "République arabe d'Égypte", 'Égypte', 'Arapska Republika Egipat', 'Egipat', 'Egyiptomi Arab Köztársaság', 'Egyiptom', "Repubblica araba d'Egitto", 'Egitto', 'エジプト·アラブ共和国', 'エジプト', '이집트 아랍 공화국', '이집트', 'Arabische Republiek Egypte', 'Egypte', 'جمهوری عربی مصر', 'Arabska Republika Egiptu', 'Egipt', 'República Árabe do Egipto', 'Egito', 'Арабская Республика Египет', 'Египет', 'República Árabe de Egipto', 'Egipto', 'Arabrepubliken Egypten', 'Egypten', 'مصری عرب جمہوریہ', '阿拉伯埃及共和国', '埃及']
}, {
  common: 'Eritrea',
  official: 'State of Eritrea',
  cca2: 'ER',
  ccn3: '232',
  cca3: 'ERI',
  cioc: 'ERI',
  alternativeNames: ['State of Eritrea', 'Eritrea', 'دولة إرتريا', 'إرتريا‎', 'ሃገረ ኤርትራ', 'ኤርትራ', 'Stát Eritrea', 'Gwladwriaeth Eritrea', 'Staat Eritrea', 'Eritrea Riik', 'Eritrean valtio', "État d'Érythrée", 'Érythrée', 'Država Eritreji', 'Eritreja', 'Stato di Eritrea', 'エリトリア国', 'エリトリア', '에리트레아국', '에리트레아', 'جمهوری اریتره', 'اریتره', 'Państwo Erytrea', 'Erytrea', 'Estado da Eritreia', 'Eritreia', 'Государство Эритрея', 'Эритрея', 'Eritrejský štát', 'Estado de Eritrea', 'Staten Eritrea', 'ریاستِ ارتریا', 'ارتریا', '厄立特里亚', 'Dawlat Iritriyá', 'ʾErtrā', 'Iritriyā']
}, {
  common: 'Western Sahara',
  official: 'Sahrawi Arab Democratic Republic',
  cca2: 'EH',
  ccn3: '732',
  cca3: 'ESH',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Sahrawi Arab Democratic Republic', 'Western Sahara', 'الجمهورية العربية الصحراوية الديمقراطية', 'الصحراء الغربية', 'República Árabe Saharaui Democrática', 'Sahara Occidental', 'Západní Sahara', 'Demokratische Arabische Republik Sahara', 'Westsahara', 'Lääne-Sahara', 'Länsi-Sahara', 'République arabe sahraouie démocratique', 'Sahrawi Arab Demokratska Republika', 'Zapadna Sahara', 'Nyugat-Szahara', 'Repubblica Araba Saharawi Democratica', 'Sahara Occidentale', 'サハラアラブ民主共和国', '西サハラ', '사하라 아랍 민주 공화국', '서사하라', 'Sahrawi Arabische Democratische Republiek', 'Westelijke Sahara', 'صحرای غربی', 'Saharyjska Arabska Republika Demokratyczna', 'Sahara Zachodnia', 'Saara Ocidental', 'Sahrawi Арабская Демократическая Республика', 'Западная Сахара', 'Západná Sahara', 'Västsahara', 'صحراوی عرب عوامی جمہوریہ', 'مغربی صحارا', '阿拉伯撒哈拉民主共和国', '西撒哈拉', 'Taneẓroft Tutrimt']
}, {
  common: 'Spain',
  official: 'Kingdom of Spain',
  cca2: 'ES',
  ccn3: '724',
  cca3: 'ESP',
  cioc: 'ESP',
  alternativeNames: ['Kingdom of Spain', 'Spain', 'Reino de España', 'España', 'Španělské království', 'Španělsko', 'Königreich Spanien', 'Spanien', 'Hispaania Kuningriik', 'Hispaania', 'Espanjan kuningaskunta', 'Espanja', "Royaume d'Espagne", 'Espagne', 'Kraljevina Španjolska', 'Španjolska', 'Spanyol Királyság', 'Spanyolország', 'Regno di Spagna', 'Spagna', 'スペイン王国', 'スペイン', '에스파냐 왕국', '스페인', 'Koninkrijk Spanje', 'Spanje', 'پادشاهی اسپانیا', 'اسپانیا', 'Królestwo Hiszpanii ', 'Hiszpania', 'Reino de Espanha', 'Espanha', 'Королевство Испания', 'Испания', 'Španielske kráľovstvo', 'Španielsko', 'Konungariket Spanien', 'مملکتِ ہسپانیہ', 'ہسپانیہ', '西班牙王国', '西班牙']
}, {
  common: 'Estonia',
  official: 'Republic of Estonia',
  cca2: 'EE',
  ccn3: '233',
  cca3: 'EST',
  cioc: 'EST',
  alternativeNames: ['Republic of Estonia', 'Estonia', 'Eesti Vabariik', 'Eesti', 'Estonská republika', 'Estonsko', 'Gweriniaeth Estonia', 'Republik Estland', 'Estland', 'Viron tasavalta', 'Viro', "République d'Estonie", 'Estonie', 'Republika Estonija', 'Estonija', 'Észt Köztársaság', 'Észtország', 'Repubblica di Estonia', 'エストニア共和国', 'エストニア', '에스토니아 공화국', '에스토니아', 'Republiek Estland', 'جمهوری استونی', 'اِستونی', 'Republika Estońska', 'República da Estónia', 'Estónia', 'Эстонская Республика', 'Эстония', 'Estónska republika', 'Estónsko', 'República de Estonia', 'Republiken Estland', 'جمہوریہ اسٹونیا', 'اسٹونیا', '爱沙尼亚共和国', '爱沙尼亚']
}, {
  common: 'Ethiopia',
  official: 'Federal Democratic Republic of Ethiopia',
  cca2: 'ET',
  ccn3: '231',
  cca3: 'ETH',
  cioc: 'ETH',
  alternativeNames: ['Federal Democratic Republic of Ethiopia', 'Ethiopia', 'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ', 'ኢትዮጵያ', 'Etiopská federativní demokratická republika', 'Etiopie', 'Gweriniaeth Ddemocrataidd Ffederal Ethiopia', 'Demokratische Bundesrepublik Äthiopien', 'Äthiopien', 'Etioopia Demokraatlik Liitvabariik', 'Etioopia', 'Etiopian demokraattinen liittotasavalta', 'Etiopia', "République fédérale démocratique d'Éthiopie", 'Éthiopie', 'Savezna Demokratska Republika Etiopija', 'Etiopija', 'Etióp Szövetségi Demokratikus Köztársaság', 'Etiópia', 'Repubblica federale democratica di Etiopia', 'エチオピア連邦民主共和国', 'エチオピア', '에티오피아 연방 민주 공화국', '에티오피아', 'Federale Democratische Republiek Ethiopië', 'Ethiopië', 'جمهوری فدرال دموکراتیک اتیوپی', 'اِتیوپی', 'Federalna Demokratyczna Republika Etiopii', 'República Federal Democrática da Etiópia', 'Федеративная Демократическая Республика Эфиопия', 'Эфиопия', 'Etiópska federatívna demokratická republika', 'República Democrática Federal de Etiopía', 'Etiopía', 'Demokratiska förbundsrepubliken Etiopien', 'Etiopien', 'وفاقی جمہوری جمہوریہ ایتھوپیا', 'ایتھوپیا', '埃塞俄比亚联邦民主共和国', '埃塞俄比亚', 'ʾĪtyōṗṗyā']
}, {
  common: 'Finland',
  official: 'Republic of Finland',
  cca2: 'FI',
  ccn3: '246',
  cca3: 'FIN',
  cioc: 'FIN',
  alternativeNames: ['Republic of Finland', 'Finland', 'Suomen tasavalta', 'Suomi', 'Republiken Finland', 'Finská republika', 'Finsko', 'Republik Finnland', 'Finnland', 'Soome Vabariik', 'Soome', 'République de Finlande', 'Finlande', 'Republika Finska', 'Finska', 'Finn Köztársaság', 'Finnország', 'Repubblica di Finlandia', 'Finlandia', 'フィンランド共和国', 'フィンランド', '핀란드 공화국', '핀란드', 'Republiek Finland', 'جمهوری فنلاند', 'فنلاند', 'Republika Finlandii', 'República da Finlândia', 'Finlândia', 'Финляндская Республика', 'Финляндия', 'Fínska republika', 'Fínsko', 'República de Finlandia', 'جمہوریہ فن لینڈ', 'فن لینڈ', '芬兰共和国', '芬兰']
}, {
  common: 'Fiji',
  official: 'Republic of Fiji',
  cca2: 'FJ',
  ccn3: '242',
  cca3: 'FJI',
  cioc: 'FIJ',
  alternativeNames: ['Republic of Fiji', 'Fiji', 'Matanitu Tugalala o Viti', 'Viti', 'रिपब्लिक ऑफ फीजी', 'फिजी', 'Republika Fidžijských ostrovů', 'Fidži', 'Republik Fidschi', 'Fidschi', 'Fidži Vabariik', 'Fidžin tasavalta', 'République des Fidji', 'Fidji', 'Republika Fidži', 'Fiđi', 'Fidzsi-szigeteki Köztársaság', 'Fidzsi-szigetek', 'Repubblica di Figi', 'Figi', 'フィジー共和国', 'フィジー', '피지 공화국', '피지', 'Republiek Fiji', 'جمهوری جزایر فیجی', 'فیجی', 'Republika Fidżi', 'Fidżi', 'República de Fiji', 'Республика Фиджи', 'Фиджи', 'Fiǆijská republika', 'Fiǆi', 'Fiyi', 'Republiken Fiji', 'جمہوریہ فجی', 'فجی', '斐济共和国', '斐济', 'Matanitu ko Viti', 'Fijī Gaṇarājya']
}, {
  common: 'Falkland Islands',
  official: 'Falkland Islands',
  cca2: 'FK',
  ccn3: '238',
  cca3: 'FLK',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Falkland Islands', 'Falklandské ostrovy', 'Falklandy', 'Falklandinseln', 'Falklandi saared', 'Falkandinsaaret', 'Îles Malouines', 'Falklandski otoci', 'Falklandski Otoci', 'Falkland-szigetek', 'Isole Falkland', 'Isole Falkland o Isole Malvine', 'フォークランド', 'フォークランド（マルビナス）諸島', '포클랜드 제도', 'Falkland eilanden', 'Falklandeilanden', 'جزایر فالکلند', 'Ilhas Malvinas', 'Фолклендские острова', 'islas Malvinas', 'Islas Malvinas', 'Falklandsöarna', 'جزائر فاکلینڈ', '福克兰群岛', 'Falkland Islands (Malvinas)']
}, {
  common: 'France',
  official: 'French Republic',
  cca2: 'FR',
  ccn3: '250',
  cca3: 'FRA',
  cioc: 'FRA',
  alternativeNames: ['French Republic', 'France', 'République française', 'Francouzská republika', 'Francie', 'Französische Republik', 'Frankreich', 'Prantsuse Vabariik', 'Prantsusmaa', 'Ranskan tasavalta', 'Ranska', 'Francuska Republika', 'Francuska', 'Francia Köztársaság', 'Franciaország', 'Repubblica francese', 'Francia', 'フランス共和国', 'フランス', '프랑스 공화국', '프랑스', 'Franse Republiek', 'Frankrijk', 'جمهوری فرانسه', 'فرانسه', 'Republika Francuska', 'Francja', 'República Francesa', 'França', 'Французская Республика', 'Франция', 'Francúzska republika', 'Francúzsko', 'República francés', 'Republiken Frankrike', 'Frankrike', 'جمہوریہ فرانس', 'فرانس', '法兰西共和国', '法国']
}, {
  common: 'Faeroe Islands',
  official: 'Faroe Islands',
  cca2: 'FO',
  ccn3: '234',
  cca3: 'FRO',
  cioc: '',
  alternativeNames: ['Faroe Islands', 'Færøerne', 'Føroyar', 'Faerské ostrovy', 'Färöer', 'Färöer-Inseln', 'Fääri saared', 'Färsaaret', 'Îles Féroé', 'Farski Otoci', 'Feröer', 'Isole Faroe', 'Isole Far Oer', 'フェロー諸島', '페로 제도', 'Faeröer', 'جزایر فاروئه', 'Wyspy Owcze', 'Ilhas Faroe', 'Ilhas Faroé', 'Фарерские острова', 'Islas Feroe', 'Islas Faroe', 'Färöarna', 'جزائر فارو', '法罗群岛']
}, {
  common: 'Micronesia',
  official: 'Federated States of Micronesia',
  cca2: 'FM',
  ccn3: '583',
  cca3: 'FSM',
  cioc: 'FSM',
  isShipmentUnavailable: true,
  alternativeNames: ['Federated States of Micronesia', 'Micronesia', 'Federativní státy Mikronésie', 'Mikronésie', 'Föderierte Staaten von Mikronesien', 'Mikronesien', 'Mikroneesia Liiduriigid', 'Mikroneesia', 'Mikronesian liittovaltio', 'Mikronesia', 'États fédérés de Micronésie', 'Micronésie', 'Savezne Države Mikronezije', 'Mikronezija', 'Mikronéziai Szövetségi Államok', 'Stati federati di Micronesia', 'ミクロネシア連邦', '미크로네시아 연방', '미크로네시아', 'Federale Staten van Micronesia', 'Micronesië', 'ایالات فدرال میکرونزی', 'میکرونزی', 'Sfederowane Stany Mikronezji', 'Mikronezja', 'Estados Federados da Micronésia', 'Micronésia', 'Федеративные Штаты Микронезии', 'Mikronézske federatívne štáty', 'Mikronézia', 'Estados Federados de Micronesia', 'Mikronesiska federationen', 'ریاستہائے وفاقیہ مائکرونیشیا', 'مائکرونیشیا', '密克罗尼西亚联邦', '密克罗尼西亚', 'Micronesia, Federated States of']
}, {
  common: 'Gabon',
  official: 'Gabonese Republic',
  cca2: 'GA',
  ccn3: '266',
  cca3: 'GAB',
  cioc: 'GAB',
  alternativeNames: ['Gabonese Republic', 'Gabon', 'République gabonaise', 'Gabonská republika', 'Gabunische Republik', 'Gabun', 'Gaboni Vabariik', 'Gabonin tasavalta', 'Gabon Republika', 'Gaboni Köztársaság', 'Repubblica gabonese', 'ガボン共和国', 'ガボン', '가봉 공화국', '가봉', 'Republiek Gabon', 'جمهوری گابُن', 'گابن', 'Republika Gabońska', 'República do Gabão', 'Gabão', 'Габона Республика', 'Габон', 'República de Gabón', 'Gabón', 'Republiken Gabon', 'جمہوریہ گیبون', 'گیبون', '加蓬共和国', '加蓬', 'République Gabonaise']
}, {
  common: 'United Kingdom',
  official: 'United Kingdom of Great Britain and Northern Ireland',
  cca2: 'GB',
  ccn3: '826',
  cca3: 'GBR',
  cioc: 'GBR',
  alternativeNames: ['United Kingdom of Great Britain and Northern Ireland', 'United Kingdom', 'Spojené království Velké Británie a Severního Irska', 'Spojené království', 'Vereinigtes Königreich Großbritannien und Nordirland', 'Vereinigtes Königreich', 'Suurbritannia ja Põhja-Iiri Ühendkuningriik', 'Suurbritannia', 'Ison-Britannian ja Pohjois-Irlannin yhdistynyt kuningaskunta', 'Yhdistynyt kuningaskunta', "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord", 'Royaume-Uni', 'Ujedinjeno Kraljevstvo Velike Britanije i Sjeverne Irske', 'Ujedinjeno Kraljevstvo', 'Nagy-Britannia és Észak-Írország Egyesült Királysága', 'Egyesült Királyság', 'Regno Unito di Gran Bretagna e Irlanda del Nord', 'Regno Unito', 'グレート·ブリテンおよび北アイルランド連合王国', 'イギリス', '그레이트브리튼 북아일랜드 연합 왕국', '영국', 'Verenigd Koninkrijk van Groot-Brittannië en Noord-Ierland', 'Verenigd Koninkrijk', 'پادشاهی متحد بریتانیای کبیر و ایرلند شمالی', 'انگلیس', 'Zjednoczone Królestwo Wielkiej Brytanii i Irlandii Północnej', 'Zjednoczone Krłlestwo', 'Reino Unido da Grã-Bretanha e Irlanda do Norte', 'Reino Unido', 'Соединенное Королевство Великобритании и Северной Ирландии', 'Великобритания', 'Spojené kráľovstvo Veľkej Británie a SevernéhoÌrska', 'Veľká Británia (Spojené kráľovstvo)', 'Reino Unido de Gran Bretaña e Irlanda del Norte', 'Förenade konungariket Storbritannien och Nordirland', 'Storbritannien', 'مملکتِ متحدہ برطانیہ عظمی و شمالی آئرلینڈ', 'مملکتِ متحدہ', '大不列颠及北爱尔兰联合王国', '英国', 'Scotland', 'SF', 'UK', 'England', 'Great Britain']
}, {
  common: 'Georgia',
  official: 'Georgia',
  cca2: 'GE',
  ccn3: '268',
  cca3: 'GEO',
  cioc: 'GEO',
  alternativeNames: ['Georgia', 'საქართველო', 'Gruzie', 'Georgien', 'Gruusia', 'République de Géorgie', 'Géorgie', 'Gruzija', 'Grúzia', 'グルジア', '조지아', 'Georgië', 'گرجستان', 'Gruzja', 'Geórgia', 'Грузия', 'Gruzínsko', 'جارجیا', '格鲁吉亚', 'Sakartvelo']
}, {
  common: 'Guernsey',
  official: 'Bailiwick of Guernsey',
  cca2: 'GG',
  ccn3: '831',
  cca3: 'GGY',
  cioc: '',
  hasComplemarError: true,
  alternativeNames: ['Bailiwick of Guernsey', 'Guernsey', 'Bailliage de Guernesey', 'Guernesey', 'Dgèrnésiais', 'Rychtářství Guernsey', 'Vogtei Guernsey', 'Guernsey foogtkond', 'Struka Guernsey', 'Baliato di Guernsey', 'ガーンジーの得意分野', 'ガーンジー', '건지 섬', 'Baljuwschap Guernsey', 'گرنزی', 'Baliwat Guernsey', 'Bailiado de Guernsey', 'Коронное владение Гернси', 'Гернси', 'Bailía de Guernsey', 'گرنزی رودبار', '根西岛']
}, {
  common: 'Ghana',
  official: 'Republic of Ghana',
  cca2: 'GH',
  ccn3: '288',
  cca3: 'GHA',
  cioc: 'GHA',
  alternativeNames: ['Republic of Ghana', 'Ghana', 'Ghanská republika', 'Republik Ghana', 'Ghana Vabariik', 'Ghanan tasavalta', 'République du Ghana', 'Republika Gana', 'Gana', 'Ghánai Köztársaság', 'Ghána', 'Repubblica del Ghana', 'ガーナ共和国', 'ガーナ', '가나 공화국', '가나', 'Republiek Ghana', 'جمهوری غنا', 'غنا', 'Republika Ghany', 'República do Gana', 'Республика Гана', 'Гана', 'República de Ghana', 'Republiken Ghana', 'جمہوریہ گھانا', 'گھانا', '加纳共和国', '加纳']
}, {
  common: 'Gibraltar',
  official: 'Gibraltar',
  cca2: 'GI',
  ccn3: '292',
  cca3: 'GIB',
  cioc: '',
  alternativeNames: ['Gibraltar', 'Gibraltár', 'Gibilterra', 'ジブラルタル', '지브롤터', 'جبل طارق', 'Гибралтар', 'جبل الطارق', '直布罗陀']
}, {
  common: 'Guinea',
  official: 'Republic of Guinea',
  cca2: 'GN',
  ccn3: '324',
  cca3: 'GIN',
  cioc: 'GUI',
  alternativeNames: ['Republic of Guinea', 'Guinea', 'République de Guinée', 'Guinée', 'Guinejská republika', 'Republik Guinea', 'Guinea Vabariik', 'Guinean tasavalta', 'Republika Gvineja', 'Gvineja', 'Guineai Köztársaság', 'Repubblica di Guinea', 'ギニア共和国', 'ギニア', '기니 공화국', '기니', 'Republiek Guinee', 'Guinee', 'مملکت مستقل پاپوآ گینه نو', 'پاپوآ گینه نو', 'Republika Gwinei', 'Gwinea', 'República da Guiné', 'Guiné', 'Республика Гвинея', 'Гвинея', 'República de Guinea', 'Republiken Guinea', 'جمہوریہ گنی', 'گنی', '几内亚共和国', '几内亚']
}, {
  common: 'Guadeloupe',
  official: 'Guadeloupe',
  cca2: 'GP',
  ccn3: '312',
  cca3: 'GLP',
  cioc: '',
  alternativeNames: ['Guadeloupe', 'Guadeloupe’i ja sõltkondade departemang', 'Guadeloupen departmentti', 'Gvadalupa', 'Guadeloupa', 'グアドループ島', 'グアドループ', '과들루프', 'گوادلوپ', 'Gwadelupa', 'Guadalupe', 'Гваделупа', 'گواڈیلوپ', '瓜德罗普岛', 'Gwadloup']
}, {
  common: 'Gambia',
  official: 'Republic of the Gambia',
  cca2: 'GM',
  ccn3: '270',
  cca3: 'GMB',
  cioc: 'GAM',
  alternativeNames: ['Republic of the Gambia', 'Gambia', 'Gambijská republika', 'Gambie', 'Republik Gambia', 'Gambia Vabariik', 'Gambian tasavalta', 'République de Gambie', 'Republika Gambija', 'Gambija', 'Gambiai Köztársaság', 'Repubblica del Gambia', 'ガンビア共和国', 'ガンビア', '감비아 공화국', '감비아', 'Republiek Gambia', 'جمهوری گامبیا', 'گامبیا', 'Republika Gambii', 'República da Gâmbia', 'Gâmbia', 'Республика Гамбия', 'Гамбия', 'República de Gambia', 'Republiken Gambia', 'جمہوریہ گیمبیا', 'گیمبیا', '冈比亚共和国', '冈比亚']
}, {
  common: 'Guinea-Bissau',
  official: 'Republic of Guinea-Bissau',
  cca2: 'GW',
  ccn3: '624',
  cca3: 'GNB',
  cioc: 'GBS',
  alternativeNames: ['Republic of Guinea-Bissau', 'Guinea-Bissau', 'República da Guiné-Bissau', 'Guiné-Bissau', 'Republika Guinea-Bissau', 'Republik Guinea-Bissau', 'Guinea-Bissau Vabariik', 'Guinea-Bissaun tasavalta', 'République de Guinée-Bissau', 'Guinée-Bissau', 'Republika Gvineja Bisau', 'Gvineja Bisau', 'Bissau-Guineai Köztársaság', 'Bissau-Guinea', 'Repubblica di Guinea-Bissau', 'ギニアビサウ共和国', 'ギニアビサウ', '기니비사우 공화국', '기니비사우', 'Republiek Guinee-Bissau', 'Guinee-Bissau', 'جمهوری گینه بیسائو', 'گینه بیسائو', 'Republika Gwinei Bissau', 'Gwinea Bissau', 'Республика Гвинея -Бисау', 'Гвинея-Бисау', 'Guinejsko-bissauská republika', 'República de Guinea-Bissau', 'Guinea-Bisáu', 'Republiken Guinea-Bissau', 'جمہوریہ گنی بساؤ', 'گنی بساؤ', '几内亚比绍共和国', '几内亚比绍']
}, {
  common: 'Equatorial Guinea',
  official: 'Republic of Equatorial Guinea',
  cca2: 'GQ',
  ccn3: '226',
  cca3: 'GNQ',
  cioc: 'GEQ',
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of Equatorial Guinea', 'Equatorial Guinea', 'République de la Guinée Équatoriale', 'Guinée équatoriale', 'República da Guiné Equatorial', 'Guiné Equatorial', 'República de Guinea Ecuatorial', 'Guinea Ecuatorial', 'Republika Rovníková Guinea', 'Rovníková Guinea', 'Gweriniaeth Gini Gyhydeddol', 'Gini Gyhydeddol', 'Republik Äquatorialguinea', 'Äquatorialguinea', 'Ekvatoriaal-Guinea Vabariik', 'Ekvatoriaal-Guinea', 'Päiväntasaajan Guinean tasavalta', 'Päiväntasaajan Guinea', 'République de Guinée équatoriale', 'Republika Ekvatorska Gvineja', 'Ekvatorijalna Gvineja', 'Egyenlítői-Guinea-i Köztársaság', 'Egyenlítői-Guinea', 'Repubblica della Guinea Equatoriale', 'Guinea Equatoriale', '赤道ギニア共和国', '赤道ギニア', '적도 기니 공화국', '적도 기니', 'Republiek Equatoriaal-Guinea', 'Equatoriaal-Guinea', 'جمهوری گینه استوایی', 'گینه استوایی', 'Republika Gwinei Równikowej', 'Gwinea Równikowa', 'Республика Экваториальная Гвинея', 'Экваториальная Гвинея', 'Republika rovníkovej Guiney', 'Republiken Ekvatorialguinea', 'Ekvatorialguinea', 'جمہوریہ استوائی گنی', 'استوائی گنی', '赤道几内亚共和国', '赤道几内亚']
}, {
  common: 'Greece',
  official: 'Hellenic Republic',
  cca2: 'GR',
  ccn3: '300',
  cca3: 'GRC',
  cioc: 'GRE',
  alternativeNames: ['Hellenic Republic', 'Greece', 'Ελληνική Δημοκρατία', 'Ελλάδα', 'Řecká republika', 'Řecko', 'Hellenische Republik', 'Griechenland', 'Kreeka Vabariik', 'Kreeka', 'Helleenien tasavalta', 'Kreikka', 'République hellénique', 'Grèce', 'Helenska Republika', 'Grčka', 'Görög Köztársaság', 'Görögország', 'Repubblica ellenica', 'Grecia', 'ギリシャ共和国', 'ギリシャ', '그리스 공화국', '그리스', 'Helleense Republiek', 'Griekenland', 'جمهوری یونان', 'یونان', 'Republika Grecka', 'Grecja', 'República Helénica', 'Grécia', 'Греческая Республика', 'Греция', 'Grécka republika', 'Greécko', 'Republiken Grekland', 'Grekland', 'جمہوریہ ہیلینیہ', '希腊共和国', '希腊', 'Elláda']
}, {
  common: 'Grenada',
  official: 'Grenada',
  cca2: 'GD',
  ccn3: '308',
  cca3: 'GRD',
  cioc: 'GRN',
  alternativeNames: ['Grenada', 'Grenade', 'グレナダ', '그레나다', 'گرنادا', 'Granada', 'Гренада', 'گریناڈا', '格林纳达']
}, {
  common: 'Greenland',
  official: 'Greenland',
  cca2: 'GL',
  ccn3: '304',
  cca3: 'GRL',
  cioc: '',
  alternativeNames: ['Greenland', 'Kalaallit Nunaat', 'Grónsko', 'Grönland', 'Gröönimaa', 'Groönlanti', 'Groenland', 'Grenland', 'Groenlandia', 'グリーンランド', '그린란드', 'گروئنلند', 'گرینلند', 'Grenlandia', 'Groenlândia', 'Gronelândia', 'Гренландия', 'گرین لینڈ', '格陵兰', 'Grønland']
}, {
  common: 'Guatemala',
  official: 'Republic of Guatemala',
  cca2: 'GT',
  ccn3: '320',
  cca3: 'GTM',
  cioc: 'GUA',
  alternativeNames: ['Republic of Guatemala', 'Guatemala', 'República de Guatemala', 'Republika Guatemala', 'Republik Guatemala', 'Guatemala Vabariik', 'Guatemalan tasavalta', 'République du Guatemala', 'Republika Gvatemala', 'Gvatemala', 'Guatemalai Köztársaság', 'Repubblica del Guatemala', 'グアテマラ共和国', 'グアテマラ', '과테말라 공화국', '과테말라', 'Republiek Guatemala', 'جمهوری گواتِمالا', 'گواتِمالا', 'Republika Gwatemali', 'Gwatemala', 'República da Guatemala', 'Республика Гватемала', 'Гватемала', 'Guatemalská republika', 'Republiken Guatemala', 'جمہوریہ گواتیمالا', 'گواتیمالا', '危地马拉共和国', '危地马拉']
}, {
  common: 'French Guiana',
  official: 'Guiana',
  cca2: 'GF',
  ccn3: '254',
  cca3: 'GUF',
  cioc: '',
  alternativeNames: ['Guiana', 'French Guiana', 'Guyane', 'Guyane française', 'Francouzská Guyana', 'Französisch-Guayana', 'Guajaana departemang', 'Prantsuse Guajaana', 'Ranskan Guayana', 'Gijana', 'Francuska Gvajana', 'Francia Guyana', 'Guyana francese', 'ギアナ', 'フランス領ギアナ', '프랑스령 기아나', 'Guyana', 'Frans-Guyana', 'گویان فرانسه', 'Gujana Francuska', 'Guiana Francesa', 'Гвиана', 'Французская Гвиана', 'Francúzska Guyana', 'Guayana', 'Guayana Francesa', 'Franska Guyana', 'گیانا', 'فرانسیسی گیانا', '法属圭亚那']
}, {
  common: 'Guam',
  official: 'Guam',
  cca2: 'GU',
  ccn3: '316',
  cca3: 'GUM',
  cioc: 'GUM',
  alternativeNames: ['Guam', 'Guåhån', 'Guami ala', 'グアム', '괌', 'گوآم', 'Terytorium Guamu', 'Гуам', 'گوام', '关岛']
}, {
  common: 'Guyana',
  official: 'Co-operative Republic of Guyana',
  cca2: 'GY',
  ccn3: '328',
  cca3: 'GUY',
  cioc: 'GUY',
  alternativeNames: ['Co-operative Republic of Guyana', 'Guyana', 'Kooperativní republika Guyana', 'Kooperative Republik Guyana', 'Guyana Vabariik', 'Guayanan osuustoiminnallinen tasavalta', 'Guayana', 'République coopérative de Guyana', 'Zadruga Republika Gvajana', 'Gvajana', 'Guyanai Szövetkezeti Köztársaság', 'Co -operative Republic of Guyana', 'ガイアナの協同共和国', 'ガイアナ', '가이아나 협동 공화국', '가이아나', 'Coöperatieve Republiek Guyana', 'جمهوری تعاونی گویان', 'گویان', 'Kooperacyjna Republika Gujany', 'Gujana', 'Co -operative República da Guiana', 'Guiana', 'Кооперативная Республика Гайана', 'Гайана', 'Guyanská kooperatívna republika', 'República Cooperativa de Guyana', 'Kooperativa republiken Guyana', 'تعاونی جمہوریہ گیانا', 'گیانا', '圭亚那共和国', '圭亚那']
}, {
  common: 'Hong Kong',
  official: "Hong Kong Special Administrative Region of the People's Republic of China",
  cca2: 'HK',
  ccn3: '344',
  cca3: 'HKG',
  cioc: 'HKG',
  alternativeNames: ["Hong Kong Special Administrative Region of the People's Republic of China", 'Hong Kong', 'Hong Kong SAR', '中华人民共和国香港特别行政区', '香港', 'Zvláštní administrativní oblast Čínské lidové republiky Hongkong', 'Hongkong', 'Sonderverwaltungszone Hongkong der Volksrepublik China', 'Hongkongi erihalduspiirkond', 'Hong Kongin erityishallintoalue', 'Région administrative spéciale de Hong Kong de la République populaire de Chine', 'Hong Kong Posebnog upravnog područjaNarodne Republike Kine', 'Hong Kong Regione amministrativa speciale della Repubblica Popolare Cinese', '中華人民共和国香港特別行政区', '중화인민공화국 홍콩 특별행정구', '홍콩', 'Hong Kong Speciale Administratieve Regio van de Volksrepubliek China', 'هُنگ کُنگ', 'Specjalny Region Administracyjny Chińskiej Republiki Ludowej Hongkong', 'Hong Kong Região Administrativa Especial da República Popular da China', 'Hong Kong Специальный административный район Китайской Народной Республики Китая', 'Гонконг', 'Špeciálna administratívna oblasťČínskej ľudovej republiky Hongkong', 'Hong Kong Región Administrativa Especial de la República Popular China', 'ہانگ کانگ عوامی جمہوریہ چین کا خصوصی انتظامی علاقہ', 'ہانگ کانگ']
}, {
  common: 'Heard Island and McDonald Islands',
  official: 'Heard Island and McDonald Islands',
  cca2: 'HM',
  ccn3: '334',
  cca3: 'HMD',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Heard Island and McDonald Islands', 'Heardův ostrov a McDonaldovy ostrovy', 'Heard und McDonaldinseln', 'Heard und die McDonaldinseln', 'Heardi ja McDonaldi saarte ala', 'Heard ja McDonald', 'Heard ja McDonaldinsaaret', 'Des îles Heard et McDonald', 'Îles Heard-et-MacDonald', 'Otok Heard i otočje McDonald', 'Heard-sziget és McDonald-szigetek', 'Isole Heard e McDonald', 'ハード島とマクドナルド諸島', '허드 맥도널드 제도', 'Heard en McDonaldeilanden', 'Heard-en McDonaldeilanden', 'جزیره هرد و جزایر مک‌دونالد', 'Terytorium Wysp Heard i McDonalda', 'Wyspy Heard i McDonalda', 'Ilha Heard e Ilhas McDonald', 'Остров Херд и острова Макдональд', 'Teritórium Heardovho ostrova a Macdonaldových ostrovov', 'Heardov ostrov', 'Islas Heard y McDonald', 'Heard- och McDonaldöarna', 'جزیرہ ہرڈ و جزائر مکڈونلڈ', '赫德岛和麦当劳群岛']
}, {
  common: 'Honduras',
  official: 'Republic of Honduras',
  cca2: 'HN',
  ccn3: '340',
  cca3: 'HND',
  cioc: 'HON',
  alternativeNames: ['Republic of Honduras', 'Honduras', 'República de Honduras', 'Honduraská republika', 'Republik Honduras', 'Hondurase Vabariik', 'Hondurasin tasavalta', 'République du Honduras', 'Republika Honduras', 'Hondurasi Köztársaság', 'Repubblica di Honduras', 'ホンジュラス共和国', 'ホンジュラス', '온두라스 공화국', '온두라스', 'Republiek Honduras', 'جمهوری هندوراس', 'هُندوراس', 'Republika Hondurasu', 'Республика Гондурас', 'Гондурас', 'Republiken Honduras', 'جمہوریہ ہونڈوراس', 'ہونڈوراس', '洪都拉斯共和国', '洪都拉斯']
}, {
  common: 'Croatia',
  official: 'Republic of Croatia',
  cca2: 'HR',
  ccn3: '191',
  cca3: 'HRV',
  cioc: 'CRO',
  alternativeNames: ['Republic of Croatia', 'Croatia', 'Republika Hrvatska', 'Hrvatska', 'Chorvatská republika', 'Chorvatsko', 'Gweriniaeth Croatia', 'Republik Kroatien', 'Kroatien', 'Horvaatia Vabariik', 'Horvaatia', 'Kroatian tasavalta', 'Kroatia', 'République de Croatie', 'Croatie', 'Horvát Köztársaság', 'Horvátország', 'Repubblica di Croazia', 'Croazia', 'クロアチア共和国', 'クロアチア', '크로아티아 공화국', '크로아티아', 'Republiek Kroatië', 'Kroatië', 'جمهوری کرواسی', 'کرُواسی', 'Republika Chorwacji', 'Chorwacja', 'República da Croácia', 'Croácia', 'Республика Хорватия', 'Хорватия', 'Chorvátska republika', 'Chorvátsko', 'República de Croacia', 'Croacia', 'Republiken Kroatien', 'جمہوریہ کرویئشا', 'کرویئشا', '克罗地亚共和国', '克罗地亚']
}, {
  common: 'Haiti',
  official: 'Republic of Haiti',
  cca2: 'HT',
  ccn3: '332',
  cca3: 'HTI',
  cioc: 'HAI',
  alternativeNames: ['Republic of Haiti', 'Haiti', "République d'Haïti", 'Haïti', 'Repiblik Ayiti', 'Ayiti', 'Republika Haiti', 'Republik Haiti', 'Haiti Vabariik', 'Haitin tasavalta', 'Haiti Köztársaság', 'Repubblica di Haiti', 'ハイチ共和国', 'ハイチ', '아이티 공화국', '아이티', 'Republiek Haïti', 'جمهوری هائیتی', 'هائیتی', 'República do Haiti', 'Республика Гаити', 'Гаити', 'Haitská republika', 'República de Haití', 'Haití', 'Republiken Haiti', 'جمہوریہ ہیٹی', 'ہیٹی', '海地共和国', '海地']
}, {
  common: 'Hungary',
  official: 'Hungary',
  cca2: 'HU',
  ccn3: '348',
  cca3: 'HUN',
  cioc: 'HUN',
  alternativeNames: ['Hungary', 'Magyarország', 'Maďarsko', 'Ungarn', 'Ungari', 'Unkari', 'Hongrie', 'Madžarska', 'Mađarska', 'Ungheria', 'ハンガリー', '헝가리', 'Hongarije', 'مجارستان', 'Węgry', 'Hungria', 'Венгрия', 'Hungría', 'Ungern', '匈牙利']
}, {
  common: 'Indonesia',
  official: 'Republic of Indonesia',
  cca2: 'ID',
  ccn3: '360',
  cca3: 'IDN',
  cioc: 'INA',
  alternativeNames: ['Republic of Indonesia', 'Indonesia', 'Republik Indonesia', 'Indonéská republika', 'Indonésie', 'Republik Indonesien', 'Indonesien', 'Indoneesia Vabariik', 'Indoneesia', 'Indonesian tasavalta', "République d'Indonésie", 'Republika Indonezija', 'Indonezija', 'Indonéz Köztársaság', 'Indonézia', 'Repubblica di Indonesia', 'インドネシア共和国', 'インドネシア', '인도네시아 공화국', '인도네시아', 'Republiek Indonesië', 'Indonesië', 'جمهوری اندونزی', 'اندونزی', 'Republika Indonezji', 'Indonezja', 'República da Indonésia', 'Indonésia', 'Республика Индонезия', 'Индонезия', 'Indonézska republika', 'República de Indonesia', 'Republiken Indonesien', 'جمہوریہ انڈونیشیا', 'انڈونیشیا', '印度尼西亚共和国', '印度尼西亚']
}, {
  common: 'Isle of Man',
  official: 'Isle of Man',
  cca2: 'IM',
  ccn3: '833',
  cca3: 'IMN',
  cioc: '',
  hasComplemarError: true,
  hasUpsError: true,
  alternativeNames: ['Isle of Man', 'Ellan Vannin or Mannin', 'Mannin', 'Ostrov Man', 'Insel Man', 'Mani saar', 'Mansaari', 'Île de Man', 'Mana ostrvo', 'Otok Man', 'Man', 'Isola di Man', 'マン島', '맨섬', 'جزیرهٔ مَن', 'Wyspa Man', 'Ilha de Man', 'Остров Мэн', 'Isla de Man', 'آئل آف مین', '马恩岛', 'Ellan Vannin', 'Mann']
}, {
  common: 'India',
  official: 'Republic of India',
  cca2: 'IN',
  ccn3: '356',
  cca3: 'IND',
  cioc: 'IND',
  alternativeNames: ['Republic of India', 'India', 'भारत गणराज्य', 'भारत', 'இந்தியக் குடியரசு', 'இந்தியா', 'Indická republika', 'Indie', 'Republik Indien', 'Indien', 'India Vabariik', 'Intian tasavalta', 'Intia', "République de l'Inde", 'Inde', 'Republika Indija', 'Indija', 'Indiai Köztársaság', "Repubblica dell'India", 'インド共和国', 'インド', '인도 공화국', '인도', 'Republiek India', 'جمهوری هندوستان', 'هند', 'Republika Indii', 'República da Índia', 'Índia', 'Республика Индия', 'Индия', 'República de la India', 'Republiken Indien', 'جمہوریہ بھارت', 'بھارت', '印度共和国', '印度', 'Bhārat', 'Bharat Ganrajya']
}, {
  common: 'British Indian Ocean Territory',
  official: 'British Indian Ocean Territory',
  cca2: 'IO',
  ccn3: '086',
  cca3: 'IOT',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['British Indian Ocean Territory', 'Britské indickooceánské území', 'Tiriogaeth Brydeinig Cefnfor India', 'Britisches Territorium im Indischen Ozean', 'Briti India ookeani ala', 'Brittiläinen Intian valtameren alue', "Territoire britannique de l' océan Indien", "Territoire britannique de l'océan Indien", 'Britanski Indijskooceanski teritorij', 'Brit Indiai-óceáni Terület', "Territorio britannico dell'Oceano Indiano", "Territorio britannico dell'oceano indiano", 'イギリス領インド洋地域', '인도 공화국', '인도', 'Brits Indische Oceaan Territorium', 'Britse Gebieden in de Indische Oceaan', 'قلمرو بریتانیا در اقیانوس هند', 'Brytyjskie Terytorium Oceanu Indyjskiego', 'Território Britânico do Oceano Índico', 'Британская территория Индийского океана', 'Британская территория в Индийском океане', 'Britské indickooceánske územie', 'Territorio Británico del Océano Índico', 'Brittiska territoriet i Indiska Oceanen', 'برطانوی بحرہند خطہ', '英属印度洋领地']
}, {
  common: 'Ireland, Republic of',
  official: 'Republic of Ireland',
  cca2: 'IE',
  ccn3: '372',
  cca3: 'IRL',
  cioc: 'IRL',
  alternativeNames: ['Republic of Ireland', 'Ireland', 'Poblacht na hÉireann', 'Éire', 'Irsko', 'Republik Irland', 'Irland', 'Iirimaa', 'Irlannin tasavalta', 'Irlanti', "République d'Irlande", 'Irlande', 'Republika Irska', 'Irska', 'Ír Köztársaság', 'Írország', "Repubblica d'Irlanda", 'Irlanda', 'アイルランド共和国', 'アイルランド', '아일랜드 공화국', '아일랜드', 'Ierland', 'ایرلند', 'Republika Irlandii', 'Irlandia', 'República da Irlanda', 'Ирландия', 'Írska republika', 'Írsko', 'República de Irlanda', 'جمہوریہ جزیرہ آئرلینڈ', 'جزیرہ آئرلینڈ', '爱尔兰共和国', '爱尔兰']
}, {
  common: 'Iran',
  official: 'Islamic Republic of Iran',
  cca2: 'IR',
  ccn3: '364',
  cca3: 'IRN',
  cioc: 'IRI',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Islamic Republic of Iran', 'Iran', 'جمهوری اسلامی ایران', 'ایران', 'Islámská republika Írán', 'Írán', 'Islamische Republik Iran', 'Iraani Islamivabariik', 'Iraan', 'Iranin islamilainen tasavalta', "République islamique d'Iran", 'Islamska Republika Iran', 'Iráni Iszlám Köztársaság', 'Irán', "Repubblica islamica dell'Iran", 'イラン·イスラム共和国', 'イラン・イスラム共和国', '이란 이슬람 공화국', '이란', 'Islamitische Republiek Iran', 'Islamska Republika Iranu', 'República Islâmica do Irã', 'Irão', 'Исламская Республика Иран', 'Иран', 'Iránska islamská republika', 'República Islámica de Irán', 'Islamiska republiken Iran', 'جمہوریہ ایران', '伊朗伊斯兰共和国', '伊朗', 'Iran, Islamic Republic of', 'Jomhuri-ye Eslāmi-ye Irān']
}, {
  common: 'Iraq',
  official: 'Republic of Iraq',
  cca2: 'IQ',
  ccn3: '368',
  cca3: 'IRQ',
  cioc: 'IRQ',
  alternativeNames: ['Republic of Iraq', 'Iraq', 'جمهورية العراق', 'العراق', 'ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ', 'ܩܘܼܛܢܵܐ', 'کۆماری عێراق', 'کۆماری', 'Irácká republika', 'Irák', 'Republik Irak', 'Irak', 'Iraagi Vabariik', 'Iraak', 'Irakin tasavalta', "République d'Irak", 'Republika Irak', 'Iraki Köztársaság', "Repubblica dell'Iraq", 'イラク共和国', 'イラク', '이라크 공화국', '이라크', 'Republiek Irak', 'جمهوری عراق', 'عراق', 'Republika Iraku', 'República do Iraque', 'Iraque', 'Республика Ирак', 'Ирак', 'Iracká republika', 'República de Irak', 'Republiken Irak', 'جمہوریہ عراق', '伊拉克共和国', '伊拉克', 'Jumhūriyyat al-‘Irāq']
}, {
  common: 'Iceland',
  official: 'Iceland',
  cca2: 'IS',
  ccn3: '352',
  cca3: 'ISL',
  cioc: 'ISL',
  alternativeNames: ['Iceland', 'Ísland', 'Island', 'Islandi Vabariik', 'Islanti', "République d'Islande", 'Islande', 'Izland', 'Islanda', 'アイスランド', '아이슬란드 공화국', '아이슬란드', 'IJsland', 'جمهوری ایسلند', 'ایسلند', 'Republika Islandii', 'Islandia', 'Islândia', 'Исландия', 'Islandská republika', 'آئس لینڈ', '冰岛', 'Republic of Iceland', 'Lýðveldið Ísland']
}, {
  common: 'Israel',
  official: 'State of Israel',
  cca2: 'IL',
  ccn3: '376',
  cca3: 'ISR',
  cioc: 'ISR',
  alternativeNames: ['State of Israel', 'Israel', 'دولة إسرائيل', 'إسرائيل', 'מדינת ישראל', 'ישראל', 'Stát Izrael', 'Izrael', 'Staat Israel', 'Iisraeli Riik', 'Iisrael', 'Israelin valtio', "État d'Israël", 'Israël', 'Država Izrael', 'Stato di Israele', 'Israele', 'イスラエル国', 'イスラエル', '이스라엘국', '이스라엘', 'Staat Israël', 'فلسطين اشغالی', 'Państwo Izrael', 'Estado de Israel', 'Государство Израиль', 'Израиль', 'Izraelský štát', 'Staten Israel', 'ریاستِ اسرائیل', 'اسرائیل', '以色列国', '以色列', "Medīnat Yisrā'el"]
}, {
  common: 'Italy',
  official: 'Italian Republic',
  cca2: 'IT',
  ccn3: '380',
  cca3: 'ITA',
  cioc: 'ITA',
  alternativeNames: ['Italian Republic', 'Italy', 'Repubblica italiana', 'Italia', 'Italská republika', 'Itálie', 'Italienische Republik', 'Italien', 'Itaalia Vabariik', 'Itaalia', 'Italian tasavalta', 'République italienne', 'Italie', 'talijanska Republika', 'Italija', 'Olasz Köztársaság', 'Olaszország', 'イタリア共和国', 'イタリア', '이탈리아 공화국', '이탈리아', 'Italiaanse Republiek', 'Italië', 'جمهوری ایتالیا', 'ایتالیا', 'Republika Włoska', 'Włochy', 'República Italiana', 'Itália', 'итальянская Республика', 'Италия', 'Talianska republika', 'Taliansko', 'Republiken Italien', 'جمہوریہ اطالیہ', 'اطالیہ', '意大利共和国', '意大利']
}, {
  common: 'Jamaica',
  official: 'Jamaica',
  cca2: 'JM',
  ccn3: '388',
  cca3: 'JAM',
  cioc: 'JAM',
  alternativeNames: ['Jamaica', 'Jamajka', 'Jamaika', 'Jamaïque', 'Giamaica', 'ジャマイカ', '자메이카', 'جامائیکا', 'Ямайка', 'جمیکا', '牙买加']
}, {
  common: 'Jersey',
  official: 'Bailiwick of Jersey',
  cca2: 'JE',
  ccn3: '832',
  cca3: 'JEY',
  cioc: '',
  hasComplemarError: true,
  alternativeNames: ['Bailiwick of Jersey', 'Jersey', 'Bailliage de Jersey', 'Bailliage dé Jèrri', 'Jèrri', 'Rychtářství Jersey', 'Vogtei Jersey', 'Jersey foogtkond', 'Struka od Jersey', 'Baliato di Jersey', 'Isola di Jersey', 'ジャージの得意分野', 'ジャージー', '저지 섬', 'Baljuwschap Jersey', 'جرزی', 'Bailiado de Jersey', 'Коронное владение Джерси', 'Джерси', 'Bailiwick Jersey', 'Bailía de Jersey', '泽西岛']
}, {
  common: 'Jordan',
  official: 'Hashemite Kingdom of Jordan',
  cca2: 'JO',
  ccn3: '400',
  cca3: 'JOR',
  cioc: 'JOR',
  alternativeNames: ['Hashemite Kingdom of Jordan', 'Jordan', 'المملكة الأردنية الهاشمية', 'الأردن', 'Jordánské hášimovské království', 'Jordánsko', 'Haschemitisches Königreich Jordanien', 'Jordanien', 'Jordaania Hašimiidi Kuningriik', 'Jordaania', 'Jordanian hašemiittinen kunigaskunta', 'Jordania', 'Royaume hachémite de Jordanie', 'Jordanie', 'Hašemitske Kraljevine Jordan', 'Jordánia', 'Regno hascemita di Giordania', 'Giordania', 'ヨルダン·ハシミテ王国', 'ヨルダン', '요르단 하심 왕국', '요르단', 'Hasjemitisch Koninkrijk Jordanië', 'Jordanië', 'پادشاهی اُردُن هاشمی', 'اردن', 'Jordańskie Królestwo Haszymidzkie', 'Reino Hachemita da Jordânia', 'Jordânia', 'Иорданского Хашимитского Королевства', 'Иордания', 'Jordánske hášimovské kráľovstvo', 'Reino Hachemita de Jordania', 'Hashimitiska kungadömet Jordanien', 'ھاشمی مملکتِ اردن', '约旦哈希姆王国', '约旦', 'al-Mamlakah al-Urdunīyah al-Hāshimīyah']
}, {
  common: 'Japan',
  official: 'Japan',
  cca2: 'JP',
  ccn3: '392',
  cca3: 'JPN',
  cioc: 'JPN',
  alternativeNames: ['Japan', '日本', 'Japonsko', 'Jaapan', 'Japani', 'Japon', 'Japán', 'Giappone', '일본국', '일본', 'ژاپن', 'Japonia', 'Japão', 'Япония', 'Japón', 'جاپان', '日本国', 'Nippon', 'Nihon']
}, {
  common: 'Kazakhstan',
  official: 'Republic of Kazakhstan',
  cca2: 'KZ',
  ccn3: '398',
  cca3: 'KAZ',
  cioc: 'KAZ',
  alternativeNames: ['Republic of Kazakhstan', 'Kazakhstan', 'Қазақстан Республикасы', 'Қазақстан', 'Республика Казахстан', 'Казахстан', 'Republika Kazachstán', 'Kazachstán', 'Republik Kasachstan', 'Kasachstan', 'Kasahstani Vabariik', 'Kasahstan', 'Kazakstanin tasavalta', 'Kazakstan', 'République du Kazakhstan', 'Republika Kazahstan', 'Kazahstan', 'Kazah Köztársaság', 'Kazahsztán', 'Repubblica del Kazakhstan', 'Kazakistan', 'カザフスタン共和国', 'カザフスタン', '카자흐스탄 공화국', '카자흐스탄', 'Republiek Kazachstan', 'Kazachstan', 'جمهوری قزاقستان', 'قزاقستان', 'Republika Kazachstanu', 'República do Cazaquistão', 'Cazaquistão', 'Kazašská republika', 'República de Kazajstán', 'Kazajistán', 'Republiken Kazakstan', 'جمہوریہ قازقستان', 'قازقستان', '哈萨克斯坦共和国', '哈萨克斯坦', 'Qazaqstan', 'Qazaqstan Respublïkası', 'Respublika Kazakhstan']
}, {
  common: 'Kenya',
  official: 'Republic of Kenya',
  cca2: 'KE',
  ccn3: '404',
  cca3: 'KEN',
  cioc: 'KEN',
  alternativeNames: ['Republic of Kenya', 'Kenya', 'Keňská republika', 'Keňa', 'Republik Kenia', 'Kenia', 'Keenia Vabariik', 'Keenia', 'Kenian tasavalta', 'République du Kenya', 'Republika Kenija', 'Kenija', 'Kenyai Köztársaság', 'Repubblica del Kenya', 'ケニア共和国', 'ケニア', '케냐 공화국', '케냐', 'Republiek Kenia', 'جمهوری کنیا', 'کنیا', 'Republika Kenii', 'República do Quénia', 'Quénia', 'Республика Кения', 'Кения', 'Kenská republika', 'República de Kenya', 'Republiken Kenya', 'جمہوریہ کینیا', 'کینیا', '肯尼亚共和国', '肯尼亚', 'Jamhuri ya Kenya']
}, {
  common: 'Kosovo',
  official: 'Kosovo',
  cca2: 'KV',
  ccn3: '383',
  cca3: 'XKX',
  cioc: 'XKX',
  alternativeNames: []
}, {
  common: 'Kyrgyzstan',
  official: 'Kyrgyz Republic',
  cca2: 'KG',
  ccn3: '417',
  cca3: 'KGZ',
  cioc: 'KGZ',
  alternativeNames: ['Kyrgyz Republic', 'Kyrgyzstan', 'Кыргыз Республикасы', 'Кыргызстан', 'Кыргызская Республика', 'Киргизия', 'Kyrgyzská republika', 'Kyrgyzstán', 'Kirgisische Republik', 'Kirgisistan', 'Kirgiisi Vabariik', 'Kõrgõzstan', 'Kirgisian tasavalta', 'Kirgisia', 'République kirghize', 'Kirghizistan', 'Kirgistanu', 'Kirgistan', 'Kirgiz Köztársaság', 'Kirgizisztán', 'キルギス共和国', 'キルギス', '키르기스 공화국', '키르기스스탄', 'Kirgizische Republiek', 'Kirgizië', 'جمهوری قِرقیزستان', 'قرقیزستان', 'Republika Kirgiska', 'República do Quirguistão', 'Quirguistão', 'Kirgizská republika', 'Kirgizsko', 'República Kirguisa', 'Kirguizistán', 'Republiken Kirgizistan', 'Kirgizistan', 'جمہوریہ کرغیزستان', 'کرغیزستان', '吉尔吉斯斯坦共和国', '吉尔吉斯斯坦', 'Kyrgyz Respublikasy']
}, {
  common: 'Cambodia',
  official: 'Kingdom of Cambodia',
  cca2: 'KH',
  ccn3: '116',
  cca3: 'KHM',
  cioc: 'CAM',
  alternativeNames: ['Kingdom of Cambodia', 'Cambodia', 'ព្រះរាជាណាចក្រកម្ពុជា', 'Kâmpŭchéa', 'Kambodžské království', 'Kambodža', 'Teyrnas Cambodia', 'Königreich Kambodscha', 'Kambodscha', 'Kambodža Kuningriik', 'Kambodžan kuningaskunta', 'Royaume du Cambodge', 'Cambodge', 'Kraljevina Kambodža', 'Kambodzsai Királyság', 'Kambodzsa', 'Regno di Cambogia', 'Cambogia', 'カンボジア王国', 'カンボジア', '캄보디아 왕국', '캄보디아', 'Koninkrijk Cambodja', 'Cambodja', 'پادشاهی کامبوج', 'کامبوج', 'Królestwo Kambodży', 'Kambodża', 'Reino do Camboja', 'Camboja', 'Королевство Камбоджа', 'Камбоджа', 'Kamboǆské kráľovstvo', 'Kamboǆa', 'Reino de Camboya', 'Camboya', 'Konungariket Kambodja', 'Kambodja', 'مملکتِ کمبوڈیا', 'کمبوڈیا', '柬埔寨王国', '柬埔寨']
}, {
  common: 'Kiribati',
  official: 'Independent and Sovereign Republic of Kiribati',
  cca2: 'KI',
  ccn3: '296',
  cca3: 'KIR',
  cioc: 'KIR',
  alternativeNames: ['Independent and Sovereign Republic of Kiribati', 'Kiribati', 'Ribaberiki Kiribati', 'Republika Kiribati', 'Republik Kiribati', 'Kiribati Vabariik', 'Kiribatin tasavalta', 'République de Kiribati', 'Samostalne i suverene Republike Kiribati', 'Kiribati Köztársaság', 'Repubblica indipendente e sovrano di Kiribati', 'キリバスの独立と主権共和国', 'キリバス', '키리바시 공화국', '키리바시', 'Onafhankelijke en soevereine republiek Kiribati', 'جمهوری کیریباتی', 'کیریباتی', 'Independente e soberano República de Kiribati', 'Независимой и суверенной Республики Кирибати', 'Кирибати', 'Kiribatská republika', 'República Independiente y Soberano de Kiribati', 'Republiken Kiribati', 'سلطنت آزاد جمہوریہ کیریباتی', '基里巴斯共和国', '基里巴斯', 'Republic of Kiribati']
}, {
  common: 'St. Kitts and Nevis',
  official: 'Federation of Saint Christopher and Nevis',
  cca2: 'KN',
  ccn3: '659',
  cca3: 'KNA',
  cioc: 'SKN',
  alternativeNames: ['Federation of Saint Christopher and Nevis', 'Saint Kitts and Nevis', 'Federace Sv. Kryštof a Nevis', 'Svatý Kryštof a Nevis', 'Föderation von St. Kitts und Nevis', 'St. Kitts und Nevis', 'Saint Kittsi ja Nevise Föderatsioon', 'Saint Kitts ja Nevis', 'Saint Christopherin ja Nevisin federaatio', 'Fédération de Saint-Christophe-et-Niévès', 'Saint-Christophe-et-Niévès', 'Federacija Sv.Kristofora i Nevisa', 'Sveti Kristof i Nevis', 'Saint Christopher és Nevis Államszövetség', 'Saint Kitts és Nevis', 'Federazione di Saint Christopher e Nevis', 'Saint Kitts e Nevis', 'セントクリストファーNevis連盟', 'セントクリストファー・ネイビス', '세인트키츠 네비스 연방', '세인트키츠 네비스', 'Federatie van Saint Kitts en Nevis', 'Saint Kitts en Nevis', 'فدراسیون سنت کیتس و نویس', 'سنت کیتس و نویس', 'Federacja Saint Kitts i Nevis', 'Saint Kitts i Nevis', 'Federação de São Cristóvão e Nevis', 'São Cristóvão e Nevis', 'Федерация Сент-Кристофер и Н е в и с', 'Сент-Китс и Невис', 'Feder໡cia Svätého Krištofa a Nevisu', 'Svätý Krištof a Nevis', 'Federación de San Cristóbal y Nevis', 'San Cristóbal y Nieves', 'Federationen Saint Kitts och Nevis', 'Saint Kitts och Nevis', 'وفاقِ سینٹ کیٹز و ناویس', 'سینٹ کیٹز و ناویس', '圣克里斯托弗和尼维斯联邦', '圣基茨和尼维斯']
}, {
  common: 'South Korea',
  official: 'Republic of Korea',
  cca2: 'KR',
  ccn3: '410',
  cca3: 'KOR',
  cioc: 'KOR',
  alternativeNames: ['Republic of Korea', 'South Korea', '대한민국', '한국', 'Korejská republika', 'Jižní Korea', 'Republik Korea', 'Südkorea', 'Korea Vabariik', 'Lõuna-Korea', 'Korean tasavalta', 'Etelä-Korea', 'République de Corée', 'Corée du Sud', 'Republika Koreja', 'Južna Koreja', 'Koreai Köztársaság', 'Dél-Korea', 'Repubblica di Corea', 'Corea del Sud', '大韓民国', '韓国', 'Republiek Korea', 'Zuid-Korea', 'جمهوری کره', 'کرهٔ جنوبی', 'Republika Korei', 'Korea Południowa', 'República da Coreia', 'Coreia do Sul', 'Республика Корея', 'Южная Корея', 'Kórejská republika', 'Južná Kórea', 'República de Corea', 'Corea del Sur', 'Republiken Korea', 'Sydkorea', 'جمہوریہ کوریا ', 'جنوبی کوریا', '大韩民国', '韩国', 'Republic of Korea (South Korea)', 'Korea, Republic of', '남한', '남조선']
}, {
  common: 'Kosovo',
  official: 'Republic of Kosovo',
  cca2: 'KV',
  ccn3: '',
  cca3: 'UNK',
  cioc: 'KOS',
  hasComplemarError: true,
  hasUpsError: true,
  alternativeNames: ['Republic of Kosovo', 'Kosovo', 'Republika e Kosovës', 'Kosova', 'Република Косово', 'Косово', 'Kosovská republika', 'Republik Kosovo', 'Kosovo Vabariik', 'Kosovon tasavalta', 'République du Kosovo', 'Republika Kosovo', 'Koszovó', 'Repubblica del Kosovo', '코소보 공화국', '코소보', 'Republiek Kosovo', 'جمهوری کوزوو', 'کوزوو', 'Republika Kosowa', 'Kosowo', 'República do Kosovo', 'Республика Косово', 'República de Kosovo', 'Republiken Kosovo', 'جمہوریہ کوسووہ', 'کوسووہ', '科索沃共和国', '科索沃']
}, {
  common: 'Kuwait',
  official: 'State of Kuwait',
  cca2: 'KW',
  ccn3: '414',
  cca3: 'KWT',
  cioc: 'KUW',
  alternativeNames: ['State of Kuwait', 'Kuwait', 'دولة الكويت', 'الكويت', 'Stát Kuvajt', 'Kuvajt', 'Staat Kuwait', 'Kuveidi Riik', 'Kuveit', 'Kuwaitin valtio', 'État du Koweït', 'Koweït', 'Država Kuvajt', 'Kuvaiti Állam', 'Kuvait', 'Stato del Kuwait', 'クウェート国', 'クウェート', '쿠웨이트국', '쿠웨이트', 'Staat Koeweit', 'Koeweit', 'دولت کویت', 'کُویت', 'Państwo Kuwejt', 'Kuwejt', 'Estado do Kuwait', 'Государство Кувейт', 'Кувейт', 'Kuvajtský štát', 'Estado de Kuwait', 'Staten Kuwait', 'دولتِ کویت', 'کویت', '科威特国', '科威特', 'Dawlat al-Kuwait']
}, {
  common: 'Laos',
  official: "Lao People's Democratic Republic",
  cca2: 'LA',
  ccn3: '418',
  cca3: 'LAO',
  cioc: 'LAO',
  isShipmentUnavailable: true,
  alternativeNames: ["Lao People's Democratic Republic", 'Laos', 'ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ', 'ສປປລາວ', 'Laoská lidově demokratická republika', 'Demokratische Volksrepublik Laos', 'Laose Demokraatlik Rahvavabariik', 'Laosin demokraattinen kansantasavalta', 'République démocratique populaire lao', 'Narodna Demokratska Republika', 'Laoszi Népi Demokratikus Köztársaság', 'Laosz', 'Repubblica democratica popolare del Laos', 'ラオス人民民主共和国', '라오 인민 민주 공화국', '라오스', 'Lao Democratische Volksrepubliek', 'جمهوری دموکراتیک خلق لائوس', 'لائوس', 'Laotańska Republika Ludowo-Demokratyczna', 'Laos, República Democrática', 'Лаосская Народно-Демократическая Республика', 'Лаос', 'Laoská ľudovodemokratická republika', 'República Democrática Popular Lao', 'Demokratiska folkrepubliken Laos', 'عوامی جمہوری جمہوریہ لاؤ', 'لاؤس', '老挝人民民主共和国', '老挝', 'Lao', 'Sathalanalat Paxathipatai Paxaxon Lao']
}, {
  common: 'Lebanon',
  official: 'Lebanese Republic',
  cca2: 'LB',
  ccn3: '422',
  cca3: 'LBN',
  cioc: 'LIB',
  alternativeNames: ['Lebanese Republic', 'Lebanon', 'الجمهورية اللبنانية', 'لبنان', 'République libanaise', 'Liban', 'Libanonská republika', 'Libanon', 'Libanesische Republik', 'Liibanoni Vabariik', 'Liibanon', 'Libanonin tasavalta', 'Libanonska Republika', 'Libanoni Köztársaság', 'Repubblica libanese', 'Libano', 'レバノン共和国', 'レバノン', '레바논 공화국', '레바논', 'Libanese Republiek', 'جمهوری لبنان', 'Republika Libańska', 'República Libanesa', 'Líbano', 'Ливанская Республика', 'Ливан', 'Republiken Libanon', 'جمہوریہ لبنان', '黎巴嫩共和国', '黎巴嫩', 'Al-Jumhūrīyah Al-Libnānīyah']
}, {
  common: 'Liberia',
  official: 'Republic of Liberia',
  cca2: 'LR',
  ccn3: '430',
  cca3: 'LBR',
  cioc: 'LBR',
  alternativeNames: ['Republic of Liberia', 'Liberia', 'Liberijská republika', 'Libérie', 'Republik Liberia', 'Libeeria Vabariik', 'Libeeria', 'Liberian tasavalta', 'République du Libéria', 'Republika Liberija', 'Liberija', 'Libériai Köztársaság', 'Libéria', 'Repubblica di Liberia', 'リベリア共和国', 'リベリア', '라이베리아 공화국', '라이베리아', 'Republiek Liberia', 'جمهوری لیبریا', 'لیبـِریا', 'Republika Liberii', 'República da Libéria', 'Республика Либерия', 'Либерия', 'Libérijská republika', 'República de Liberia', 'Republiken Liberia', 'جمہوریہ لائبیریا', 'لائبیریا', '利比里亚共和国', '利比里亚']
}, {
  common: 'Libya',
  official: 'State of Libya',
  cca2: 'LY',
  ccn3: '434',
  cca3: 'LBY',
  cioc: 'LBA',
  isShipmentUnavailable: true,
  alternativeNames: ['State of Libya', 'Libya', 'الدولة ليبيا', '‏ليبيا', 'Stát Libye', 'Libye', 'Staat Libyen', 'Libyen', 'Liibüa', 'Libyan valtio', 'Grande République arabe libyenne populaire et socialiste', 'Država Libiji', 'Libija', 'Líbia Állam', 'Líbia', 'Stato della Libia', 'Libia', 'リビアの国家', 'リビア', '리비아', 'Staat van Libië', 'Libië', 'دولت لیبی', 'لیبی', 'Państwo Libia', 'Estado da Líbia', 'Государство Ливии', 'Ливия', 'Líbya', 'Estado de Libia', 'Staten Libyen', 'ریاستِ لیبیا', 'لیبیا', '利比亚国', '利比亚', 'Dawlat Libya']
}, {
  common: 'St. Lucia',
  official: 'Saint Lucia',
  cca2: 'LC',
  ccn3: '662',
  cca3: 'LCA',
  cioc: 'LCA',
  alternativeNames: ['Saint Lucia', 'Svatá Lucie', 'St. Lucia', 'Sainte-Lucie', 'Sveta Lucija', 'Santa Lucia', 'セントルシア', '세인트루시아', 'سنت لوسیا', 'Santa Lúcia', 'Сент-Люсия', 'Svätá Lucia', 'Santa Lucía', 'سینٹ لوسیا', '圣卢西亚']
}, {
  common: 'Liechtenstein',
  official: 'Principality of Liechtenstein',
  cca2: 'LI',
  ccn3: '438',
  cca3: 'LIE',
  cioc: 'LIE',
  alternativeNames: ['Principality of Liechtenstein', 'Liechtenstein', 'Fürstentum Liechtenstein', 'Knížectví Lichtenštejnské', 'Lichtenštejnsko', 'Liechtensteini Vürstiriik', 'Liechensteinin ruhtinaskunta', 'Liechenstein', 'Principauté du Liechtenstein', 'Kneževina Lihtenštajn', 'Lihtenštajn', 'Liechtensteini Hercegség', 'Principato del Liechtenstein', 'リヒテンシュタイン公国', 'リヒテンシュタイン', '리히텐슈타인 공국', '리히텐슈타인', 'Vorstendom Liechtenstein', 'شاهزاده‌نشین لیختن‌اشتاین', 'لیختن‌اشتاین', 'Księstwo Liechtensteinu', 'Principado de Liechtenstein', 'Княжество Лихтенштейн', 'Лихтенштейн', 'Lichtenštajnské kniežatstvo', 'Lichtenštajnsko', 'Furstendömet Liechtenstein', 'امارات لیختینستائن', 'لیختینستائن', '列支敦士登公国', '列支敦士登']
}, {
  common: 'Sri Lanka',
  official: 'Democratic Socialist Republic of Sri Lanka',
  cca2: 'LK',
  ccn3: '144',
  cca3: 'LKA',
  cioc: 'SRI',
  alternativeNames: ['Democratic Socialist Republic of Sri Lanka', 'Sri Lanka', 'ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය', 'ශ්‍රී ලංකාව', 'இலங்கை சனநாயக சோசலிசக் குடியரசு', 'இலங்கை', 'Srílanská demokratická socialistická republika', 'Srí Lanka', 'Demokratische Sozialistische Republik Sri Lanka', 'Sri Lanka Demokraatlik Sotsialistlik Vabariik', 'Sri Lankan demokraattinen sosialistinen tasavalta', 'République démocratique socialiste du Sri Lanka', 'Demokratska Socijalističke Republike Šri Lanke', 'Šri Lanka', 'Srí Lanka-i Demokratikus Szocialista Köztársaság', 'Repubblica democratica socialista dello Sri Lanka', 'スリランカ民主社会主義共和国', 'スリランカ', '스리랑카 민주 사회주의 공화국', '스리랑카', 'Democratische Socialistische Republiek Sri Lanka', 'جمهوری دموکراتیک سوسیالیستی سری‌لانکا', 'سری‌لانکا', 'Demokratyczno-Socjalistyczna Republika Sri Lanki', 'República Democrática Socialista do Sri Lanka', 'Демократическая Социалистическая Республика Шри-Ланка', 'Шри-Ланка', 'República Democrática Socialista de Sri Lanka', 'Demokratiska socialistiska republiken Sri Lanka', 'جمہوری و اشتراکی جمہوریہ سری لنکا', 'سری لنکا', '斯里兰卡民主社会主义共和国', '斯里兰卡', 'ilaṅkai']
}, {
  common: 'Lesotho',
  official: 'Kingdom of Lesotho',
  cca2: 'LS',
  ccn3: '426',
  cca3: 'LSO',
  cioc: 'LES',
  alternativeNames: ['Kingdom of Lesotho', 'Lesotho', 'Lesothské království', 'Königreich Lesotho', 'Lesotho Kuningriik', 'Lesothon kuningaskunta', 'Royaume du Lesotho', 'Kraljevina Lesoto', 'Lesoto', 'Lesothói Királyság', 'Regno del Lesotho', 'レソト王国', 'レソト', '레소토 왕국', '레소토', 'Koninkrijk Lesotho', 'پادشاهی لسوتو', 'لسوتو', 'Królestwo Lesotho', 'Reino do Lesoto', 'Королевство Лесото', 'Лесото', 'Lesothské kráľovstvo', 'Reino de Lesotho', 'Konungariket Lesotho', 'مملکتِ لیسوتھو', 'لیسوتھو', '莱索托王国', '莱索托', 'Muso oa Lesotho']
}, {
  common: 'Lithuania',
  official: 'Republic of Lithuania',
  cca2: 'LT',
  ccn3: '440',
  cca3: 'LTU',
  cioc: 'LTU',
  alternativeNames: ['Republic of Lithuania', 'Lithuania', 'Lietuvos Respublikos', 'Lietuva', 'Litevská republika', 'Litva', 'Republik Litauen', 'Litauen', 'Leedu Vabariik', 'Leedu', 'Liettuan tasavalta', 'Liettua', 'République de Lituanie', 'Lituanie', 'Republika Litva', 'Litván Köztársaság', 'Litvánia', 'Repubblica di Lituania', 'Lituania', 'リトアニア共和国', 'リトアニア', '리투아니아 공화국', '리투아니아', 'Republiek Litouwen', 'Litouwen', 'لیتوانیایی‌ها', 'Republika Litewska', 'Litwa', 'República da Lituânia', 'Lituânia', 'Литовская Республика', 'Литва', 'Litovská republika', 'República de Lituania', 'Republiken Litauen', 'جمہوریہ لتھووینیا', 'لتھووینیا', '立陶宛共和国', '立陶宛', 'Lietuvos Respublika']
}, {
  common: 'Luxembourg',
  official: 'Grand Duchy of Luxembourg',
  cca2: 'LU',
  ccn3: '442',
  cca3: 'LUX',
  cioc: 'LUX',
  alternativeNames: ['Grand Duchy of Luxembourg', 'Luxembourg', 'Großherzogtum Luxemburg', 'Luxemburg', 'Grand-Duché de Luxembourg', 'Groussherzogtum Lëtzebuerg', 'Lëtzebuerg', 'Lucemburské velkovévodství', 'Lucembursko', 'Großherzogtum Luxemburg,', 'Luksemburgi Suurhertsogiriik', 'Luksemburg', 'Luxemburgin suurherttuakunta', 'Veliko Vojvodstvo Luksemburg', 'Luxemburgi Nagyhercegség', 'Granducato di Lussemburgo', 'Lussemburgo', 'ルクセンブルク大公国', 'ルクセンブルク', '룩셈부르크 대공국', '룩셈부르크', 'Groothertogdom Luxemburg', 'دوک‌نشین لوکزامبورگ', 'لوکزامبورگ', 'Wielkie Księstwo Luksemburga', 'Grão-Ducado do Luxemburgo', 'Luxemburgo', 'Великое Герцогство Люксембург', 'Люксембург', 'Luxemburské veľkovojvodstvo', 'Luxembursko', 'Gran Ducado de Luxemburgo', 'Storhertigdömet Luxemburg', 'دوقیہ کبیرلکسمبرگ', 'لکسمبرگ', '卢森堡大公国', '卢森堡']
}, {
  common: 'Latvia',
  official: 'Republic of Latvia',
  cca2: 'LV',
  ccn3: '428',
  cca3: 'LVA',
  cioc: 'LAT',
  alternativeNames: ['Republic of Latvia', 'Latvia', 'Latvijas Republikas', 'Latvija', 'Lotyšská republika', 'Lotyšsko', 'Republik Lettland', 'Lettland', 'Läti Vabariik', 'Läti', 'Latvian tasavalta', 'République de Lettonie', 'Lettonie', 'Republika Latvija', 'Lett Köztársaság', 'Lettország', 'Repubblica di Lettonia', 'Lettonia', 'ラトビア共和国', 'ラトビア', '라트비아 공화국', '라트비아', 'Republiek Letland', 'Letland', 'جمهوری لتونی', 'لتونی', 'Republika Łotewska', 'Łotwa', 'República da Letónia', 'Letónia', 'Латвийская Республика', 'Латвия', 'República de Letonia', 'Letonia', 'Republiken Lettland', 'جمہوریہ لٹویا', 'لٹویا', '拉脱维亚共和国', '拉脱维亚', 'Latvijas Republika']
}, {
  common: 'Macau',
  official: "Macao Special Administrative Region of the People's Republic of China",
  cca2: 'MO',
  ccn3: '446',
  cca3: 'MAC',
  cioc: '',
  alternativeNames: ["Macao Special Administrative Region of the People's Republic of China", 'Macau', 'Região Administrativa Especial de Macau da República Popular da China', '中华人民共和国澳门特别行政区', '澳门', 'Zvláštní správní oblast Čínské lidové republiky Macao', 'Macao', 'Sonderverwaltungsregion Macau der Volksrepublik China', 'Macau erihalduspiirkond', 'Macaon Kiinan kansantasavallan erityishallintoalue', 'Région administrative spéciale de Macao de la République populaire de Chine', 'Makao Posebnog upravnog područjaNarodne Republike Kine', 'Makao', 'Makaó', 'Macao Regione amministrativa speciale della Repubblica Popolare Cinese', '中華人民共和国マカオ特別行政区', 'マカオ', '중화인민공화국 마카오 특별행정구', '마카오', 'Speciale Administratieve Regio Macau van de Volksrepubliek China', 'ماکائو', 'Specjalny Region Administracyjny Chińskiej Republiki Ludowej Makau', 'Makau', 'Macau Região Administrativa Especial da República Popular da China', 'Специальный административный район Макао Китайской Народной Республики Китай', 'Макао', 'Macao, Špeciàlna administratívna oblasŦ', 'Macao, Región Administrativa Especial de la República Popular China', 'مکاؤ عوامی جمہوریہ چین کا خصوصی انتظامی علاقہ', 'مکاؤ', '中華人民共和國澳門特別行政區']
}, {
  common: 'Saint Martin',
  official: 'Saint Martin',
  cca2: 'MF',
  ccn3: '663',
  cca3: 'MAF',
  cioc: '',
  hasComplemarError: true,
  hasUpsError: true,
  alternativeNames: ['Saint Martin', 'Saint-Martin', 'Svatý Martin', 'Svatý Martin (Francie)', 'Saint-Martini ühendus', 'Sveti Martin', 'Saint-Martin Közösség', 'saint Martin', 'サンマルタン島', 'サン・マルタン（フランス領）', '생마르탱', 'سن مارتن', 'Wspólnota Saint-Martin', 'São Martinho', 'Сен-Мартен', 'Förvaltningsområdet Saint-Martin', 'سینٹ مارٹن', '圣马丁', 'Collectivity of Saint Martin', 'Collectivité de Saint-Martin', 'Saint Martin (French part)']
}, {
  common: 'Morocco',
  official: 'Kingdom of Morocco',
  cca2: 'MA',
  ccn3: '504',
  cca3: 'MAR',
  cioc: 'MAR',
  alternativeNames: ['Kingdom of Morocco', 'Morocco', 'المملكة المغربية', 'المغرب', 'ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ', 'ⵍⵎⴰⵖⵔⵉⴱ', 'Marocké království', 'Maroko', 'Königreich Marokko', 'Marokko', 'Maroko Kuningriik', 'Marokon kuningaskunta', 'Royaume du Maroc', 'Maroc', 'Kraljevina Maroko', 'Marokkói Királyság', 'Marokkó', 'Regno del Marocco', 'Marocco', 'モロッコ王国', 'モロッコ', '모로코 왕국', '모로코', 'Koninkrijk Marokko', 'پادشاهی مراکش', 'مراکش', 'Królestwo Marokańskie', 'Reino de Marrocos', 'Marrocos', 'Королевство Марокко', 'Марокко', 'Marocké kniežatstvo', 'Reino de Marruecos', 'Marruecos', 'Konungariket Marocko', 'Marocko', 'مملکتِ مراکش', '摩洛哥王国', '摩洛哥', 'Al-Mamlakah al-Maġribiyah']
}, {
  common: 'Monaco',
  official: 'Principality of Monaco',
  cca2: 'MC',
  ccn3: '492',
  cca3: 'MCO',
  cioc: 'MON',
  alternativeNames: ['Principality of Monaco', 'Monaco', 'Principauté de Monaco', 'Monacké knížectví', 'Monako', 'Fürstentum Monaco', 'Monaco Vürstiriik', 'Monacon ruhtinaskunta', 'Kneževina Monako', 'Monacói Hercegség', 'Principato di Monaco', 'モナコ公国', 'モナコ', '모나코 공국', '모나코', 'Vorstendom Monaco', 'شاهزاده‌نشین موناکو', 'موناکو', 'Księstwo Monako', 'Principado do Mónaco', 'Mónaco', 'Княжество Монако', 'Монако', 'Monacké kniežatstvo', 'Principado de Mónaco', 'Furstendömet Monaco', 'جمہوریہ مناکو', '摩纳哥公国', '摩纳哥']
}, {
  common: 'Moldova',
  official: 'Republic of Moldova',
  cca2: 'MD',
  ccn3: '498',
  cca3: 'MDA',
  cioc: 'MDA',
  alternativeNames: ['Republic of Moldova', 'Moldova', 'Republica Moldova', 'Moldavská republika', 'Moldavsko', 'Republik Moldau', 'Moldawien', 'Moldova Vabariik', 'Moldovan tasavalta', 'République de Moldavie', 'Moldavie', 'Moldavija', 'Moldovai Köztársaság', 'Repubblica di Moldova', 'Moldavia', 'モルドバ共和国', '몰도바 공화국', '몰도바', 'Republiek Moldavië', 'Moldavië', 'جمهوری مولداوی', 'مولداوی', 'Republika Mołdawii', 'Mołdawia', 'República da Moldávia', 'Moldávia', 'Молдова', 'Молдавия', 'República de Moldova', 'Republiken Moldavien', 'Moldavien', 'جمہوریہ مالدووا', 'مالدووا', '摩尔多瓦共和国', '摩尔多瓦', 'Moldova, Republic of']
}, {
  common: 'Madagascar',
  official: 'Republic of Madagascar',
  cca2: 'MG',
  ccn3: '450',
  cca3: 'MDG',
  cioc: 'MAD',
  alternativeNames: ['Republic of Madagascar', 'Madagascar', 'République de Madagascar', "Repoblikan'i Madagasikara", 'Madagasikara', 'Madagaskarská republika', 'Madagaskar', 'Republik Madagaskar', 'Madagaskari Vabariik', 'Madagaskarin tasavalta', 'Republika Madagaskar', 'Madagaszkári Köztársaság', 'Madagaszkár', 'Repubblica del Madagascar', 'マダガスカル共和国', 'マダガスカル', '마다가스카르 공화국', '마다가스카르', 'Republiek Madagaskar', 'جمهوری ماداگاسکار', 'ماداگاسکار', 'Republika Madagaskaru', 'República de Madagáscar', 'Madagáscar', 'Республика Мадагаскар', 'Мадагаскар', 'República de Madagascar', 'Republiken Madagaskar', 'جمہوریہ مڈغاسکر', 'مڈغاسکر', '马达加斯加共和国', '马达加斯加']
}, {
  common: 'Maldives',
  official: 'Republic of the Maldives',
  cca2: 'MV',
  ccn3: '462',
  cca3: 'MDV',
  cioc: 'MDV',
  alternativeNames: ['Republic of the Maldives', 'Maldives', 'ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ', 'ދިވެހިރާއްޖޭގެ', 'Maledivská republika', 'Maledivy', 'Republik Malediven', 'Malediven', 'Maldiivi Vabariik', 'Maldiivid', 'Malediivien tasavalta', 'Malediivit', 'République des Maldives', 'Republika Maldivi', 'Maldivi', 'Maldív-szigetek', 'Repubblica delle Maldive', 'Maldive', 'モルディブ共和国', 'モルディブ', '몰디브 공화국', '몰디브', 'Republiek van de Malediven', 'Maldiven', 'جمهوری مالدیو', 'مالدیو', 'Republika Malediwów', 'Malediwy', 'República das Maldivas', 'Maldivas', 'Республика Мальдивы', 'Мальдивы', 'Maldivská republika', 'Maldivy', 'República de las Maldivas', 'Republiken Maldiverna', 'Maldiverna', 'جمہوریہ مالدیپ', 'مالدیپ', '马尔代夫共和国', '马尔代夫', 'Maldive Islands', 'Dhivehi Raajjeyge Jumhooriyya']
}, {
  common: 'Mexico',
  official: 'United Mexican States',
  cca2: 'MX',
  ccn3: '484',
  cca3: 'MEX',
  cioc: 'MEX',
  alternativeNames: ['United Mexican States', 'Mexico', 'Estados Unidos Mexicanos', 'México', 'Spojené státy mexické', 'Mexiko', 'Vereinigte Mexikanische Staaten', 'Mehhiko Ühendriigid', 'Mehhiko', 'Meksikon yhdysvallat', 'Meksiko', 'États-Unis du Mexique', 'Mexique', 'Sjedinjene Meksičke Države', 'Mexikói Egyesült Államok', 'Mexikó', 'Stati Uniti del Messico', 'Messico', 'メキシコ合衆国', 'メキシコ', '멕시코 합중국', '멕시코', 'Verenigde Mexicaanse Staten', 'ایالات متحد مکزیک', 'مکزیک', 'Meksykańskie Stany Zjednoczone', 'Meksyk', 'Мексиканские Соединённые Штаты', 'Мексика', 'Spojené štášy mexické', 'Mexikos förenta stater', 'ریاستہائے متحدہ میکسیکو', 'میکسیکو', '墨西哥合众国', '墨西哥', 'Mexicanos']
}, {
  common: 'Marshall Islands',
  official: 'Republic of the Marshall Islands',
  cca2: 'MH',
  ccn3: '584',
  cca3: 'MHL',
  cioc: 'MHL',
  alternativeNames: ['Republic of the Marshall Islands', 'Marshall Islands', 'M̧ajeļ', 'Republika Marshallovy ostrovy', 'Marshallovy ostrovy', 'Republik Marshallinseln', 'Marshallinseln', 'Marshalli Saarte Vabariik', 'Marshalli Saared', 'Marshallinsaarten tasavalta', 'Marshallinsaaret', 'République des Îles Marshall', 'Îles Marshall', 'Republika Maršalovi Otoci', 'Maršalovi Otoci', 'Marshall-szigetek', 'Repubblica delle Isole Marshall', 'Isole Marshall', 'マーシャル諸島共和国', 'マーシャル諸島', '마셜 제도 공화국', '마셜 제도', 'Republiek van de Marshall-eilanden', 'Marshalleilanden', 'جمهوری جزایر مارشال', 'جزایر مارشال', 'Republika Wysp Marshalla', 'Wyspy Marshalla', 'República das Ilhas Marshall', 'Ilhas Marshall', 'Республика Маршалловы острова', 'Маршалловы Острова', 'Republika Marshallových ostrovov', 'Marshallove ostrovy', 'República de las Islas Marshall', 'Islas Marshall', 'Republiken Marshallöarna', 'Marshallöarna', 'جمہوریہ جزائر مارشل', 'جزائر مارشل', '马绍尔群岛共和国', '马绍尔群岛', 'Aolepān Aorōkin M̧ajeļ']
}, {
  common: 'Macedonia (Fyrom)',
  official: 'Republic of North Macedonia',
  cca2: 'MK',
  ccn3: '807',
  cca3: 'MKD',
  cioc: 'MKD',
  alternativeNames: ['Republic of North Macedonia', 'North Macedonia', 'Република Северна Македонија', 'Македонија', 'Republika Severní Makedonie', 'Severní Makedonie', 'Republik Nordmazedonien', 'Nordmazedonien', 'Põhja-Makedoonia Vabariik', 'Põhja-Makedoonia', 'Pohjois-Makedonian tasavalta', 'Pohjois-Makedonia', 'République de Macédoine du Nord', 'Macédoine du Nord', 'Republika Sjeverna Makedonija', 'Sjeverna Makedonija', 'Észak-macedón Köztársaság', 'Észak-Macedónia', 'Repubblica di Macedonia del Nord', 'Macedonia del Nord', '北マケドニア共和国', '北マケドニア ', '북마케도니아 공화국', '북마케도니아', 'Republiek Noord-Macedonië', 'Noord-Macedonië', 'جمهوری مقدونیه شمالی', 'مقدونیه شمالی', 'Republika Macedonii Północnej', 'Macedonia Północna', 'República da Macedônia do Norte', 'Macedónia do Norte', 'Республика Северная Македония', 'Северная Македония', 'Severomacedónska republika', 'Severné Macedónsko', 'República de Macedonia del Norte', 'Macedonia del Norte', 'Republiken Nordmakedonien', 'Nordmakedonien', 'جمہوریہ مقدونیہ', 'شمالی مقدونیہ', '北馬其頓共和國', '北馬其頓', 'The former Yugoslav Republic of Macedonia', 'Macedonia, The Former Yugoslav Republic of']
}, {
  common: 'Mali',
  official: 'Republic of Mali',
  cca2: 'ML',
  ccn3: '466',
  cca3: 'MLI',
  cioc: 'MLI',
  alternativeNames: ['Republic of Mali', 'Mali', 'République du Mali', 'Republika Mali', 'Republik Mali', 'Mali Vabariik', 'Malin tasavalta', 'Mali Köztársaság', 'Repubblica del Mali', 'マリ共和国', 'マリ', '말리 공화국', '말리', 'Republiek Mali', 'جمهوری مالی', 'مالی', 'República do Mali', 'Республика Мали', 'Мали', 'Malijská republika', 'República de Malí', 'Republiken Mali', 'جمہوریہ مالی', '马里共和国', '马里']
}, {
  common: 'Malta',
  official: 'Republic of Malta',
  cca2: 'MT',
  ccn3: '470',
  cca3: 'MLT',
  cioc: 'MLT',
  alternativeNames: ['Republic of Malta', 'Malta', "Repubblika ta ' Malta", 'Maltská republika', 'Republik Malta', 'Malta Vabariik', 'Maltan tasavalta', 'République de Malte', 'Malte', 'Republika Malta', 'Máltai Köztársaság', 'Málta', 'Repubblica di Malta', 'マルタ共和国', 'マルタ', '몰타 공화국', '몰타', 'Republiek Malta', 'جمهوری مالت', 'مالت', 'Republika Malty', 'República de Malta', 'Республика Мальта', 'Мальта', 'Republiken Malta', 'جمہوریہ مالٹا', 'مالٹا', '马耳他共和国', '马耳他', "Repubblika ta' Malta"]
}, {
  common: 'Myanmar',
  official: 'Republic of the Union of Myanmar',
  cca2: 'MM',
  ccn3: '104',
  cca3: 'MMR',
  cioc: 'MYA',
  hasUpsError: true,
  alternativeNames: ['Republic of the Union of Myanmar', 'Myanmar', 'ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်', 'မြန်မာ', 'Republika Myanmarský svaz', 'Republik der Union Myanmar', 'Myanmari Liidu Vabariik', 'Myanmarin liiton tasavalta', "République de l'Union du Myanmar", 'Birmanie', 'Republika Unije Mijanmar', 'Mijanmar', 'Mianmari Államszövetség Köztársasága', 'Mianmar', "Repubblica dell'Unione di Myanmar", 'Birmania', 'ミャンマー連邦共和国', 'ミャンマー', '미얀마 연방 공화국', '미얀마', 'Republiek van de Unie van Myanmar', 'اتحادیه جمهوری میانمار', 'میانمار', 'Republika Związku Mjanmy', 'Mjanma', 'República da União de Myanmar', 'Республика Союза Мьянма', 'Мьянма', 'Mjanmarská zväzová republika', 'Mjanmarsko', 'República de la Unión de Myanmar', 'Republiken Unionen Myanmar', 'متحدہ جمہوریہ میانمار', '缅甸联邦共和国', '缅甸', 'Burma', 'Pyidaunzu Thanmăda Myăma Nainngandaw']
}, {
  common: 'Montenegro',
  official: 'Montenegro',
  cca2: 'ME',
  ccn3: '499',
  cca3: 'MNE',
  cioc: 'MNE',
  hasComplemarError: false,
  alternativeNames: ['Montenegro', 'Црна Гора', 'Černá Hora', 'Monténégro', 'Crna Gora', 'Montenegró', 'モンテネグロ', '몬테네그로', 'مونته‌نگرو', 'Czarnogóra', 'Черногория', 'Čierna Hora', 'مونٹینیگرو', '黑山']
}, {
  common: 'Mongolia',
  official: 'Mongolia',
  cca2: 'MN',
  ccn3: '496',
  cca3: 'MNG',
  cioc: 'MGL',
  isShipmentUnavailable: true,
  alternativeNames: ['Mongolia', 'Монгол улс', 'Stát Mongolsko', 'Mongolsko', 'Mongolei', 'Mongoolia', 'Mongolian tasavalta', 'Mongolie', 'Mongolija', 'Mongólia', 'モンゴル', '몽골', '몽골국', 'Mongolië', 'مغولستان', 'Монголия', 'Mongoliet', 'منگولیا', '蒙古']
}, {
  common: 'N. Mariana Islands',
  official: 'Commonwealth of the Northern Mariana Islands',
  cca2: 'MP',
  ccn3: '580',
  cca3: 'MNP',
  cioc: '',
  alternativeNames: ['Commonwealth of the Northern Mariana Islands', 'Northern Mariana Islands', 'Sankattan Siha Na Islas Mariånas', 'Na Islas Mariånas', 'Společenství Severních Marian', 'Severní Mariany', 'Commonwealth der Nördlichen Marianen', 'Nördliche Marianen', 'Põhja-Mariaani Ühendus', 'Põhja-Mariaanid', 'Pohjois-Mariaanit', 'Commonwealth des îles Mariannes du Nord', 'Îles Mariannes du Nord', 'Zajednica je Sjeverni Marijanski otoci', 'Sjevernomarijanski otoci', 'Északi-Mariana-szigetek', 'Commonwealth delle Isole Marianne Settentrionali', 'Isole Marianne Settentrionali', '北マリアナ諸島', '북마리아나 제도', 'Commonwealth van de Noordelijke Marianen', 'Noordelijke Marianeneilanden', 'جزایر ماریانای شمالی', 'Wspólnota Marianów Północnych', 'Mariany Północne', 'Comunidade das Ilhas Marianas do Norte', 'Marianas Setentrionais', 'Содружество Северных Марианских островов', 'Северные Марианские острова', 'Spoločenstvo ostrovov Severné Mariány', 'Severné Mariány', 'Mancomunidad de las Islas Marianas del Norte', 'Islas Marianas del Norte', 'Nordmarianerna', 'دولتِ مشترکہ جزائر شمالی ماریانا', 'جزائر شمالی ماریانا', '北马里亚纳群岛']
}, {
  common: 'Mozambique',
  official: 'Republic of Mozambique',
  cca2: 'MZ',
  ccn3: '508',
  cca3: 'MOZ',
  cioc: 'MOZ',
  alternativeNames: ['Republic of Mozambique', 'Mozambique', 'República de Moçambique', 'Moçambique', 'Mosambická republika', 'Mosambik', 'Republik Mosambik', 'Mosambiigi Vabariik', 'Mosambiik', 'Mosambikin tasavalta', 'République du Mozambique', 'Republika Mozambiku', 'Mozambik', 'Mozambiki Köztársaság', 'Repubblica del Mozambico', 'Mozambico', 'モザンビーク共和国', 'モザンビーク', '모잠비크 공화국', '모잠비크', 'Republiek Mozambique', 'جمهوری موزامبیک', 'موزامبیک', 'Республика Мозамбик', 'Мозамбик', 'Mozambická republika', 'República de Mozambique', 'Republiken Moçambique', 'جمہوریہ موزمبیق', 'موزمبیق', '莫桑比克共和国', '莫桑比克']
}, {
  common: 'Mauritania',
  official: 'Islamic Republic of Mauritania',
  cca2: 'MR',
  ccn3: '478',
  cca3: 'MRT',
  cioc: 'MTN',
  alternativeNames: ['Islamic Republic of Mauritania', 'Mauritania', 'الجمهورية الإسلامية الموريتانية', 'موريتانيا', 'Mauritánská islámská republika', 'Mauritánie', 'Islamische Republik Mauretanien', 'Mauretanien', 'Mauritaania Islamivabariik', 'Mauritaania', 'Mauritanian islamilainen tasavalta', 'République islamique de Mauritanie', 'Mauritanie', 'Islamska Republika Mauritanija', 'Mauritanija', 'Mauritániai Iszlám Köztársaság', 'Mauritánia', 'Repubblica islamica di Mauritania', 'モーリタニア·イスラム共和国', 'モーリタニア', '모리타니 이슬람 공화국', '모리타니', 'Islamitische Republiek Mauritanië', 'Mauritanië', 'جمهوری اسلامی موریتانی', 'موریتانی', 'Islamska Republika Mauretańska', 'Mauretania', 'República Islâmica da Mauritânia', 'Mauritânia', 'Исламская Республика Мавритания', 'Мавритания', 'Mauritánska islamská republika', 'República Islámica de Mauritania', 'Islamiska republiken Mauretanien', 'اسلامی جمہوریہ موریتانیہ', 'موریتانیہ', '毛里塔尼亚伊斯兰共和国', '毛里塔尼亚', 'al-Jumhūriyyah al-ʾIslāmiyyah al-Mūrītāniyyah']
}, {
  common: 'Montserrat',
  official: 'Montserrat',
  cca2: 'MS',
  ccn3: '500',
  cca3: 'MSR',
  cioc: '',
  alternativeNames: ['Montserrat', 'モントセラト', '몬트세랫', 'مونتسرات', 'Монтсеррат', 'مانٹسریٹ', '蒙特塞拉特']
}, {
  common: 'Martinique',
  official: 'Martinique',
  cca2: 'MQ',
  ccn3: '474',
  cca3: 'MTQ',
  cioc: '',
  alternativeNames: ['Martinique', 'Martinik', 'Martinique’i departemang', 'Martinica', 'マルティニーク島', 'マルティニーク', '마르티니크', 'مارتینیک', 'Martynika', 'Мартиника', 'مارٹینیک', '马提尼克']
}, {
  common: 'Mauritius',
  official: 'Republic of Mauritius',
  cca2: 'MU',
  ccn3: '480',
  cca3: 'MUS',
  cioc: 'MRI',
  alternativeNames: ['Republic of Mauritius', 'Mauritius', 'République de Maurice', 'Maurice', 'Republik Moris', 'Moris', 'Mauricijská republika', 'Mauricius', 'Republik Mauritius', 'Mauritiuse Vabariik', 'Mauritiuksen tasavalta', 'Île Maurice', 'Republika Mauricijus', 'Mauricijus', 'Mauritiusi Köztársaság', 'Repubblica di Mauritius', 'モーリシャス共和国', 'モーリシャス', '모리셔스 공화국', '모리셔스', 'Republiek Mauritius', 'جمهوری موریس', 'موریس', 'Republika Mauritiusu', 'República das Maurícias', 'Maurício', 'Республика Маврикий', 'Маврикий', 'Maurícijská republika', 'Maurícius', 'República de Mauricio', 'Mauricio', 'Republiken Mauritius', 'جمہوریہ موریشس', 'موریشس', '毛里求斯共和国', '毛里求斯']
}, {
  common: 'Malawi',
  official: 'Republic of Malawi',
  cca2: 'MW',
  ccn3: '454',
  cca3: 'MWI',
  cioc: 'MAW',
  alternativeNames: ['Republic of Malawi', 'Malawi', 'Chalo cha Malawi, Dziko la Malaŵi', 'Malaŵi', 'Malawiská republika', 'Republik Malawi', 'Malawi Vabariik', 'Malawin tasavalta', 'République du Malawi', 'Republika Malavi', 'Malavi', 'Malawi Köztársaság', 'Repubblica del Malawi', 'マラウイ共和国', 'マラウイ', '말라위 공화국', '말라위', 'Republiek Malawi', 'جمهوری مالاوی', 'مالاوی', 'Republika Malawi', 'República do Malawi', 'Республика Малави', 'Малави', 'Malawijská republika', 'República de Malawi', 'Republiken Malawi', 'جمہوریہ ملاوی', 'ملاوی', '马拉维共和国', '马拉维']
}, {
  common: 'Malaysia',
  official: 'Malaysia',
  cca2: 'MY',
  ccn3: '458',
  cca3: 'MYS',
  cioc: 'MAS',
  alternativeNames: ['Malaysia', 'مليسيا', 'Malajsie', 'Malaisia', 'Malesia', 'Fédération de Malaisie', 'Malaisie', 'Malezija', 'Malajzia', 'マレーシア', '말레이시아', 'Maleisië', 'فدراسیون مالزی', 'مالزی', 'Malezja', 'Malásia', 'Малайзия', 'Malasia', 'ملائیشیا', '马来西亚']
}, {
  common: 'Mayotte',
  official: 'Department of Mayotte',
  cca2: 'YT',
  ccn3: '175',
  cca3: 'MYT',
  cioc: '',
  alternativeNames: ['Department of Mayotte', 'Mayotte', 'Département de Mayotte', 'Übersee-Département Mayotte', 'Odjel Mayotte', 'Dipartimento di Mayotte', 'マヨット科', 'マヨット', '마요트', 'Afdeling Mayotte', 'مجموعه شهرستانی مایوت', 'مایوت', 'Majotta', 'Departamento de Mayotte', 'Департамент Майотта', 'Майотта', 'Department Mayotte', 'Departementsområdet Mayotte', 'مایوٹ', '马约特']
}, {
  common: 'Namibia',
  official: 'Republic of Namibia',
  cca2: 'NA',
  ccn3: '516',
  cca3: 'NAM',
  cioc: 'NAM',
  alternativeNames: ['Republic of Namibia', 'Namibia', 'Republiek van Namibië', 'Namibië', 'Republik Namibia', 'Lefatshe la Namibia', 'Namibijská republika', 'Namibie', 'Namiibia Vabariik', 'Namiibia', 'Namibian tasavalta', 'République de Namibie', 'Republika Namibija', 'Namibija', 'Namíbiai Köztársaság', 'Namíbia', 'Repubblica di Namibia', 'ナミビア共和国', 'ナミビア', '나미비아 공화국', '나미비아', 'Republiek Namibië', 'جمهوری نامیبیا', 'نامیبیا', 'Republika Namibii', 'República da Namíbia', 'Республика Намибия', 'Намибия', 'Namíbijská republika', 'República de Namibia', 'Republiken Namibia', 'جمہوریہ نمیبیا', 'نمیبیا', '纳米比亚共和国', '纳米比亚']
}, {
  common: 'New Caledonia',
  official: 'New Caledonia',
  cca2: 'NC',
  ccn3: '540',
  cca3: 'NCL',
  cioc: '',
  alternativeNames: ['New Caledonia', 'Nouvelle-Calédonie', 'Nová Kaledonie', 'Neukaledonien', 'Uus-Kaledoonia', 'Uusi-Kaledonia', 'Nova Kaledonija', 'Új-Kaledónia', 'Nuova Caledonia', 'ニューカレドニア', '누벨칼레도니', 'nieuw -Caledonië', 'Nieuw-Caledonië', 'کالدونیای جدید', 'Nowa Kaledonia', 'Nova Caledónia', 'Новая Каледония', 'Nová Kaledónia', 'nueva Caledonia', 'Nueva Caledonia', 'Nya Kaledonien', 'نیو کیلیڈونیا', '新喀里多尼亚']
}, {
  common: 'Niger',
  official: 'Republic of Niger',
  cca2: 'NE',
  ccn3: '562',
  cca3: 'NER',
  cioc: 'NIG',
  alternativeNames: ['Republic of Niger', 'Niger', 'République du Niger', 'Nigerská republika', 'Republik Niger', 'Nigeri Vabariik', 'Nigerin tasavalta', 'Republika Niger', 'Nigeri Köztársaság', 'Repubblica del Niger', 'ニジェール共和国', 'ニジェール', '니제르 공화국', '니제르', 'Republiek Niger', 'جمهوری نیجر', 'نیجر', 'Republika Nigru', 'República do Níger', 'Níger', 'Республика Нигер', 'Нигер', 'Nigérská republika', 'República de Níger', 'Republiken Niger', 'جمہوریہ نائجر', 'نائجر', '尼日尔共和国', '尼日尔', 'Nijar']
}, {
  common: 'Norfolk Island',
  official: 'Territory of Norfolk Island',
  cca2: 'NF',
  ccn3: '574',
  cca3: 'NFK',
  cioc: '',
  alternativeNames: ['Territory of Norfolk Island', 'Norfolk Island', "Teratri of Norf'k Ailen", "Norf'k Ailen", 'Teritorium ostrova Norfolk', 'Norfolk', 'Gebiet der Norfolkinsel', 'Norfolkinsel', 'Norfolki saare ala', 'Norfolkinsaaren territorio', 'Norfolkinsaari', "Territoire de l'île Norfolk", 'Île Norfolk', 'Teritorij Norfolk Island', 'Otok Norfolk', 'Norfolk-sziget', 'Territorio di Norfolk Island', 'Isola Norfolk', 'ノーフォーク島の領土', 'ノーフォーク島', '노퍽 섬', 'Grondgebied van Norfolk Island', 'Norfolkeiland', 'قلمرو جزایر نورفک', 'جزیره نورفک', 'Terytorium Wyspy Norfolk', 'Wyspa Norfolk', 'Território da Ilha Norfolk', 'Ilha Norfolk', 'Территория острова Норфолк', 'Норфолк', 'Teritórium ostrova Norfolk', 'Territorio de la Isla Norfolk', 'Isla de Norfolk', 'Norfolkön', 'جزیرہ نورفک خطہ', 'جزیرہ نورفک', '诺福克岛']
}, {
  common: 'Nigeria',
  official: 'Federal Republic of Nigeria',
  cca2: 'NG',
  ccn3: '566',
  cca3: 'NGA',
  cioc: 'NGR',
  alternativeNames: ['Federal Republic of Nigeria', 'Nigeria', 'Nigerijská federativní republika', 'Nigérie', 'Bundesrepublik Nigeria', 'Nigeeria Liitvabariik', 'Nigeeria', 'Nigerian liittotasavalta', 'République fédérale du Nigeria', 'Nigéria', 'Savezna Republika Nigerija', 'Nigerija', 'Repubblica federale di Nigeria', 'ナイジェリア連邦共和国', 'ナイジェリア', '나이지리아 연방 공화국', '나이지리아', 'Federale Republiek Nigeria', 'جمهوری فدرال نیجریه', 'نیجریه', 'Federalna Republika Nigerii', 'República Federal da Nigéria', 'Федеративная Республика Нигерия', 'Нигерия', 'Nigérijská federatívna republika', 'República Federal de Nigeria', 'Förbundsrepubliken Nigeria', 'وفاقی جمہوریہ نائجیریا', 'نائجیریا', '尼日利亚联邦共和国', '尼日利亚', 'Nijeriya', 'Naíjíríà']
}, {
  common: 'Nicaragua',
  official: 'Republic of Nicaragua',
  cca2: 'NI',
  ccn3: '558',
  cca3: 'NIC',
  cioc: 'NCA',
  alternativeNames: ['Republic of Nicaragua', 'Nicaragua', 'República de Nicaragua', 'Republika Nikaragua', 'Nikaragua', 'Republik Nicaragua', 'Nicaragua Vabariik', 'Nicaraguan tasavalta', 'République du Nicaragua', 'Republika Nikaragva', 'Nikaragva', 'Nicaraguai Köztársaság', 'Repubblica del Nicaragua', 'ニカラグア共和国', 'ニカラグア', '니카라과 공화국', '니카라과', 'Republiek Nicaragua', 'جمهوری نیکاراگوئه', 'نیکاراگوئه', 'Republika Nikaragui', 'República da Nicarágua', 'Nicarágua', 'Республика Никарагуа', 'Никарагуа', 'Nikaragujská republika', 'Republiken Nicaragua', 'جمہوریہ نکاراگوا', 'نکاراگوا', '尼加拉瓜共和国', '尼加拉瓜']
}, {
  common: 'Niue',
  official: 'Niue',
  cca2: 'NU',
  ccn3: '570',
  cca3: 'NIU',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Niue', 'Niuē', 'ニウエ', '니우에', 'نیووی', 'Ниуэ', 'نیووے', '纽埃']
}, {
  common: 'Netherlands',
  official: 'Kingdom of the Netherlands',
  cca2: 'NL',
  ccn3: '528',
  cca3: 'NLD',
  cioc: 'NED',
  alternativeNames: ['Kingdom of the Netherlands', 'Netherlands', 'Koninkrijk der Nederlanden', 'Nederland', 'Nizozemské království', 'Nizozemsko', 'Niederlande', 'Madalmaade Kuningriik', 'Holland', 'Alankomaat', 'Pays-Bas', 'Holandija', 'Nizozemska', 'Holland Királyság', 'Hollandia', 'Paesi Bassi', 'オランダ', '네덜란드 왕국', '네덜란드', 'هلند', 'Królestwo Niderlandów', 'Holandia', 'Holanda', 'Нидерланды', 'Holandské kráľovstvo', 'Holansko', 'Países Bajos', 'Nederländerna', 'مملکتِ نیدرلینڈز', 'نیدرلینڈز', '荷兰', 'The Netherlands']
}, {
  common: 'Norway',
  official: 'Kingdom of Norway',
  cca2: 'NO',
  ccn3: '578',
  cca3: 'NOR',
  cioc: 'NOR',
  alternativeNames: ['Kingdom of Norway', 'Norway', 'Kongeriket Noreg', 'Noreg', 'Kongeriket Norge', 'Norge', 'Norgga gonagasriika', 'Norgga', 'Norské království', 'Norsko', 'Königreich Norwegen', 'Norwegen', 'Norra Kuningriik', 'Norra', 'Norjan kuningaskunta', 'Norja', 'Royaume de Norvège', 'Norvège', 'Kraljevina Norveška', 'Norveška', 'Norvég Királyság', 'Norvégia', 'Regno di Norvegia', 'Norvegia', 'ノルウェー王国', 'ノルウェー', '노르웨이 왕국', '노르웨이', 'Koninkrijk Noorwegen', 'Noorwegen', 'پادشاهی نروژ', 'نروژ', 'Królestwo Norwegii', 'Norwegia', 'Reino da Noruega', 'Noruega', 'Королевство Норвегия', 'Норвегия', 'Nórske kráľovstvo', 'Nórsko', 'Reino de Noruega', 'Konungariket Norge', 'مملکتِ ناروے', 'ناروے', '挪威王国', '挪威']
}, {
  common: 'Nepal',
  official: 'Federal Democratic Republic of Nepal',
  cca2: 'NP',
  ccn3: '524',
  cca3: 'NPL',
  cioc: 'NEP',
  alternativeNames: ['Federal Democratic Republic of Nepal', 'Nepal', 'नेपाल संघीय लोकतान्त्रिक गणतन्त्र', 'नेपाल', 'Federativní demokratická republika Nepál', 'Nepál', 'Demokratische Bundesrepublik Nepal', 'Nepali Demokraatlik Liitvabariik', 'Nepalin demokraattinen liittotasavalta', 'République du Népal', 'Népal', 'Savezna Demokratska Republika Nepal', 'Nepáli Szövetségi Demokratikus Köztársaság', 'Repubblica federale democratica del Nepal', 'ネパール連邦民主共和国', 'ネパール', '네팔 연방 민주 공화국', '네팔', 'Federale Democratische Republiek Nepal', 'جمهوری فدرال دموکراتیک نپال', 'نپال', 'Federalna Demokratyczna Republika Nepalu', 'República Democrática Federal do Nepal', 'Федеративная Демократическая Республика Непал', 'Непал', 'Nepálska federatívna demokratická republika', 'República Democrática Federal de Nepal', 'Demokratiska förbundsrepubliken Nepal', 'وفاقی جمہوری جمہوریہ نیپال', 'نیپال', '尼泊尔联邦民主共和国', '尼泊尔', 'Loktāntrik Ganatantra Nepāl']
}, {
  common: 'Nauru',
  official: 'Republic of Nauru',
  cca2: 'NR',
  ccn3: '520',
  cca3: 'NRU',
  cioc: 'NRU',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of Nauru', 'Nauru', 'Republika Nauru', 'Republik Nauru', 'Nauru Vabariik', 'Naurun tasavalta', 'République de Nauru', 'Naurui Köztársaság', 'Repubblica di Nauru', 'ナウル共和国', 'ナウル', '나우루 공화국', '나우루', 'Republiek Nauru', 'جمهوری نائورو', 'نائورو', 'República de Nauru', 'Республика Науру', 'Науру', 'Naurská republika', 'Republiken Nauru', 'جمہوریہ ناورو', 'ناورو', '瑙鲁共和国', '瑙鲁', 'Naoero', 'Pleasant Island', 'Ripublik Naoero']
}, {
  common: 'New Zealand',
  official: 'New Zealand',
  cca2: 'NZ',
  ccn3: '554',
  cca3: 'NZL',
  cioc: 'NZL',
  alternativeNames: ['New Zealand', 'Aotearoa', 'Nový Zéland', 'Neuseeland', 'Uus-Meremaa', 'Uusi-Seelanti', 'Nouvelle-Zélande', 'Novi Zeland', 'Új-Zéland', 'Nuova Zelanda', 'ニュージーランド', '뉴질랜드', 'Nieuw Zeeland', 'Nieuw-Zeeland', 'نیوزیلند', 'Nowa Zelandia', 'nova Zelândia', 'Nova Zelândia', 'Новая Зеландия', 'nueva Zelanda', 'Nueva Zelanda', 'Nya Zeeland', 'نیوزی لینڈ', '新西兰']
}, {
  common: 'Oman',
  official: 'Sultanate of Oman',
  cca2: 'OM',
  ccn3: '512',
  cca3: 'OMN',
  cioc: 'OMA',
  alternativeNames: ['Sultanate of Oman', 'Oman', 'سلطنة عمان', 'عمان', 'Sultanát Omán', 'Omán', 'Sultanat Oman', 'Omaani Sultaniriik', 'Omaan', 'Omanin sulttaanikunta', "Sultanat d'Oman", 'Ománi Szultanátus', "Sultanato dell'Oman", 'oman', 'オマーン·スルタン国', 'オマーン', '오만 술탄국', '오만', 'Sultanaat van Oman', 'سلطان‌نشین عُمان', 'Sułtanat Omanu', 'Sultanato de Omã', 'Omã', 'Султанат Оман', 'Оман', 'Ománsky sultanát', 'Sultanato de Omán', 'Sultanatet Oman', 'سلطنت عمان', '阿曼苏丹国', '阿曼', 'Salṭanat ʻUmān']
}, {
  common: 'Pakistan',
  official: 'Islamic Republic of Pakistan',
  cca2: 'PK',
  ccn3: '586',
  cca3: 'PAK',
  cioc: 'PAK',
  alternativeNames: ['Islamic Republic of Pakistan', 'Pakistan', 'اسلامی جمہوریۂ پاكستان', 'پاكستان', 'Pákistánská islámská republika', 'Pákistán', 'Islamische Republik Pakistan', 'Pakistani Islamivabariik', 'Pakistanin islamilainen tasavalta', 'République islamique du Pakistan', 'Islamska Republika Pakistan', 'Pakisztán', 'Repubblica islamica del Pakistan', 'パキスタン', '파키스탄 이슬람 공화국', '파키스탄', 'Islamitische Republiek Pakistan', 'جمهوری اسلامی پاکستان', 'پاکستان', 'Islamska Republika Pakistanu', 'República Islâmica do Paquistão', 'Paquistão', 'Исламская Республика Пакистан', 'Пакистан', 'Pakistanská islamská republika', 'República Islámica de Pakistán', 'Pakistán', 'Islamiska republiken Pakistan', 'اسلامی جمہوریہ پاکستان', '巴基斯坦伊斯兰共和国', '巴基斯坦', 'Pākistān', "Islāmī Jumhūriya'eh Pākistān"]
}, {
  common: 'Panama',
  official: 'Republic of Panama',
  cca2: 'PA',
  ccn3: '591',
  cca3: 'PAN',
  cioc: 'PAN',
  alternativeNames: ['Republic of Panama', 'Panama', 'República de Panamá', 'Panamá', 'Panamská republika', 'Republik Panama', 'Panama Vabariik', 'Panaman tasavalta', 'République du Panama', 'Republika Panama', 'Panamai Köztársaság', 'Repubblica di Panama', 'パナマ共和国', 'パナマ', '파나마 공화국', '파나마', 'Republiek Panama', 'جمهوری پاناما', 'پاناما', 'Republika Panamy', 'República do Panamá', 'Республика Панама', 'Панама', 'Republiken Panama', 'جمہوریہ پاناما', '巴拿马共和国', '巴拿马']
}, {
  common: 'Pitcairn Islands',
  official: 'Pitcairn Group of Islands',
  cca2: 'PN',
  ccn3: '612',
  cca3: 'PCN',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Pitcairn Group of Islands', 'Pitcairn Islands', 'Pitcairnovy ostrovy', 'Pitcairninseln', 'Pitcairni, Hendersoni, Ducie ja Oeno saar', 'Pitcairn', "Groupe d'îles Pitcairn", 'Îles Pitcairn', 'Pitcairn skupine otoka', 'Pitcairnovo otočje', 'Pitcairn-szigetek', 'Pitcairn gruppo di isole', 'Isole Pitcairn', '島のピトケアングループ', 'ピトケアン', '핏케언 제도', 'Pitcairn groep eilanden', 'Pitcairneilanden', 'جزایر پیت‌کرن', 'Wyspy Pitcairn, Henderson, Ducie i Oeno', 'Pitcairn grupo de ilhas', 'Ilhas Pitcairn', 'Питкэрн группа островов', 'Острова Питкэрн', 'Pitcairnove ostrovy', 'Grupo de Islas Pitcairn', 'Islas Pitcairn', 'Pitcairnöarna', 'پٹکیرن جزائر', 'جزائر پٹکیرن', '皮特凯恩群岛', 'Pitcairn Henderson Ducie and Oeno Islands']
}, {
  common: 'Peru',
  official: 'Republic of Peru',
  cca2: 'PE',
  ccn3: '604',
  cca3: 'PER',
  cioc: 'PER',
  alternativeNames: ['Republic of Peru', 'Peru', 'Piruw Suyu', 'Piruw', 'Piruw Ripuwlika', 'República del Perú', 'Perú', 'Peruánská republika', 'Republik Peru', 'Peruu Vabariik', 'Peruu', 'Perun tasavalta', 'République du Pérou', 'Pérou', 'Republika Peru', 'Perui Köztársaság', 'Repubblica del Perù', 'Perù', 'ペルー共和国', 'ペルー', '페루 공화국', '페루', 'Republiek Peru', 'جمهوری پرو', 'پرو', 'República do Peru', 'Республика Перу', 'Перу', 'Peruánska republika', 'República de Perú', 'Republiken Peru', 'جمہوریہ پیرو', 'پیرو', '秘鲁共和国', '秘鲁']
}, {
  common: 'Philippines',
  official: 'Republic of the Philippines',
  cca2: 'PH',
  ccn3: '608',
  cca3: 'PHL',
  cioc: 'PHI',
  alternativeNames: ['Republic of the Philippines', 'Philippines', 'Pilipinas', 'Filipínská republika', 'Filipíny', 'Republik der Philippinen', 'Philippinen', 'Filipiini Vabariik', 'Filipiinid', 'Filippiinien tasavalta', 'Filippiinit', 'République des Philippines', 'Republika Filipini', 'Filipini', 'Fülöp-szigeteki Köztársaság', 'Fülöp-szigetek', 'Repubblica delle Filippine', 'Filippine', 'フィリピン共和国', 'フィリピン', '필리핀 공화국', '필리핀', 'Republiek der Filipijnen', 'Filipijnen', 'جمهوری فیلیپین', 'فیلیپین', 'Republika Filipin', 'Filipiny', 'República das Filipinas', 'Filipinas', 'Республика Филиппины', 'Филиппины', 'Filipínska republika', 'República de las Filipinas', 'Republiken Filippinerna', 'Filippinerna', 'جمہوریہ فلپائن', 'فلپائن', '菲律宾共和国', '菲律宾', 'Repúblika ng Pilipinas']
}, {
  common: 'Palau',
  official: 'Republic of Palau',
  cca2: 'PW',
  ccn3: '585',
  cca3: 'PLW',
  cioc: 'PLW',
  alternativeNames: ['Republic of Palau', 'Palau', 'Beluu er a Belau', 'Belau', 'Republika Palau', 'Republik Palau', 'Belau Vabariik', 'Palaun tasavalta', 'République des Palaos (Palau)', 'Palaos (Palau)', 'Palaui Köztársaság', 'Repubblica di Palau', 'パラオ共和国', 'パラオ', '팔라우 공화국', '팔라우', 'Republiek van Palau', 'جمهوری پالائو', 'پالائو', 'República de Palau', 'Республика Палау', 'Палау', 'Palauská republika', 'Republiken Palau', 'جمہوریہ پلاؤ', 'پلاؤ', '帕劳共和国', '帕劳']
}, {
  common: 'Papua New Guinea',
  official: 'Independent State of Papua New Guinea',
  cca2: 'PG',
  ccn3: '598',
  cca3: 'PNG',
  cioc: 'PNG',
  isShipmentUnavailable: true,
  alternativeNames: ['Independent State of Papua New Guinea', 'Papua New Guinea', 'Independen Stet bilong Papua Niugini', 'Papua Niu Gini', 'Papua Niugini', 'Nezávislý stát Papua Nová Guinea', 'Papua-Nová Guinea', 'Unabhängiger Staat Papua-Neuguinea', 'Papua-Neuguinea', 'Paapua Uus-Guinea Iseseisvusriik', 'Paapua Uus-Guinea', 'Papua-Uuden-Guinean Itsenäinen valtio', 'Papua-Uusi-Guinea', 'État indépendant de Papouasie-Nouvelle-Guinée', 'Papouasie-Nouvelle-Guinée', 'Nezavisna Država Papui Novoj Gvineji', 'Papua Nova Gvineja', 'Pápua Új-Guinea Független Állam', 'Pápua Új-Guinea', 'Stato indipendente di Papua Nuova Guinea', 'Papua Nuova Guinea', 'パプアニューギニア独立国', 'パプアニューギニア', '파푸아뉴기니 독립국', '파푸아뉴기니', 'Onafhankelijke Staat Papoea -Nieuw-Guinea', 'Papoea-Nieuw-Guinea', 'مملکت مستقل پاپوآ گینهٔ نو', 'پاپوآ گینه نو', 'Niezależne Państwo Papui-Nowej Gwinei', 'Papua-Nowa Gwinea', 'Estado Independente da Papua Nova Guiné', 'Papua Nova Guiné', 'Независимое Государство Папуа-Новой Гвинеи', 'Папуа — Новая Гвинея', 'Nezávislý štát Papua-Nová Guinea', 'Estado Independiente de Papúa Nueva Guinea', 'Papúa Nueva Guinea', 'Den oberoende staten Papua Nya Guinea', 'Papua Nya Guinea', 'آزاد ریاستِ پاپوا نیو گنی', 'پاپوا نیو گنی', '巴布亚新几内亚']
}, {
  common: 'Poland',
  official: 'Republic of Poland',
  cca2: 'PL',
  ccn3: '616',
  cca3: 'POL',
  cioc: 'POL',
  alternativeNames: ['Republic of Poland', 'Poland', 'Rzeczpospolita Polska', 'Polska', 'Polská republika', 'Polsko', 'Republik Polen', 'Polen', 'Poola Vabariik', 'Poola', 'Puolan tasavalta', 'Puola', 'République de Pologne', 'Pologne', 'Republika Poljska', 'Poljska', 'Lengyel Köztársaság', 'Lengyelország', 'Repubblica di Polonia', 'Polonia', 'ポーランド共和国', 'ポーランド', '폴란드 공화국', '폴란드', 'Republiek Polen', 'جمهوری لهستان', 'لهستان', 'República da Polónia', 'Polónia', 'Республика Польша', 'Польша', 'Poľská republika', 'Poľsko', 'República de Polonia', 'Republiken Polen', 'جمہوریہ پولینڈ', 'پولینڈ', '波兰共和国', '波兰']
}, {
  common: 'Puerto Rico',
  official: 'Commonwealth of Puerto Rico',
  cca2: 'PR',
  ccn3: '630',
  cca3: 'PRI',
  cioc: 'PUR',
  alternativeNames: ['Commonwealth of Puerto Rico', 'Puerto Rico', 'Estado Libre Asociado de Puerto Rico', 'Portoriko', 'Freistaat Puerto Rico', 'Puerto Rico Ühendus', 'Porto Rico', 'Zajednica Puerto Rico', 'Commonwealth di Porto Rico', 'プエルトリコのコモンウェルス', 'プエルトリコ', '푸에르토리코', 'Gemenebest van Puerto Rico', 'قلمرو همسود پورتوریکو', 'پورتوریکو', 'Wolne Stowarzyszone Państwo Portoryko', 'Portoryko', 'Содружество Пуэрто-Рико', 'Пуэрто-Рико', 'Portorické spoločenstvo', 'Asociado de Puerto Rico', ' دولتِ مشترکہ پورٹو ریکو', 'پورٹو ریکو', '波多黎各联邦', '波多黎各']
}, {
  common: 'North Korea',
  official: "Democratic People's Republic of Korea",
  cca2: 'KP',
  ccn3: '408',
  cca3: 'PRK',
  cioc: 'PRK',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ["Democratic People's Republic of Korea", 'North Korea', '조선민주주의인민공화국', '조선', 'Korejská lidově demokratická republika', 'Severní Korea', 'Demokratische Volksrepublik Korea', 'Nordkorea', 'Korea Rahvademokraatlik Vabariik', 'Põhja-Korea', 'Korean demokraattinen kansantasavalta', 'Pohjois-Korea', 'République populaire démocratique de Corée', 'Corée du Nord', 'Demokratska Narodna Republika Koreja', 'Sjeverna Koreja', 'Koreai Népi Demokratikus Köztársaság', 'Észak-Korea', 'Repubblica democratica popolare di Corea', 'Corea del Nord', '朝鮮民主主義人民共和国', 'Democratische Volksrepubliek Korea', 'Noord-Korea', 'جمهوری دموکراتیک خلق کره', 'کُره شمالی', 'Koreańska Republika Ludowo-Demokratyczna', 'Korea Północna', 'República Popular Democrática da Coreia', 'Coreia do Norte', 'Корейская Народно-Демократическая Республика Корея', 'Северная Корея', 'Kórejská ľudovodemokratická republika', 'Kórejská ľudovodemokratická republika (KĽR, Severná Kórea)', 'República Popular Democrática de Corea', 'Corea del Norte', 'Demokratiska Folkrepubliken Korea', 'جمہوری عوامی جمہوریہ کوریا', 'شمالی کوریا', '朝鲜人民民主共和国', '朝鲜', 'DPRK', 'Chosŏn Minjujuŭi Inmin Konghwaguk', "Korea, Democratic People's Republic of", '북한', '북조선']
}, {
  common: 'Portugal',
  official: 'Portuguese Republic',
  cca2: 'PT',
  ccn3: '620',
  cca3: 'PRT',
  cioc: 'POR',
  alternativeNames: ['Portuguese Republic', 'Portugal', 'República português', 'Portugalská republika', 'Portugalsko', 'Portugiesische Republik', 'Portugali Vabariik', 'Portugalin tasavalta', 'Portugali', 'République portugaise', 'Portugalska Republika', 'Portugál Köztársaság', 'Portugália', 'Repubblica portoghese', 'Portogallo', 'ポルトガル共和国', 'ポルトガル', '포르투갈 공화국', '포르투갈', 'Portugese Republiek', 'جمهوری پرتغال', 'پرتغال', 'Republika Portugalska', 'Portugalia', 'Португальская Республика', 'Португалия', 'República Portuguesa', 'Republiken Portugal', 'جمہوریہ پرتگال', 'پرتگال', '葡萄牙共和国', '葡萄牙', 'Portuguesa']
}, {
  common: 'Paraguay',
  official: 'Republic of Paraguay',
  cca2: 'PY',
  ccn3: '600',
  cca3: 'PRY',
  cioc: 'PAR',
  alternativeNames: ['Republic of Paraguay', 'Paraguay', 'Tetã Paraguái', 'Paraguái', 'República de Paraguay', 'Paraguayská republika', 'Republik Paraguay', 'Paraguay Vabariik', 'Paraguayn tasavalta', 'République du Paraguay', 'Republika Paragvaj', 'Paragvaj', 'Paraguayi Köztársaság', 'Repubblica del Paraguay', 'パラグアイ共和国', 'パラグアイ', '파라과이 공화국', '파라과이', 'Republiek Paraguay', 'جمهوری پاراگوئه', 'پاراگوئه', 'Republika Paragwaju', 'Paragwaj', 'República do Paraguai', 'Paraguai', 'Республика Парагвай', 'Парагвай', 'Paraguajská republika', 'Paraguaj', 'Republiken Paraguay', 'جمہوریہ پیراگوئے', 'پیراگوئے', '巴拉圭共和国', '巴拉圭', 'República del Paraguay']
}, {
  common: 'Palestine',
  official: 'State of Palestine',
  cca2: 'PS',
  ccn3: '275',
  cca3: 'PSE',
  cioc: 'PLE',
  hasUpsError: true,
  alternativeNames: ['State of Palestine', 'Palestine', 'دولة فلسطين', 'فلسطين', 'Stát Palestina', 'Palestina', 'Staat Palästina', 'Palästina', 'Palestiina Riik', 'Palestiina', 'Palestiinan valtio', 'État de Palestine', 'Palesztin Autonómia', 'Palesztina', 'Stato di Palestina', 'パレスチナ自治政府', 'パレスチナ', '팔레스타인국', '팔레스타인', 'Staat Palestina', 'Palestijnse gebieden', 'دولت فلسطین', 'فلسطین', 'Państwo Palestyna', 'Palestyna', 'Estado da Palestina', 'Государство Палестина', 'Палестина', 'Palestínsky štát', 'Palestína', 'Estado de Palestina', 'ریاستِ فلسطین', '巴勒斯坦国', '巴勒斯坦', 'Palestine, State of', 'Dawlat Filasṭin']
}, {
  common: 'French Polynesia',
  official: 'French Polynesia',
  cca2: 'PF',
  ccn3: '258',
  cca3: 'PYF',
  cioc: '',
  alternativeNames: ['French Polynesia', 'Polynésie française', 'Francouzská Polynésie', 'Französisch-Polynesien', 'Prantsuse Polüneesia', 'Ranskan Polynesia', 'Francuska Polinezija', 'Francia Polinézia', 'Polinesia Francese', 'フランス領ポリネシア', '프랑스령 폴리네시아', 'Frans-Polynesië', 'پُلی‌نِزی فرانسه', 'Polinezja Francuska', 'Polinésia Francesa', 'Французская Полинезия', 'Francúzska Polynézia', 'Polinesia francés', 'Polinesia Francesa', 'Franska Polynesien', 'فرانسیسی پولینیشیا', '法属波利尼西亚', 'Pōrīnetia Farāni']
}, {
  common: 'Qatar',
  official: 'State of Qatar',
  cca2: 'QA',
  ccn3: '634',
  cca3: 'QAT',
  cioc: 'QAT',
  alternativeNames: ['State of Qatar', 'Qatar', 'دولة قطر', 'قطر', 'Stát Katar', 'Katar', 'Staat Katar', 'Katari Riik', 'Qatarin valtio', 'État du Qatar', 'Država Katar', 'Katari Állam', 'Stato del Qatar', 'カタール国', 'カタール', '카타르국', '카타르', 'Staat Qatar', 'دولت قطر', 'Państwo Katar', 'Estado do Qatar', 'Catar', 'Государство Катар', 'Катар', 'Katarský štát', 'Estado de Qatar', 'Staten Qatar', 'ریاستِ قطر', '卡塔尔国', '卡塔尔', 'Dawlat Qaṭar']
}, {
  common: 'Reunion',
  official: 'Réunion Island',
  cca2: 'RE',
  ccn3: '638',
  cca3: 'REU',
  cioc: '',
  alternativeNames: ['Réunion Island', 'Réunion', 'Ile de la Réunion', 'La Réunion', 'Réunioni departemang', 'Riunione', 'レユニオン島', 'レユニオン', '레위니옹', 'رئونیون', 'Reunion', 'Ilha da Reunião', 'Reunião', 'Реюньон', 'Réunionský zámorský departmán', 'Isla de la Reunión', 'Reunión', 'رے یونیوں جزیرہ', 'رے یونیوں', '留尼旺岛']
}, {
  common: 'Romania',
  official: 'Romania',
  cca2: 'RO',
  ccn3: '642',
  cca3: 'ROU',
  cioc: 'ROU',
  alternativeNames: ['Romania', 'România', 'Rumunsko', 'Rumänien', 'Rumeenia', 'Roumanie', 'Rumunija', 'Rumunjska', 'Románia', 'ルーマニア', '루마니아', 'Roemenië', 'رومانی', 'Rumunia', 'Romênia', 'Roménia', 'Румыния', 'Rumania', 'رومانیہ', '罗马尼亚', 'Roumania']
}, {
  common: 'Russia',
  official: 'Russian Federation',
  cca2: 'RU',
  ccn3: '643',
  cca3: 'RUS',
  cioc: 'ROU',
  isShipmentUnavailable: true,
  alternativeNames: ['Россия', 'Ruská federace', 'Russische Föderation', 'Venemaa Föderatsioon', 'Venäjän federaatio', 'Fédération de Russie', 'Ruska Federacija', 'Oroszországi Föderáció', 'Federazione russa', 'ロシア連邦', '러시아 연방', 'Russische Federatie', 'فدراسیون روسیه', 'Federacja Rosyjska', 'Federação Russa', 'Российская Федерация', 'Ruská federácia', 'Federación de Rusia', 'Ryska federationen', 'روسی وفاق', '俄罗斯联邦', 'Rusko', 'Russland', 'Venemaa', 'Venäjä', 'Russie', 'Rusija', 'Oroszország', 'Russia', 'ロシア連邦', '러시아', 'Rusland', 'روسیه', 'Rosja', 'Rússia', 'Россия', 'Rusko', 'Rusia', 'Ryssland', 'روس', '俄罗斯']
}, {
  common: 'Rwanda',
  official: 'Republic of Rwanda',
  cca2: 'RW',
  ccn3: '646',
  cca3: 'RWA',
  cioc: 'RWA',
  alternativeNames: ['Republic of Rwanda', 'Rwanda', 'République rwandaise', "Repubulika y'u Rwanda", 'Rwandská republika', 'Republik Ruanda', 'Ruanda', 'Rwanda Vabariik', 'Ruandan tasavalta', 'Republika Ruandi', 'Ruandai Köztársaság', 'Repubblica del Ruanda', 'ルワンダ共和国', 'ルワンダ', '르완다 공화국', '르완다', 'Republiek Rwanda', 'جمهوری رواندا', 'رواندا', 'Republika Rwandy', 'República do Ruanda', 'Республика Руанда', 'Руанда', 'República de Rwanda', 'Republiken Rwanda', 'جمہوریہ روانڈا', 'روانڈا', '卢旺达共和国', '卢旺达', 'République du Rwanda']
}, {
  common: 'Saudi Arabia',
  official: 'Kingdom of Saudi Arabia',
  cca2: 'SA',
  ccn3: '682',
  cca3: 'SAU',
  cioc: 'KSA',
  alternativeNames: ['Kingdom of Saudi Arabia', 'Saudi Arabia', 'المملكة العربية السعودية', 'العربية السعودية', 'Saúdskoarabské království', 'Saúdská Arábie', 'Königreich Saudi-Arabien', 'Saudi-Arabien', 'Saudi Araabia Kuningriik', 'Saudi Araabia', 'Saudi-Arabian kuningaskunta', 'Saudi-Arabia', "Royaume d'Arabie Saoudite", 'Arabie Saoudite', 'Kraljevina Saudijska Arabija', 'Saudijska Arabija', 'Szaúd-Arábia', 'Arabia Saudita', 'サウジアラビア王国', 'サウジアラビア', '사우디아라비아 왕국', '사우디아라비아', 'Koninkrijk van Saoedi-Arabië', 'Saoedi-Arabië', 'پادشاهی عربی سَعودی', 'عربستان سعودی', 'Królestwo Arabii Saudyjskiej', 'Arabia Saudyjska', 'Reino da Arábia Saudita', 'Arábia Saudita', 'Королевство Саудовская Аравия', 'Саудовская Аравия', 'Saudskoarabské kráľovstvo', 'Saudská Arábia', 'Reino de Arabia Saudita', 'Arabia Saudí', 'Kungadömet Saudiarabien', 'Saudiarabien', 'مملکتِ سعودی عرب', 'سعودی عرب', '沙特阿拉伯王国', '沙特阿拉伯', 'Saudi', 'Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah']
}, {
  common: 'Sudan',
  official: 'Republic of the Sudan',
  cca2: 'SD',
  ccn3: '729',
  cca3: 'SDN',
  cioc: 'SUD',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of the Sudan', 'Sudan', 'جمهورية السودان', 'السودان', 'Súdánská republika', 'Súdán', 'Republik Sudan', 'Sudaani Vabariik', 'Sudaan', 'Sudanin tasavalta', 'République du Soudan', 'Soudan', 'Republika Sudan', 'Szudáni Köztársaság', 'Szudán', 'Repubblica del Sudan', 'スーダン共和国', 'スーダン', '수단 공화국', '수단', 'Republiek Soedan', 'Soedan', 'جمهوری سودان', 'سودان', 'Republika Sudanu', 'República do Sudão', 'Sudão', 'Республика Судан', 'Судан', 'Sudánska republika', 'Sudán', 'República de Sudán', 'Republiken Sudan', 'جمہوریہ سودان', '苏丹共和国', '苏丹', 'Jumhūrīyat as-Sūdān']
}, {
  common: 'Senegal',
  official: 'Republic of Senegal',
  cca2: 'SN',
  ccn3: '686',
  cca3: 'SEN',
  cioc: 'SEN',
  alternativeNames: ['Republic of Senegal', 'Senegal', 'République du Sénégal', 'Sénégal', 'Senegalská republika', 'Republik Senegal', 'Senegali Vabariik', 'Senegalin tasavalta', 'Republika Senegal', 'Szenegáli Köztársaság', 'Szenegál', 'Repubblica del Senegal', 'セネガル共和国', 'セネガル', '세네갈 공화국', '세네갈', 'Republiek Senegal', 'جمهوری سنگال', 'سنگال', 'República do Senegal', 'Республика Сенегал', 'Сенегал', 'República de Senegal', 'Republiken Senegal', 'جمہوریہ سینیگال', 'سینیگال', '塞内加尔共和国', '塞内加尔']
}, {
  common: 'Singapore',
  official: 'Republic of Singapore',
  cca2: 'SG',
  ccn3: '702',
  cca3: 'SGP',
  cioc: 'SIN',
  alternativeNames: ['Republic of Singapore', 'Singapore', '新加坡共和国', '新加坡', 'Republik Singapura', 'Singapura', 'சிங்கப்பூர் குடியரசு', 'சிங்கப்பூர்', 'Singapurská republika', 'Singapur', 'Republik Singapur', 'Singapuri Vabariik', 'Singaporen tasavalta', 'République de Singapour', 'Singapour', 'Republika Singapur', 'Szingapúri Köztársaság', 'Szingapúr', 'Repubblica di Singapore', 'シンガポール共和国', 'シンガポール', '싱가포르 공화국', '싱가포르', 'Republiek Singapore', 'جمهوری سنگاپور', 'سنگاپور', 'Republika Singapuru', 'República de Singapura', 'Республика Сингапур', 'Сингапур', 'República de Singapur', 'Republiken Singapore', 'جمہوریہ سنگاپور']
}, {
  common: 'South Georgia',
  official: 'South Georgia and the South Sandwich Islands',
  cca2: 'GS',
  ccn3: '239',
  cca3: 'SGS',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['South Georgia and the South Sandwich Islands', 'South Georgia', 'Jižní Georgie a Jižní Sandwichovy ostrovy', 'Südgeorgien und die Südlichen Sandwichinseln', 'Lõuna-Georgia ja Lõuna-Sandwichi saared', 'Etelä-Georgia ja Eteläiset Sandwichsaaret', 'Géorgie du Sud et les îles Sandwich du Sud', 'Géorgie du Sud-et-les Îles Sandwich du Sud', 'Južna Džordžija i Otoci Južni Sendvič', 'Južna Georgija i otočje Južni Sandwich', 'Déli-Georgia és Déli-Sandwich-szigetek', 'Georgia del Sud e isole Sandwich del Sud', 'Georgia del Sud e Isole Sandwich Meridionali', 'サウスジョージア·サウスサンドウィッチ諸島', 'サウスジョージア・サウスサンドウィッチ諸島', '조지아', 'Zuid-Georgië en de Zuidelijke Sandwich-eilanden', 'Zuid-Georgia en Zuidelijke Sandwicheilanden', 'جزایر جورجیای جنوبی و ساندویچ جنوبی', 'Georgia Południowa i Sandwich Południowy', 'Geórgia do Sul e Sandwich do Sul', 'Ilhas Geórgia do Sul e Sandwich do Sul', 'Южная Георгия и Южные Сандвичевы острова', 'Južná Georgia a Južné Sandwichove ostrovy', 'Georgia del Sur y las Islas Sandwich del Sur', 'Islas Georgias del Sur y Sandwich del Sur', 'Sydgeorgien', 'جنوبی جارجیا و جزائر جنوبی سینڈوچ', 'جنوبی جارجیا', '南乔治亚岛和南桑威奇群岛', '南乔治亚']
}, {
  common: 'Svalbard and Jan Mayen',
  official: 'Svalbard og Jan Mayen',
  cca2: 'SJ',
  ccn3: '744',
  cca3: 'SJM',
  cioc: '',
  hasUpsError: true,
  alternativeNames: ['Svalbard og Jan Mayen', 'Svalbard and Jan Mayen', 'Špicberky a Jan Mayen', 'Spitzbergen und Jan Mayen', 'Svalbard', 'Huippuvuoret', 'Jan Mayen Svalbard', 'Svalbard et Jan Mayen', 'Svalbard i Jan Mayen', 'Svalbard és Jan Mayen', 'Svalbard e Jan Mayen', 'スバールバル諸島OGヤンマイエン', 'スヴァールバル諸島およびヤンマイエン島', '스발바르 얀마옌 제도', 'Svalbard en Jan Mayen', 'سوالبارد و یان ماین', 'Ilhas Svalbard e Jan Mayen', 'Свальбарда ог Ян-Майен', 'Шпицберген и Ян-Майен', 'Svalbard a Jan Mayen', 'Islas Svalbard y Jan Mayen', 'Svalbard och Jan Mayen', 'سوالبارڈ اور جان میئن', '斯瓦尔巴特', 'Svalbard and Jan Mayen Islands']
}, {
  common: 'Solomon Islands',
  official: 'Solomon Islands',
  cca2: 'SB',
  ccn3: '090',
  cca3: 'SLB',
  cioc: 'SOL',
  alternativeNames: ['Solomon Islands', 'Šalamounovy ostrovy', 'Salomonen', 'Saalomoni Saared', 'Salomonsaaret', 'Îles Salomon', 'Solomonski Otoci', 'Salamon-szigetek', 'Isole Salomone', 'ソロモン諸島', '솔로몬 제도', 'Solomon eilanden', 'Salomonseilanden', 'جزایر سلیمان', 'Wyspy Salomona', 'Ilhas Salomão', 'Соломоновы острова', 'Соломоновы Острова', 'Salomonove ostrovy', 'islas Salomón', 'Islas Salomón', 'Salomonöarna', 'جزائر سلیمان', '所罗门群岛']
}, {
  common: 'Sierra Leone',
  official: 'Republic of Sierra Leone',
  cca2: 'SL',
  ccn3: '694',
  cca3: 'SLE',
  cioc: 'SLE',
  alternativeNames: ['Republic of Sierra Leone', 'Sierra Leone', 'Republika Sierra Leone', 'Republik Sierra Leone', 'Sierra Leone Vabariik', 'Sierra Leonen tasavalta', 'République de Sierra Leone', 'Republika Sijera Leone', 'Sijera Leone', 'Sierra Leone Köztársaság', 'Repubblica della Sierra Leone', 'シエラレオネ共和国', 'シエラレオネ', '시에라리온 공화국', '시에라리온', 'Republiek Sierra Leone', 'جمهوری سیرالئون', 'سیرالئون', 'República da Serra Leoa', 'Serra Leoa', 'Республика Сьерра-Леоне', 'Сьерра-Леоне', 'Sierraleonská republika', 'República de Sierra Leona', 'Republiken Sierra Leone', 'جمہوریہ سیرالیون', 'سیرالیون', '塞拉利昂共和国', '塞拉利昂']
}, {
  common: 'El Salvador',
  official: 'Republic of El Salvador',
  cca2: 'SV',
  ccn3: '222',
  cca3: 'SLV',
  cioc: 'ESA',
  alternativeNames: ['Republic of El Salvador', 'El Salvador', 'República de El Salvador', 'Salvadorská republika', 'Salvador', 'Gweriniaeth El Salfador', 'El Salfador', 'Republik El Salvador', 'El Salvadori Vabariik', 'El Salvadorin tasavalta', 'République du Salvador', 'Republika El Salvador', 'Salvadori Köztársaság', 'Repubblica di El Salvador', 'エルサルバドル共和国', 'エルサルバドル', '엘살바도르 공화국', '엘살바도르', 'Republiek El Salvador', 'جمهوری السالوادور', 'السالوادور', 'Republika Salwadoru', 'Salwador', 'Республика Эль-Сальвадор', 'Сальвадор', 'Salvádorská republika', 'Salvádor', 'Republiken El Salvador', 'جمہوریہ ایل سیلواڈور', 'ایل سیلواڈور', '萨尔瓦多共和国', '萨尔瓦多']
}, {
  common: 'San Marino',
  official: 'Most Serene Republic of San Marino',
  cca2: 'SM',
  ccn3: '674',
  cca3: 'SMR',
  cioc: 'SMR',
  alternativeNames: ['Most Serene Republic of San Marino', 'San Marino', 'Serenissima Repubblica di San Marino', 'Republika San Marino', 'Republik San Marino', 'San Marino Vabariik', 'San Marinon seesteinen tasavalta', 'République de Saint-Marin', 'Saint-Marin', 'Većina Serene Republika San Marino', 'San Marino Köztársaság', 'サンマリノのほとんどセリーヌ共和国', 'サンマリノ', '산마리노 공화국', '산마리노', 'Meest Serene Republiek San Marino', 'جمهوری سان مارینو', 'سان مارینو', 'Sereníssima República de San Marino', 'Большинство Serene Республика Сан-Марино', 'Сан-Марино', 'Sanmarínska republika', 'San Maríno', 'Serenísima República de San Marino', 'Republiken San Marino', 'جمہوریہ سان مارینو', '圣马力诺共和国', '圣马力诺', 'Republic of San Marino', 'Repubblica di San Marino']
}, {
  common: 'Somalia',
  official: 'Federal Republic of Somalia',
  cca2: 'SO',
  ccn3: '706',
  cca3: 'SOM',
  cioc: 'SOM',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Federal Republic of Somalia', 'Somalia', 'جمهورية الصومال‎‎', 'الصومال‎‎', 'Jamhuuriyadda Federaalka Soomaaliya', 'Soomaaliya', 'Somálská demokratická republika', 'Somálsko', 'Bundesrepublik Somalia', 'Somaalia Liitvabariik', 'Somaalia', 'Somalian liittotasavalta', 'République fédérale de Somalie', 'Somalie', 'Savezna Republika Somaliji', 'Somalija', 'Szomáli Szövetségi Köztársaság', 'Szomália', 'Repubblica federale di Somalia', 'ソマリア連邦共和国', 'ソマリア', ' 소말리아 연방 공화국', '소말리아', 'Federale Republiek Somalië', 'Somalië', 'جمهوری فدرال سومالی', 'سومالی', 'Federalna Republika Somalii', 'República Federal da Somália', 'Somália', 'Федеративная Республика Сомали', 'Сомали', 'Somálska federatívna republika', 'República Federal de Somalia', 'Förbundsrepubliken Somalia', 'وفاقی جمہوریہ صومالیہ', 'صومالیہ', '索马里共和国', '索马里', 'aṣ-Ṣūmāl', 'Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya']
}, {
  common: 'Saint Pierre and Miquelon',
  official: 'Saint Pierre and Miquelon',
  cca2: 'PM',
  ccn3: '666',
  cca3: 'SPM',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Saint Pierre and Miquelon', 'Collectivité territoriale de Saint-Pierre-et-Miquelon', 'Saint-Pierre-et-Miquelon', 'Saint-Pierre a Miquelon', 'St. Pierre und Miquelon', 'Saint-Pierre’i ja Miqueloni territoriaalühendus', 'Saint-Pierre ja Miquelon', 'Saint Pierre i Miquelon', 'Sveti Petar i Mikelon', 'Saint-Pierre és Miquelon', 'Saint Pierre e Miquelon', 'Saint-Pierre e Miquelon', 'サンピエール島·ミクロン島', 'サンピエール島・ミクロン島', '생피에르 미클롱', 'Saint-Pierre en Miquelon', 'Saint Pierre en Miquelon', 'سن-پیر-ا-میکلون', 'سن-پیِر و میکلُن', 'Saint-Pierre i Miquelon', 'Сен-Пьер и Микелон', 'Ostrovy Saint Pierre a Miquelon', 'Saint Pierre a Miquelon', 'San Pedro y Miquelón', 'Saint-Pierre och Miquelon', 'سینٹ پیئر و میکیلون', '圣皮埃尔和密克隆']
}, {
  common: 'Serbia',
  official: 'Republic of Serbia',
  cca2: 'RS',
  ccn3: '688',
  cca3: 'SRB',
  cioc: 'SRB',
  alternativeNames: ['Republic of Serbia', 'Serbia', 'Република Србија', 'Србија', 'Srbská republika', 'Srbsko', 'Republik Serbien', 'Serbien', 'Serbia Vabariik', 'Serbian tasavalta', 'République de Serbie', 'Serbie', 'Republika Srbija', 'Srbija', 'Szerb Köztársaság', 'Szerbia', 'Repubblica di Serbia', 'セルビア共和国', 'セルビア', '세르비아 공화국', '세르비아', 'Republiek Servië', 'Servië', 'جمهوری صربستان', 'صربستان', 'Republika Serbii', 'República da Sérvia', 'Sérvia', 'Республика Сербия', 'Сербия', 'República de Serbia', 'Republiken Serbien', 'جمہوریہ سربیا', 'سربیا', '塞尔维亚共和国', '塞尔维亚']
}, {
  common: 'South Sudan',
  official: 'Republic of South Sudan',
  cca2: 'SS',
  ccn3: '728',
  cca3: 'SSD',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of South Sudan', 'South Sudan', 'Jihosúdánská republika', 'Jižní Súdán', 'Republik Südsudan', 'Südsudan', 'Lõuna-Sudaani Vabariik', 'Lõuna-Sudaan', 'Etelä-Sudanin tasavalta', 'Etelä-Sudan', 'République du Soudan du Sud', 'Soudan du Sud', 'Republika Južni Sudan', 'Južni Sudan', 'Dél-szudáni Köztársaság', 'Dél-Szudán', 'Repubblica del Sudan del Sud', 'Sudan del sud', '南スーダン共和国', '南スーダン', '남수단 공화국', '남수단', 'Republiek Zuid-Soedan', 'Zuid-Soedan', 'جمهوری سودان جنوبی', 'سودان جنوبی', 'Republika Sudanu', 'Sudan', 'República do Sudão do Sul', 'Sudão do Sul', 'Республика Южный Судан', 'Южный Судан', 'Juhosudánska republika', 'Južný Sudán', 'República de Sudán del Sur', 'Sudán del Sur', 'Republiken Sydsudan', 'Sydsudan', 'جمہوریہ جنوبی سوڈان', 'جنوبی سوڈان', '南苏丹共和国', '南苏丹']
}, {
  common: 'São Tomé and Príncipe',
  official: 'Democratic Republic of São Tomé and Príncipe',
  cca2: 'ST',
  ccn3: '678',
  cca3: 'STP',
  cioc: 'STP',
  isShipmentUnavailable: true,
  alternativeNames: ['Democratic Republic of São Tomé and Príncipe', 'São Tomé and Príncipe', 'República Democrática do São Tomé e Príncipe', 'São Tomé e Príncipe', 'Demokratická republika Svatý Tomáš a Princův ostrov', 'Svatý Tomáš a Princův ostrov', 'Demokratische Republik São Tomé und Príncipe', 'São Tomé und Príncipe', 'São Tomé ja Príncipe Demokraatlik Vabariik', 'São Tomé ja Príncipe', 'São Tomé ja Príncipen demokraattinen tasavalta', 'São Téme ja Príncipe', 'République démocratique de São Tomé et Príncipe', 'São Tomé et Príncipe', 'Demokratska Republika São Tome i Principe', 'Sveti Toma i Princip', 'São Tomé és Príncipe Demokratikus Köztársaság', 'São Tomé és Príncipe', 'Repubblica democratica di São Tomé e Príncipe', 'サントメ·プリンシペ民主共和国', 'サントメ・プリンシペ', '상투메 프린시페 민주 공화국', '상투메 프린시페', 'Democratische Republiek Sao Tomé en Principe', 'Sao Tomé en Principe', 'جمهوری دموکراتیک سائوتومه و پرنسیپ', 'سائوتومه و پرنسیپ', 'Demokratyczna Republika Wysp Świętego Tomasza i Książęcej', 'Wyspy Świętego Tomasza i Książęca', 'República Democrática de São Tomé e Príncipe', 'Демократическая Республика Сан-Томе и Принсипи', 'Сан-Томе и Принсипи', 'Demokratická republika Svätého Tomáša A princovho ostrova', 'Svätý Tomáš a Princov ostrov', 'República Democrática de Santo Tomé y Príncipe', 'Santo Tomé y Príncipe', 'Demokratiska republiken São Tomé och Príncipe', 'São Tomé och Príncipe', 'جمہوریہ ساؤ ٹومے و پرنسپے', 'ساؤ ٹومے و پرنسپے', '圣多美和普林西比民主共和国', '圣多美和普林西比', 'Sao Tome and Principe']
}, {
  common: 'Suriname',
  official: 'Republic of Suriname',
  cca2: 'SR',
  ccn3: '740',
  cca3: 'SUR',
  cioc: 'SUR',
  alternativeNames: ['Republic of Suriname', 'Suriname', 'Republiek Suriname', 'Republika Surinam', 'Surinam', 'Republik Suriname', 'Suriname Vabariik', 'Surinamen tasavalta', 'République du Suriname', 'Suriname Köztársaság', 'Repubblica del Suriname', 'スリナム共和国', 'スリナム', '수리남 공화국', '수리남', 'جمهوری سورینام', 'سورینام', 'Republika Surinamu', 'República do Suriname', 'Республика Суринам', 'Суринам', 'Surinamská republika', 'República de Suriname', 'Republiken Surinam', 'جمہوریہ سرینام', 'سرینام', '苏里南共和国', '苏里南', 'Sarnam', 'Sranangron']
}, {
  common: 'Slovakia',
  official: 'Slovak Republic',
  cca2: 'SK',
  ccn3: '703',
  cca3: 'SVK',
  cioc: 'SVK',
  alternativeNames: ['Slovak Republic', 'Slovakia', 'Slovenská republika', 'Slovensko', 'Slowakische Republik', 'Slowakei', 'Slovaki Vabariik', 'Slovakkia', 'Slovakian tasavalta', 'République slovaque', 'Slovaquie', 'slovačka', 'Slovačka', 'Szlovák Köztársaság', 'Szlovákia', 'Repubblica slovacca', 'Slovacchia', 'スロバキア共和国', 'スロバキア', '슬로바키아 공화국', '슬로바키아', 'Slowaakse Republiek', 'Slowakije', 'جمهوری اسلواکی', 'اِسلُواکی', 'Republika Słowacka', 'Słowacja', 'República Eslovaca', 'Eslováquia', 'Словацкая Республика', 'Словакия', 'Republiken Slovakien', 'Slovakien', 'جمہوریہ سلوواکیہ', 'سلوواکیہ', '斯洛伐克共和国', '斯洛伐克']
}, {
  common: 'Slovenia',
  official: 'Republic of Slovenia',
  cca2: 'SI',
  ccn3: '705',
  cca3: 'SVN',
  cioc: 'SLO',
  alternativeNames: ['Republic of Slovenia', 'Slovenia', 'Republika Slovenija', 'Slovenija', 'Slovinská republika', 'Slovinsko', 'Republik Slowenien', 'Slowenien', 'Sloveenia Vabariik', 'Sloveenia', 'Slovenian tasavalta', 'République de Slovénie', 'Slovénie', 'Szlovén Köztársaság', 'Szlovénia', 'Repubblica di Slovenia', 'スロベニア共和国', 'スロベニア', '슬로베니아 공화국', '슬로베니아', 'Republiek Slovenië', 'Slovenië', 'جمهوری اسلوونی', 'اسلوونی', 'Republika Słowenii', 'Słowenia', 'República da Eslovénia', 'Eslovénia', 'Республика Словения', 'Словения', 'República de Eslovenia', 'Eslovenia', 'Republiken Slovenien', 'Slovenien', 'جمہوریہ سلووینیا', 'سلووینیا', '斯洛文尼亚共和国', '斯洛文尼亚']
}, {
  common: 'Sweden',
  official: 'Kingdom of Sweden',
  cca2: 'SE',
  ccn3: '752',
  cca3: 'SWE',
  cioc: 'SWE',
  alternativeNames: ['Kingdom of Sweden', 'Sweden', 'Konungariket Sverige', 'Sverige', 'Švédské království', 'Švédsko', 'Königreich Schweden', 'Schweden', 'Rootsi Kuningriik', 'Rootsi', 'Ruotsin kuningaskunta', 'Ruotsi', 'Royaume de Suède', 'Suède', 'Kraljevina Švedska', 'Švedska', 'Svéd Királyság', 'Svédország', 'Regno di Svezia', 'Svezia', 'スウェーデン王国', 'スウェーデン', '스웨덴 왕국', '스웨덴', 'Koninkrijk Zweden', 'Zweden', 'پادشاهی سوئد', 'سوئد', 'Królestwo Szwecji', 'Szwecja', 'Reino da Suécia', 'Suécia', 'Королевство Швеция', 'Швеция', 'Švédske kráľovstvo', 'Reino de Suecia', 'Suecia', 'مملکتِ سویڈن', 'سویڈن', '瑞典王国', '瑞典']
}, {
  common: 'Swaziland',
  official: 'Kingdom of Eswatini',
  cca2: 'SZ',
  ccn3: '748',
  cca3: 'SWZ',
  cioc: 'SWZ',
  alternativeNames: ['Kingdom of Eswatini', 'Eswatini', 'Umbuso weSwatini', 'eSwatini', 'Svazijské království', 'Svazijsko', 'Königreich Eswatini', 'Swasiland', 'eSwatini Kuningriik', 'Svaasimaa', 'Swazimaan kuningaskunta', 'Swazimaa', 'Royaume d’Eswatini', 'Swaziland', 'Kraljevina eSwatini', 'Svazi', 'Szváziföldi Királyság', 'Szváziföld', 'Regno di eSwatini', 'スワジランド王国', 'スワジランド', '에스와티니 왕국', '에스와티니', 'Koninkrijk eSwatini', 'پادشاهی سوازیلند', 'اسواتینی', 'Królestwo Suazi', 'Suazi', 'Reino de eSwatini', 'Suazilândia', 'Королевство Свазиленд', 'Свазиленд', 'Svazijské kráľovstvo', 'Suazilandia', 'Konungariket Eswatini', 'مملکتِ سوازی لینڈ', 'سوازی لینڈ', '斯威士兰王国', '斯威士兰', 'weSwatini', 'Swatini', 'Ngwane']
}, {
  common: 'St. Maarten and St. Martin',
  official: 'Sint Maarten',
  cca2: 'SX',
  ccn3: '534',
  cca3: 'SXM',
  cioc: '',
  hasComplemarError: true,
  alternativeNames: ['Sint Maarten', 'Saint-Martin', 'Svatý Martin', 'Svatý Martin (Nizozemsko)', 'Sveti Martin', 'シントマールテン島', 'シント・マールテン', '신트마르턴', 'سن مارتن', 'São Martinho', 'Синт-Маартен', 'Синт-Мартен', 'سنٹ مارٹن', '圣马丁岛', 'Sint Maarten (Dutch part)']
}, {
  common: 'Seychelles',
  official: 'Republic of Seychelles',
  cca2: 'SC',
  ccn3: '690',
  cca3: 'SYC',
  cioc: 'SEY',
  alternativeNames: ['Republic of Seychelles', 'Seychelles', 'Repiblik Sesel', 'Sesel', 'République des Seychelles', 'Seychelská republika', 'Seychely', 'Republik der Seychellen', 'Seychellen', 'Seišelli Vabariik', 'Seišellid', 'Seychellien tasavalta', 'Seychellit', 'Republika Sejšeli', 'Sejšeli', 'Seychelle Köztársaság', 'Seychelle-szigetek', 'Repubblica delle Seychelles', 'セイシェル共和国', 'セーシェル', '세이셸 공화국', '세이셸', 'Republiek der Seychellen', 'جمهوری سیشل', 'سیشل', 'Republika Seszeli', 'Seszele', 'República das Seychelles', 'Seicheles', 'Республика Сейшельские Острова', 'Сейшельские Острова', 'República de las Seychelles', 'Republiken Seychellerna', 'Seychellerna', 'جمہوریہ سیچیلیس', 'سیچیلیس', '塞舌尔共和国', '塞舌尔']
}, {
  common: 'Syria',
  official: 'Syrian Arab Republic',
  cca2: 'SY',
  ccn3: '760',
  cca3: 'SYR',
  cioc: 'SYR',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Syrian Arab Republic', 'Syria', 'الجمهورية العربية السورية', 'سوريا', 'Syrská arabská republika', 'Sýrie', 'Arabische Republik Syrien', 'Syrien', 'Süüria Araabia Vabariik', 'Süüria', 'Syyrian arabitasavalta', 'Syyria', 'République arabe syrienne', 'Syrie', 'Sirijska Arapska Republika', 'Sirija', 'Szíriai Arab Köztársaság', 'Szíria', 'Repubblica araba siriana', 'Siria', 'シリアアラブ共和国', 'シリア・アラブ共和国', '시리아 아랍 공화국', '시리아', 'Syrische Arabische Republiek', 'Syrië', 'جمهوری عربی سوریه', 'سوریه', 'Syryjska Republika Arabska', 'República Árabe Síria', 'Síria', 'Сирийская Арабская Республика', 'Сирия', 'Sýrska arabská republika', 'Sýria', 'República Árabe Siria', 'Syriska arabiska republiken', 'عرب جمہوریہ سوریہ', 'سوریہ', '叙利亚阿拉伯共和国', '叙利亚', 'Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah']
}, {
  common: 'Turks and Caicos Islands',
  official: 'Turks and Caicos Islands',
  cca2: 'TC',
  ccn3: '796',
  cca3: 'TCA',
  cioc: '',
  alternativeNames: ['Turks and Caicos Islands', 'Turks a Caicos', 'Turks und Caicos Inseln', 'Turks-und Caicosinseln', 'Turksi ja Caicose saared', 'Turks ja Caicos', 'Turks-ja Caicossaaret', 'Îles Turques et Caïques', 'Îles Turques-et-Caïques', 'Otoci Turks i Caicos', 'Turks- és Caicos-szigetek', 'Turks e Caicos', 'Isole Turks e Caicos', 'タークス·カイコス諸島', 'タークス・カイコス諸島', '터크스 케이커스 제도', 'Turks-en Caicoseilanden', 'جزایر تورکس و کایکوس', 'Turks i Caicos', 'Ilhas Turks e Caicos', 'Теркс и Кайкос острова', 'Теркс и Кайкос', 'Ostrovy Turks a Caicos', 'Islas Turcas y Caicos', 'Islas Turks y Caicos', 'Turks- och Caicosöarna', 'جزائر کیکس و ترکیہ', '特克斯和凯科斯群岛']
}, {
  common: 'Chad',
  official: 'Republic of Chad',
  cca2: 'TD',
  ccn3: '148',
  cca3: 'TCD',
  cioc: 'CHA',
  alternativeNames: ['Republic of Chad', 'Chad', 'جمهورية تشاد', 'تشاد‎', 'République du Tchad', 'Tchad', 'Čadská republika', 'Čad', 'Gweriniaeth Tsiad', 'Tsiad', 'Republik Tschad', 'Tschad', 'Tšaadi Vabariik', 'Tšaad', 'Tšadin tasavalta', 'Tšad', 'Čadu', 'Csád Köztársaság', 'Csád', 'Repubblica del Ciad', 'Ciad', 'チャド共和国', 'チャド', '차드 공화국', '차드', 'Republiek Tsjaad', 'Tsjaad', 'جمهوری چاد', 'چاد', 'Republika Czadu', 'Czad', 'República do Chade', 'Chade', 'Республика Чад', 'Чад', 'República de Chad', 'Republiken Tchad', 'جمہوریہ چاڈ', 'چاڈ', '乍得共和国', '乍得']
}, {
  common: 'Togo',
  official: 'Togolese Republic',
  cca2: 'TG',
  ccn3: '768',
  cca3: 'TGO',
  cioc: 'TOG',
  alternativeNames: ['Togolese Republic', 'Togo', 'République togolaise', 'Republika Togo', 'Republik Togo', 'Togo Vabariik', 'Togon tasavalta', 'Togolese Republika', 'Togói Köztársaság', 'Repubblica del Togo', 'トーゴ共和国', 'トーゴ', '토고 공화국', '토고', 'Republiek Togo', 'جمهوری توگو', 'توگو', 'Republika Togijska', 'República do Togo', 'Того Республика', 'Того', 'Togská republika', 'República de Togo', 'Republiken Togo', 'جمہوریہ ٹوگو', 'ٹوگو', '多哥共和国', '多哥', 'Togolese', 'République Togolaise']
}, {
  common: 'Thailand',
  official: 'Kingdom of Thailand',
  cca2: 'TH',
  ccn3: '764',
  cca3: 'THA',
  cioc: 'THA',
  alternativeNames: ['Kingdom of Thailand', 'Thailand', 'ราชอาณาจักรไทย', 'ประเทศไทย', 'Thajské království', 'Thajsko', 'Königreich Thailand', 'Tai Kuningriik', 'Tai', 'Thaimaan kuningaskunta', 'Thaimaa', 'Royaume de Thaïlande', 'Thaïlande', 'Kraljevina Tajland', 'Tajland', 'Thaiföldi Királyság', 'Thaiföld', 'Regno di Thailandia', 'Tailandia', 'タイ王国', 'タイ', '타이 왕국', '태국', 'Koninkrijk Thailand', 'پادشاهی تایلند', 'تایلند', 'Królestwo Tajlandii', 'Tajlandia', 'Reino da Tailândia', 'Tailândia', 'Королевство Таиланд', 'Таиланд', 'Thajské kráľovstvo', 'Reino de Tailandia', 'Konungariket Thailand', 'مملکتِ تھائی لینڈ', 'تھائی لینڈ', '泰王国', '泰国', 'Prathet', 'Thai', 'Ratcha Anachak Thai']
}, {
  common: 'Tajikistan',
  official: 'Republic of Tajikistan',
  cca2: 'TJ',
  ccn3: '762',
  cca3: 'TJK',
  cioc: 'TJK',
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of Tajikistan', 'Tajikistan', 'Республика Таджикистан', 'Таджикистан', 'Ҷумҳурии Тоҷикистон', 'Тоҷикистон', 'Republika Tádžikistán', 'Tádžikistán', 'Republik Tadschikistan', 'Tadschikistan', 'Tadžikistani Vabariik', 'Tadžikistan', 'Tadžikistanin tasavalta', 'République du Tadjikistan', 'Tadjikistan', 'Republika Tadžikistan', 'Tađikistan', 'Tádzsik Köztársaság', 'Tádzsikisztán', 'Repubblica del Tajikistan', 'Tagikistan', 'タジキスタン共和国', 'タジキスタン', '타지키스탄 공화국', '타지키스탄', 'Tadzjikistan', 'جمهوری تاجیکستان', 'تاجیکِستان', 'Republika Tadżykistanu', 'Tadżykistan', 'República do Tajiquistão', 'Tajiquistão', 'Taǆická republika', 'Taǆikistan', 'República de Tayikistán', 'Tayikistán', 'Republiken Tadzjikistan', 'جمہوریہ تاجکستان', 'تاجکستان', '塔吉克斯坦共和国', '塔吉克斯坦', 'Toçikiston', 'Çumhuriyi Toçikiston']
}, {
  common: 'Tokelau',
  official: 'Tokelau',
  cca2: 'TK',
  ccn3: '772',
  cca3: 'TKL',
  cioc: '',
  hasUpsError: true,
  isShipmentUnavailable: true,
  alternativeNames: ['Tokelau', 'Îles Tokelau', 'Tokelau-szigetek', 'Isole Tokelau', 'トケラウ諸島', 'トケラウ', '토켈라우', 'توکلائو', 'Токелау', 'Tokelauské ostrovy', 'Islas Tokelau', 'Tokelauöarna', 'ٹوکیلاؤ', '托克劳']
}, {
  common: 'Turkmenistan',
  official: 'Turkmenistan',
  cca2: 'TM',
  ccn3: '795',
  cca3: 'TKM',
  cioc: 'TKM',
  isShipmentUnavailable: true,
  alternativeNames: ['Turkmenistan', 'Туркменистан', 'Туркмения', 'Türkmenistan', 'Turkmenistán', 'Turkménistan', 'Türkmén Köztársaság', 'Türkmenisztán', 'トルクメニスタン', '투르크메니스탄', 'جمهوری خلق ترکمنستان', 'ترکمنستان', 'Republika Turkmenistanu', 'Turcomenistão', 'Turquemenistão', 'Turkménsko', 'ترکمانستان', '土库曼斯坦']
}, {
  common: 'Timor-Leste',
  official: 'Democratic Republic of Timor-Leste',
  cca2: 'TL',
  ccn3: '626',
  cca3: 'TLS',
  cioc: 'TLS',
  alternativeNames: ['Democratic Republic of Timor-Leste', 'Timor-Leste', 'República Democrática de Timor-Leste', 'Repúblika Demokrátika Timór-Leste', 'Timór-Leste', 'Demokratická republika Východní Timor', 'Východní Timor', 'Demokratische Republik Timor-Leste', 'Osttimor', 'Timor-Leste Demokraatlik Vabariik', 'Ida-Timor', 'Itä-Timorin demokraattinen tasavalta', 'Itä-Timor', 'République démocratique du Timor oriental', 'Timor oriental', 'Demokratska Republika Timor-Leste', 'Istočni Timor', 'Kelet-timori Demokratikus Köztársaság', 'Kelet-Timor', 'Repubblica Democratica di Timor Est', 'Timor Est', '東ティモール民主共和国', '東ティモール', '동티모르 민주 공화국', '동티모르', 'Democratische Republiek Oost-Timor', 'Oost-Timor', 'جمهوری دموکراتیک تیمور شرقی', 'تیمور شرقی', 'Demokratyczna Republika Timoru Wschodniego', 'Timor Wschodni', 'Демократическая Республика Тимор -Лешти', 'Восточный Тимор', 'Východotimorská demokratická republika', 'Východný Timor', 'Timor Oriental', 'Demokratiska republiken Östtimor', 'Östtimor a.k.a. Timor-Leste', 'جمہوری جمہوریہ مشرقی تیمور', 'مشرقی تیمور', '东帝汶民主共和国', '东帝汶', 'East Timor', "Timór Lorosa'e", 'Timor Lorosae']
}, {
  common: 'Tonga',
  official: 'Kingdom of Tonga',
  cca2: 'TO',
  ccn3: '776',
  cca3: 'TON',
  cioc: 'TGA',
  alternativeNames: ['Kingdom of Tonga', 'Tonga', 'Království Tonga', 'Königreich Tonga', 'Tonga Kuningriik', 'Tongan kuningaskunta', 'Royaume des Tonga', 'Kraljevina Tonga', 'Tongai Királyság', 'Regno di Tonga', 'トンガ王国', 'トンガ', '통가 왕국', '통가', 'Koninkrijk Tonga', 'پادشاهی تونگا', 'تونگا', 'Królestwo Tonga', 'Reino de Tonga', 'Королевство Тонга', 'Тонга', 'Tongské kráľovstvo', 'Konungariket Tonga', 'مملکتِ ٹونگا', 'ٹونگا', '汤加王国', '汤加']
}, {
  common: 'Trinidad and Tobago',
  official: 'Republic of Trinidad and Tobago',
  cca2: 'TT',
  ccn3: '780',
  cca3: 'TTO',
  cioc: 'TTO',
  alternativeNames: ['Republic of Trinidad and Tobago', 'Trinidad and Tobago', 'Republika Trinidad a Tobago', 'Trinidad a Tobago', 'Republik Trinidad und Tobago', 'Trinidad und Tobago', 'Trinidadi ja Tobago Vabariik', 'Trinidad ja Tobago', 'Trinidadin ja Tobagon tasavalta', 'République de Trinité-et-Tobago', 'Trinité-et-Tobago', 'Republika Trinidad i Tobago', 'Trinidad i Tobago', 'Trinidad és Tobago Köztársaság', 'Trinidad és Tobago', 'Repubblica di Trinidad e Tobago', 'Trinidad e Tobago', 'トリニダード·トバゴ共和国', 'トリニダード・トバゴ', '트리니다드 토바고 공화국', '트리니다드 토바고', 'Republiek Trinidad en Tobago', 'Trinidad en Tobago', 'جمهوری ترینیداد و توباگو', 'ترینیداد و توباگو', 'Trynidad i Tobago', 'República de Trinidad e Tobago', 'Trinidade e Tobago', 'Республика Тринидад и Тобаго', 'Тринидад и Тобаго', 'Republika Trinidad a Tobaga', 'República de Trinidad y Tobago', 'Trinidad y Tobago', 'Republiken Trinidad och Tobago', 'Trinidad och Tobago', 'جمہوریہ ٹرینیڈاڈ و ٹوباگو', 'ٹرینیڈاڈ و ٹوباگو', '特立尼达和多巴哥共和国', '特立尼达和多巴哥']
}, {
  common: 'Tunisia',
  official: 'Tunisian Republic',
  cca2: 'TN',
  ccn3: '788',
  cca3: 'TUN',
  cioc: 'TUN',
  alternativeNames: ['Tunisian Republic', 'Tunisia', 'الجمهورية التونسية', 'تونس', 'Tuniská republika', 'Tunisko', 'Tunesische Republik', 'Tunesien', 'Tuneesia Vabariik', 'Tuneesia', 'Tunisian tasavalta', 'République tunisienne', 'Tunisie', 'Tuniski Republika', 'Tunis', 'Tunéziai Köztársaság', 'Tunézia', 'Repubblica tunisina', 'チュニジア共和国', 'チュニジア', '튀니지 공화국', '튀니지', 'Republiek Tunesië', 'Tunesië', 'جمهوری تونس', 'Republika Tunezyjska', 'Tunezja', 'República da Tunísia', 'Tunísia', 'Тунисской Республики', 'Тунис', 'República de Túnez', 'Túnez', 'Republiken Tunisien', 'Tunisien', 'جمہوریہ تونس', '突尼斯共和国', '突尼斯', 'Republic of Tunisia', 'al-Jumhūriyyah at-Tūnisiyyah']
}, {
  common: 'Turkey',
  official: 'Republic of Turkey',
  cca2: 'TR',
  ccn3: '792',
  cca3: 'TUR',
  cioc: 'TUR',
  alternativeNames: ['Republic of Turkey', 'Turkey', 'Türkiye Cumhuriyeti', 'Türkiye', 'Turecká republika', 'Turecko', 'Republik Türkei', 'Türkei', 'Türgi Vabariik', 'Türgi', 'Turkin tasavalta', 'Turkki', 'République de Turquie', 'Turquie', 'Republika Turska', 'Turska', 'Török Köztársaság', 'Törökország', 'Repubblica di Turchia', 'Turchia', 'トルコ共和国', 'トルコ', '터키 공화국', '터키', 'Republiek Turkije', 'Turkije', 'جمهوری ترکیه', 'ترکیه', 'Republika Turcji', 'Turcja', 'República da Turquia', 'Turquia', 'Республика Турции', 'Турция', 'República de Turquía', 'Turquía', 'Republiken Turkiet', 'Turkiet', 'جمہوریہ ترکی', 'ترکی', '土耳其共和国', '土耳其', 'Turkiye']
}, {
  common: 'Tuvalu',
  official: 'Tuvalu',
  cca2: 'TV',
  ccn3: '798',
  cca3: 'TUV',
  cioc: 'TUV',
  alternativeNames: ['Tuvalu', 'ツバル', '투발루', 'تووالو', 'Тувалу', '图瓦卢']
}, {
  common: 'Taiwan',
  official: 'Republic of China (Taiwan)',
  cca2: 'TW',
  ccn3: '158',
  cca3: 'TWN',
  cioc: 'TPE',
  alternativeNames: ['Republic of China (Taiwan)', 'Taiwan', '中華民國', '台灣', 'Čínská republika', 'Tchaj-wan', 'Republik China (Taiwan)', 'Taiwani', 'Kiinan tasavalta', 'République de Chine (Taïwan)', 'Taïwan', 'Republika Kina', 'Tajvan', 'Kínai Köztársaság', 'Repubblica cinese (Taiwan)', '中華民国', '台湾', '중화민국', '대만', 'Republiek China (Taiwan)', 'جمهوری چین', 'تایوان', 'Republika Chińska (Tajwan)', 'Tajwan', 'República da China', 'Ilha Formosa', 'Китайская Республика', 'Тайвань', 'Čínska republika', 'República de China en Taiwán', 'Taiwán', 'Republiken Kina', 'جمہوریہ چین (تائیوان)', 'تائیوان', 'Táiwān', 'Republic of China', 'Zhōnghuá Mínguó', 'Chinese Taipei']
}, {
  common: 'Tanzania',
  official: 'United Republic of Tanzania',
  cca2: 'TZ',
  ccn3: '834',
  cca3: 'TZA',
  cioc: 'TAN',
  alternativeNames: ['United Republic of Tanzania', 'Tanzania', 'Jamhuri ya Muungano wa Tanzania', 'Sjednocená tanzanská republika', 'Tanzanie', 'Vereinigte Republik Tansania', 'Tansania', 'Tansaania Ühendvabariik', 'Tansaania', 'Tansanian yhdistynyt tasavalta', 'République -Unie de Tanzanie', 'Ujedinjena Republika Tanzanija', 'Tanzanija', 'Tádzsik Köztársaság', 'Tádzsikisztán', 'Repubblica Unita di Tanzania', 'タンザニア連合共和国', 'タンザニア', '탄자니아 연합 공화국', '탄자니아', 'Verenigde Republiek Tanzania', 'جمهوری متحد تانزانیا', 'تانزانیا', 'Zjednoczona Republika Tanzanii', 'República Unida da Tanzânia', 'Tanzânia', 'Объединенная Республика Танзания', 'Танзания', 'Tanzánijská zjednotená republika', 'Tanzánia', 'República Unida de Tanzania', 'Förenade republiken Tanzania', 'متحدہ جمہوریہ تنزانیہ', 'تنزانیہ', '坦桑尼亚联合共和国', '坦桑尼亚', 'Tanzania, United Republic of']
}, {
  common: 'Uganda',
  official: 'Republic of Uganda',
  cca2: 'UG',
  ccn3: '800',
  cca3: 'UGA',
  cioc: 'UGA',
  alternativeNames: ['Republic of Uganda', 'Uganda', 'Ugandská republika', 'Republik Uganda', 'Uganda Vabariik', 'Ugandan tasavalta', "République de l'Ouganda", 'Ouganda', 'Republika Uganda', 'Ugandai Köztársaság', 'Repubblica di Uganda', 'ウガンダ共和国', 'ウガンダ', '우간다 공화국', '우간다', 'Republiek Uganda', 'Oeganda', 'جمهوری اوگاندا', 'اوگاندا', 'Republika Ugandy', 'República do Uganda', 'Республика Уганда', 'Уганда', 'República de Uganda', 'Republiken Uganda', 'جمہوریہ یوگنڈا', 'یوگنڈا', '乌干达共和国', '乌干达', 'Jamhuri ya Uganda']
}, {
  common: 'Ukraine',
  official: 'Ukraine',
  cca2: 'UA',
  ccn3: '804',
  cca3: 'UKR',
  cioc: 'UKR',
  alternativeNames: ['Ukraine', 'Україна', 'Ukrajina', 'Ukraina', 'Ukrajna', 'Ucraina', 'ウクライナ', '우크라이나', 'Oekraïne', 'اوکراین', 'Ucrânia', 'Украина', 'Ucrania', 'یوکرین', '乌克兰', 'Ukrayina'],
  isBlackListed: true
}, {
  common: 'United States Minor Outlying Islands',
  official: 'United States Minor Outlying Islands',
  cca2: 'UM',
  ccn3: '581',
  cca3: 'UMI',
  cioc: '',
  hasUpsError: true,
  alternativeNames: ['United States Minor Outlying Islands', 'Menší odlehlé ostrovy Spojených států amerických', 'Menší odlehlé ostrovy USA', 'USA, kleinere ausgelagerte Inseln', 'Kleinere Inselbesitzungen der Vereinigten Staaten', 'Ühendriikide väikesed hajasaared', 'Ühendriikide hajasaared', 'Yhdysvaltain asumattomat saaret', 'Îles mineures éloignées des États-Unis', 'Mali udaljeni otoci SAD-a', 'Az Amerikai Egyesült Államok lakatlan külbirtokai', 'Stati Uniti Isole Minori', "Isole minori esterne degli Stati Uniti d'America", 'アメリカ合衆国外諸島', '合衆国領有小離島', '미국령 군소 제도', 'Kleine afgelegen eilanden van de Verenigde Staten', 'جزایر کوچک حاشیه‌ای ایالات متحده آمریکا', 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych', 'Estados Unidos Ilhas Menores Distantes', 'Ilhas Menores Distantes dos Estados Unidos', 'Внешние малые острова США', 'Menšie odľahlé ostrovy Spjoených štátov', 'Menšie odľahlé ostrovy USA', 'Estados Unidos Islas menores alejadas de', 'Islas Ultramarinas Menores de Estados Unidos', 'Förenta staternas mindre öar i Oceanien och Västindien', 'امریکی چھوٹے بیرونی جزائر', '美国本土外小岛屿']
}, {
  common: 'Uruguay',
  official: 'Oriental Republic of Uruguay',
  cca2: 'UY',
  ccn3: '858',
  cca3: 'URY',
  cioc: 'URU',
  alternativeNames: ['Oriental Republic of Uruguay', 'Uruguay', 'República Oriental del Uruguay', 'Uruguayská východní republika', 'Republik Östlich des Uruguay', 'Uruguay Idavabariik', 'Uruguayn itäinen tasavalta', "République orientale de l'Uruguay", 'Orijentalna Republika Urugvaj', 'Urugvaj', 'Uruguayi Keleti Köztársaság', "Repubblica Orientale dell'Uruguay", 'ウルグアイ東方共和国', 'ウルグアイ', '우루과이 동방 공화국', '우루과이', 'Oosterse Republiek Uruguay', 'جمهوری اروگوئه', 'اروگوئه', 'Wschodnia Republika Urugwaju', 'Urugwaj', 'República Oriental do Uruguai', 'Uruguai', 'Восточной Республики Уругвай', 'Уругвай', 'Uruguajská východná republika', 'Uruguaj', 'Republiken Uruguay', 'جمہوریہ شرقیہ یوراگوئے', 'یوراگوئے', '乌拉圭东岸共和国', '乌拉圭']
}, {
  common: 'United States',
  official: 'United States of America',
  cca2: 'US',
  ccn3: '840',
  cca3: 'USA',
  cioc: 'USA',
  alternativeNames: ['United States of America', 'United States', 'Spojené státy americké', 'Spojené státy', 'Vereinigte Staaten von Amerika', 'Vereinigte Staaten', 'Ameerika Ühendriigid', 'Amerikan yhdysvallat', 'Yhdysvallat', "Les états-unis d'Amérique", 'États-Unis', 'Sjedinjene Države Amerike', 'Sjedinjene Američke Države', 'Amerikai Egyesült Államok', "Stati Uniti d'America", 'アメリカ合衆国', '아메리카 합중국', '미국', 'Verenigde Staten van Amerika', 'Verenigde Staten', 'ایالات متحده آمریکا', 'Stany Zjednoczone Ameryki', 'Stany Zjednoczone', 'Estados Unidos da América', 'Estados Unidos', 'Соединенные Штаты Америки', 'Соединённые Штаты Америки', 'Spojené štáty Americké', 'Spojené štáty americké', 'Estados Unidos de América', 'Amerikas förenta stater', 'USA', 'ریاستہائے متحدہ امریکا', 'ریاستہائے متحدہ', '美利坚合众国', '美国']
}, {
  common: 'Uzbekistan',
  official: 'Republic of Uzbekistan',
  cca2: 'UZ',
  ccn3: '860',
  cca3: 'UZB',
  cioc: 'UZB',
  alternativeNames: ['Republic of Uzbekistan', 'Uzbekistan', 'Республика Узбекистан', 'Узбекистан', "O'zbekiston Respublikasi", 'O‘zbekiston', 'Republika Uzbekistán', 'Uzbekistán', 'Republik Usbekistan', 'Usbekistan', 'Usbekistani Vabariik', 'Uzbekistanin tasavalta', "République d'Ouzbékistan", 'Ouzbékistan', 'Republika Uzbekistan', 'Üzbég Köztársaság', 'Üzbegisztán', 'Repubblica di Uzbekistan', 'ウズベキスタン共和国', 'ウズベキスタン', '우즈베키스탄 공화국', '우즈베키스탄', 'Republiek Oezbekistan', 'Oezbekistan', 'جمهوری ازبکستان', 'ازبکستان', 'Republika Uzbekistanu', 'República do Usbequistão', 'Uzbequistão', 'Uzbecká republika', 'República de Uzbekistán', 'Republiken Uzbekistan', 'جمہوریہ ازبکستان', '乌兹别克斯坦共和国', '乌兹别克斯坦', 'O‘zbekiston Respublikasi', 'Ўзбекистон Республикаси']
}, {
  common: 'Vatican City State',
  official: 'Vatican City State',
  cca2: 'VA',
  ccn3: '336',
  cca3: 'VAT',
  cioc: '',
  alternativeNames: ['Vatican City State', 'Vatican City', 'Stato della Città del Vaticano', 'Vaticano', 'Status Civitatis Vaticanæ', 'Vaticanæ', 'Městský stát Vatikán', 'Vatikán', 'Staat Vatikanstadt', 'Vatikanstadt', 'Vatikani Linnriik', 'Vatikan', 'Vatikaanin kaupunkivaltio', 'Vatikaani', 'Cité du Vatican', 'Vatikán Állam', 'Città del Vaticano', 'バチカン市国の状態', 'バチカン市国', '바티칸 시국', '바티칸', 'Vaticaanstad', 'دولت‌شهر واتیکان', 'واتیکان', 'Państwo Watykańskie', 'Watykan', 'Cidade do Vaticano', 'Город-государство Ватикан', 'Ватикан', 'Svätá stolica (Vatikánsky mestský štát', 'Ciudad del Vaticano', 'Vatikanstaten', 'ویٹیکن سٹی', '梵蒂冈城国', '梵蒂冈', 'Holy See (Vatican City State)']
}, {
  common: 'St. Vincent/Grenadines',
  official: 'Saint Vincent and the Grenadines',
  cca2: 'VC',
  ccn3: '670',
  cca3: 'VCT',
  cioc: 'VIN',
  alternativeNames: ['Saint Vincent and the Grenadines', 'Svatý Vincenc a Grenadiny', 'St. Vincent und die Grenadinen', 'Saint Vincent ja Grenadiinid', 'Saint Vincent', 'Saint Vincent ja Grenadiinit', 'Saint-Vincent-et-les Grenadines', 'Saint-Vincent-et-les-Grenadines', 'Sveti Vincent i Grenadini', 'Saint Vincent és a Grenadine-szigetek', 'Saint Vincent e Grenadine', 'セントビンセントおよびグレナディーン諸島', '세인트빈센트 그레나딘', 'Saint Vincent en de Grenadines', 'سنت وینسنت و گرنادین‌ها', 'Saint Vincent i Grenadyny', 'São Vicente e Granadinas', 'São Vincente e Granadinas', 'Сент-Винсент и Гренадины', 'Svätý Vincent a Grenadíny', 'San Vicente y las Granadinas', 'San Vicente y Granadinas', 'Saint Vincent och Grenadinerna', 'سینٹ وینسینٹ و گریناڈائنز', '圣文森特和格林纳丁斯']
}, {
  common: 'Venezuela',
  official: 'Bolivarian Republic of Venezuela',
  cca2: 'VE',
  ccn3: '862',
  cca3: 'VEN',
  cioc: 'VEN',
  alternativeNames: ['Bolivarian Republic of Venezuela', 'Venezuela', 'República Bolivariana de Venezuela', 'Bolívarská republika Venezuela', 'Bolivarische Republik Venezuela', 'Venezuela Bolívari Vabariik', 'Venezuelan bolivariaainen tasavalta', 'République bolivarienne du Venezuela', 'BOLIVARIJANSKA Republika Venezuela', 'Venezuelai Bolivári Köztársaság', 'Repubblica Bolivariana del Venezuela', 'ベネズエラ·ボリバル共和国', 'ベネズエラ・ボリバル共和国', '베네수엘라 볼리바르 공화국', '베네수엘라', 'Bolivariaanse Republiek Venezuela', 'جمهوری بولیواری ونزوئلا', 'ونزوئلا', 'Boliwariańska Republika Wenezueli', 'Wenezuela', 'República Bolivariana da Venezuela', 'Боливарианская Республика Венесуэла', 'Венесуэла', 'Venezuelská bolívarovská republika', 'Bolivarianska republiken Venezuela', 'جمہوریہ وینیزویلا', 'وینیزویلا', '委内瑞拉玻利瓦尔共和国', '委内瑞拉', 'Venezuela, Bolivarian Republic of']
}, {
  common: 'British Virgin Isles',
  official: 'Virgin Islands',
  cca2: 'VG',
  ccn3: '092',
  cca3: 'VGB',
  cioc: 'IVB',
  alternativeNames: ['Virgin Islands', 'British Virgin Islands', 'Britské Panenské ostrovy', 'Jungferninseln', 'Britische Jungferninseln', 'Neitsisaared', 'Briti Neitsisaared', 'Brittiläiset Neitsytsaaret', 'Neitsytsaaret', 'îles Vierges', 'Îles Vierges britanniques', 'Djevičanski Otoci', 'Britanski Djevičanski Otoci', 'Brit Virgin-szigetek', 'Isole Vergini', 'Isole Vergini Britanniche', 'バージン諸島', 'イギリス領ヴァージン諸島', '영국령 버진아일랜드', 'Maagdeneilanden', 'Britse Maagdeneilanden', 'جزایر ویرجین بریتانیا', 'Brytyjskie Wyspy Dziewicze', 'Ilhas Virgens', 'Виргинские острова', 'Британские Виргинские острова', 'Panenské ostrovy', 'Islas Vírgenes', 'Islas Vírgenes del Reino Unido', 'Brittiska Jungfruöarna', 'برطانوی جزائر ورجن', '英属维尔京群岛', 'Virgin Islands, British']
}, {
  common: 'US Virgin Islands',
  official: 'Virgin Islands of the United States',
  cca2: 'VI',
  ccn3: '850',
  cca3: 'VIR',
  cioc: 'ISV',
  alternativeNames: ['Virgin Islands of the United States', 'United States Virgin Islands', 'Americké Panenské ostrovy', 'Amerikanische Jungferninseln', 'Ühendriikide Neitsisaared', 'Neitsisaared, USA', 'Yhdysvaltain Neitsytsaaret', 'Neitsytsaaret', 'Îles Vierges des États-Unis', 'Djevičanski Otoci SAD', 'Američki Djevičanski Otoci', 'Amerikai Virgin-szigetek', 'Isole Vergini degli Stati Uniti', 'Isole Vergini americane', '米国のバージン諸島', 'アメリカ領ヴァージン諸島', '미국령 버진아일랜드', 'Maagdeneilanden van de Verenigde Staten', 'Amerikaanse Maagdeneilanden', 'جزایر ویرجین ایالات متحده آمریکا', 'Wyspy Dziewicze Stanów Zjednoczonych', 'Ilhas Virgens dos Estados Unidos', 'Виргинские острова Соединенных Штатов', 'Виргинские Острова', 'Islas Vírgenes de los Estados Unidos', 'Amerikanska Jungfruöarna', 'امریکی جزائر ورجن', '美属维尔京群岛', 'Virgin Islands, U.S.']
}, {
  common: 'Vietnam',
  official: 'Socialist Republic of Vietnam',
  cca2: 'VN',
  ccn3: '704',
  cca3: 'VNM',
  cioc: 'VIE',
  alternativeNames: ['Socialist Republic of Vietnam', 'Vietnam', 'Cộng hòa xã hội chủ nghĩa Việt Nam', 'Việt Nam', 'Vietnamská socialistická republika', 'Sozialistische Republik Vietnam', 'Vietnami Sotsialistlik Vabariik', 'Vietnamin sosialistinen tasavalta', 'République socialiste du Viêt Nam', 'Viêt Nam', 'Socijalistička Republika Vijetnam', 'Vijetnam', 'Vietnámi Szocialista Köztársaság', 'Vietnám', 'Repubblica socialista del Vietnam', 'ベトナム社会主義共和国', 'ベトナム', '베트남 사회주의 공화국', '베트남', 'Socialistische Republiek Vietnam', 'جمهوری سوسیالیستی ویتنام', 'ویتنام', 'Socjalistyczna Republika Wietnamu', 'Wietnam', 'República Socialista do Vietname', 'Vietname', 'Социалистическая Республика Вьетнам', 'Вьетнам', 'República Socialista de Vietnam', 'Socialistiska republiken Vietnam', 'اشتراکی جمہوریہ ویتنام', '越南社会主义共和国', '越南', 'Cộng hòa Xã hội chủ nghĩa Việt Nam', 'Viet Nam']
}, {
  common: 'Vanuatu',
  official: 'Republic of Vanuatu',
  cca2: 'VU',
  ccn3: '548',
  cca3: 'VUT',
  cioc: 'VAN',
  alternativeNames: ['Republic of Vanuatu', 'Vanuatu', 'Ripablik blong Vanuatu', 'République de Vanuatu', 'Republika Vanuatu', 'Vanuatu Vabariik', 'Vanuatun tasavalta', 'Vanuatui Köztársaság', 'Repubblica di Vanuatu', 'バヌアツ共和国', 'バヌアツ', '바누아투 공화국', '바누아투', 'Republiek Vanuatu', 'جمهوری وانواتو', 'وانواتو', 'República de Vanuatu', 'Республика Вануату', 'Вануату', 'Vanuatská republika', 'Republiken Vanuatu', 'جمہوریہ وانواتو', '瓦努阿图共和国', '瓦努阿图']
}, {
  common: 'Wallis and Futuna Islands',
  official: 'Territory of the Wallis and Futuna Islands',
  cca2: 'WF',
  ccn3: '876',
  cca3: 'WLF',
  cioc: '',
  alternativeNames: ['Territory of the Wallis and Futuna Islands', 'Wallis and Futuna', 'Territoire des îles Wallis et Futuna', 'Wallis et Futuna', 'Teritorium ostrovů Wallis a Futuna', 'Wallis a Futuna', 'Gebiet der Wallis und Futuna', 'Wallis und Futuna', 'Wallise ja Futuna ala', 'Wallis ja Futuna', 'Wallisin ja Futunan yhteisö', 'Wallis-et-Futuna', 'Teritoriju Wallis i Futuna', 'Wallis i Fortuna', 'Wallis és Futuna', 'Territorio delle Isole Wallis e Futuna', 'Wallis e Futuna', 'ウォリス·フツナ諸島の領土', 'ウォリス・フツナ', '왈리스 퓌튀나', 'Grondgebied van de Wallis en Futuna', 'Wallis en Futuna', 'جزایر والیس و فوتونا', 'والیس و فوتونا', 'Terytorium Wysp Wallis i Futuna', 'Wallis i Futuna', 'Território das Ilhas Wallis e Futuna', 'Территория Уоллис и Футуна острова', 'Уоллис и Футуна', 'Teritórium ostrovov Wallis a Futuna', 'Territorio de las Islas Wallis y Futuna', 'Wallis y Futuna', 'Territoriet Wallis- och Futunaöarna', 'Wallis- och Futunaöarna', 'سر زمینِ والس و فتونہ جزائر', 'والس و فتونہ', '瓦利斯和富图纳群岛']
}, {
  common: 'Samoa',
  official: 'Independent State of Samoa',
  cca2: 'WS',
  ccn3: '882',
  cca3: 'WSM',
  cioc: 'SAM',
  alternativeNames: ['Independent State of Samoa', 'Samoa', 'Malo Saʻoloto Tutoʻatasi o Sāmoa', 'Sāmoa', 'Nezávislý stát Samoa', 'Unabhängige Staat Samoa', 'Samoa Iseseisvusriik', 'Samoan itsenäinen valtio', 'Nezavisna Država Samoa', 'Szamoai Független Állam', 'Szamoa', 'Stato indipendente di Samoa', 'サモア独立国', 'サモア', '사모아 독립국', '사모아', 'Onafhankelijke Staat Samoa', 'ایالت مستقل ساموآ', 'ساموآ', 'Niezależne Państwo Samoa', 'Estado Independente de Samoa', 'Независимое Государство Самоа', 'Самоа', 'Nezávislý štátSamoa', 'Estado Independiente de Samoa', 'Självständiga staten Samoa', 'آزاد سلطنتِ ساموا', 'سامووا', '萨摩亚独立国', '萨摩亚']
}, {
  common: 'Yemen',
  official: 'Republic of Yemen',
  cca2: 'YE',
  ccn3: '887',
  cca3: 'YEM',
  cioc: 'YEM',
  isShipmentUnavailable: true,
  alternativeNames: ['Republic of Yemen', 'Yemen', 'الجمهورية اليمنية', 'اليَمَن', 'Jemenská republika', 'Jemen', 'Republik Jemen', 'Jeemeni Vabariik', 'Jeemen', 'Jemenin tasavalta', 'République du Yémen', 'Yémen', 'Republika Jemen', 'Jemeni Köztársaság', 'Repubblica dello Yemen', 'イエメン共和国', 'イエメン', '예멘 공화국', '예멘', 'Republiek Jemen', 'جمهوری یمن', 'یمن', 'Republika Jemeńska', 'República do Iêmen', 'Iémen', 'Йеменская Республика', 'Йемен', 'República de Yemen', 'Republiken Jemen', 'جمہوریہ یمن', '也门共和国', '也门', 'Yemeni Republic', 'al-Jumhūriyyah al-Yamaniyyah']
}, {
  common: 'South Africa',
  official: 'Republic of South Africa',
  cca2: 'ZA',
  ccn3: '710',
  cca3: 'ZAF',
  cioc: 'RSA',
  alternativeNames: ['Republic of South Africa', 'South Africa', 'Republiek van Suid-Afrika', 'IRiphabliki yeSewula Afrika', 'Sewula Afrika', 'Rephaboliki ya Afrika-Borwa ', 'Afrika-Borwa', 'Rephaboliki ya Afrika Borwa', 'Afrika Borwa', 'IRiphabhulikhi yeNingizimu Afrika', 'Ningizimu Afrika', 'Rephaboliki ya Aforika Borwa', 'Aforika Borwa', 'Riphabliki ra Afrika Dzonga', 'Afrika Dzonga', 'Riphabuḽiki ya Afurika Tshipembe', 'Afurika Tshipembe', 'IRiphabliki yaseMzantsi Afrika', 'Mzantsi Afrika', 'IRiphabliki yaseNingizimu Afrika', 'Jihoafrická republika', 'Republik Südafrika', 'Südafrika', 'Lõuna-Aafrika Vabariik', 'Etelä-Afrikan tasavalta', 'Etelä-Afrikka', "République d'Afrique du Sud", 'Afrique du Sud', 'Južnoafrička Republika', 'Dél-afrikai Köztársaság', 'Repubblica del Sud Africa', 'Sud Africa', '南アフリカ共和国', '南アフリカ', '남아프리카 공화국', '남아프리카', 'Republiek Zuid-Afrika', 'Zuid-Afrika', 'جمهوری آفریقای جنوبی', 'آفریقای جنوبی', 'Republika Południowej Afryki', 'Południowa Afryka', 'República da África do Sul', 'África do Sul', 'Южно-Африканская Республика', 'Juhoafrická republika', 'República de Sudáfrica', 'Republiken Sydafrika', 'Sydafrika', 'جمہوریہ جنوبی افریقا', 'جنوبی افریقا', '南非共和国', '南非', 'RSA', 'Suid-Afrika']
}, {
  common: 'Zambia',
  official: 'Republic of Zambia',
  cca2: 'ZM',
  ccn3: '894',
  cca3: 'ZMB',
  cioc: 'ZAM',
  alternativeNames: ['Republic of Zambia', 'Zambia', 'Zambijská republika', 'Zambie', 'Republik Sambia', 'Sambia', 'Sambia Vabariik', 'Sambian tasavalta', 'République de Zambie', 'Republika Zambija', 'Zambija', 'Zambiai Köztársaság', 'Repubblica di Zambia', 'ザンビア共和国', 'ザンビア', '잠비아 공화국', '잠비아', 'Republiek Zambia', 'جمهوری زامبیا', 'زامبیا', 'Republika Zambii', 'República da Zâmbia', 'Zâmbia', 'Республика Замбия', 'Замбия', 'República de Zambia', 'Republiken Zambia', 'جمہوریہ زیمبیا', 'زیمبیا', '赞比亚共和国', '赞比亚']
}, {
  common: 'Zimbabwe',
  official: 'Republic of Zimbabwe',
  cca2: 'ZW',
  ccn3: '716',
  cca3: 'ZWE',
  cioc: 'ZIM',
  alternativeNames: ['Republic of Zimbabwe', 'Zimbabwe', 'Zimbabwská republika', 'Republik Simbabwe', 'Simbabwe', 'Zimbabwe Vabariik', 'Zimbabwen tasavalta', 'République du Zimbabwe', 'Republika Zimbabve', 'Zimbabve', 'Zimbabwei Köztársaság', 'Repubblica dello Zimbabwe', 'ジンバブエ共和国', 'ジンバブエ', '짐바브웨 공화국', '짐바브웨', 'Republiek Zimbabwe', 'جمهوری زیمبابوه', 'زیمبابوه', 'Republika Zimbabwe', 'República do Zimbabwe', 'Республика Зимбабве', 'Зимбабве', 'Zimbabwianska republika', 'República de Zimbabue', 'Zimbabue', 'Republiken Zimbabwe', 'جمہوریہ زمبابوے', 'زمبابوے', '津巴布韦共和国', '津巴布韦']
}];
export const COUNTRIES_INCLUDING_UNAVAILABLE_SHIPMENT = COUNTRIES_LIST_ALL.filter(country => !country.hasUpsError && !country.hasComplemarError && !country.isBlackListed && !country.isShipmentUnavailable);
export const AVAILABLE_COUNTRIES_LIST = COUNTRIES_INCLUDING_UNAVAILABLE_SHIPMENT.filter(({
  isShipmentUnavailable
}) => !isShipmentUnavailable);
export const COUNTRY_KEYS_LIST = AVAILABLE_COUNTRIES_LIST.map(country => country.cca2);
export const COUNTRIES_WITH_RESTRICTED_SHIPMEMT = COUNTRIES_LIST_ALL.filter(({
  isShipmentUnavailable
}) => isShipmentUnavailable);
export const COMPLEMAR_COUNTRIES_MAP_REPLACER = {
  ME: 'RS'
};
const RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM = '12345';
export const WITHOUT_STATES_COUNTRY_ZIP_CODES = {
  MT: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  ZM: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  GN: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  SS: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  CU: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  PY: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  OM: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  MW: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  SO: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  SD: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  TK: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  NU: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  NR: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  KE: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  KP: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  TF: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  TL: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  ME: '633001',
  AF: '1001',
  AL: '443001',
  AO: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  AI: 'AI-2640',
  AG: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  AW: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  BS: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  BB: 'BB25067',
  BZ: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  BJ: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  BT: '11002',
  BO: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  BW: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  VG: 'VG1110',
  BF: 'BOBO-DIOULASSO 01',
  BI: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  KH: '12100',
  CM: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  KY: 'KY1-1001',
  CF: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  TD: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  KM: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  CG: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  CK: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  CD: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  DJ: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  DM: '00109-8000',
  GQ: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  ER: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  FO: '100',
  FJ: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  PF: '98735',
  GA: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  GM: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  GH: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  GI: 'GX11 1AA',
  GL: '3900',
  GD: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  GY: '413741',
  HK: '999077',
  CI: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  JM: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  KI: 'KI0101',
  LS: '100',
  LR: '50101',
  LY: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  MO: '999078',
  ML: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  MR: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  MU: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  MS: 'MSR1120',
  NA: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  NI: '12005',
  NF: '2899',
  PW: 'PW96940',
  PA: '0801',
  QA: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  RW: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  WS: '95564',
  SM: '47890',
  ST: '59400-000',
  SA: '11564',
  SC: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  SL: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  SB: '20688',
  KN: 'KN0101',
  LC: 'LC03 101',
  VC: 'VC0400',
  SR: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  TZ: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  TG: '55788',
  TO: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  TT: '170505',
  TC: 'TKCA 1ZZ',
  TV: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  UG: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  AE: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  VU: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM,
  VA: '00120',
  YE: '37970',
  ZW: RANDOM_ZIP_CODE_FOR_COUNTRY_WITHOUT_POSTAL_CODES_SYSTEM
};
export const getCountryUnavailableForShipmentValidationMessage = (country = 'this country') => `
  "country": sorry, shipment to ${country} is currently unavailable
`;