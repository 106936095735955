import _omit from "lodash/omit";
import _cloneDeep from "lodash/cloneDeep";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { OrderApi } from 'swag-client-common/api/order.api';
import { ResellerApi } from 'swag-client-common/api/reseller.api';
import { BOX_QUANTITY_EDITOR_POPUP, BUILD_A_CART_CREATE_LINK_POPUP, CART_ADD_ITEM_ERROR_TYPE, CART_ORDER_VERSION_ERROR_TYPE, COMMENT_PREBUILT_LIMITED_CART_SUCCESS_POPUP, CONFIRM_CHANGE_SHIPPING_POPUP, CONFIRM_TRANSFORM_SUPER_SPEED_ITEMS_POPUP, REGULAR_QUANTITY_EDITOR_POPUP, REGULAR_SIZE_POPUP, SHARE_MOCKUP_DESIGN_DRAWER, SHOW_ERROR_POPUP, SIZE_EDITOR_POPUP } from 'swag-client-common/constants';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { createAsyncAction, emptyAction, errorAction, successAction } from 'swag-client-common/libs/redux-helpers';
import { showPopup, hidePopup, setPopupParams } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { getCartLinkId } from 'swag-client-common/libs/url-modifier';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { adminModeSelector, loggedInUserIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { getIsOrderTokenShouldBeRemoved, getRecentlyViewedFromLS } from 'swag-client-common/utils/local-storage.util';
import { getTotalCount } from 'swag-client-common/utils/product.utils';
import { isKSPlan } from 'swag-client-common/utils/tricks';
import { AdminMode } from 'swag-common/constants/admin.constants';
import { CartErrorCodes } from 'swag-common/constants/error-codes';
import { OrderStorageTypes, UPSELL_WIDGET_STATES } from 'swag-common/constants/main-swag.constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import prepareItemToShare from 'swag-common/utils/order/prepare-item-to-share.util';
import { v1 } from 'uuid';
import { isSuperSpeedProductionOption } from 'swag-common/utils/product/product.utils';
import RecentlyViewedApi from '../../../customer/api/recently-viewed.api';
import { allItemsInCartAndDraftSelector, cartItemsCountSelector, cartItemsUpsellDismissedStatesSelector, draftItemsSelector, draftVersionSelector, flatDraftItemsSelector, orderVersionSelector } from '../../../customer/redux/cart/cart.selectors';
import { getDefaultProductionTimeIdx } from '../../../customer/redux/product/product.utils';
import { showDebossedPopupOnItemEditAction } from '../../../customer/redux/debossed-popup/debossed-popup.actions';
import { showLaserEngravedPopupOnItemEditAction } from '../../../customer/redux/laser-engraved-popup/laser-engraved-popup.actions';
import { resetLogosPaginationAction } from '../company-art-folder/company-art-folder.actions';
import { userIdSelector } from '../user/user.selectors';
import { cartItemsSelector, draftTokenSelector, orderTokenSelector } from './cart.selectors';
import { addDraftToken, addOrderAndDraftTokens, addOrderToken, changeServiceCode, checkIsSharedItemExistsOnlyInBox, checkIsSharedItemWasChangedAcrossCartAndDraft, formatCart, formatCartEditItems, formatCartRemoveItem, formatDraftRemoveItem, getEditedItemWithProd, getValuesToEditForSharedItems, removeRecentlyViewedForGuests, transformDataForMoveItemsBetweenOrders } from './cart.utils';
import { handleCartUnavailableError } from './error-handlers';
import { DIRECTIONS, getItemsWithCorrectBlendedQuantity } from './move-item-between-cart-and-draft';
const PREFIX = 'CART_';
const CART_INDENT = 250;
const ITEM_COUNT_FOR_SCROLL = 5;
const handleCartPostErrorPopup = (dispatch, getState, error) => {
  dispatch(hidePopup());
  if (error.status === 401) {
    return;
  }
  switch (error.code) {
    case CartErrorCodes.INVALID_ORDER_VERSION:
      dispatch(showPopup({
        popup: SHOW_ERROR_POPUP,
        type: CART_ORDER_VERSION_ERROR_TYPE,
        errorMessage: error.message
      }));
      break;
    case CartErrorCodes.SUPER_SPEED_DISABLED:
      dispatch(showPopup({
        popup: CONFIRM_TRANSFORM_SUPER_SPEED_ITEMS_POPUP
      }));
      break;
    default:
      dispatch(showPopup({
        popup: SHOW_ERROR_POPUP,
        type: CART_ADD_ITEM_ERROR_TYPE,
        errorMessage: error.message
      }));
      break;
  }
};
export const ERROR = `${PREFIX}ERROR`;
export const CART_LOADED = `${PREFIX}CART_LOADED`;
export const GET_CART = `${PREFIX}GET_CART`;
export const GET_CART_REQUEST = `${PREFIX}GET_CART_REQUEST`;
export const getCart = createAsyncAction({
  transformData: addOrderAndDraftTokens,
  preFn: (dispatch, getState) => {
    const {
      cart,
      user
    } = getState();
    const cartLinkId = getCartLinkId();
    if (cart.orderToken === null && !user.email && !cartLinkId) {
      dispatch({
        type: CART_LOADED
      });
      return true;
    }
    dispatch({
      type: GET_CART_REQUEST
    });
  },
  fn: OrderApi.getCart,
  success: body => ({
    type: GET_CART,
    body
  }),
  error: errorAction(ERROR),
  postError: handleCartUnavailableError
});
export const GET_DRAFT_REQUEST = `${PREFIX}GET_DRAFT_REQUEST`;
export const GET_DRAFT_SUCCESS = `${PREFIX}GET_DRAFT_SUCCESS`;
export const GET_DRAFT_FAILURE = `${PREFIX}GET_DRAFT_FAILURE`;
export const getDraft = createAsyncAction({
  transformData: addOrderAndDraftTokens,
  preFn: (dispatch, getState) => {
    const {
      cart,
      user
    } = getState();
    if (cart.draftToken === null && !user.email) {
      dispatch({
        type: CART_LOADED
      });
      return true;
    }
    dispatch({
      type: GET_DRAFT_REQUEST
    });
  },
  fn: OrderApi.getDraft,
  success: ({
    draftItems,
    draftToken,
    supplierInventoryLevels,
    suppliers,
    orderVersion,
    upsellDismissedStates
  }) => ({
    type: GET_DRAFT_SUCCESS,
    payload: {
      draftItems,
      draftToken,
      supplierInventoryLevels,
      suppliers,
      draftVersion: orderVersion,
      upsellDismissedStates
    }
  }),
  error: errorAction(GET_DRAFT_FAILURE)
});
export const CLEAR_CART = `${PREFIX}CLEAR_CART`;
export const getCartInitial = () => (dispatch, getState) => {
  const isOrderTokenShouldBeRemoved = getIsOrderTokenShouldBeRemoved();
  try {
    if (isOrderTokenShouldBeRemoved) {
      dispatch({
        type: CLEAR_CART
      });
    }
    const {
      orderToken,
      draftToken
    } = getState().cart;
    dispatch(getCart({
      orderToken
    }));
    dispatch(getDraft({
      draftToken
    }));
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
};
export const getCartWithoutDraft = () => (dispatch, getState) => {
  const isOrderTokenShouldBeRemoved = getIsOrderTokenShouldBeRemoved();
  try {
    if (isOrderTokenShouldBeRemoved) {
      dispatch({
        type: CLEAR_CART
      });
    }
    const {
      orderToken
    } = getState().cart;
    dispatch(getCart({
      orderToken
    }));
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
};
export const PROMOCODE_APPLIED_SUCCESS = 'PROMOCODE_APPLIED_SUCCESS';
export const PROMOCODE_APPLIED_REQUEST = 'PROMOCODE_APPLIED_REQUEST';
export const PROMOCODE_APPLIED_ERROR = 'PROMOCODE_APPLIED_ERROR';
export const applyPromocode = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: PROMOCODE_APPLIED_REQUEST
    });
  },
  fn: OrderApi.applyPromocode,
  success: response => _objectSpread({
    type: PROMOCODE_APPLIED_SUCCESS
  }, response.order),
  error: errorAction(PROMOCODE_APPLIED_ERROR),
  postSuccess: (dispatch, getState) => {
    const {
      plan
    } = getState().cart;
    if (isKSPlan(plan)) {
      dispatch(showPopup({
        popup: 'kickstarterShipping'
      }));
    }
  }
});
export const REMOVE_PROMOCODE_REQUEST = `${PREFIX}REMOVE_PROMOCODE_REQUEST`;
const removePromocodeRequest = emptyAction(REMOVE_PROMOCODE_REQUEST);
export const REMOVE_PROMOCODE_FAIL = `${PREFIX}REMOVE_PROMOCODE_FAIL`;
export const REMOVE_PROMOCODE_SUCCESS = `${PREFIX}REMOVE_PROMOCODE_SUCCESS`;
export const removePromocode = createAsyncAction({
  preFn: dispatch => {
    dispatch(removePromocodeRequest());
  },
  fn: OrderApi.removePromocode,
  success: successAction(REMOVE_PROMOCODE_SUCCESS),
  error: errorAction(REMOVE_PROMOCODE_FAIL)
});
export const ADD_RELATED_ITEMS_TO_CART = `${PREFIX}ADD_RELATED_ITEMS_TO_CART`;
export const ADD_ITEM_TO_CART_REQUEST = `${PREFIX}ADD_ITEM_TO_CART_REQUEST`;
export const ADD_ITEM_TO_CART = `${PREFIX}ADD_ITEM_TO_CART`;
export const ADD_ITEM_TO_CART_ERROR = `${PREFIX}ADD_ITEM_TO_CART_ERROR`;
export const addItemsToCart = createAsyncAction({
  transformData: (data, getState) => _objectSpread({}, addOrderToken(data, getState), {
    userId: loggedInUserIdSelector(getState())
  }),
  preFn: dispatch => dispatch({
    type: ADD_ITEM_TO_CART_REQUEST
  }),
  fn: OrderApi.addItemToCart,
  success: (body, data) => ({
    type: ADD_ITEM_TO_CART,
    body: _objectSpread({}, body, {
      addedItems: data.items
    })
  }),
  postSuccess: (dispatch, getState, __, res) => {
    browserHistory.push(APP_ROUTES.CART);
    dispatch(toggleCartBadgeLoader(true));
    dispatch(toggleCartBadgeLoader(false));
    dispatch(resetLogosPaginationAction());
    removeRecentlyViewedForGuests(getState, res);
  },
  error: errorAction(ADD_ITEM_TO_CART_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: CART_ADD_ITEM_ERROR_TYPE,
      errorMessage: error.message
    }));
  }
});
export const ADD_ITEM_TO_DRAFT_REQUEST = `${PREFIX}ADD_ITEM_TO_DRAFT_REQUEST`;
export const ADD_ITEM_TO_DRAFT_SUCCESS = `${PREFIX}ADD_ITEM_TO_DRAFT_SUCCESS`;
export const ADD_ITEM_TO_DRAFT_ERROR = `${PREFIX}ADD_ITEM_TO_DRAFT_ERROR`;
export const addItemToDraft = createAsyncAction({
  transformData: addDraftToken,
  preFn: dispatch => dispatch({
    type: ADD_ITEM_TO_DRAFT_REQUEST
  }),
  fn: OrderApi.addItemToDraft,
  success: ({
    draftItems,
    draftToken
  }) => ({
    type: ADD_ITEM_TO_DRAFT_SUCCESS,
    payload: {
      draftItems,
      draftToken
    }
  }),
  postSuccess: (_, getState, __, res) => {
    browserHistory.push(APP_ROUTES.CART);
    removeRecentlyViewedForGuests(getState, res);
  },
  error: errorAction(ADD_ITEM_TO_DRAFT_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: CART_ADD_ITEM_ERROR_TYPE,
      errorMessage: error.message
    }));
  }
});
export const addRelatedItemToCart = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: ADD_ITEM_TO_CART_REQUEST
    });
  },
  fn: OrderApi.addItemToCart,
  success: body => ({
    type: ADD_ITEM_TO_CART,
    body
  }),
  postSuccess: (_, getState, __, res) => {
    removeRecentlyViewedForGuests(getState, res);
  },
  error: errorAction(ADD_ITEM_TO_CART_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: CART_ADD_ITEM_ERROR_TYPE,
      errorMessage: error.message
    }));
  }
});
const getItemsToRebuild = (cart, storageType) => {
  switch (storageType) {
    case OrderStorageTypes.DRAFT:
      {
        return cart.draftItems;
      }
    default:
      return cart.items;
  }
};
export const REBUILDING_ITEM_REQUESTED = `${PREFIX}REBUILDING_ITEM_REQUESTED`;
export const rebuildItem = (item, storageType) => (dispatch, getState) => {
  browserHistory.push(`${APP_ROUTES.PRODUCT}/${item.prod.seoSlug}`);
  const state = getState();
  const {
    cart
  } = state;
  const items = getItemsToRebuild(cart, storageType);
  const cartItemsUpsellDismissedStates = cartItemsUpsellDismissedStatesSelector(state);
  if (item.asRelatedItemId && !item.boxItemsId) {
    const relatedItems = items.filter(i => {
      const isRelated = item.asRelatedItemId === i.asRelatedItemId && item._id !== i._id && !i.splitShippedType;
      const isSharedExistsOnlyInBox = checkIsSharedItemExistsOnlyInBox(item, i, items);
      return isRelated && !i.boxItemsId || isSharedExistsOnlyInBox;
    });
    return dispatch({
      type: REBUILDING_ITEM_REQUESTED,
      payload: {
        item,
        storageType,
        relatedItems,
        upsellDismissedStates: cartItemsUpsellDismissedStates[item._id]
      }
    });
  }
  const itemToRebuild = _objectSpread({}, item);
  if (item.prod.designUploadMode === DesignUploadMode.box) {
    itemToRebuild.products = items.filter(i => i.boxItemsId === item.boxItemsId && item._id !== i._id).map(i => {
      return _objectSpread({}, i, {
        prod: _objectSpread({}, i.prod, {
          quantity: i.quantityPerBox
        })
      });
    });
  }
  return dispatch({
    type: REBUILDING_ITEM_REQUESTED,
    payload: {
      item: itemToRebuild,
      storageType,
      upsellDismissedStates: cartItemsUpsellDismissedStates[item._id]
    }
  });
};
export const editShipping = ({
  item,
  isSideShippingChange = false
}) => (dispatch, getState) => {
  const {
    cart,
    checkout
  } = getState();
  const {
    items
  } = cart;
  const {
    deliverToFulfillmentCenter
  } = checkout;
  const {
    prodId,
    asRelatedItemId
  } = item;
  const sharedItems = items.filter(el => el.asRelatedItemId === asRelatedItemId && el.prodId === prodId);
  if (deliverToFulfillmentCenter && sharedItems.length > 1) {
    const itemsToUpdate = sharedItems.map(el => _objectSpread({}, el, {
      serviceCode: item.serviceCode
    }));
    const {
      prod
    } = item;
    if (!isSideShippingChange) {
      dispatch(showPopup({
        popup: CONFIRM_CHANGE_SHIPPING_POPUP,
        productName: prod === null || prod === void 0 ? void 0 : prod.name,
        itemsToUpdate
      }));
    }
  } else {
    dispatch(editItemInCart({
      items: [item],
      editedItem: item
    }));
  }
};
export const EDIT_ITEM_IN_CART_REQUEST = `${PREFIX}EDIT_ITEM_IN_CART_REQUEST`;
export const editItemInCartRequest = emptyAction(EDIT_ITEM_IN_CART_REQUEST);
export const EDIT_ITEM_IN_CART_SUCCESS = `${PREFIX}EDIT_ITEM_IN_CART_SUCCESS`;
export const EDIT_ITEM_IN_CART_FAIL = `${PREFIX}EDIT_ITEM_IN_CART_FAIL`;
export const editItemInCart = createAsyncAction({
  transformData: formatCartEditItems,
  preFn: dispatch => {
    dispatch(editItemInCartRequest());
  },
  fn: OrderApi.updateCart,
  success: (res, {
    recalculateOrder
  }) => ({
    type: EDIT_ITEM_IN_CART_SUCCESS,
    body: _objectSpread({}, res.cart, {
      recalculateOrder
    })
  }),
  postSuccess: (dispatch, _, {
    showDebossedPopup,
    showLaserEngravedPopup,
    itemId
  }) => {
    dispatch(hidePopup());
    if (showDebossedPopup) {
      dispatch(showDebossedPopupOnItemEditAction(itemId, true));
    }
    if (showLaserEngravedPopup) {
      dispatch(showLaserEngravedPopupOnItemEditAction(itemId, true));
    }
  },
  error: errorAction(EDIT_ITEM_IN_CART_FAIL),
  postError: handleCartPostErrorPopup
});
export const EDIT_ITEM_IN_DRAFT_REQUEST = `${PREFIX}EDIT_ITEM_IN_DRAFT_REQUEST`;
export const EDIT_ITEM_IN_DRAFT_SUCCESS = `${PREFIX}EDIT_ITEM_IN_DRAFT_SUCCESS`;
export const EDIT_ITEM_IN_DRAFT_FAIL = `${PREFIX}EDIT_ITEM_DRAFT_FAIL`;
export const editItemInDraft = createAsyncAction({
  transformData: transformDataForEditItemInDraft,
  preFn: dispatch => {
    dispatch({
      type: EDIT_ITEM_IN_DRAFT_REQUEST
    });
  },
  fn: OrderApi.updateCart,
  success: ({
    draft,
    draftToken
  }) => ({
    type: EDIT_ITEM_IN_DRAFT_SUCCESS,
    payload: {
      draftItems: draft === null || draft === void 0 ? void 0 : draft.items,
      draftToken,
      draftVersion: draft === null || draft === void 0 ? void 0 : draft.orderVersion
    }
  }),
  postSuccess: (dispatch, _, {
    showDebossedPopup,
    showLaserEngravedPopup,
    itemId
  }) => {
    dispatch(hidePopup());
    if (showDebossedPopup) {
      dispatch(showDebossedPopupOnItemEditAction(itemId, true));
    }
    if (showLaserEngravedPopup) {
      dispatch(showLaserEngravedPopupOnItemEditAction(itemId, true));
    }
  },
  error: errorAction(EDIT_ITEM_IN_DRAFT_FAIL),
  postError: handleCartPostErrorPopup
});
export const MOVE_ITEM_TO_DRAFT_REQUEST = `${PREFIX}MOVE_ITEM_TO_DRAFT_REQUEST`;
export const MOVE_ITEM_TO_DRAFT_SUCCESS = `${PREFIX}MOVE_ITEM_TO_DRAFT_SUCCESS`;
export const MOVE_ITEM_TO_DRAFT_FAIL = `${PREFIX}MOVE_ITEM_TO_DRAFT_FAIL`;
export const moveItemToDraft = createAsyncAction({
  transformData: transformDataForMoveItemsBetweenOrders(DIRECTIONS.FROM_CART_TO_DRAFT),
  preFn: dispatch => {
    dispatch({
      type: MOVE_ITEM_TO_DRAFT_REQUEST
    });
  },
  fn: OrderApi.updateCart,
  success: ({
    cart,
    draft,
    orderToken,
    draftToken
  }) => ({
    type: MOVE_ITEM_TO_DRAFT_SUCCESS,
    payload: {
      cart,
      draft,
      orderToken,
      draftToken
    }
  }),
  postSuccess: (dispatch, getState) => {
    var _document$getElementB, _cart$height;
    const count = cartItemsCountSelector(getState());
    if (count < ITEM_COUNT_FOR_SCROLL) {
      return true;
    }
    const cart = (_document$getElementB = document.getElementById('cart')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.getBoundingClientRect();
    window.scrollTo({
      top: ((_cart$height = cart === null || cart === void 0 ? void 0 : cart.height) !== null && _cart$height !== void 0 ? _cart$height : 0) + CART_INDENT,
      left: 100,
      behavior: 'smooth'
    });
  },
  error: errorAction(MOVE_ITEM_TO_DRAFT_FAIL),
  postError: handleCartPostErrorPopup
});
export const MOVE_ITEM_TO_CART_REQUEST = `${PREFIX}MOVE_ITEM_TO_CART_REQUEST`;
export const MOVE_ITEM_TO_CART_SUCCESS = `${PREFIX}MOVE_ITEM_TO_CART_SUCCESS`;
export const MOVE_ITEM_TO_CART_FAIL = `${PREFIX}MOVE_ITEM_TO_CART_FAIL`;
export const moveItemToCart = createAsyncAction({
  transformData: transformDataForMoveItemsBetweenOrders(DIRECTIONS.FROM_DRAFT_TO_CART),
  preFn: dispatch => {
    dispatch({
      type: MOVE_ITEM_TO_CART_REQUEST
    });
  },
  fn: OrderApi.updateCart,
  success: ({
    cart,
    draft,
    orderToken,
    draftToken
  }) => ({
    type: MOVE_ITEM_TO_CART_SUCCESS,
    payload: {
      cart,
      draft,
      orderToken,
      draftToken
    }
  }),
  error: errorAction(MOVE_ITEM_TO_CART_FAIL),
  postError: handleCartPostErrorPopup
});
export const REMOVE_ITEM_FROM_DRAFT_REQUEST = `${PREFIX}REMOVE_ITEM_FROM_DRAFT_REQUEST`;
export const REMOVE_ITEM_FROM_DRAFT_SUCCESS = `${PREFIX}REMOVE_ITEM_FROM_DRAFT_SUCCESS`;
export const REMOVE_ITEM_FROM_DRAFT_FAIL = `${PREFIX}REMOVE_ITEM_FROM_DRAFT_FAIL`;
export const removeItemFromDraft = createAsyncAction({
  transformData: formatDraftRemoveItem,
  preFn: dispatch => {
    dispatch({
      type: REMOVE_ITEM_FROM_DRAFT_REQUEST
    });
  },
  fn: OrderApi.updateCart,
  success: ({
    draft,
    draftToken
  }, transformedData, dispatch, {
    itemIdFromReorder
  }) => ({
    type: REMOVE_ITEM_FROM_DRAFT_SUCCESS,
    payload: {
      draftItems: draft.items,
      draftToken,
      itemIdFromReorder,
      draftVersion: draft.orderVersion
    }
  }),
  error: errorAction(REMOVE_ITEM_FROM_DRAFT_FAIL),
  postError: handleCartPostErrorPopup
});
const editItemQuantity = createAsyncAction({
  transformData: formatCartEditItems,
  preFn: dispatch => {
    dispatch(editItemInCartRequest());
  },
  fn: OrderApi.updateCart,
  success: (res, {
    recalculateOrder
  }) => ({
    type: EDIT_ITEM_IN_CART_SUCCESS,
    body: _objectSpread({}, res.cart, {
      recalculateOrder
    })
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
  },
  error: errorAction(EDIT_ITEM_IN_CART_FAIL),
  postError: handleCartPostErrorPopup
});
export const UPDATE_UPSELL_WIDGET_STATES = `${PREFIX}UPDATE_UPSELL_WIDGET_STATES`;
const UPDATE_UPSELL_WIDGET_STATES_ERROR = `${PREFIX}UPDATE_UPSELL_WIDGET_STATES_ERROR`;
export const editItemUpsellWidgetState = createAsyncAction({
  transformData: addOrderToken,
  fn: OrderApi.editItemUpsellWidgetState,
  success: (res, data) => {
    const payload = {
      itemId: data.itemId
    };
    if (data.upsellDismissedStateCheckout) {
      payload[UPSELL_WIDGET_STATES.CHECKOUT] = data.upsellDismissedStateCheckout;
    }
    if (data.upsellDismissedStateQuantityChanged) {
      payload[UPSELL_WIDGET_STATES.IS_QUANTITY_CHANGED] = data.upsellDismissedStateQuantityChanged;
    }
    return {
      type: UPDATE_UPSELL_WIDGET_STATES,
      payload
    };
  },
  error: errorAction(UPDATE_UPSELL_WIDGET_STATES_ERROR)
});
const editDraftItemQuantity = createAsyncAction({
  transformData: transformEditDraftItemQuantity,
  preFn: dispatch => {
    dispatch(editItemInCartRequest());
  },
  fn: OrderApi.updateCart,
  success: ({
    draft,
    draftToken
  }) => ({
    type: EDIT_ITEM_IN_DRAFT_SUCCESS,
    payload: {
      draftItems: draft === null || draft === void 0 ? void 0 : draft.items,
      draftToken,
      draftVersion: draft === null || draft === void 0 ? void 0 : draft.orderVersion
    }
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
  },
  error: errorAction(EDIT_ITEM_IN_DRAFT_FAIL),
  postError: handleCartPostErrorPopup
});
export const getEditDraftPayload = (cart, data) => {
  if (cart.storageType === OrderStorageTypes.DRAFT) {
    return _objectSpread({}, data, {
      draftToken: cart.draftToken
    });
  }
  return _objectSpread({}, data, {
    orderToken: cart.orderToken
  });
};
export const editProductionTimeInCart = ({
  prodTime,
  isOnCheckout,
  embellishmentMethodId,
  showDebossedPopup,
  showLaserEngravedPopup
}) => {
  return (dispatch, getState) => {
    var _item$allProductSetti;
    const state = getState();
    const {
      cart,
      cart: {
        storageType
      }
    } = state;
    const item = _objectSpread({}, cart.selectedItem);
    let newServiceCode = item.serviceCode;
    const newProductSettings = (_item$allProductSetti = item.allProductSettings) === null || _item$allProductSetti === void 0 ? void 0 : _item$allProductSetti.find(setting => String(setting.embellishmentMethodId) === embellishmentMethodId);
    const isDraft = storageType === OrderStorageTypes.DRAFT;
    const action = isDraft ? editItemInDraft : editItemInCart;
    delete item.oldSetupFee;
    if (isOnCheckout) {
      const isChangedToSuperSpeedProdTime = isSuperSpeedProductionOption(newProductSettings === null || newProductSettings === void 0 ? void 0 : newProductSettings.productionTimeList[prodTime]);
      newServiceCode = changeServiceCode(isChangedToSuperSpeedProdTime, item, newServiceCode);
    }
    const originalCartItem = _cloneDeep(cart.selectedItem);
    const isEmbellishmentMethodUpdated = originalCartItem.embellishmentMethod !== embellishmentMethodId;
    if (originalCartItem.isReorder && isEmbellishmentMethodUpdated) {
      item.isReorder = false;
      item.reorderId = null;
      item.setupFeeDeductionDiscount = 0;
    }
    const editedItem = _objectSpread({}, item, {
      prodTime,
      serviceCode: newServiceCode,
      embellishmentMethod: embellishmentMethodId
    });
    const itemsToUpdate = [editedItem];
    const payload = getEditDraftPayload(cart, {
      items: itemsToUpdate,
      editedItemId: editedItem._id,
      showDebossedPopup,
      showLaserEngravedPopup
    });
    dispatch(action(payload));
  };
};
export const REPLACE_ITEM_SUCCESS = `${PREFIX}REPLACE_ITEM_SUCCESS`;
export const replaceItem = createAsyncAction({
  transformData: formatCartEditItems,
  preFn: dispatch => {
    dispatch(editItemInCartRequest());
  },
  fn: OrderApi.updateCart,
  success: res => ({
    type: REPLACE_ITEM_SUCCESS,
    body: _objectSpread({}, res.cart)
  }),
  postSuccess: () => {
    browserHistory.push(APP_ROUTES.CART);
  },
  error: errorAction(ERROR),
  postError: handleCartPostErrorPopup
});
export const replaceItemInDraft = createAsyncAction({
  transformData: transformDataForEditItemInDraft,
  preFn: dispatch => {
    dispatch(editItemInCartRequest());
  },
  fn: OrderApi.updateCart,
  success: ({
    draft,
    draftToken
  }) => ({
    type: EDIT_ITEM_IN_DRAFT_SUCCESS,
    payload: {
      draftItems: draft === null || draft === void 0 ? void 0 : draft.items,
      draftToken,
      draftVersion: draft === null || draft === void 0 ? void 0 : draft.orderVersion
    }
  }),
  postSuccess: () => {
    browserHistory.push(APP_ROUTES.CART);
  },
  error: errorAction(ERROR),
  postError: handleCartPostErrorPopup
});
export const REMOVE_ITEM_FROM_CART_REQUEST = `${PREFIX}REMOVE_ITEM_FROM_CART_REQUEST`;
export const REMOVE_ITEM_FROM_CART_SUCCESS = `${PREFIX}REMOVE_ITEM_FROM_CART_SUCCESS`;
export const REMOVE_ITEM_FROM_CART_FAIL = `${PREFIX}REMOVE_ITEM_FROM_CART_FAIL`;
export const removeItemFromCart = createAsyncAction({
  transformData: formatCartRemoveItem,
  preFn: (dispatch, getState, data) => {
    dispatch({
      type: REMOVE_ITEM_FROM_CART_REQUEST,
      payload: data
    });
  },
  fn: OrderApi.updateCart,
  success: (res, _, __, data, getState) => {
    let deletedItemIndex = 0;
    const cartItems = cartItemsSelector(getState());
    const deletedItem = cartItems.find((item, itemIndex) => {
      deletedItemIndex = itemIndex;
      return item._id === data.id;
    });
    return {
      type: REMOVE_ITEM_FROM_CART_SUCCESS,
      body: _objectSpread({}, res.cart, {
        deletedItem,
        deletedItemIndex
      })
    };
  },
  error: errorAction(REMOVE_ITEM_FROM_CART_FAIL),
  postError: handleCartPostErrorPopup
});
export const applyUserPlan = createAsyncAction({
  transformData: addOrderToken,
  fn: OrderApi.applyUserPlan,
  success: () => ({
    type: 'none'
  }),
  error: () => ({
    type: 'none'
  })
});
export const CHECKOUT_STARTED = `${PREFIX}CHECKOUT_STARTED`;
export const checkoutStarted = () => ({
  type: CHECKOUT_STARTED
});
export const CHANGE_SIZE_INDEX_IN_ITEM = `${PREFIX}CHANGE_SIZE_INDEX_IN_ITEM`;
export const changeSizeIndex = ({
  itemId,
  sizeIndex
}) => ({
  type: CHANGE_SIZE_INDEX_IN_ITEM,
  payload: {
    itemId,
    sizeIndex
  }
});
export const openEditSize = ({
  itemId,
  storageType
}) => (dispatch, getState) => {
  const state = getState();
  const items = getItemsToRebuild(state.cart, storageType);
  const item = items.find(item => item._id === itemId);
  const {
    boxItemsId,
    isMainItem
  } = item;
  const popup = boxItemsId && isMainItem ? SIZE_EDITOR_POPUP : REGULAR_SIZE_POPUP;
  dispatch(showPopup({
    popup,
    itemId,
    storageType
  }));
};
export const SELECT_SUGGESTED_PRODUCT = `${PREFIX}SELECT_SUGGESTED_PRODUCT`;
export const selectSuggestedProduct = selectedSuggestedProduct => ({
  type: SELECT_SUGGESTED_PRODUCT,
  payload: {
    selectedSuggestedProduct
  }
});
export const startSharing = item => dispatch => {
  const updatedItem = _objectSpread({}, prepareItemToShare(item), {
    textImages: {}
  });
  const productName = item.prod.name;
  dispatch(showPopup({
    popup: SHARE_MOCKUP_DESIGN_DRAWER,
    payload: {
      from: 'cart',
      productName: [productName],
      itemsToShare: [updatedItem]
    }
  }));
};
export const SELECT_ITEM = `${PREFIX}SELECT_ITEM`;
export const selectItem = item => ({
  type: SELECT_ITEM,
  payload: {
    item
  }
});
export const SET_ITEM_ID = `${PREFIX}SET_ITEM_ID`;
export const setItemToEdit = itemToEdit => ({
  type: SET_ITEM_ID,
  payload: {
    itemToEdit
  }
});
export const openEditQuantity = ({
  itemId,
  storageType,
  recalculateOrder = false
}) => (dispatch, getState) => {
  const {
    cart
  } = getState();
  const items = getItemsToRebuild(cart, storageType);
  const {
    boxItemsId
  } = items.find(item => item._id === itemId);
  const popup = boxItemsId ? BOX_QUANTITY_EDITOR_POPUP : REGULAR_QUANTITY_EDITOR_POPUP;
  dispatch(showPopup({
    popup,
    itemId,
    storageType,
    recalculateOrder
  }));
};
export const editQuantityOfItem = params => (dispatch, getState) => {
  const {
    item,
    recalculateOrder
  } = params;
  const state = getState();
  const {
    cart,
    cart: {
      storageType
    }
  } = state;
  const action = storageType === OrderStorageTypes.DRAFT ? editDraftItemQuantity : editItemQuantity;
  if (item.asRelatedItemId && !item.boxItemsId) {
    const items = getItemsToRebuild(cart, storageType);
    const relatedItems = items.filter(i => i.asRelatedItemId === item.asRelatedItemId);
    const index = relatedItems.findIndex(i => i._id === item._id);
    const prevQuantity = relatedItems[index].quantity;
    const newQuantity = item.quantity;

    // eslint-disable-next-line no-unused-vars
    const updatedItems = getItemsWithCorrectBlendedQuantity(items.map(i => i._id === item._id ? item : i));
    const data = {
      items: updatedItems,
      recalculateOrder
    };
    const cartItemsUpsellDismissedStates = cartItemsUpsellDismissedStatesSelector(state);
    const dismissedStates = cartItemsUpsellDismissedStates[item._id];
    if (prevQuantity !== newQuantity && dismissedStates !== null && dismissedStates !== void 0 && dismissedStates[UPSELL_WIDGET_STATES.PRODUCT_BUILDER]) {
      dispatch(editItemUpsellWidgetState({
        itemId: item._id,
        upsellDismissedStateQuantityChanged: true
      }));
    }
    return dispatch(action(data));
  }
  if (item.boxItemsId) {
    const updatedItems = [...item.products];
    delete item.products;
    const data = {
      items: [item, ...updatedItems].map(itemData => _omit(itemData, 'shipmentCharges')),
      recalculateOrder
    };
    return dispatch(action(data));
  }
  delete item.products;
  dispatch(action({
    items: [item],
    recalculateOrder
  }));
};
export const editSizeOfItem = ({
  item
}) => (dispatch, getState) => {
  const {
    storageType
  } = getState().cart;
  const action = storageType === OrderStorageTypes.DRAFT ? editItemInDraft : editItemInCart;
  dispatch(action({
    items: [item],
    editedItemId: item._id
  }));
};
export const editSampleSizeOfItem = ({
  items
}) => (dispatch, getState) => {
  const {
    storageType
  } = getState().cart;
  if (storageType === OrderStorageTypes.DRAFT) {
    return dispatch(editItemInDraft({
      items
    }));
  }
  return dispatch(editItemInCart({
    items
  }));
};
export const UPDATE_BOX_VALIDATIONS = `${PREFIX}UPDATE_BOX_VALIDATIONS`;
export const updateBoxValidations = data => ({
  type: UPDATE_BOX_VALIDATIONS,
  payload: {
    data
  }
});
export const RESET_BOX_VALIDATIONS = `${PREFIX}RESET_BOX_VALIDATIONS`;
export const resetBoxValidations = () => ({
  type: RESET_BOX_VALIDATIONS
});
export const SET_INITIAL_DATA = `${PREFIX}SET_INITIAL_DATA`;
export const setInitialData = () => ({
  type: SET_INITIAL_DATA
});
export const TOGGLE_CART_BADGE_LOADER = `${PREFIX}TOGGLE_CART_BADGE_LOADER`;
export const toggleCartBadgeLoader = payload => ({
  type: TOGGLE_CART_BADGE_LOADER,
  payload
});
function transformDataForEditItemInDraft({
  draftToken: token,
  items = [],
  deletedOrderItems = {},
  editedItemId,
  isLogoDimensionsModified
}, getState) {
  const state = getState();
  const oldDraftItems = flatDraftItemsSelector(state);
  const draftToken = token !== null && token !== void 0 ? token : draftTokenSelector(state);
  const allItems = allItemsInCartAndDraftSelector(state);
  const orderVersion = orderVersionSelector(state);
  const draftVersion = draftVersionSelector(state);
  const editedItem = getEditedItemWithProd(items, allItems, editedItemId);
  const isSharedItemWasChangedAcrossCartAndDraft = checkIsSharedItemWasChangedAcrossCartAndDraft(editedItem, allItems, isLogoDimensionsModified);
  const filteredOldDraftItems = oldDraftItems.filter(draftItem => !items.find(i => String(draftItem._id) === String(i._id)));
  let itemsToProcess = [...filteredOldDraftItems, ...items];
  let newAsRelatedItemId;
  if (isSharedItemWasChangedAcrossCartAndDraft) {
    newAsRelatedItemId = v1();
  }
  if (editedItem) {
    itemsToProcess = itemsToProcess.map(item => {
      if (isSharedItem(editedItem, [item])) {
        var _editedItem$prod, _editedItem$prod2;
        return _objectSpread({}, item, getValuesToEditForSharedItems({
          editedItem,
          editedItemIsVariantDependentPrice: !!(editedItem !== null && editedItem !== void 0 && (_editedItem$prod = editedItem.prod) !== null && _editedItem$prod !== void 0 && _editedItem$prod.variantDependentPrice),
          editedItemIsSizeDependentPrice: !!(editedItem !== null && editedItem !== void 0 && (_editedItem$prod2 = editedItem.prod) !== null && _editedItem$prod2 !== void 0 && _editedItem$prod2.sizeDependentPrice),
          itemToMerge: item,
          newAsRelatedItemId
        }));
      } else {
        if (String(editedItem._id) === String(item._id) && newAsRelatedItemId) {
          return _objectSpread({}, item, {
            asRelatedItemId: newAsRelatedItemId
          });
        }
        return item;
      }
    });
  }
  if (Object.keys(deletedOrderItems).length > 0) {
    itemsToProcess = itemsToProcess.filter(i => !deletedOrderItems[i._id]);
  }
  return {
    draftItems: itemsToProcess,
    orderVersion,
    draftVersion,
    draftToken,
    userId: loggedInUserIdSelector(state)
  };
}
function transformEditDraftItemQuantity({
  draftToken: token,
  items = []
}, getState) {
  const state = getState();
  const draftToken = token !== null && token !== void 0 ? token : draftTokenSelector(state);
  const orderVersion = orderVersionSelector(state);
  const draftVersion = draftVersionSelector(state);
  const updatedItems = items.reduce((acc, item) => {
    var _item$variants$map, _item$variants;
    const totalQty = getTotalCount((_item$variants$map = (_item$variants = item.variants) === null || _item$variants === void 0 ? void 0 : _item$variants.map(v => getTotalCount((v === null || v === void 0 ? void 0 : v.quantities) || []) || 0)) !== null && _item$variants$map !== void 0 ? _item$variants$map : 0);
    if (totalQty > 0) {
      acc.push(item);
      return acc;
    }
    return acc;
  }, []);
  return {
    userId: loggedInUserIdSelector(state),
    draftToken,
    orderVersion,
    draftVersion,
    draftItems: updatedItems
  };
}
export const GET_RECENTLY_VIEWED_REQUEST_STARTED = `${PREFIX}GET_RECENTLY_VIEWED_REQUEST_STARTED`;
export const GET_RECENTLY_VIEWED_AND_SUGGESTED_REQUEST_SUCCESS = `${PREFIX}GET_RECENTLY_VIEWED_AND_SUGGESTED_REQUEST_SUCCESS`;
export const GET_RECENTLY_VIEWED_REQUEST_ERROR = `${PREFIX}GET_RECENTLY_VIEWED_REQUEST_ERROR`;
export const getRecentlyViewedAndSuggested = createAsyncAction({
  transformData: (_, getState) => {
    var _orderTokenSelector, _draftTokenSelector;
    const state = getState();
    const userId = userIdSelector(state);
    const orderToken = (_orderTokenSelector = orderTokenSelector(state)) !== null && _orderTokenSelector !== void 0 ? _orderTokenSelector : '';
    const draftToken = (_draftTokenSelector = draftTokenSelector(state)) !== null && _draftTokenSelector !== void 0 ? _draftTokenSelector : '';
    const data = {
      orderToken,
      draftToken
    };
    if (!userId) {
      data.products = getRecentlyViewedFromLS();
    }
    return data;
  },
  preFn: dispatch => {
    dispatch({
      type: GET_RECENTLY_VIEWED_REQUEST_STARTED
    });
  },
  fn: RecentlyViewedApi.getProducts,
  success: res => ({
    type: GET_RECENTLY_VIEWED_AND_SUGGESTED_REQUEST_SUCCESS,
    payload: res
  }),
  error: errorAction(GET_RECENTLY_VIEWED_REQUEST_ERROR)
});
export const TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_REQUEST = `${PREFIX}TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_REQUEST`;
export const TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_SUCCESS = `${PREFIX}TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_SUCCESS`;
export const TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_FAIL = `${PREFIX}TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_FAIL`;
export const transformSuperSpeedItemsToStandard = createAsyncAction({
  transformData: (_, getState) => {
    const state = getState();
    const cartItems = cartItemsSelector(state);
    const cartDraftItems = draftItemsSelector(state);
    const items = cartItems.map(item => {
      var _item$prod, _item$prod$productSet, _item$prod$productSet2;
      return isSuperSpeedItem(item) ? _objectSpread({}, item, {
        prodTime: getDefaultProductionTimeIdx((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$productSet = _item$prod.productSettings) === null || _item$prod$productSet === void 0 ? void 0 : (_item$prod$productSet2 = _item$prod$productSet[0]) === null || _item$prod$productSet2 === void 0 ? void 0 : _item$prod$productSet2.productionTimeList)
      }) : item;
    });
    const draftItems = cartDraftItems.map(item => {
      var _item$prod2, _item$prod2$productSe, _item$prod2$productSe2;
      return isSuperSpeedItem(item) ? _objectSpread({}, item, {
        prodTime: getDefaultProductionTimeIdx((_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : (_item$prod2$productSe = _item$prod2.productSettings) === null || _item$prod2$productSe === void 0 ? void 0 : (_item$prod2$productSe2 = _item$prod2$productSe[0]) === null || _item$prod2$productSe2 === void 0 ? void 0 : _item$prod2$productSe2.productionTimeList)
      }) : item;
    });
    const draftToken = draftTokenSelector(state);
    return _objectSpread({}, formatCart({
      items
    }, getState), {
      draftToken,
      draftItems
    });
  },
  preFn: dispatch => {
    dispatch({
      type: TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_REQUEST
    });
  },
  fn: OrderApi.updateCart,
  success: ({
    cart,
    draft,
    orderToken,
    draftToken
  }) => ({
    type: TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_SUCCESS,
    payload: {
      cart,
      draft,
      orderToken,
      draftToken
    }
  }),
  error: errorAction(TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_FAIL),
  postError: handleCartPostErrorPopup
});
export const RESELLER_PRE_BUILD_CART_SAVE_REQUEST = `${PREFIX}RESELLER_PRE_BUILD_CART_SAVE_REQUEST`;
export const RESELLER_PRE_BUILD_CART_SAVE_SUCCESS = `${PREFIX}RESELLER_PRE_BUILD_CART_SAVE_SUCCESS`;
export const RESELLER_PRE_BUILD_CART_SAVE_ERROR = `${PREFIX}RESELLER_PRE_BUILD_CART_SAVE_ERROR`;
export const saveResellerPreBuiltCart = createAsyncAction({
  preFn: dispatch => dispatch({
    type: RESELLER_PRE_BUILD_CART_SAVE_REQUEST
  }),
  fn: ResellerApi.exitResellerMode,
  success: body => ({
    type: RESELLER_PRE_BUILD_CART_SAVE_SUCCESS,
    payload: body.data
  }),
  error: error => ({
    type: RESELLER_PRE_BUILD_CART_SAVE_ERROR,
    error
  }),
  postError: (dispatch, getState, error) => {
    if (error.code === CartErrorCodes.INVALID_ORDER_VERSION) {
      return dispatch(setPopupParams({
        errorMessage: error.message
      }));
    }
    return dispatch(showNotification({
      text: error.message
    }));
  },
  postSuccess: (dispatch, getState, transformedData, res) => {
    return window.location.replace(res.redirectUrl);
  }
});
export const COMMENT_PRE_BUILT_LIMITED_CART_REQUEST = `${PREFIX}COMMENT_PRE_BUILT_LIMITED_CART_REQUEST`;
export const COMMENT_PRE_BUILT_LIMITED_CART_SUCCESS = `${PREFIX}COMMENT_PRE_BUILT_LIMITED_CART_SUCCESS`;
export const COMMENT_PRE_BUILT_LIMITED_CART_ERROR = `${PREFIX}COMMENT_PRE_BUILT_LIMITED_CART_ERROR`;
export const commentPreBuiltLimitedCartSave = createAsyncAction({
  preFn: dispatch => dispatch({
    type: COMMENT_PRE_BUILT_LIMITED_CART_REQUEST
  }),
  transformData: ({
    note,
    itemId
  }, getState) => {
    const {
      _id
    } = getState().cart;
    return {
      note,
      itemId,
      orderId: _id
    };
  },
  fn: OrderApi.commentPreBuiltLimitedCart,
  success: () => ({
    type: COMMENT_PRE_BUILT_LIMITED_CART_SUCCESS
  }),
  error: error => ({
    type: COMMENT_PRE_BUILT_LIMITED_CART_ERROR,
    error
  }),
  postError: (dispatch, getState, error) => {
    return dispatch(showNotification({
      text: error.message
    }));
  },
  postSuccess: dispatch => {
    return dispatch(showPopup({
      popup: COMMENT_PREBUILT_LIMITED_CART_SUCCESS_POPUP
    }));
  }
});
export const savePreBuiltCart = () => {
  document.location.replace('/api/v1/order/pre-built-cart/save');
};
export const exitAdminMode = () => {
  document.location.replace('/api/v1/order/pre-built-cart/exit');
};
export const handleSwagAdminModeCheckout = () => (dispatch, getState) => {
  const state = getState();
  const adminMode = adminModeSelector(state);
  if (adminMode === AdminMode.CreatingCart) {
    return dispatch(showPopup({
      popup: BUILD_A_CART_CREATE_LINK_POPUP
    }));
  }
  if (adminMode === AdminMode.EditingCart) {
    return savePreBuiltCart();
  }
};