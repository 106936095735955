import _cloneDeep from "lodash/cloneDeep";
import _isObject from "lodash/isObject";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { v4 } from 'uuid';
import { OrderApi } from 'swag-client-common/api/order.api';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { normalizePantoneName } from 'swag-common/utils/colors';
import { NON_VECTOR_IMAGE_UPLOAD_POPUP, SHOW_ERROR_POPUP, LOGO_UPLOAD_ERROR_TYPE, LOGO_UPLOAD_SIZE_LIMIT_ERROR_TYPE, MAX_PRINT_COLORS_LIMIT_EXCEEDED_POPUP } from 'swag-client-common/constants';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { showPopup, hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { DesignUploadMode } from 'swag-common/interfaces';
import { getShouldSkipNonVectorPopupAppearing } from 'swag-client-common/utils/local-storage.util';
import { updateLogoDetails, UPDATE_LOGO_DETAILS } from '../logo/logo.actions';
import { selectedLogoSelector } from '../logo/logo.selectors';
import { detectColors } from '../../utils/detect-colors';
import { PRINTING_METHODS } from '../product/product.constants';
import { changeEmbellishmentMethodWithMaxPrintColorsValidation, changeOpenedToolbarItem } from '../product/product.actions';
import hasPostscriptExtension from '../../utils/has-postscript-extension';
import { notValidatedEmbellishmentSelector } from '../product/product.selectors';
import { LOGO_HANDLING_STATUSES, COLORS_DETECTION_ERRORS } from './logo-editing.constants';
import { getColorsToPrint, getPrintingMethod } from './logo-editing.utils';
import { editingLogoIdSelector, hasDtgOptionSelector, maxPrintColorsAmountSelector, minimumColorsForDtgSelector } from './logo-editing.selectors';
const PREFIX = 'LOGO_EDITING_';
const WHITE_PANTONE = 'white c';
const WHITE_HEX = '#fff';
const checkIsProductWithLogoUploadType = product => {
  const {
    designUploadMode
  } = product;
  return designUploadMode === DesignUploadMode.logo || designUploadMode === DesignUploadMode.box;
};
const UPLOAD_FINISH_ANIMATION_DURATION_FULL = 450;
const UPLOAD_FINISH_ANIMATION_DURATION = 300;
const UPLOAD_CLOSE_ANIMATION_DURATION = 200;
export const continueProcessingFile = (file, logoView) => (dispatch, getState) => {
  processGeneralFile(file, logoView, getState, dispatch);
};
export const processGeneralFile = (file, logoView, getState, dispatch) => {
  const form = new FormData();
  const {
    product: {
      product,
      product: {
        designUploadMode
      },
      view
    }
  } = getState();
  const selectedView = logoView || view;
  const isProductWithLogoUploadType = checkIsProductWithLogoUploadType(product);
  const type = isProductWithLogoUploadType ? DesignUploadMode.logo : designUploadMode;
  form.append(type, file);
  form.append('view', selectedView);
  const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB
  if (Number(fileSize) > 10) {
    return dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: LOGO_UPLOAD_SIZE_LIMIT_ERROR_TYPE
    }));
  }
  if (/\.(png|jpeg|jpg|gif|eps|ai)$/i.test(file.name)) {
    window.URL.revokeObjectURL(file);
    dispatch(toggleUploadingOverlay(true));
    dispatch(changeOpenedToolbarItem(null));
    if (isProductWithLogoUploadType) {
      return dispatch(uploadLogo(form));
    }
    return dispatch(uploadDesign(form));
  }
};
export const handleLogoUploadRequest = (file, view) => (dispatch, getState) => {
  const skipNonVectorPopupAppearing = getShouldSkipNonVectorPopupAppearing();
  if (!file) {
    return;
  }
  if (!hasPostscriptExtension(file) && !skipNonVectorPopupAppearing) {
    return dispatch(showPopup({
      popup: NON_VECTOR_IMAGE_UPLOAD_POPUP,
      file,
      view
    }));
  }
  processGeneralFile(file, view, getState, dispatch);
};
let progressInterval;
export const LOGO_UPLOAD_FAILED = `${PREFIX}LOGO_UPLOAD_FAILED`;
export const LOGO_UPLOAD_STARTED = `${PREFIX}LOGO_UPLOAD_STARTED`;
export const uploadLogoFail = error => dispatch => {
  clearInterval(progressInterval);
  dispatch(errorAction(LOGO_UPLOAD_FAILED)(error));
  dispatch(showPopup({
    popup: SHOW_ERROR_POPUP,
    type: LOGO_UPLOAD_ERROR_TYPE
  }));
  dispatch(toggleUploadingOverlay(false));
  dispatch(finishUploadLogo());
};
export const LOGO_UPLOAD_STATUS_CHANGE = `${PREFIX}LOGO_UPLOAD_STATUS_CHANGE`;
export const uploadLogo = createAsyncAction({
  preFn: (dispatch, getState, data) => {
    dispatch({
      type: LOGO_UPLOAD_STARTED,
      payload: {
        location: data.get('view')
      }
    });
    let progress = 0;
    dispatch({
      type: LOGO_UPLOAD_STATUS_CHANGE,
      payload: {
        progress: progress,
        status: LOGO_HANDLING_STATUSES.UPLOADING_IMAGE
      }
    });
    progressInterval = setInterval(() => {
      progress += 0.2;
      if (progress > 8) {
        clearInterval(progressInterval);
      }
      dispatch({
        type: LOGO_UPLOAD_STATUS_CHANGE,
        payload: {
          progress: progress,
          status: LOGO_HANDLING_STATUSES.UPLOADING_IMAGE
        }
      });
    }, 100);
  },
  // @ts-ignore
  progress: dispatch => {
    dispatch({
      type: LOGO_UPLOAD_STATUS_CHANGE,
      payload: {
        progress: 15,
        status: LOGO_HANDLING_STATUSES.UPLOADING_IMAGE
      }
    });
  },
  fn: OrderApi.convertLogo,
  success: body => (dispatch, getState) => {
    clearInterval(progressInterval);
    processUploadedLogo(body, dispatch, getState);
  },
  error: uploadLogoFail
});
export const DESIGN_UPLOAD_STARTED = `${PREFIX}DESIGN_UPLOAD_STARTED`;
export const DESIGN_UPLOAD_SUCCESS = `${PREFIX}DESIGN_UPLOAD_SUCCESS`;
export const DESIGN_UPLOAD_FAILED = `${PREFIX}DESIGN_UPLOAD_FAILED`;
export const uploadDesign = createAsyncAction({
  preFn: (dispatch, getState, data) => {
    dispatch({
      type: DESIGN_UPLOAD_STARTED,
      payload: {
        location: data.get('view')
      }
    });
    let progress = 0;
    dispatch({
      type: LOGO_UPLOAD_STATUS_CHANGE,
      payload: {
        progress: progress,
        status: LOGO_HANDLING_STATUSES.LOADING
      }
    });
    progressInterval = setInterval(() => {
      progress += 1;
      if (progress > 98) {
        clearInterval(progressInterval);
      }
      dispatch({
        type: LOGO_UPLOAD_STATUS_CHANGE,
        payload: {
          progress: progress,
          status: LOGO_HANDLING_STATUSES.LOADING
        }
      });
    }, 100);
  },
  fn: OrderApi.uploadDesign,
  success: designData => ({
    type: DESIGN_UPLOAD_SUCCESS,
    payload: designData
  }),
  postSuccess: dispatch => {
    clearInterval(progressInterval);
    dispatch({
      type: LOGO_UPLOAD_STATUS_CHANGE,
      payload: {
        progress: 100,
        isSuccess: true,
        status: LOGO_HANDLING_STATUSES.SUCCESS
      }
    });
    setTimeout(() => {
      dispatch(toggleUploadingOverlay(false));
      dispatch(finishUploadLogo());
    }, 400);
    setTimeout(() => {
      dispatch(showNotification({
        text: 'Your design uploaded successfully!',
        hideIn: 6000
      }));
    }, 1000);
  },
  error: error => dispatch => {
    clearInterval(progressInterval);
    dispatch(errorAction(DESIGN_UPLOAD_FAILED)(error));
    dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: LOGO_UPLOAD_ERROR_TYPE
    }));
    dispatch(toggleUploadingOverlay(false));
  }
});
export const COLORS_DETECTED = `${PREFIX}COLORS_DETECTED`;
const handleLogoColorDetected = ({
  detectedColors,
  hasBackground
}) => (dispatch, getState) => {
  const state = getState();
  const hasDtgOption = hasDtgOptionSelector(state);
  const minimumColorsForDtg = minimumColorsForDtgSelector(state);
  const productMaxPrintColors = maxPrintColorsAmountSelector(state);
  const isBackgroundRemoved = hasBackground;
  const selectedColors = isBackgroundRemoved ? detectedColors.filter(color => color.pms !== WHITE_PANTONE) : detectedColors;
  const selectedColorsNumber = selectedColors.length;
  dispatch({
    type: COLORS_DETECTED,
    detectedColors,
    selectedColors,
    hasBackground,
    isBackgroundRemoved
  });
  if (hasDtgOption && selectedColorsNumber >= minimumColorsForDtg) {
    dispatch(showPopup({
      popup: 'confirmDtgPopup'
    }));
  } else if (!!productMaxPrintColors && selectedColorsNumber > productMaxPrintColors) {
    dispatch(showPopup({
      popup: MAX_PRINT_COLORS_LIMIT_EXCEEDED_POPUP
    }));
  } else {
    dispatch(handlePantoneSelectionAction({
      isConfirmedByCustomer: false
    }));
  }
  dispatch(toggleUploadingOverlay(false));
};
export const LOGO_UPLOAD = `${PREFIX}LOGO_UPLOAD`;
export const COLOR_DETECTION_STARTED = `${PREFIX}COLOR_DETECTION_STARTED`;
export const processUploadedLogo = ({
  original,
  originalPreview,
  previewWOBackground,
  customerLogoOriginalName,
  id,
  view,
  etag
}, dispatch, getState) => {
  const state = getState();
  const hasDtgOption = hasDtgOptionSelector(state);
  const notUpdatedEmbellishmentMethod = notValidatedEmbellishmentSelector(state);
  const editingLogoId = editingLogoIdSelector(state);
  const idToUse = notUpdatedEmbellishmentMethod ? editingLogoId : id;
  dispatch({
    type: LOGO_UPLOAD,
    payload: {
      original,
      preview: originalPreview,
      previewWOBackground,
      customerLogoOriginalName,
      id: idToUse,
      location: view,
      etag
    }
  });
  dispatch({
    type: COLOR_DETECTION_STARTED
  });
  detectColors({
    filePath: originalPreview,
    filterPhotos: hasDtgOption,
    onProgress: ({
      progress,
      status
    }) => {
      dispatch({
        type: LOGO_UPLOAD_STATUS_CHANGE,
        payload: {
          progress,
          status
        }
      });
    },
    onSuccess: body => dispatch(handleLogoColorDetected(body)),
    onError: ({
      error
    }) => dispatch(handleColorsDetectionError({
      error
    }))
  });
};
export const CHANGE_COLOR_LOGO = `${PREFIX}CHANGE_COLOR_LOGO`;
export const CHANGE_COLOR_LOGO_SUCCESS = `${PREFIX}CHANGE_COLOR_LOGO_SUCCESS`;
export const CHANGE_COLOR_LOGO_FAILURE = `${PREFIX}CHANGE_COLOR_LOGO_FAILURE`;

/**
 * Handles click on color picker for transforming preview to specified color
 *
 * @param color
 */
export const convertLogoToOneColor = createAsyncAction({
  transformData: (color, getState) => {
    const {
      hasBackground,
      previewWOBackground,
      originalPreview
    } = getState().logoEditing;
    const filePath = hasBackground ? previewWOBackground : originalPreview;
    return {
      filePath,
      color
    };
  },
  preFn: (dispatch, getState, data) => {
    const pantone = data.pms;
    const {
      logoEditing: {
        previewsConvertedToOneColor
      }
    } = getState();
    if (previewsConvertedToOneColor[pantone]) {
      dispatch({
        type: CHANGE_COLOR_LOGO_SUCCESS,
        payload: {
          convertTo: data,
          preview: previewsConvertedToOneColor[pantone]
        }
      });
      return true;
    }
    dispatch({
      type: CHANGE_COLOR_LOGO
    });
  },
  fn: OrderApi.changeColorLogo,
  success: (body, data) => dispatch => {
    const {
      color
    } = data;
    dispatch({
      type: CHANGE_COLOR_LOGO_SUCCESS,
      payload: _objectSpread({
        convertTo: color
      }, body)
    });
  },
  error: errorAction(CHANGE_COLOR_LOGO_FAILURE)
});
export const REMOVE_BACKGROUND = `${PREFIX}REMOVE_BACKGROUND`;
export const REMOVE_BACKGROUND_REVERT = `${REMOVE_BACKGROUND}_REVERT`;
export const onRemoveBackgroundClick = (e, shouldRemove) => dispatch => {
  if (shouldRemove) {
    dispatch(removeColor(WHITE_PANTONE));
    dispatch({
      type: REMOVE_BACKGROUND
    });
  } else {
    dispatch(addColor({
      pms: WHITE_PANTONE,
      hex: WHITE_HEX
    }));
    dispatch({
      type: REMOVE_BACKGROUND_REVERT
    });
  }
};
export const REVERT_TO_ORIGINAL_PREVIEW = `${PREFIX}REVERT_TO_ORIGINAL_PREVIEW`;
export const revertToOriginalPreview = () => ({
  type: REVERT_TO_ORIGINAL_PREVIEW
});
export const updateSelectedLogo = () => (dispatch, getState) => {
  const state = getState();
  const {
    logoEditing: {
      selectedColors,
      detectedColors,
      preview,
      convertTo,
      originalPreview,
      isBackgroundRemoved,
      hasBackground,
      previewWOBackground,
      previewsConvertedToOneColor,
      etag
    },
    product: {
      product: {
        hasDtgOption,
        minimumColorsForDtg
      },
      view
    },
    logo: {
      logos
    }
  } = state;
  const selectedLogo = selectedLogoSelector(state);
  const colorsToPrint = getColorsToPrint({
    convertTo,
    selectedColors
  });
  const printingMethod = getPrintingMethod({
    colorsToPrint,
    hasDtgOption,
    minimumColorsForDtg
  });
  const logoDetails = {
    colorsToPrint,
    detectedColors,
    selectedColors,
    original: logos[selectedLogo.id].original,
    originalPreview,
    convertTo,
    isBackgroundRemoved,
    hasBackground,
    printingMethod,
    preview,
    previewWOBackground,
    previewsConvertedToOneColor,
    originalFileName: logos[selectedLogo.id].originalFileName,
    id: selectedLogo.id,
    location: view,
    etag
  };
  dispatch({
    type: UPDATE_LOGO_DETAILS,
    payload: _objectSpread({}, logoDetails)
  });
  dispatch(hidePopup());
};

/**
 * Handles confirm after specifying pantone colors
 */
export const PANTONES_CONFIRMED = `${PREFIX}PANTONES_CONFIRMED`;
export const onPantoneSelectionConfirm = () => dispatch => {
  dispatch(hidePopup());
  dispatch(updateSelectedLogo());
  dispatch({
    type: PANTONES_CONFIRMED
  });
};
export const PANTONES_DECISION_POSTPONED = `${PREFIX}PANTONES_DECISION_POSTPONED`;
export const onPantoneSelectionSkip = () => dispatch => {
  dispatch({
    type: PANTONES_DECISION_POSTPONED
  });
  dispatch(hidePopup());
};
export const FIRST_COLORS_SELECTION = `${PREFIX}FIRST_COLORS_SELECTION`;
const handlePantoneSelectionAction = ({
  isConfirmedByCustomer,
  isColorsModified = false
}) => (dispatch, getState) => {
  const state = getState();
  const {
    logoEditing: {
      logoHandlingProgress
    }
  } = state;
  !!logoHandlingProgress && dispatch({
    type: LOGO_UPLOAD_STATUS_CHANGE,
    payload: {
      progress: 100,
      status: LOGO_HANDLING_STATUSES.FINISHED
    }
  });
  setTimeout(() => {
    const {
      logoEditing: {
        selectedColors,
        detectedColors,
        preview,
        convertTo,
        original,
        originalPreview,
        isBackgroundRemoved,
        hasBackground,
        previewWOBackground,
        previewsConvertedToOneColor,
        neverSelectedColors,
        originalFileName,
        id,
        location,
        etag
      },
      product: {
        product: {
          hasDtgOption,
          minimumColorsForDtg
        },
        view
      },
      logo: {
        logos
      }
    } = state;
    const selectedLogo = selectedLogoSelector(state);
    const colorsToPrint = getColorsToPrint({
      convertTo,
      selectedColors
    });
    const printingMethod = getPrintingMethod({
      colorsToPrint,
      hasDtgOption,
      minimumColorsForDtg
    });
    !!logoHandlingProgress && dispatch(finishUploadLogo());
    setTimeout(() => {
      var _logos$original, _logos, _selectedLogo$id, _logos$originalFileNa, _logos2, _selectedLogo$id2;
      const logoDetails = {
        colorsToPrint,
        detectedColors,
        selectedColors,
        original: original || ((_logos$original = (_logos = logos[(_selectedLogo$id = selectedLogo === null || selectedLogo === void 0 ? void 0 : selectedLogo.id) !== null && _selectedLogo$id !== void 0 ? _selectedLogo$id : 0]) === null || _logos === void 0 ? void 0 : _logos.original) !== null && _logos$original !== void 0 ? _logos$original : ''),
        originalPreview,
        convertTo,
        isConfirmedByCustomer,
        isBackgroundRemoved,
        hasBackground,
        printingMethod,
        preview,
        previewWOBackground,
        previewsConvertedToOneColor,
        originalFileName: originalFileName || ((_logos$originalFileNa = (_logos2 = logos[(_selectedLogo$id2 = selectedLogo === null || selectedLogo === void 0 ? void 0 : selectedLogo.id) !== null && _selectedLogo$id2 !== void 0 ? _selectedLogo$id2 : 0]) === null || _logos2 === void 0 ? void 0 : _logos2.originalFileName) !== null && _logos$originalFileNa !== void 0 ? _logos$originalFileNa : ''),
        id: id || (selectedLogo === null || selectedLogo === void 0 ? void 0 : selectedLogo.id) || v4(),
        location: location || view,
        isColorsModified,
        etag
      };
      dispatch(updateLogoDetails(logoDetails));
      if (neverSelectedColors) {
        dispatch({
          type: FIRST_COLORS_SELECTION
        });
      }
      const notUpdatedEmbellishmentMethod = notValidatedEmbellishmentSelector(state);
      if (notUpdatedEmbellishmentMethod) {
        dispatch(changeEmbellishmentMethodWithMaxPrintColorsValidation(notUpdatedEmbellishmentMethod));
      }
    }, UPLOAD_FINISH_ANIMATION_DURATION_FULL);
  }, 200);
};
export const handlePantoneSelectionConfirm = () => dispatch => {
  dispatch(handlePantoneSelectionAction({
    isConfirmedByCustomer: true,
    isColorsModified: true
  }));
  dispatch({
    type: PANTONES_CONFIRMED
  });
  dispatch(hidePopup());
};
export const editColors = viewToEdit => (dispatch, getState) => {
  const view = viewToEdit || getState().product.view;
  dispatch(setDataToProcess(view));
  dispatch(showPopup({
    popup: 'selectPantonesPopup'
  }));
};
export const SET_DATA_TO_PROCESS = `${PREFIX}SET_DATA_TO_PROCESS`;
export const setDataToProcess = logo => (dispatch, getState) => {
  const selectedLogo = _isObject(logo) ? logo : selectedLogoSelector(getState());
  return dispatch({
    type: SET_DATA_TO_PROCESS,
    payload: _objectSpread({}, selectedLogo, {
      colors: _cloneDeep(selectedLogo.colors)
    })
  });
};
export const toolbarConvertToColor = () => (dispatch, getState) => {
  const view = getState().product.view;
  dispatch(setDataToProcess(view));
};
export const RESET_LOGOS = `${PREFIX}RESET_LOGOS`;
export const resetLogos = () => ({
  type: RESET_LOGOS
});
export const handleUploadNewLogo = (file, view) => dispatch => {
  dispatch(hidePopup());
  dispatch(handleLogoUploadRequest(file, view));
};
export const CHANGE_PRINTING_METHOD = `${PREFIX}CHANGE_PRINING_METHODS`;
export const confirmDtg = () => dispatch => {
  dispatch(hidePopup());
  dispatch({
    type: CHANGE_PRINTING_METHOD,
    payload: PRINTING_METHODS.DTG
  });
  dispatch(handlePantoneSelectionAction({
    isConfirmedByCustomer: true
  }));
};
export const COLORS_DETECTED_ERROR = `${PREFIX}COLORS_DETECTED_ERROR`;
const handleColorsDetectionError = ({
  error
}) => dispatch => {
  dispatch(hidePopup());
  if (error === COLORS_DETECTION_ERRORS.TOO_MANY_COLORS) {
    dispatch(showPopup({
      popup: 'confirmDtgPopup'
    }));
  } else {
    dispatch({
      type: COLORS_DETECTED_ERROR
    });
    dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: LOGO_UPLOAD_ERROR_TYPE
    }));
    dispatch(toggleUploadingOverlay(false));
  }
};
export const REMOVE_COLOR = `${PREFIX}REMOVE_COLOR`;
export const removeColorHandler = pms => ({
  type: REMOVE_COLOR,
  payload: {
    pms: pms.toLowerCase()
  }
});
export const removeColor = pms => dispatch => {
  dispatch(showAnimationFrameNotification());
  dispatch(removeColorHandler(pms));
};
export const ADD_COLOR = `${PREFIX}ADD_COLOR`;
export const addColorHandler = color => ({
  type: ADD_COLOR,
  payload: {
    color: _objectSpread({}, color, {
      pms: color.pms.toLowerCase()
    })
  }
});
export const addColor = color => (dispatch, getState) => {
  const {
    selectedColors
  } = getState().logoEditing;
  const normalizedPantone = normalizePantoneName(color.pms);
  if (selectedColors.some(c => c.pms === normalizedPantone)) {
    return;
  }
  dispatch(showAnimationFrameNotification());
  dispatch(addColorHandler(color));
};
export const SHOW_NOTIFICATION = `${PREFIX}SHOW_NOTIFICATION`;
export const HIDE_NOTIFICATION = `${PREFIX}HIDE_NOTIFICATION`;
const NOTIFICATION_TIME = 7000;
let timeoutId;
export const showAnimationFrameNotification = () => dispatch => {
  window.requestAnimationFrame(() => {
    dispatch({
      type: SHOW_NOTIFICATION
    });
  });
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    window.requestAnimationFrame(() => {
      dispatch({
        type: HIDE_NOTIFICATION
      });
    });
  }, NOTIFICATION_TIME);
};
const finishUploadLogo = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: LOGO_UPLOAD_STATUS_CHANGE,
      payload: {
        progress: 100,
        status: LOGO_HANDLING_STATUSES.CLOSE
      }
    });
    setTimeout(() => {
      dispatch({
        type: LOGO_UPLOAD_STATUS_CHANGE,
        payload: {
          progress: 0,
          status: ''
        }
      });
    }, UPLOAD_FINISH_ANIMATION_DURATION);
  }, UPLOAD_CLOSE_ANIMATION_DURATION);
};
export const cancelUploadingAnimation = () => dispatch => {
  dispatch(toggleUploadingOverlay(false));
  setTimeout(() => {
    dispatch({
      type: LOGO_UPLOAD_STATUS_CHANGE,
      payload: {
        progress: 0,
        status: LOGO_HANDLING_STATUSES.UPLOADING_IMAGE
      }
    });
    setTimeout(() => {
      dispatch({
        type: LOGO_UPLOAD_STATUS_CHANGE,
        payload: {
          progress: 0,
          status: ''
        }
      });
    }, UPLOAD_FINISH_ANIMATION_DURATION);
  }, UPLOAD_CLOSE_ANIMATION_DURATION);
};
export const LOGO_UPLOAD_OVERLAY = `${PREFIX}LOGO_UPLOAD_OVERLAY`;
export const toggleUploadingOverlay = show => dispatch => {
  dispatch({
    type: LOGO_UPLOAD_OVERLAY,
    payload: {
      show
    }
  });
};
export const resolveColorConflict = id => (dispatch, getState) => {
  const {
    logo: {
      logos
    }
  } = getState();
  const logo = Object.values(logos).find(({
    location
  }) => location.includes(id));
  if (!logo) {
    return;
  }
  dispatch(setDataToProcess(logo));
};