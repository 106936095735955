export function getLocationsDataFromProductSettings({
  productSettings,
  variantId,
  embellishmentMethodId
}) {
  const currentSettings = productSettings.find(settings => String(settings.embellishmentMethodId) === embellishmentMethodId);
  if (!currentSettings) {
    return {};
  }
  return currentSettings.locations[variantId] || {};
}