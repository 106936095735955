import _uniq from "lodash/uniq";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import getSetupFeeDeductionDiscount from 'swag-common/business-logic/order/get-setup-fee-deduction-discount.logic';
import { filterSharedItemsForDiscountCalculation } from 'swag-common/business-logic/order/calculate-sample-deduction-discount.logic';
import isFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { isItemBoxSpecialNotecard } from 'swag-common/utils/order/item-is-box.util';
import { PRODUCTION_MOCKUP_STATUSES } from 'swag-common/constants/order-statuses.constants';
import { isProductOutOfStock } from '../product/product.selectors';
import { getProductVariantsToChoose } from '../product/product.utils';
import { PERIODS } from './account-orders.constants';
export const mapDateToMilliseconds = orders => orders.map(order => _objectSpread({}, order, {
  confirmedAt: new Date(order.confirmedAt).getTime()
}));
export const filterOrdersByPeriod = (orders, period) => {
  const currentDate = new Date().getTime();
  const edgeDate = currentDate - period.value;
  return orders.filter(order => order.confirmedAt > edgeDate);
};
export const filterOrdersByLvl = (orders, lvl) => {
  return orders.filter(order => lvl.orders ? lvl.orders.find(o => o.orderId === order._id) : true);
};
export const filterOrdersByPeriodAndLvl = (orders, period, lvl) => {
  return filterOrdersByLvl(filterOrdersByPeriod(orders, period), lvl);
};
export const getInitialPeriod = orders => {
  const currentDate = new Date().getTime();
  const latestOrder = Math.max(...orders.map(order => order.confirmedAt));
  const latestOrderAge = currentDate - latestOrder;
  return PERIODS.find(period => period.value >= latestOrderAge);
};
export const sortOrdersByTime = orders => orders.sort((order, nextOrder) => order.confirmedAt < nextOrder.confirmedAt ? 1 : -1);
export const prepareItemToReorder = item => {
  const {
    logos,
    prod,
    designNotes,
    itemSku,
    discount,
    prodId,
    price,
    variants,
    quantity,
    sizeInd,
    prodTime,
    isSample,
    isPrintedSample,
    embellishmentMethod,
    asRelatedItemId,
    attachments,
    adminAttachments,
    texts,
    readyForPromo,
    _id,
    reorderItemChainId,
    isReorder,
    initialCreationDate,
    setupFeeDeductionDiscount,
    products,
    boxColor,
    variantInd,
    boxItemsId,
    itemId,
    quantityPerBox,
    shipmentCharges,
    serviceCode,
    customProductId,
    customProductCreated,
    colors,
    isCustomizedBlank,
    isBoxNotecardAbsent,
    name,
    sendosoSKUs,
    notecardDesignData
  } = item;
  return {
    logos,
    prod,
    prodId,
    asRelatedItemId,
    designNotes,
    discount,
    variants,
    quantity,
    sizeInd,
    prodTime,
    initialCreationDate,
    itemSku,
    isSample,
    isPrintedSample,
    price,
    embellishmentMethod,
    texts,
    attachments,
    readyForPromo,
    itemId: _id || itemId,
    reorderItemChainId: reorderItemChainId || _id || itemId,
    isReorder,
    setupFeeDeductionDiscount,
    adminAttachments,
    products,
    boxColor,
    variantInd,
    boxItemsId,
    quantityPerBox,
    shipmentCharges,
    serviceCode,
    customProductId,
    customProductCreated,
    colors,
    isCustomizedBlank,
    isBoxNotecardAbsent,
    name,
    sendosoSKUs,
    notecardDesignData
  };
};
export const getListOfSampleDeductionDiscounts = items => {
  const filteredWithoutSharedItems = filterSharedItemsForDiscountCalculation(items);
  return filteredWithoutSharedItems.reduce((list, item) => {
    const {
      prod,
      sampleDeductionDiscount
    } = item;
    if (sampleDeductionDiscount) {
      return [...list, {
        productName: (prod === null || prod === void 0 ? void 0 : prod.name) || '',
        discount: sampleDeductionDiscount
      }];
    }
    return list;
  }, []);
};
export const isItemExistInCartWithForPromo = (cartItems, reorderItem) => cartItems.some(itemFromCart => itemFromCart.reorderId === reorderItem.reorderId && itemFromCart.itemId === reorderItem.itemId && itemFromCart.readyForPromo && reorderItem.readyForPromo);
export const mapSamplePromoItemsFromCart = items => {
  const samplePromoItemsInCart = {};
  items.forEach(item => {
    if (item.readyForPromo && item.sampleDeductionDiscount) {
      samplePromoItemsInCart[item.itemId] = item.itemId;
    }
  });
  return samplePromoItemsInCart;
};
export const getColumnSpan = (defaultColumnSpan, isToFulfillmentCenter) => {
  return defaultColumnSpan + (isToFulfillmentCenter ? 1 : 0);
};
export const getApprovedProductionMockup = (productionMockups = []) => {
  const approvedProductionMockups = productionMockups.filter(i => i.status === PRODUCTION_MOCKUP_STATUSES.APPROVED_BY_CUSTOMER) || [];
  const {
    length
  } = approvedProductionMockups;
  return length ? approvedProductionMockups[length - 1] : null;
};
export const getBoxItemToReorder = (boxItemsId = null, {
  notecardQuantity,
  reorderId
} = {
  notecardQuantity: null,
  reorderId: null
}) => item => {
  const itemToPrepare = _objectSpread({}, item, {
    isReorder: true
  });
  if (isItemBoxSpecialNotecard(item) && notecardQuantity) {
    itemToPrepare.quantity = notecardQuantity;
  }
  const itemToReorder = _objectSpread({}, prepareItemToReorder(itemToPrepare), {
    setupFeeDeductionDiscount: getSetupFeeDeductionDiscount(item),
    customerMockups: item.customerMockups,
    reorderId
  });
  if (boxItemsId) {
    itemToReorder.boxItemsId = boxItemsId;
  }
  if (!itemToReorder.customProductId) {
    delete itemToReorder.customProductId;
    delete itemToReorder.customProductCreated;
  }
  return itemToReorder;
};
export const getPossibleSizes = ({
  variants,
  prod
}) => {
  var _prod$size;
  if (!(prod !== null && prod !== void 0 && (_prod$size = prod.size) !== null && _prod$size !== void 0 && _prod$size.options)) {
    return [];
  }
  return _uniq(variants.reduce((acc, {
    quantities
  }) => {
    prod.size.options.forEach((size, index) => {
      const quantity = quantities[index];
      if (quantity) {
        acc.push(size);
      }
    });
    return acc;
  }, []));
};
export const checkIfItemAvailableForReorder = product => {
  if (product.hidden) {
    return false;
  }
  const productVariants = getProductVariantsToChoose(product);
  const isFullDesignProduct = isFullDesign(product);
  const isOutOfStock = isProductOutOfStock(productVariants, isFullDesignProduct, product);
  return !isOutOfStock;
};
export const getItemsAvailableForReorder = products => {
  const availableItems = [];
  const unavailableItems = [];
  products.forEach(product => {
    if (checkIfItemAvailableForReorder(product)) {
      availableItems.push(product);
    } else {
      unavailableItems.push(product);
    }
  });
  const isReorderAvailable = Boolean(availableItems.length);
  return {
    availableItems,
    unavailableItems,
    isReorderAvailable
  };
};
export const removeUnavailableItems = (boxItem, unavailableItems = []) => {
  var _boxItem$products;
  const itemsToRemoveIds = unavailableItems.map(item => String(item._id));
  const filteredBoxProducts = (_boxItem$products = boxItem.products) === null || _boxItem$products === void 0 ? void 0 : _boxItem$products.filter(item => {
    var _item$prod;
    return !itemsToRemoveIds.includes(String(item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod._id));
  });
  return _objectSpread({}, boxItem, {
    products: filteredBoxProducts
  });
};
export const getRealItemsInsideBoxProdIds = boxItem => {
  const {
    products = []
  } = boxItem;
  return products.reduce((acc, item) => {
    var _item$prod2;
    if (!(item !== null && item !== void 0 && (_item$prod2 = item.prod) !== null && _item$prod2 !== void 0 && _item$prod2.specialProductType)) {
      acc.push(item.prodId);
    }
    return acc;
  }, []);
};
export const preparePaginationData = pagination => {
  const {
    page,
    perPage,
    total
  } = pagination;
  const lastPage = total ? Math.ceil(total / perPage) : 1;
  const shouldPaginationShow = Boolean(total && lastPage > 1);
  const prevPage = page === 1 ? 0 : page - 1;
  const nextPage = page === lastPage ? 0 : page + 1;
  const totalPages = total ? Math.ceil(total / perPage) : 1;
  return {
    page,
    perPage,
    total,
    prevPage,
    nextPage,
    shouldPaginationShow,
    totalPages
  };
};
export const getRelatedItems = items => {
  const relatedItems = items.reduce((acc, item) => {
    if (item.asRelatedItemId && !item.boxItemsId) {
      return _objectSpread({}, acc, {
        [item.asRelatedItemId]: (acc[item.asRelatedItemId] || []).concat(item)
      });
    }
    return acc;
  }, {});
  return Object.values(relatedItems).filter(item => item.length >= 2).flat().map(item => item._id);
};